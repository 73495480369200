import React , { Component } from 'react'
import Link from '@mui/material/Link';
import './form.css';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme  , ThemeProvider } from '@mui/material/styles';
import {  Widget } from './components/Widget';
import PortalLogin from "./PortalLogin"
import Page from './components/Page';
import Overlay from './components/Overlay';
import Theme from './components/PortalTheme'
import './widget.css';
import './form.css';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import useToken from './useToken';

const widgetRef = React.createRef();
function emailIsValid(email) {
    
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}



//const theme = createTheme();
class Login extends Component {
    
    state = {
        username: '',
        password: '',
        email: '',
        phonenumber:'',
        otp:'',
        showLoadingError: false,
        showPassword: true,
        error: null,
        success: null,
        showAdminLoginForm:false,
        showForgotPasswordForm: false,
        showOTPLoginForm : false,
        disableSubmit: false,
        disableUserSubmit : false,
        disableForgotPasswordSubmit: false,
        disableOTPSubmit : false,
        emailValidation: '',
        passwordValidation: '',
        isWebViewer : false,
    };
    componentDidMount() {
        let config;
        const { t } = this.props;
        const { showAdLogin,isWebViewer} = this.props;
        this.state.showAdminLoginForm = showAdLogin;
        this.state.isWebViewer = this.isWebViewer;  

    }
    
    componentWillUnmount() {
        window.removeEventListener('keydown', this.validateKeyDown);
    }

    handleChangeUsername = e => this.setState({username: e.target.value, emailValidation: '' });

    
    errorMsgWithLink = () => {
        const {t} = this.props;
        const incorrectEmailPart1 = t('Error.IncorrectEmailProvided.part1');
        const incorrectEmailPart2 = t('Error.IncorrectEmailProvided.part2');
        const onairClassicLoginUrl = ``;
        return (
            <span>
                {incorrectEmailPart1}
                <Link
                    id="button-incorrect-email"
                    href={onairClassicLoginUrl}
                >
                    
                </Link>
                {incorrectEmailPart2}
            </span>
        )
    }

    handleBlurEmail = e => {
        const {showLoadingError} = this.state;
        if (showLoadingError) {
            this.setState({showLoadingError: false});
            return;
        }
        const email = e.target.value.trim();
        if (!email) {
            return;
        }
        if (emailIsValid(email)) {
            this.setState({
                disableSubmit: false,
                error: null
            });
        } else {
            this.setState({
                disableSubmit: true,
                error: this.errorMsgWithLink()
            });
        }
    }

    handleChangePassword = e => this.setState({ password: e.target.value, passwordValidation: '' });

    handleChangeEmail = e => this.setState({ email: e.target.value });

    handlePhoneNumberChange = e => this.setState({ phonenumber: e.target.value });

    handleChangeOTP = e => this.setState({ otp: e.target.value });

    toggleShowPassword = () =>
        this.setState(state => ({ showPassword: !state.showPassword }));

    handleShowForgotPassword = () => {
        const { error, success } = this.state;
        if (error != null) {
            // reset error message
            this.setState({ error: null });
        }
        if (success != null) {
            // reset success message
            this.setState({ success: null });
        }
        // show/hide form
        this.setState(state => ({
            showForgotPasswordForm: !state.showForgotPasswordForm,
        }));
    };

    handlePreviousClick = () => {
        const { error, success } = this.state;
        if (error != null) {
            // reset error message
            this.setState({ error: null });
        }
        if (success != null) {
            // reset success message
            this.setState({ success: null });
        }

        this.setState(state => ({
            showOTPLoginForm: !state.showOTPLoginForm,
        }));
    }

    handleAdminLogin = () => {
        const { error, success } = this.state;
        if (error != null) {
            // reset error message
            this.setState({ error: null });
        }
        if (success != null) {
            // reset success message
            this.setState({ success: null });
        }

        this.setState(state => ({
            showAdminLoginForm: !state.showAdminLoginForm,
        }));
    }
    validateUserPhoneNumber = evt =>{
        evt.preventDefault();
        const {phonenumber} = this.state;
        const {t} = this.props;
        let phoneNumberIsValid = phonenumber !== '';
        ////console.log('phone number'+phoneNumberIsValid);
        if (phoneNumberIsValid) {
            if(phonenumber.length != 10){
                this.setState({ error: t('Error.MisDigitPhoneNumber') });
            }else{
              /*  if(phonenumber == token.phone){
                    this.loginwithoutotp();
                }else{*/
                    this.userlogin();
                //}
               
            }
        }else {
            this.setState({ error: t('Error.MissingPhoneNumber') });
        }

    }
    validateOTP = evt => {
        evt.preventDefault();
        const {otp} = this.state;
        
        const {t} = this.props;
        let otpIsValid = otp !== '';
        ////console.log('phone number'+phoneNumberIsValid);
        if (otpIsValid) {
            this.otplogin();
        }else {
            this.setState({ error: t('Error.MissingOTP') });
        }
    }

    validateUsernameAndPassword = evt => {
        evt.preventDefault();
        const { username, password } = this.state;
        const {token} = this.props;
        const { t } = this.props;
        let usernameIsValid = username !== '';
        let passwordIsValid = password !== '';
        let checkemail = '';
        let checkpassword = '';
        //console.log('token is '+JSON.stringify(token));
        if(!token){

        }
        else{
             checkemail = token.email !== '';
             checkpassword = token.password !== '';
        }
       
       ////console.log(token.email);
        if (usernameIsValid && passwordIsValid) {
            //console.log('usernameIsValid');
            if(checkemail && checkpassword){
                //console.log('checkemail');
                if(username == token.email){
                    //console.log('loginwithoutotp');
                    this.loginwithoutotp();
                }else{
                this.login();
                }
            }else{
                this.login();
            }
        } else {
            this.setState({ error: t('Error.MissingUsernamePassword') });
        }
        
    };



    validateEmail = evt => {
        evt.preventDefault();
        const { email } = this.state;
        const { t } = this.props;
        const isNotEmpty = email !== '';

        if (isNotEmpty) {
            const isValid = emailIsValid(email);
            if (isValid) {
                this.reset();
            } else {
                this.setState({
                    success: null,
                    error: t('Error.InvalideEmailAddress'),
                });
            }
        } else {
            this.setState({
                success: null,
                error: t('Error.EmailAdressIsEmpty'),
            });
        }
    };
    
    
    userlogin() {
        this.setState({ disableUserSubmit: true });
        const { phonenumber } = this.state;
        const { t } = this.props;
        
        const requestBody = {
            'phoneno' : phonenumber,
        };
        const requestOptions = {
            method: 'POST',
            headers: {
                Accept:  'application/json',
                'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
            },
            body: JSON.stringify(requestBody)
        };
        fetch("https://mdvaccess.com/php/userlogin.php?phoneno="+phonenumber, requestOptions
            ).then( async  response => {
                const data = await response.json();
                //console.log(response);
                if(!response.ok){
                    throw new Error();
                }
                else{
                    //console.log("response is "+JSON.stringify( data));
                    if(data.mydoorview.status === undefined || data.mydoorview.status != 'Success'){
                        this.setState({ error: t('invalid_phone_number') });
                    }
                    else{
                        let ema = data.mydoorview.email;                        ;
                        //console.log(ema);
                        this.setState({showOTPLoginForm : true});
                        this.setState({username : ema});
                    }
                   
                }
                    
            }).catch(error => {
                //console.log(error);
                this.setState({success: null, error: t('Login.Failed')});
            }).finally(() => {
                this.setState({ disableUserSubmit: false });
            });


       
    }

    
    otplogin() {
        this.setState({ disableOTPSubmit: true });
        //this.setState({ error: '' });

        const { otp ,phonenumber,username,password} = this.state;
        const { t } = this.props;
        const {showAdLogin,isWebViewer } = this.props; 
        const { LoginUser } = this.props;
        const usname = username.replace(/\s/g, "");
        const ps = password.replace(/\s/g, "");
        
       // //console.log('username is '+ username + " password is "+ password);
       /* if(username == 'admin@gmail.com' && password == 'admin'){
           
        }else{
            this.setState({ error: t('invalid_user_password') });
        }*/
        const requestBody = {
            'otp' : otp,
            'phone' :phonenumber,
            'email' : usname,
            'isAdmin' : showAdLogin,
            'password' :ps,
            'isWebViewer' : isWebViewer
        };
        const requestOptions = {
            method: 'POST',
            headers: {
                Accept:  'application/json',
                'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
            },
            body: JSON.stringify(requestBody)
        };
        
        fetch("https://mdvaccess.com/webphp/login", requestOptions
            ).then( async  response => {
                const data = await response.json();
              //  //console.log(JSON.stringify(data));
                if(!response.ok){
                    throw new Error();
                }
                else{
                //    //console.log(JSON.stringify(data));
                //    //console.log(JSON.stringify(data.rows.length));
                    if(data.rows.length === 0){
                       // //console.log('on lenght 0');
                        this.setState({ error: t('invalid_otp') });
                    }
                    else{
                     //   //console.log('on lenght 1');
                        LoginUser(data);
                    }
                   
                }
                    
            }).catch(error => {
                //console.log(error.response);
                this.setState({success: null, error: t('Login.Failed')});
            }).finally(() => {
                this.setState({ disableOTPSubmit: false });
            });



       
    }
    loginwithoutotp(){
       
        const { otp ,phonenumber,username,password} = this.state;
        const { t } = this.props;
        const {showAdLogin,isWebViewer } = this.props; 
        const { LoginUser } = this.props;
        const usname = username.replace(/\s/g, "");
        const ps = password.replace(/\s/g, "");
       
        const requestBody = {
            'email' : usname,
            'password' :ps
        };
        const requestOptions = {
            method: 'POST',
            headers: {
                Accept:  'application/json',
                'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
            },
            body: JSON.stringify(requestBody)
        };
        let url = '';
        if(isWebViewer == true){
            url = "https://mdvaccess.com/webphp/loginmainwebviewer";
        }
        else{
            url = "https://mdvaccess.com/webphp/loginmain";
        }
        //console.log(url);
        //console.log(requestBody);
        fetch(url, requestOptions
            ).then( async  response => {
                const data = await response.json();
                //console.log(response);
                if(!response.ok){
                    throw new Error();
                }
                else{
                    //console.log(data);
                    if((data.rows).length === 0){
                        this.setState({ error: t('invalid_user_password') });
                    }
                    else{
                        LoginUser(data);
                    }
                   
                }
                    
            }).catch(error => {
                //console.log(error);
                this.setState({success: null, error: t('Login.Failed')});
            }).finally(() => {
                this.setState({ disableOTPSubmit: false });
            });
    }
   
    login() {
        this.setState({ disableSubmit: true });
        //this.setState({ error: '' });
        const { username, password } = this.state;
        const { t } = this.props;
        const { LoginUser,isWebViewer } = this.props;
        //console.log('username is '+ username + " password is "+ password);
       /* if(username == 'admin@gmail.com' && password == 'admin'){
           
        }else{
            this.setState({ error: t('invalid_user_password') });
        }*/
        const usname = username.replace(/\s/g, "");
        const ps = password.replace(/\s/g, "");
        const requestBody = {
            'email' : usname,
            'password' :ps
        };
        const requestOptions = {
            method: 'POST',
            headers: {
                Accept:  'application/json',
                'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
            },
            body: JSON.stringify(requestBody)
        };
        let url = '';
        if(isWebViewer == true){
            url = "https://mdvaccess.com/php/webviewerlogin.php?email=";
        }
        else{
            url = "https://mdvaccess.com/php/weblogin.php?email=";
        }

        fetch(url+usname+"&password="+ps, requestOptions
            ).then( async  response => {
                const data = await response.json();
                //console.log(response);
                if(!response.ok){
                    throw new Error();
                }
                else{
                    //console.log("response is "+JSON.stringify( data));
                    if(data.mydoorview.status === undefined || data.mydoorview.status != 'Success'){
                        this.setState({ error: t('invalid_user_password') });
                    }
                    else{
                        //LoginUser(data);
                        let ema = data.mydoorview.phone;                        ;
                        //console.log(ema);
                       this.setState({showOTPLoginForm : true});
                       this.setState({phonenumber:ema});
                    }
                   
                }
                    
            }).catch(error => {
                //console.log(error);
                this.setState({success: null, error: t('Login.Failed')});
            }).finally(() => {
                this.setState({ disableSubmit: false });
            });


       
    }
    reset() {
        this.setState({ disableForgotPasswordSubmit: true });
        const { email } = this.state;
        const { t } = this.props;

        const requestBody = {
            'email' : email,
        };

        const requestOptions = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody)
        };

        fetch("https://mdvaccess.com/webphp/forgetPassword", requestOptions
            ).then(async response => {
               /* if(response.status === 201)
                    this.setState({success: t('Password.Reset.Success'), error: null});
                else
                    throw new Error();*/
                    const data = await response.json();
                //    console.log("response of forget password " + JSON.stringify(response));
                    if(!response.ok){
                        throw new Error();
                    }
                    else{
                        //console.log(data);
                        if(data.rows === 0){
                            this.setState({ error: t('invalid_email') });
                        }
                        else{
                            this.setState({success: t('Password.Reset.Success'), error: null});
                        }
                       
                    }
            }).catch(error => {
                //console.log(error);
                this.setState({success: null, error: t('Password.Reset.Failed')});
            }).finally(() => {
                this.setState({ disableForgotPasswordSubmit: false });
            });

      
       

    }
    render() {
const theme = Theme;
const {showAdLogin } = this.props; 
//const {token} = useToken();
const {
    username,
    password,
    email,
    phonenumber,
    showPassword,
    error,
    success,
    showForgotPasswordForm,
    showAdminLoginForm,
    disableSubmit,
    disableUserSubmit,
    disableForgotPasswordSubmit,
    emailValidation,
    passwordValidation,
    showOTPLoginForm,
    otp,
    disableOTPSubmit
} = this.state;
    return (
        <ThemeProvider theme={theme}>
        <CssBaseline>
        <Page>
                        <Overlay>
                    {
                       
                                <>
                              
                                    <Widget id={"widget"} ref={widgetRef} theme={theme}>
                                        <PortalLogin
                                            showForgotPasswordForm={showForgotPasswordForm}
                                            errorMessage={error}
                                            successMessage={success}
                                            email={email}
                                            disableForgotPasswordSubmit={disableForgotPasswordSubmit}
                                            username={username}
                                            password={password}
                                            showPassword={showPassword}
                                            disableSubmit={disableSubmit}
                                            emailValidation={emailValidation}
                                            passwordValidation={passwordValidation}
                                            showAdminLoginForm={showAdLogin}
                                            onChangeEmail={this.handleChangeEmail}
                                            onForgotPasswordSubmit={this.validateEmail}
                                            onClickTogglePassword={this.toggleShowPassword}
                                            onChangeUsername={this.handleChangeUsername}
                                            onBlurEmail={this.handleBlurEmail}
                                            onChangePassword={this.handleChangePassword}
                                            onLoginSubmit={this.validateUsernameAndPassword}
                                            onClickForgotPassword={this.handleShowForgotPassword}
                                            phonenumber={phonenumber}
                                            disableUserSubmit={disableUserSubmit}
                                            onUserSubmit={this.validateUserPhoneNumber}
                                            onClickAdminLogin={this.handleAdminLogin}
                                            onClickShowPreviousLogin={this.handleAdminLogin}
                                            onChangePhoneNumber={this.handlePhoneNumberChange}
                                            showOTPLoginForm={showOTPLoginForm}
                                            otp={otp}
                                            onChangeOTP={this.handleChangeOTP}
                                            onSubmitOTP={this.validateOTP}
                                            disableOTPSubmit={disableOTPSubmit}
                                            onClickPreviousFromOTP={this.handlePreviousClick}
                                        />
                                   </Widget>
                                </>
                            
                    }
                    </Overlay>
                  </Page>
        </CssBaseline>
    </ThemeProvider>
    )
                }
  }
export default withTranslation()(Login)
