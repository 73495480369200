import * as React from "react";
import { Box } from "@mui/system";
import { Button,Checkbox, Switch,Grid, InputLabel, List,Divider, FormControlLabel, Typography,ButtonGroup,Snackbar ,Alert ,IconButton, Icon,Dialog,DialogTitle,DialogContent,DialogActions,DialogContentText, cardMediaClasses } from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';
import FilledInput from '@mui/material/FilledInput';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { faker } from '@faker-js/faker';
import { format } from 'date-fns';
import { useState,useRef,useEffect } from "react";
import useTable from "./components/useTable";
import SvgIcon from "@mui/material/SvgIcon";
import NoImage from './asset/noimage.png';
import Popup from "./components/Popup";
import _ from "lodash";
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { styled } from '@mui/material/styles';
import axios from "./axios";
import GlobalAlert from "./GlobalAlert";
import PuffLoader from "react-spinners/PuffLoader"; 
import LoadingButton from '@mui/lab/LoadingButton';
import GlobalRoleAccess from "./GlobalRoleAccess";
import GlobalRoleAccessInner from "./GlobalRoleAccessInner";
import useToken from "./useToken";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import useInnerTable from "./components/useInnerTable";
import InputAdornment from '@mui/material/InputAdornment';
import TimezoneSelect from 'react-timezone-select'
import { useSSR } from "react-i18next";
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


function CodeOverride ( props){
  const handleCloseSnack= (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
  const {token} = useToken();
  const {RefreshToken} = props;
  const { getAlertTitle} = GlobalAlert();
  const [openDelete, setOpenDelete] = React.useState(false);
  const [titleforalert, setTitleforalert] = React.useState("");
  const [isVisible ,setIsVisibile] = useState(false);
  const [isVisibleCheck ,setIsVisibileCheck] = useState(false);
  const [isVisibleArray, setIsVisibileArray] = useState([]);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [openSnackData, setOpenSnackData] = React.useState("This is Success Data");
  const [severityData, setSeverityData] = React.useState("success");
  const [loadingbtn ,setloadingbtn] = React.useState(false);
  const [EnLog,setEnLog] = useState([]);
  const [apiValue, setAPIValue] = useState();
  const [fullName,setFullName] = useState('');
  const [emailAddress,setEmailAddress] = useState('');
  const [phoneNumber,setPhoneNumber] = useState('');
  const [passwords,setPasswords] = useState('');
  const [showUpdateButton, setshowUpdateButton] = React.useState(false);
  let [loading, setLoading] = useState(true);
  const [currentDeleteID,setCurrentDeleteId] = useState();
  const {getRoleAccessVal} = GlobalRoleAccess();
  const {getRoleAccessInnerVal} = GlobalRoleAccessInner();

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  const handleCloseActiveDelete =() =>{
    setOpenDelete(false);
    console.log("currentDeleteID"+token.issub);
     deleteMyPostData();
  }
  const deleteMyPostData = async () => {
    setloadingbtn(true);
     try {
       const res = await axios.delete("/administrator/deletecode/"+currentDeleteID,
       {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
       'token' : token.token,
       'tokenid' : token.email,
       'issub':token.issub,
       'subId':token.subid,
       'email':deleteUserName }});
       console.log( res.data);
       setOpenSnackData(getAlertTitle('SAOTCDS'));
       setSeverityData("success");
       setOpenSnack(true);
       setLoading(false);
       
       getMyPostData();
     } catch (error) {
       console.log(error.response);
       setLoading(false);
       setOpenSnackData(getAlertTitle('SAOTCDF'));
       setSeverityData("error");
       setOpenSnack(true);
       setloadingbtn(false);
       if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
     }
   };
  const getMyPostData = async () => {
    try {
      const res = await axios.get("/administrator/getcodedetails/",{
        headers: { 'Content-Type':  'application/json',
        'token' : token.token,
        'tokenid' : token.email },
      });
      console.log("data format"+JSON.stringify( res.data));
      setEnLog(res.data);
      setRecords( res.data);
    
      setLoading(false);
      setloadingbtn(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      setloadingbtn(false);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };

  const getDisableValueEdit = (val) => {
    if(token.role == 'superadmin'){
      console.log("token sub type" + token.sub_type);
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("22").administrator_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let valOfDisable = getRoleAccessVal("22").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
    
    
  }
  const getDisableValueDelete = (val) => {
    if(token.role == 'superadmin'){
      console.log("token sub type" + token.sub_type);
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("23").administrator_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let valOfDisable = getRoleAccessVal("23").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
    
    
  }
  const getDisableValueAdd = (val) => {
    if(token.role == 'superadmin'){
      console.log("token sub type" + token.sub_type);
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("21").administrator_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let valOfDisable = getRoleAccessVal("21").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
    
    
  }
  useEffect(() => {
    getMyPostData();
  }, []);
  const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });
  const [records,setRecords] = useState([]);
 
  const headCells = [
    { id: 'email', label: 'Email' },
    { id: 'otp', label: 'One Time Code', disableSorting: true },
    { id: 'access', label: 'Action', disableSorting: true },
  ];
  const {
    TblContainer,
    TblHead,
    TblPagination ,
    BelowTblPagination,
    recordsAfterPagingAndSorting ,
    setPageToNewOne ,
  } = useInnerTable(records, headCells, filterFn,5,650);
  
  const updateMyPostData = async (val) => {
    console.log(apiValue);
    setloadingbtn(true);
    try {
      const res = await axios.patch("/administrator/updatecodedetails/",val, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      'token' : token.token,
      'tokenid' : token.email }});
      console.log( res.data);
      setOpenSnackData(getAlertTitle('SAOTCUS'));
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      setshowUpdateButton(false);
      setFullName('');
      setEmailAddress('');
      setPhoneNumber('');
      setPasswords('');
      getMyPostData();
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      setOpenSnackData(getAlertTitle('SAOTCUF'));
      setSeverityData("error");
      setOpenSnack(true);
      setloadingbtn(false);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  const addMyPostData = async (val) => {
    console.log(apiValue);
    setloadingbtn(true);
    try {
      const res = await axios.post("/administrator/addcode/",val, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      'token' : token.token,
      'tokenid' : token.email,
      'issub':token.issub,
      'subId':token.subid  }});
      console.log( res.data);
      if(res.data.rows == '1'){
      setOpenSnackData(getAlertTitle('SAOTCAS'));
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      setshowUpdateButton(false);
      setFullName('');
      setPhoneNumber('');
      setEmailAddress('');
      setPasswords('');
      getMyPostData();
      setloadingbtn(false);
      }
      else{
        setOpenSnackData(getAlertTitle('SAOTCEAE'));
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      setshowUpdateButton(false);
      setloadingbtn(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setOpenSnackData(getAlertTitle('SAOTCAF'));
      setSeverityData("error");
      setOpenSnack(true);
      setloadingbtn(false);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  
  
  const handleTablePhoneNumberChange= (event) => {
    let idIn;
    //console.log('record email'+records[0]  );
    for(let i = 0; i < records.length ; i++){
      let item = records[i];
      console.log('record email'+item.email  );
      console.log(' email'+ event.target.name );
      if(item.id == event.target.name ){
       
          idIn = i;
          break;
      }
    }
    console.log(event.target.name);
    console.log(idIn );
    records[idIn].otp = event.target.value;
    setRecords(records);
  }

  const [deleteUserName,setDeleteUserName] = useState('');
  const handleDelete = (event) =>{
    let idofItem =  records.filter(x => x.id == event.target.name);
    setCurrentDeleteId(idofItem[0].id);
    setDeleteUserName(idofItem[0].email);
    setTitleforalert("Are you sure you want to delete One Time Code override for "+ idofItem[0].email + " ?");
    setOpenDelete(true);
  }
  
 
  const handleEmailChange = (event) => {
    setEmailAddress(event.target.value);
  }
  const handlePhoneChange = (event) => {
    setPhoneNumber(event.target.value);
  }
  
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

 
  const handlePropertyModify = (event) => {
  
    let idIn;
    for(let i = 0; i < records.length ; i++){
      if(records[i].id == event.target.name){
          idIn = i;
          break;
      }
    }
   
    let asv = { email:records[idIn].email,otp:records[idIn].otp,issub:token.issub,subId:token.subid};
    updateMyPostData(asv);
  }

  const handlePropertyAdd = () => {
   
    if((phoneNumber.trim()).length != 6 ){
      setOpenSnackData(getAlertTitle('SAOTCOLNV'));
      setSeverityData("error");
      setOpenSnack(true);
      return;
    }
    if((emailAddress.trim()).length <= 0 ){
      setOpenSnackData(getAlertTitle('SAOTCENE'));
      setSeverityData("error");
      setOpenSnack(true);
      return;
    }
   
    if(!isValidEmail(emailAddress)){
      setOpenSnackData(getAlertTitle('SAOTCENV'));
      setSeverityData("error");
      setOpenSnack(true);
      return;
    }
    for(let j = 0; j < records.length ; j++){
      
        if(records[j].email == emailAddress ){
          setOpenSnackData(getAlertTitle('SAOTCEAE'));
          setSeverityData("error");
          setOpenSnack(true);
          return;
        }
      
    }
    let asv = {email:emailAddress,otp:phoneNumber,"issub":token.issub,"subId":token.subid};
    console.log(asv);
    addMyPostData(asv);

  }
  let rowOfOne = -1;
  return <> <Box sx={mainThemeStyle.boxTopHeader}>
  
</Box>
<Box sx={mainThemeStyle.boxHeaderBelow}>
<Box  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px',display: { xs: "none", md: "flex" }}}>
               <div style={{ display: 'flex' }}>
                <Stack direction='row' spacing={2} padding={2}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                   One-Time Code Override
                   </Typography>
                   
       <TextField
        id="text"
        type="text"
      placeholder="Email Address"
      value={emailAddress}
       variant="filled"
       onChange={handleEmailChange}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValueAdd("")}
                      sx={mainThemeStyle.InputMenuItem}
                          style={{paddingTop: '16px'}}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      />
      <TextField
        id="text"
        type="number"
      placeholder="One Time Code"
      value={phoneNumber}
       variant="filled"
       disabled = {getDisableValueAdd("")}
       onChange={handlePhoneChange}
                        InputLabelProps={{ shrink: true }
                      }
                          style={{paddingTop: '16px'}}
                          onInput = {(e) =>{
                            e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,6)
                        }}
                          sx={mainThemeStyle.InputMenuItem}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      />
     
      <LoadingButton sx={mainThemeStyle.normalButtonDown} onClick={handlePropertyAdd}  loading={loadingbtn}  disabled = {getDisableValueAdd("")}> Add </LoadingButton>
      </Stack>
               </div>
              
           </Box>
           <Box  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px',display: { xs: "flex", md: "none" }}}>
               <Stack direction='column' spacing={0.1} padding={2}>
                <Stack direction='row' spacing={2} padding={2}>
                   <Typography sx={mainThemeStyle.popUpLable}>
                   One-Time Code Override
                   </Typography>
                  </Stack> <Stack direction='row' spacing={2} padding={2}>
       <TextField
        id="text"
        type="text"
      placeholder="Email Address"
      value={emailAddress}
       variant="filled"
       sx={mainThemeStyle.InputMenuItem}
       onChange={handleEmailChange}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValueAdd("")}
                        InputProps={{
                           
                            sx: {
                              marginTop:'-1rem',
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      />
      <TextField
        id="text"
        type="number"
      placeholder="One Time Code"
      value={phoneNumber}
       variant="filled"
       sx={mainThemeStyle.InputMenuItem}
       onChange={handlePhoneChange}
       disabled = {getDisableValueAdd("")}
                        InputLabelProps={{ shrink: true }
                      }
                      onInput = {(e) =>{
                        e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,6)
                    }}
                        InputProps={{
                           
                            sx: {
                              marginTop:'-1rem',
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      />
    </Stack> 
      <LoadingButton sx={{
          width: 'auto',
          height: '2.06rem',
          
          
          fontWeight: '700',
          fontSize: '0.93rem',
          
          
          alignItems: 'center',
          color: '#FFFFFF',
          fontFamily: "Lato",
          borderRadius:'0.31rem',
         '&:hover': {
          backgroundColor: 'rgba(109, 195, 252, 1)',
         }
      }} onClick={handlePropertyAdd}  loading={loadingbtn}  disabled = {getDisableValueAdd("")} > Add </LoadingButton>
      </Stack>
              
              
           </Box>
           <Divider sx={{backgroundColor:'white'}} />
        
<TblContainer>
                    <TblHead />

        <TableBody>
         {recordsAfterPagingAndSorting().map(item => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              {() =>{rowOfOne++; console.log(rowOfOne)}}
            
      <TableCell align="left"  sx={mainThemeStyle.cellRowBig}> {item.email}</TableCell>
      <TableCell align="left"> <TextField
        id="text"
        type="tel"
        defaultValue={item.otp}
        name = {item.id}
       onChange={handleTablePhoneNumberChange}
       sx={mainThemeStyle.InputMenuItem}
       disabled = {getDisableValueEdit("")}
          variant = "filled"
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          
                            sx: {
                              minWidth:'120px',
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      /></TableCell>
      
              
              <TableCell align="left">
                  <Stack direction='row' spacing={2}>
                  <Typography></Typography>  
               <LoadingButton sx={mainThemeStyle.viewLogButton} name={item.id} loading={loadingbtn} onClick={handlePropertyModify} disabled = {getDisableValueEdit("")}> Save Changes </LoadingButton>
                {item.email != 'Global' &&  <LoadingButton loading={loadingbtn} name={item.id} sx={mainThemeStyle.viewLogButtonRed} onClick={handleDelete} disabled = {getDisableValueDelete("")}>Delete</LoadingButton>}
                  </Stack>  </TableCell>
            </TableRow>
           ))}
        </TableBody>
        </TblContainer>
        <BelowTblPagination></BelowTblPagination>
</Box>
<Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={severityData} sx={{ width: '100%' }}>
          {openSnackData}
        </Alert>
      </Snackbar>
      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          padding: '16px',
      
         backgroundColor:'#00000000',
         
      }}
      >
        <DialogTitle id="alert-dialog-title" sx={{
        
      
         backgroundColor:'#2A2F3B',
         color:'white'
         
      }}>
          {titleforalert}
        </DialogTitle>
        <DialogContent sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          
        </DialogContent>
        <DialogActions sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          <Button onClick={handleCloseDelete} sx={mainThemeStyle.normalBigButton}>Cancel</Button>
          <Button onClick={handleCloseActiveDelete} autoFocus sx={mainThemeStyle.normalBigButtonRed}>
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>
 </> 
          
          
          
          
          ;
};

export default CodeOverride;