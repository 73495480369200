import * as React from "react";
import { Box } from "@mui/system";
import { Button,Checkbox, Switch,Grid, InputLabel, List,Divider, FormControlLabel, Typography,ButtonGroup,Snackbar ,Alert ,IconButton, Icon,Dialog,DialogTitle,DialogContent,DialogActions,DialogContentText, cardMediaClasses } from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { faker } from '@faker-js/faker';
import { format } from 'date-fns';
import { useState,useRef,useEffect } from "react";
import useTable from "./components/useTable";
import SvgIcon from "@mui/material/SvgIcon";
import NoImage from './asset/noimage.png';
import Popup from "./components/Popup";
import _ from "lodash";
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { styled } from '@mui/material/styles';
import useToken from "./useToken";
import axios from "./axios";
import useInnerTable from "./components/useInnerTable";
import LoadingButton from '@mui/lab/LoadingButton';
import PuffLoader from "react-spinners/PuffLoader"; 
import GlobalRoleAccess from "./GlobalRoleAccess";
import GlobalRoleAccessInner from "./GlobalRoleAccessInner";
import GlobalAlert from "./GlobalAlert";
import TimezoneSelect from 'react-timezone-select'
import { AppBlocking, BreakfastDiningOutlined } from "@mui/icons-material";
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


function Feature ( props){
  const handleCloseSnack= (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
  const {token} = useToken();
  const {RefreshToken} = props;
  const { getAlertTitle} = GlobalAlert();
  const [openSnack, setOpenSnack] = React.useState(false);
  const [openSnackData, setOpenSnackData] = React.useState("This is Success Data");
  const [severityData, setSeverityData] = React.useState("success");
  const [showUpdateButton, setshowUpdateButton] = React.useState(false);
  const [filterText,setfilterText] = useState('');
  const [EnLog,setEnLog] = useState([]);
  let [loading, setLoading] = useState(true);
  let [loadingbtn, setloadingbtn] = useState(false);
  const [records,setRecords] = useState([]);
  const [recordsProperty,setRecordsProperty] = useState([]);
  const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });
  const [filterFnProperty, setFilterFnProperty] = useState({ fn: items => { return items; } });
  const [currentTab,setCurrentTab] = useState(0);
  const [Tab,setTab] = useState(0);
  const [currentNumber,setCurrentNumber] = useState('');
  const [open, setOpen] = React.useState(false);
  const [titleforalert, setTitleforalert] = React.useState("");
  const {getRoleAccessVal} = GlobalRoleAccess();
  const {getRoleAccessInnerVal} = GlobalRoleAccessInner();
  const headCells = [
    
    { id: 'title', label: 'Feature Title'},
    { id: 'cost', label: 'Cost'},
    { id: 'action', label: 'Action', disableSorting: true},
  ];
  const headCellsProperty = [
    
    { id: 'loc_name', label: 'Property Name'},
    { id: 'fname', label: 'Device Owner'},
    { id: 'unit', label: 'Unit'},
  ];
  const [openPopup, setOpenPopup] = useState(false);
  const {
    TblContainer,
    TblHead,
    TblPagination ,
    BelowTblPagination,
    recordsAfterPagingAndSorting ,
    setPageToNewOne ,
  } = useInnerTable(records, headCells, filterFn,10,650);

  const {
    TblContainer : TblPContainer,
    TblHead :  TblPHead,
    TblPagination : TblPPagination,
    BelowTblPagination : BelowPTblPagination,
    recordsAfterPagingAndSorting : recordsAfterPPagingAndSorting,
    setPageToNewOne : setPPageToNewOne 
  } = useInnerTable(recordsProperty, headCellsProperty, filterFnProperty,10,650);
  const [apiValue, setAPIValue] = useState({id:"",title:"",cost:"","issub":token.issub,"subId":token.subid});
  const getMyPostData = async () => {
    try {
      const res = await axios.get("/administrator/features/",{
        headers: { 'Content-Type':  'application/json',
        'token' : token.token,
        'tokenid' : token.email },
      });
      console.log("data format"+res.data);
      setEnLog(res.data);
      setRecords(res.data);
      setLoading(false);
      setloadingbtn(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      setloadingbtn(false);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  const getDisableValue = (val) => {
    if(token.role == 'superadmin'){
      console.log("token sub type" + token.sub_type);
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("13").administrator_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let valOfDisable = getRoleAccessVal("13").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
    
    
  }
  const getDisableValueEdit = (val) => {
    if(token.role == 'superadmin'){
      console.log("token sub type" + token.sub_type);
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("14").administrator_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let valOfDisable = getRoleAccessVal("14").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
    
    
  }
  const getDisableValueDelete = (val) => {
    if(token.role == 'superadmin'){
      console.log("token sub type" + token.sub_type);
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("15").administrator_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let valOfDisable = getRoleAccessVal("15").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
    
    
  }
  useEffect(() => {
    getMyPostData();
  }, []);
  /*useEffect(() => {
    
  }, [currentTab]);*/
  const updateMyPostData = async () => {
    setloadingbtn(true);
    try {
    
      const res = await axios.patch("/administrator/updatefeature/"+apiValue.id,apiValue, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      'token' : token.token,
      'tokenid' : token.email }});
      console.log( res.data);
      setOpenSnackData(getAlertTitle('FUS'));
      setSeverityData("success");
    //  setDataOfAdd('');
      setOpenSnack(true);
      setOpenPopup(false);
      setLoading(false);
      setshowUpdateButton(false);
      setloadingbtn(false);
      setOpen(false);
      getMyPostData();
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      setOpenSnackData(getAlertTitle('FUF'));
      setSeverityData("error");
      setOpenSnack(true);
      setloadingbtn(false);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  const handleChangeEditText = e =>{
    console.log(apiValue);
    let ds = e.target.name;
    apiValue[ds] = e.target.value;
    setshowUpdateButton(true);
   // setAPIValue({id:apiValue.id,title:apiValue.title,cost:apiValue.cost});
}
  const ShowLog = () =>{
    let indexOfCurrent;
    for(let i = 0; i < records.length ; i++){
        if(records[i].id == currentId ){
          indexOfCurrent = i;
            break;
        }
    }
    
    return(<Grid container spacing={2}> 
      <Grid item >
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={35}> Feature Title </Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={35}> Feature Cost </Typography>
    
      </Stack>
      </Grid>
      <Grid item >
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={30}>  </Typography>
      <TextField
        
        name="title"
        type="text"
        defaultValue={apiValue.title }
        sx={mainThemeStyle.InputFilterPopup}
        onChange={handleChangeEditText}
        InputLabelProps={{
          shrink: true,
        }}
        hiddenLabel
        variant='filled'
        disabled = {getDisableValueEdit("")}
        InputProps={{
                           
          sx: {
              
              color: 'rgba(255, 255, 255, 1)',
              height:'33px',
              borderRadius: '5px',
              backgroundColor: '#171E29',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '5px 0 5px 0',
              textIndent: '15px !important',
              border: `1px solid rgba(31, 39, 54, 1)`,
              '&.Mui-disabled': {
             

                background: 'rgba(135, 135, 135, 0.5)',
              },
              
              "& input.Mui-disabled": {
                color: "white"
              }
              
          },
      }}
      />
      <TextField
        id="cost"
        name="cost"
        type="number"
        defaultValue={apiValue.cost }
        sx={mainThemeStyle.InputFilterPopup}
        onChange={handleChangeEditText}
        disabled = {getDisableValueEdit("")}
        InputLabelProps={{
          shrink: true,
        }}
        hiddenLabel
        variant='filled'
        InputProps={{
                           
          sx: {
              
              color: 'rgba(255, 255, 255, 1)',
              height:'33px',
              borderRadius: '5px',
              backgroundColor: '#171E29',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '5px 0 5px 0',
              textIndent: '15px !important',
              border: `1px solid rgba(31, 39, 54, 1)`,
              '&.Mui-disabled': {
             

                background: 'rgba(135, 135, 135, 0.5)',
              },
              
              "& input.Mui-disabled": {
                color: "white"
              }
              
          },
      }}
      /> 
      </Stack>
      </Grid>
      
    { showUpdateButton &&  <LoadingButton loading={loadingbtn} sx={mainThemeStyle.saveButton} onClick={handleEditFeature} disabled = {getDisableValueEdit("")}>Save</LoadingButton>}
      
      </Grid>)
  }
  const addPostData = async () => {
    setloadingbtn(true);
    try {
    
      const res = await axios.post("/administrator/addfeature/",{'title':nameOfFeature,'cost':costOfFeature,'issub' : token.issub,'subId' : token.subid}, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8' ,
      'token' : token.token,
      'tokenid' : token.email}});
      console.log( res.data);
      setOpenSnackData("New feature successfully added");
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      setshowUpdateButton(false);
      setloadingbtn(false);
      getMyPostData();
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      setOpenSnackData("New feature add failed, try again later");
      setSeverityData("error");
      setOpenSnack(true);
      setloadingbtn(false);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  const handleEditFeature = () => {
    setAPIValue(apiValue);
    if((apiValue.title.trim()).length <= 0) {
        setOpenSnackData("Feature Title Can't Be Null, Please Enter Feature Title");
        setSeverityData("error");
        setOpenSnack(true);
  }
  else if((apiValue.cost.trim()).length <= 0) {
    setOpenSnackData("Feature Cost Can't Be Null, Please Enter Feature Cost");
        setSeverityData("error");
        setOpenSnack(true);
  }
  else{
    
    updateMyPostData();
  }
  }

  const [currentId,setCurrentId] = useState();
  const [nameOfFeature,setNameOfFeature] = useState('');
  const [costOfFeature,setCostOfFeature] = useState('');
  const handleFeatureNameChange = (event) => {
    setNameOfFeature(event.target.value);
  }
  const handleFeatureCostChange = (event) => {
    setCostOfFeature(event.target.value);
  }
  const handleAddFeature = () => {
    if((nameOfFeature.trim()).length <= 0) {
        setOpenSnackData("Feature Title Can't Be Null, Please Enter Feature Title");
        setSeverityData("error");
        setOpenSnack(true);
  }
  else if((costOfFeature.trim()).length <= 0) {
    setOpenSnackData("Feature Cost Can't Be Null, Please Enter Feature Cost");
        setSeverityData("error");
        setOpenSnack(true);
  }
  else{
    addPostData();
  }
  }
  const ChangeId = (val) =>{
    console.log('val is '+val);
    
    setCurrentId(val);
    let indexOfCurrent;
    for(let i = 0; i < records.length ; i++){
      if(records[i].id == val ){
        indexOfCurrent = i;
          break;
      }
  }
  apiValue.id = val;
  apiValue.title = records[indexOfCurrent].title;
  apiValue.cost = records[indexOfCurrent].cost;
  setshowUpdateButton(false);
    setOpenPopup(true);
  }
  const [currentFeature,setCurrentFeature] = useState('');
 const DeletId = (val) =>{
    setCurrentId(val);
    let deletid;
    for(let i = 0; i < records.length ; i++){
        if(records[i].id == val ){
            deletid = i;
            break;
        }
    }
    setCurrentFeature(records[deletid].title);
     setTitleforalert("Are you sure you want to delete feature "+ records[deletid].title + " ?");
     setOpen(true);
  }
  const handleClose = () => {
    setOpen(false);
  };
  const deleteMyPostData = async () => {
    setloadingbtn(true);
    try {
      const res = await axios.delete("/administrator/deletefeature/"+currentId,
      {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      'token' : token.token,
      'tokenid' : token.email,
      'issub':token.issub,
      'subId':token.subid,
      'name':currentFeature }});
      console.log( res.data);
      setOpenSnackData("Feature successfully deleted");
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      getMyPostData();
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      setOpenSnackData("Feature delete failed, try again later");
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  const handleCloseActive =() =>{
    setOpen(false);
    deleteMyPostData();
  }
  const handleReset =() =>{
    setfilterText('');
    if(document.getElementById('input-location') != null)
    document.getElementById('input-location').value ='';
    
          setFilterFn({
            fn: items => {
              return items;
                    
            }
        })
        setPageToNewOne();
  }
  const handleSearch = () => {
    //let target = e.target;
    setFilterFn({
        fn: items => {
            if (filterText == "")
                return items;
            else{
              
               
                return items.filter(x => x.number.toString().toLowerCase().includes(filterText.toLowerCase()))
                
               
                
              
             
            }
                
        }
    })
    setPageToNewOne();
}
  return <> <Box sx={mainThemeStyle.boxTopHeader}>
 
</Box>
 <Box sx={mainThemeStyle.boxHeaderBelow}>

           <Box  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px',display:{xs:'none',md:'flex'}}}>
               <div style={{ display: 'flex' }}>
                <Stack direction='row' spacing={2} padding={2}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Add New Feature
                   </Typography>
                   <TextField
        id="text"
        type="text"
      placeholder="Enter Feature Title"
      value={nameOfFeature}
      onChange={handleFeatureNameChange}
      sx={mainThemeStyle.InputMenuItem}
       variant="filled"
                        InputLabelProps={{ shrink: true }
                      }
                          style={{paddingTop: '16px'}}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      />
       <TextField
        id="text"
        type="number"
      placeholder="Enter Feature Cost"
      value={costOfFeature}
      onChange={handleFeatureCostChange}
      sx={mainThemeStyle.InputMenuItem}
       variant="filled"
                        InputLabelProps={{ shrink: true }
                      }
                          style={{paddingTop: '16px'}}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      />
      
      <LoadingButton sx={mainThemeStyle.normalButtonDown} loading={loadingbtn} onClick={handleAddFeature} disabled = {getDisableValue("")}> Add </LoadingButton>
      </Stack>
               </div>
              
           </Box>

           <Box  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px',display:{xs:'flex',md:'none'}}}>
               <div style={{ display: 'flex' }}>
               <Stack direction='column'>
                <Stack direction='row' spacing={2} padding={2}>
                   <Typography variant="h6" component="div" style={{  flexGrow: 1,color:'#fff' }}>
                       Add New Feature
                   </Typography>
                   <TextField
        id="text"
        type="text"
      placeholder="Enter Feature Title"
      value={nameOfFeature}
      onChange={handleFeatureNameChange}
      sx={mainThemeStyle.InputMenuItem}
       variant="filled"
                        InputLabelProps={{ shrink: true }
                      }
                          style={{paddingTop: '16px'}}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      />
      </Stack>
      <Stack direction='row' spacing={2} padding={2}>
       <TextField
        id="text"
        type="number"
      placeholder="Enter Feature Cost"
      value={costOfFeature}
      sx={mainThemeStyle.InputMenuItem}
      onChange={handleFeatureCostChange}
       variant="filled"
                        InputLabelProps={{ shrink: true }
                      }
                          style={{paddingTop: '16px'}}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      />
      
      <LoadingButton sx={mainThemeStyle.normalButtonDown} loading={loadingbtn} onClick={handleAddFeature} disabled = {getDisableValue("")}> Add </LoadingButton>
      </Stack></Stack>
               </div>
              
           </Box>
           <Divider sx={{backgroundColor:'white'}} />
          
       
   
        <TblContainer>
                    <TblHead />

        <TableBody>
         {recordsAfterPagingAndSorting().map(item => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              
              <TableCell align="left"  sx={mainThemeStyle.cellRow}>{item.title} </TableCell>
              <TableCell align="left"  sx={mainThemeStyle.cellRow}> ${item.cost} </TableCell>
              <TableCell>
                                  <Stack direction="row" spacing={2}>
                                  <Typography></Typography>
                                    <LoadingButton loading={loadingbtn}   sx={mainThemeStyle.viewLogButton}  onClick={() => {ChangeId(item.id)}} disabled = {getDisableValueEdit("")}>Edit</LoadingButton>
                                    <LoadingButton loading={loadingbtn}   sx={mainThemeStyle.viewLogButtonRed}  onClick={() => {DeletId(item.id)}} disabled = {getDisableValueDelete("")} >Delete</LoadingButton>
                                  
                                    </Stack>
                                  </TableCell>
            </TableRow>
          ))}
        </TableBody>
      
    </TblContainer>
   
    <PuffLoader color="#878787" loading={loading} cssOverride={{display: "block",
    margin: "0 auto",
    marginTop:"50px",
    marginBottom:"50px",}} />
  <BelowTblPagination ></BelowTblPagination>




</Box>
<Popup
                title="Edit Feature"
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
            >
             
      <ShowLog/>
               
            </Popup>
<Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={severityData} sx={{ width: '100%' }}>
          {openSnackData}
        </Alert>
      </Snackbar>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          padding: '16px',
      
         backgroundColor:'#00000000',
         
      }}
      >
        <DialogTitle id="alert-dialog-title" sx={{
        
      
         backgroundColor:'#2A2F3B',
         color:'white'
         
      }}>
          {titleforalert}
        </DialogTitle>
        <DialogContent sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          
        </DialogContent>
        <DialogActions sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          <Button onClick={handleClose} sx={mainThemeStyle.normalBigButton}>Cancel</Button>
          <Button onClick={handleCloseActive} autoFocus sx={mainThemeStyle.normalBigButtonRed}>
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>
 </> 
          
          
          
          
          ;
};

export default Feature;