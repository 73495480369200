import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function useToken() {
  let navigate = useNavigate();
  const getToken = () => {
    //localStorage.setItem('token', '{}');
    //localStorage.clear();
    const tokenString = localStorage.getItem('token');
   
    const userToken = JSON.parse(tokenString);

   // console.log('user token is '+userToken.name);
    return userToken;

  };

  const getServerDetails = () => {
    //localStorage.setItem('token', '{}');
    //localStorage.clear();
    const servertokenString = localStorage.getItem('serverDetails');
   
    const userServerToken = JSON.parse(servertokenString);

   // console.log('user token is '+userToken.name);
    return userServerToken;

  };

  const [token, setToken] = useState(getToken());

  const [serverDetails, setServerDetails] = useState(getServerDetails());

  

  const clearToken = () =>{
    localStorage.clear();
    setToken(getToken());
    setServerDetails(getServerDetails());
    //window.location.reload();
  }

  const saveToken = userToken => {

    localStorage.setItem('token', JSON.stringify(userToken));

    setToken(userToken);

  };

  const saveServerDetails = userServerToken => {

    localStorage.setItem('serverDetails', JSON.stringify(userServerToken));

    setServerDetails(userServerToken);

  };

  return {

    setToken: saveToken,

    token,

    setServerDetails : saveServerDetails,

    serverDetails,

    clearToken

  }

}