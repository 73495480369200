
import * as React from "react";
import { Box } from "@mui/system";
import { Button,Checkbox, Switch,Grid, InputLabel, List,Divider, FormControlLabel, Typography,ButtonGroup,Snackbar ,Alert ,IconButton, Icon,Dialog,DialogTitle,DialogContent,DialogActions,DialogContentText, cardMediaClasses } from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Paper from '@mui/material/Paper';
import { faker } from '@faker-js/faker';
import { format } from 'date-fns';
import { useState,useRef,useEffect } from "react";
import useInnerTable from "./components/useInnerTable";
import InfoIcon from '@mui/icons-material/Info';
import useTable from "./components/useTable";
import SvgIcon from "@mui/material/SvgIcon";
import NoImage from './asset/noimage.png';
import Popup from "./components/Popup";
import _ from "lodash";
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { styled } from '@mui/system';
import axios from "./axios";
import PuffLoader from "react-spinners/PuffLoader"; 
import Autocomplete from '@mui/material/Autocomplete';
import { useAutocomplete } from '@mui/base/AutocompleteUnstyled';
import useToken from "./useToken";
import GlobalAlert from "./GlobalAlert";
import TimezoneSelect from 'react-timezone-select'
import LoadingButton from "@mui/lab/LoadingButton";
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


function EditCallingGroup ({idOfuser,idGroupName,RefreshToken,... props}){
  const [EnLog,setEnLog] = useState([]);
  const {token} = useToken();
  console.log('idogroupd '+JSON.stringify(idOfuser.Office_Group));
  let [loading, setLoading] = useState(false);
  let [iPhoneUserData, setiPhoneUserData] = useState(idOfuser.Users);
  let [androidUserData, setandroidUserData]= useState([]);
  let [residentUserData, setresidentUserData]=useState(idOfuser.Office_Group);
  const { getAlertTitle} = GlobalAlert();
  const getMyPostData = async () => {
    try {
      const res = await axios.get("/admin/callgroupedit/"+token.id+"/"+idOfuser.id,{
        headers: { 'Content-Type':  'application/json' ,
        'token' : token.token,
        'tokenid' : token.email},
      });
      console.log(res.data);
      setEnLog(res.data);
      setiPhoneUserData(res.data.Users);
     // setandroidUserData(res.data.Android);
      setresidentUserData(res.data.Office_Group);
      setLoading(false);
      setloadingbtn(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      setloadingbtn(false);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  const deleteMyGroupPostData = async () => {
    console.log(deleteData);
    setloadingbtn(true);
    try {
      const res = await axios.delete("/admin/deletecallusergroup/"+token.id+"/"+deleteData.user_type+"/"+deleteData.device_id+"/"+idOfuser.id,
      {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      'token' : token.token,
      'tokenid' : token.email,
      'issub':token.issub,
      'subId':token.subid,
      'username':deleteData.user_name, 
      'groupName':idGroupName}});
      console.log( res.data);
      setOpenSnackData(getAlertTitle('EFGUDS'));
      setSeverityData("success");
      setOpenSnack(true);
     // setLoading(false);
      getMyPostData();
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      setloadingbtn(false);
      setOpenSnackData(getAlertTitle('EFGUDF'));
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });
  const headCells = [
    { id: 'username', label: 'User Name' },
    { id: 'phone_no', label: 'Phone #' },
    { id: 'user_email', label: 'Email' },
    { id: 'type', label: 'User Type' },
    { id: 'access', label: 'Action', disableSorting: true },
  ];
  const {
    TblContainer ,
    TblHead ,
    TblPagination ,
    BelowTblPagination,
    recordsAfterPagingAndSorting ,
    setPageToNewOne 
  } = useInnerTable(residentUserData, headCells, filterFn,8,650);
  const Input = styled('input')(({ theme }) => ({
    width: 'auto',
            fontWeight: '700',
            fontSize: '17px',
            height:'40px',
            
            '& .MuiFilledInput-root': {
                border: '1px solid rgba(31, 39, 54, 1)',
                '&:hover': {
                    color:'rgba(255, 255, 255, 1)',
        height:'33px',
        borderRadius: '5px',
        backgroundColor: '#121212',
        boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
        padding: '0 0 15px 0',
        textIndent: '15px !important',
                },
                '&.Mui-focused': {
                    color:'rgba(255, 255, 255, 1)',
        height:'33px',
        borderRadius: '5px',
        backgroundColor: '#121212',
        boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
        padding: '0 0 15px 0',
        textIndent: '15px !important',
                },
              },
          
  }));

  const Listbox = styled('ul')(({ theme }) => ({
    width: 200,
    margin: 0,
    padding: 0,
    zIndex: 1,
    position: 'absolute',
    listStyle: 'none',
    backgroundColor: "#1E1E1E",
    overflow: 'auto',
    maxHeight: 200,
    border: '1px solid rgba(0,0,0,.25)',
    '& li.Mui-focused': {
      backgroundColor: '#121212',
      color: 'white',
      cursor: 'pointer',
    },
    '& li:active': {
      backgroundColor: '#121212',
      color: 'white',
    },
  }));
 
  const [openSnack, setOpenSnack] = React.useState(false);
  const groupAccessName =['Beta Tester','James Home','Everyone On The list'];
  const [iPhoneUser, setiPhoneUser] = useState('James Webb');
  const [androidUser, setAndroidUser] = useState('Derek Masa');
  const [showTable, setShowTable] = useState(0);
  const handleCloseSnack= (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
 
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id: 'use-autocomplete-demo',
    options: iPhoneUser,
    getOptionLabel: (option) => option.label,
  });
 
  const showTableOfSelcted = {
    id: "1",
    label: "By Name",
    items: [
      {
        label: "By Name",
        callback: () =>  {
        setShowTable(0);
        },
      },
      {
        label: "By Phone",
        callback: () =>  {
        setShowTable(1);
        },
      },
      
      
    ]
  };
 
  let [loadingbtn,setloadingbtn] = useState(false);
  const [titleforalert, setTitleforalert] = React.useState("");
  const [currentDeleteType, setCurrentDeleteType] = useState('');
  const [open, setOpen] = React.useState(false);
  const [openadd, setOpenAdd] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseCancel = () => {
    setOpenAdd(false);
  };
  const handleCloseActive =() =>{
    setOpen(false);
    deleteMyGroupPostData();
  }
  const handleCloseAccept = () => {
    setOpenAdd(false);
    addGroupPost();
  }
  const handleEnable = (val) => {
    updateGroupPost({id:val,web_status:1, issub:token.issub,subId:token.subid});
  }
  const handleDisable = (val) => {
    updateGroupPost({id:val,web_status:0,issub:token.issub,subId:token.subid});
  }
  const [deleteData, setDeleteData] = useState();
  const handleDelete = (val,val2,val3) => {
    setTitleforalert("Are you sure you want to delete user "+ val2 + " from Family Group?");
    setDeleteData({
      device_id : val,
      group_id : 1,
      user_type : val3,
      user_id : token.id,
      user_name:val2,
    });
    console.log(val2);
    setOpen(true);
  }
  
  const [openSnackData, setOpenSnackData] = React.useState("This is Success Data");
  const [severityData, setSeverityData] = React.useState("success");
  const updateGroupPost = async (val) => {
    console.log("In update post data");
    setloadingbtn(true);
    try {
      const res = await axios.patch("/admin/updatewebresident/"+token.id+"/",val, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      'token' : token.token,
      'tokenid' : token.email }});
      setOpenSnackData("Resident User Changes Updated Successfully.");
      setSeverityData("success");
      setOpenSnack(true);
      //setLoading(false);
      //setshowUpdateButton(false);
      console.log(res.data);
      getMyPostData();
     
    } catch (error) {
      console.log(error.response.data);
      setLoading(false);
      setloadingbtn(false);
      setOpenSnackData("Resident User Changes Updated Failed, try again later");
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  const addGroupPost = async () => {
    console.log("In update post data");
    setloadingbtn(true);
    try {
      const res = await axios.post("/admin/addnewusergroup/"+token.id,{group_id:idOfuser.id,groupName:idGroupName,device_id:currentSelectedval.devicetoken,user_id:token.id,user_type:currentSelectedval.type,issub:token.issub,subId:token.subid,username:currentSelectedval.username}, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      'token' : token.token,
      'tokenid' : token.email }});
      setOpenSnackData(getAlertTitle('EFGUAS'));
      setSeverityData("success");
      console.log(res.data);
      setOpenSnack(true);
      //setLoading(false);
      //setshowUpdateButton(false);
      getMyPostData();
     
    } catch (error) {
      console.log(error.response.data);
      setLoading(false);
      setloadingbtn(false);
      setOpenSnackData(getAlertTitle('EFGUAF'));
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  const [currentSelectedval,setCurrentSelectedval] = useState();
  const handleChangeUserName = (event,newValue) => {
    setCurrentSelectedval(newValue);
    console.log(newValue);
  }
  const handleAddMessage = () => {
    if(currentSelectedval == null){
      setOpenSnackData(getAlertTitle('EFGUNS'));
      setSeverityData("error");
      setOpenSnack(true);
    }
    else{
      setTitleforalert("You are about to add "+ currentSelectedval.username + " to the family group.");
      setOpenAdd(true);
    }
   
  }
  return <> <Box sx={mainThemeStyle.boxTopHeader}>
  
</Box>
<Box sx={mainThemeStyle.boxHeaderBelow}>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                <Stack direction='row' spacing={2} padding={2}>
                <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Search
                   </Typography>
                <NestedButtonDropdown
            itemId={showTableOfSelcted.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.filterButtonCenter}
            data={showTableOfSelcted}
          />
            <Autocomplete
            style={{marginTop: 13,minWidth: 250}}
             sx={{  "& .MuiAutocomplete-inputRoot": {
              color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
                color: "rgba(170, 170, 170, 1)",
              },}}
            disablePortal
          onChange={handleChangeUserName}
          variant="filled"
          hiddenLabel
          options={iPhoneUserData}
          getOptionLabel={(option) => showTable == 0 ? option.username : option.phone_no}
          PaperComponent={({ children }) => (
            <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
          )}
          renderInput={(params) => <TextField {...params}  variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
        
            
        }} />}
          >
            
          </Autocomplete>
          
                   <LoadingButton loading={loadingbtn} sx={mainThemeStyle.normalButtonDown} onClick={handleAddMessage}> Add </LoadingButton>
                  {/*  <Tooltip title="MyDoorView users added to this Calling Group will receive all incoming calls intended for the Property Office. If multiple users are added to the calling group, the user who answers the call first will be connected to the entry panel.  Users may temporarily disable these calls by using the 'Do Not Disturb' setting in their app. ">
      <IconButton  size="small" color="primary" >
        <InfoOutlinedIcon />
      </IconButton>
    </Tooltip> */}
                   </Stack>
 </div> </div>
           <Divider sx={{backgroundColor:'white'}} />

          
    
     
   <TblContainer>
    <TblHead/>
        <TableBody>
         {residentUserData.map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
          
              <TableCell align="left" sx={mainThemeStyle.cellRow}>
              {row.username}
              </TableCell>
              <TableCell align="left" sx={mainThemeStyle.cellRow}>
              {row.phone_no}
              </TableCell>
              <TableCell align="left" sx={mainThemeStyle.cellRow}>
              {row.user_email}
              </TableCell>
            <TableCell align="left" sx={mainThemeStyle.cellRow}>
              {row.type}
              </TableCell>
             
                 <TableCell align="left"> 
             <Stack direction="row" spacing={1} ><Typography></Typography><LoadingButton  loading={loadingbtn} sx={mainThemeStyle.viewLogButtonRed} onClick={() => {handleDelete(row.devicetoken,row.username,row.type)}}>Delete</LoadingButton></Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      
      </TblContainer>
    
   <BelowTblPagination></BelowTblPagination>
    <PuffLoader color="#878787" loading={loading} cssOverride={{display: "block",
    margin: "0 auto",
    marginTop:"50px",
    marginBottom:"50px",}} />

</Box>
<Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={severityData} sx={{ width: '100%' }}>
          {openSnackData}
        </Alert>
      </Snackbar>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          padding: '16px',
      
         backgroundColor:'#00000000',
         
      }}
      >
        <DialogTitle id="alert-dialog-title" sx={{
        
      
         backgroundColor:'#2A2F3B',
         color:'white'
         
      }}>
          {titleforalert}
        </DialogTitle>
        <DialogContent sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          
        </DialogContent>
        <DialogActions sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          <Button onClick={handleClose} sx={mainThemeStyle.normalBigButton}>Cancel</Button>
          <Button onClick={handleCloseActive} autoFocus sx={mainThemeStyle.normalBigButtonRed}>
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openadd}
        onClose={handleCloseCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          padding: '16px',
      
         backgroundColor:'#00000000',
         
      }}
      >
        <DialogTitle id="alert-dialog-title" sx={{
        
      
         backgroundColor:'#2A2F3B',
         color:'white'
         
      }}>
          {titleforalert}
        </DialogTitle>
        <DialogContent sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          
        </DialogContent>
        <DialogActions sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          <Button onClick={handleCloseAccept} sx={mainThemeStyle.normalBigButton}>Accept</Button>
          <Button onClick={handleCloseCancel} autoFocus sx={mainThemeStyle.normalBigButtonRed}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
 </> 
          
          
          
          
          ;
};

export default EditCallingGroup;