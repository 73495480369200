import React from "react";
import { Box } from "@mui/system";
import { Button, Grid, InputLabel, List, Typography,ButtonGroup,Snackbar ,Alert ,IconButton, Icon,Dialog,DialogTitle,DialogContent,DialogActions,DialogContentText } from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';
import PaymentIcon from '@mui/icons-material/Payment';
import {  Form } from "react-bootstrap";
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { faker } from '@faker-js/faker';
import { format } from 'date-fns';
import { useState,useRef,useEffect } from "react";
import useTable from "./components/useTable";
import SvgIcon from "@mui/material/SvgIcon";
import NoImage from './asset/noimage.png';
import Popup from "./components/Popup";
import _ from "lodash";
import GlobalAlert from "./GlobalAlert";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import axios from "./axios";
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import PuffLoader from "react-spinners/PuffLoader";
import EditIcon from '@mui/icons-material/Edit';
import useToken from "./useToken";
import GlobalRoleAccess from "./GlobalRoleAccess";
import GlobalRoleAccessInner from "./GlobalRoleAccessInner";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { useParams,useNavigate  } from "react-router-dom";
import validateInfo from './validateInfo';
import Cards from 'react-credit-cards-2';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
  formatFormData
} from "./utils";

const headCells = [
  { id: 'ordernumber', label: 'Order Number'},
  { id: 'company_name', label: 'Dealer Company' },
  { id: 'user_property_name', label: 'Property Name' },
 
  { id: 'user_first_name', label: 'User Name' },
  { id: 'user_phone_no', label: 'User Cell #' },
  { id: 'user_email', label: 'User Email' },
  { id: 'orderdate', label: 'Date' },
  { id: 'orderaddress', label: 'Order Address' },
  { id: 'ordercity', label: 'Order City' },
  { id: 'orderstate', label: 'Order State' },
  { id: 'orderzipcode', label: 'Order Zip' },
  { id: 'all_order_subtotal_after_discount_plus_payment_charge', label: 'Order Total' },

  { id: 'action', label: 'Action', disableSorting: true  },
]


let Alphabet =['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];



function ManageOrder ( props){
    const {token} = useToken();
  const navigate = useNavigate();
  const { getAlertTitle} = GlobalAlert();
  const {RefreshToken,switchToSubUser} = props;
  const myContainer = useRef(null);
  const [shownameType, setShownameType] = useState(true);
  const [EnLog,setEnLog] = useState([]);
  const [showDateAdded,setShowDateAdded] = useState(false);
  const [showPropertyNameType, setShowPropertyNameType] = useState(true);
  const [showCompanyName, setShowCompanyName] = useState(false);
  const [showDealerCompany, setShowDealerCompany] = useState(false);
  const [showTotalUnit, setShowTotalUnit] = useState(false);
  const [showMonthlyCost, setShowMonthlyCost] = useState(false);
  const [showAnnualCost, setShowAnnualCost] = useState(false);
  const [showOfficeNumber, setShowOfficeNumber] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const [showCity, setShowCity] = useState(false);
  const [showState, setShowState] = useState(false);
  const [showZipCode, setShowZipCode] = useState(false);
  const [showLocationCount, setShowLocationCount] = useState(false);
  const [errors, setErrors] = useState({})
  const [values, setValues] = useState({
    cardName: '',
    cardNumber: '',
    cardType: '',
    cardExpiration: '',
    cardSecurityCode: '',
    cardPostalCode: '',
    focus: ''
})

  const [filterText, setfilterText] = useState('');
  const [resetClick, setResetClicked] = useState(false);
  const [currentId, setCurrentId] = useState(0);
  const [openPopup, setOpenPopup] = useState(false);
  const {getRoleAccessVal} = GlobalRoleAccess();
  const {getRoleAccessInnerVal} = GlobalRoleAccessInner();
  const [openPopupPayment, setOpenPopupPayment] = useState(false);
  const FilterMenu = {
    id: "1",
    label: "Contact Name",
    items: [
      {
        label: "Contact Name",
        callback: () =>  {
            setShownameType(true);
            setShowPropertyNameType(true);
            setShowCompanyName(false);
            setShowDealerCompany(false);
            setShowTotalUnit(false);
            setShowMonthlyCost(false);
            setShowAnnualCost(false);
            setShowDateAdded(false);
            setShowOfficeNumber(false);
            setShowEmail(false);
            setShowPassword(false);
            setShowAddress(false);
            setShowCity(false);
            setShowState(false);
            setShowZipCode(false);
            setShowLocationCount(false);
         if(document.getElementById('input-location') != null)
            document.getElementById('input-location').value ='';
        }
         
      },
      {
        label: "Dealer Company",
        callback: () =>  {
            setShownameType(true);
            setShowPropertyNameType(false);
            setShowCompanyName(false);
            setShowDealerCompany(true);
            setShowTotalUnit(false);
            setShowMonthlyCost(false);
            setShowDateAdded(false);
            setShowAnnualCost(false);
            setShowOfficeNumber(false);
            setShowEmail(false);
            setShowPassword(false);
            setShowAddress(false);
            setShowCity(false);
            setShowState(false);
            setShowZipCode(false);
            setShowLocationCount(false);
         if(document.getElementById('input-location') != null)
            document.getElementById('input-location').value ='';
        }
         
      },
      {
        label: "Property Name",
        callback: () =>  {
            setShownameType(true);
            setShowPropertyNameType(false);
            setShowCompanyName(true);
            setShowDealerCompany(false);
            setShowTotalUnit(false);
            setShowMonthlyCost(false);
            setShowDateAdded(false);
            setShowAnnualCost(false);
            setShowOfficeNumber(false);
            setShowEmail(false);
            setShowPassword(false);
            setShowAddress(false);
            setShowCity(false);
            setShowState(false);
            setShowZipCode(false);
            setShowLocationCount(false);
         if(document.getElementById('input-location') != null)
            document.getElementById('input-location').value ='';
        }
         
      },
      {
        label: "Order Number",
        callback: () =>  {
            setShownameType(true);
            setShowPropertyNameType(false);
            setShowCompanyName(false);
            setShowDealerCompany(false);
            setShowTotalUnit(true);
            setShowDateAdded(false);
            setShowMonthlyCost(false);
            setShowAnnualCost(false);
            setShowOfficeNumber(false);
            setShowEmail(false);
            setShowPassword(false);
            setShowAddress(false);
            setShowCity(false);
            setShowState(false);
            setShowZipCode(false);
            setShowLocationCount(false);
         if(document.getElementById('input-location') != null)
            document.getElementById('input-location').value ='';
        }
         
      },
      {
        label: "User Cell",
        callback: () =>  {
            setShownameType(true);
            setShowPropertyNameType(false);
            setShowCompanyName(false);
            setShowDealerCompany(false);
            setShowDateAdded(false);
            setShowTotalUnit(false);
            setShowMonthlyCost(true);
            setShowAnnualCost(false);
            setShowOfficeNumber(false);
            setShowDateAdded(false);
            setShowEmail(false);
            setShowPassword(false);
            setShowAddress(false);
            setShowCity(false);
            setShowState(false);
            setShowZipCode(false);
            setShowLocationCount(false);
         if(document.getElementById('input-location') != null)
            document.getElementById('input-location').value ='';
        }
         
      },
      {
        label: "Order Date",
        callback: () =>  {
            setShownameType(false);
            setShowPropertyNameType(false);
            setShowCompanyName(false);
            setShowDealerCompany(false);
            setShowTotalUnit(false);
            setShowMonthlyCost(false);
            setShowAnnualCost(false);
            setShowDateAdded(true);
            setShowOfficeNumber(false);
            setShowEmail(false);
            setShowPassword(false);
            setShowAddress(false);
            setShowCity(false);
            setShowState(false);
            setShowZipCode(false);
            setShowLocationCount(false);
         if(document.getElementById('input-location') != null)
            document.getElementById('input-location').value ='';
        }
         
      },
      {
        label: "Order Total Cost",
        callback: () =>  {
            setShownameType(true);
            setShowPropertyNameType(false);
            setShowCompanyName(false);
            setShowDealerCompany(false);
            setShowTotalUnit(false);
            setShowMonthlyCost(false);
            setShowDateAdded(false);
            setShowAnnualCost(false);
            setShowOfficeNumber(true);
            setShowEmail(false);
            setShowPassword(false);
            setShowAddress(false);
            setShowCity(false);
            setShowState(false);
            setShowZipCode(false);
            setShowLocationCount(false);
         if(document.getElementById('input-location') != null)
            document.getElementById('input-location').value ='';
        }
         
      },
      {
        label: "User Email",
        callback: () =>  {
            setShownameType(true);
            setShowPropertyNameType(false);
            setShowCompanyName(false);
            setShowDealerCompany(false);
            setShowTotalUnit(false);
            setShowMonthlyCost(false);
            setShowDateAdded(false);
            setShowAnnualCost(false);
            setShowOfficeNumber(false);
            setShowEmail(true);
            setShowPassword(false);
            setShowAddress(false);
            setShowCity(false);
            setShowState(false);
            setShowZipCode(false);
            setShowLocationCount(false);
         if(document.getElementById('input-location') != null)
            document.getElementById('input-location').value ='';
        }
         
      },
      {
        label: "Order Address",
        callback: () =>  {
            setShownameType(true);
            setShowPropertyNameType(false);
            setShowCompanyName(false);
            setShowDealerCompany(false);
            setShowTotalUnit(false);
            setShowMonthlyCost(false);
            setShowDateAdded(false);
            setShowAnnualCost(false);
            setShowOfficeNumber(false);
            setShowEmail(false);
            setShowPassword(false);
            setShowAddress(true);
            setShowCity(false);
            setShowState(false);
            setShowZipCode(false);
            setShowLocationCount(false);
         if(document.getElementById('input-location') != null)
            document.getElementById('input-location').value ='';
        }
         
      },
      {
        label: "Order City",
        callback: () =>  {
            setShownameType(true);
            setShowPropertyNameType(false);
            setShowCompanyName(false);
            setShowDealerCompany(false);
            setShowTotalUnit(false);
            setShowMonthlyCost(false);
            setShowDateAdded(false);
            setShowAnnualCost(false);
            setShowOfficeNumber(false);
            setShowEmail(false);
            setShowPassword(false);
            setShowAddress(false);
            setShowCity(true);
            setShowState(false);
            setShowZipCode(false);
            setShowLocationCount(false);
         if(document.getElementById('input-location') != null)
            document.getElementById('input-location').value ='';
        }
         
      },
      {
        label: "Order State",
        callback: () =>  {
            setShownameType(true);
            setShowPropertyNameType(false);
            setShowCompanyName(false);
            setShowDealerCompany(false);
            setShowTotalUnit(false);
            setShowMonthlyCost(false);
            setShowAnnualCost(false);
            setShowDateAdded(false);
            setShowOfficeNumber(false);
            setShowEmail(false);
            setShowPassword(false);
            setShowAddress(false);
            setShowCity(false);
            setShowState(true);
            setShowZipCode(false);
            setShowLocationCount(false);
         if(document.getElementById('input-location') != null)
            document.getElementById('input-location').value ='';
        }
         
      },
      {
        label: "Order ZipCode",
        callback: () =>  {
            setShownameType(true);
            setShowPropertyNameType(false);
            setShowCompanyName(false);
            setShowDateAdded(false);
            setShowDealerCompany(false);
            setShowTotalUnit(false);
            setShowMonthlyCost(false);
            setShowAnnualCost(false);
            setShowOfficeNumber(false);
            setShowEmail(false);
            setShowPassword(false);
            setShowAddress(false);
            setShowCity(false);
            setShowState(false);
            setShowZipCode(true);
            setShowLocationCount(false);
         if(document.getElementById('input-location') != null)
            document.getElementById('input-location').value ='';
        }
         
      }
    ]
  };
  let [loading, setLoading] = useState(true);

  const getDisableValueEdit = () => {
    console.log('role is '+ token.role);
    if(token.role == 'superadmin' || token.name === 'SUPER ADMIN'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("32").administrator_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("32").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'distributor' || token.name === 'SUPER DISTRIBUTOR'){
      console.log('role is '+ token.sub_type);
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("32").distributor_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("32").distributor_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'dealer'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("32").dealer_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("32").dealer_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
    
    
  }
  const handleSubmitCard = e => {
    e.preventDefault();
    let eroor = validateInfo(values)
    setErrors(eroor);
    if(eroor.variant != "success"){
      setOpenSnackData(eroor.message);
      setSeverityData("error");
      setOpenSnack(true);
    }else {
      console.log("card number "+ values.cardNumber);
      console.log("card name "+ values.cardName);
      console.log("card postal code "+ values.cardPostalCode);
      console.log("card cvv "+ values.cardSecurityCode);
      console.log("card expiry "+ values.cardExpiration);
      setLoading(true);
      payOrder()
    }
};
const hanldleCancelPaymentPressed = () =>{
  setOpenPopupPayment(false);
  setOpenPopup(false);
  setLoading(false);
  getMyPostData();
}

const handleChange = e => {
  //const { name, value } = e.target

  if (e.target.name === "cardNumber") {
    e.target.value = formatCreditCardNumber(e.target.value);
  } else if (e.target.name === "cardExpiration") {
    e.target.value = formatExpirationDate(e.target.value);
  } else if (e.target.name === "cardSecurityCode") {
    e.target.value = formatCVC(e.target.value);
  }
  setValues({
      ...values,
      [e.target.name]: e.target.value
  })
}
const handleFocus = (e) => {
  setValues({ 
      ...values,
      focus: (e.target.name === 'cardSecurityCode') ? 'cvc' : e.target.name
  });
}
  const getMyPostData = async () => {
    try {
      const res = await axios.get("/administrator/getorderlist/",{
        headers: { 'Content-Type':  'application/json' ,
        'token' : token.token,
        'tokenid' : token.email},
      });
      console.log(res.data);
       setEnLog(res.data);
      setRecords(res.data);
      setLoading(false);
    } catch (error) {
      console.log(error.response);
      setLoading(false);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  const payOrder = async () => {
    let cardexp = values.cardExpiration;
    let cardmonth = cardexp.slice(0, 2);
    let cardyear = "20"+ cardexp.slice(3, 5);
    const cardnumber = (values.cardNumber).replace(/ /g, '')
    const requestBody = {
      'amount' : currentOrder.all_order_subtotal_after_discount_plus_payment_charge,
      'card_name' : values.cardName,
      'card_number' : cardnumber,
      'address' : currentOrder.orderaddress,
      'city' : currentOrder.ordercity,
      'state' : currentOrder.orderstate,
      'zip' : currentOrder.orderzipcode,
      'card_exp_month' : cardmonth,
      'card_exp_year' :cardyear,
      'card_cvc' : values.cardSecurityCode,
      'orderid' : currentOrder.id,
      

  };
 
  
  console.log("request body "+ JSON.stringify(requestBody));
  const requestOptions = {
    method: 'POST',
    headers: {
        Accept:  'application/json',
        
    },
    body: JSON.stringify(requestBody)
};

  fetch("https://mdvaccess.com/php/orderpayment.php",requestOptions
      ).then( async  response => {
          const data = await response.json();
          //console.log(data);
          if(data.result != 'Success'){
            setOpenSnackData(data.error);
            setSeverityData("error");
            setOpenSnack(true);
            
          }else{
            setOpenSnackData("Order Paid Successfully.");
            setSeverityData("success");
            setOpenSnack(true);
            getMyPostData();
          }
              
      }).catch(error => {
          console.log(error);
         
      }).finally(() => {
        setLoading(false);
            setOpenPopupPayment(false);
      });
  }
  useEffect(() => {
    getMyPostData();
  }, []);
  const [records, setRecords] = useState(EnLog)
  const [filterFn, setFilterFn] = useState({ fn: items => { return items; } })
  const [openSnackData, setOpenSnackData] = React.useState("This is Success Data");
  const [severityData, setSeverityData] = React.useState("success");
  const handleChangeText = e => {
    console.log(e.target.value);
    let value = e.target.value;
    setfilterText(value);
    console.log(filterText);
}
  const {
      TblContainer,
      TblHead,
      TblPagination,
      BelowTblPagination,
      recordsAfterPagingAndSorting,
      setPageToNewOne
  } = useTable(records, headCells, filterFn,"Download Order History");

  const getDataforVal = (value) => {
    try{
    let ad = JSON.parse(value);
    if(ad.id != null){
      return true;
    }
    else{
      return false;
    }
    }
    catch {
      return false;
    }
  }

  const handleReset =() =>{
    setfilterText('');
    if(document.getElementById('input-location') != null)
    document.getElementById('input-location').value ='';
    
          setFilterFn({
            fn: items => {
              return items;
                    
            }
        })
        setPageToNewOne();
  }
  const checkAtOrNot = (value) =>{
     let ad = records.filter(x => x.user_property_name.toLowerCase().startsWith(value.toLowerCase()));
     console.log(ad.length);
     return ad.length;
  }

  const jumpToAlphabet = (val) =>{
    console.log('value is '+val);
    setFilterFn({
      fn: items => {
        return items.filter(x => x.user_property_name.toLowerCase().startsWith(val.toLowerCase()))
              
      }
  })
  setPageToNewOne();
  }
  const [openDelete, setOpenDelete] = React.useState(false);
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleCloseActiveDelete =() =>{
    setOpenDelete(false);
   
  }
  const [orderRefNumber, setOrderRefNumber] = useState("");
  const [orderRefId, setOrderRefId] = useState("");
  const [orderAmount, setOrderAmount] = useState(0);
  const [currentOrder, setcurrentOrder] = useState([]);
  const ChangeId = (val,val2) =>{
    console.log(currentId);
    if(val2 == 0){
      let disableval = getDisableValueEdit();
      if(disableval == true){
        setOpenSnackData("You don't have access to perform this action");
        setSeverityData("error");
        setOpenSnack(true);
      }
      else{
      let idofItem =  records.filter(x => x.id == val);
      let itemtopass = idofItem[0];
      navigate('../extra/edit',  {state:{editorder : itemtopass} })
      }
     
    }
    if(val2 == 1){
      let idofItem =  records.filter(x => x.id == val);
      setcurrentOrder(idofItem[0]);
      setOrderAmount(idofItem[0]["all_order_subtotal_after_discount_plus_payment_charge"]);
      setOrderRefNumber(idofItem[0]["ordernumber"]);
      setOrderRefId(idofItem[0]["id"]);
      setOpenPopupPayment(true);
     
    }
  }
  
  const [open, setOpen] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [titleforalert, setTitleforalert] = React.useState("");
  

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseActive =() =>{
    setOpen(false);
    }
  const handleCloseSnack= (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
  
  
  const handleSearch = () => {
    //let target = e.target;
    setFilterFn({
        fn: items => {
            if (filterText == "")
                return items;
            else{
              if(shownameType){
                console.log(shownameType);
                if(showPropertyNameType)
                return items.filter(x => x.user_first_name.toString().toLowerCase().includes(filterText.toLowerCase()))

                else if(showCompanyName)
                return items.filter(x => x.user_property_name.toString().toLowerCase().includes(filterText.toLowerCase()))

                else if(showDealerCompany)
                return items.filter(x => x.company_name.toString().toLowerCase().includes(filterText.toLowerCase()))


               
                else if(showTotalUnit)
                return items.filter(x => x.ordernumber.toString().toLowerCase().includes(filterText.toLowerCase()))

                else if(showMonthlyCost)
                return items.filter(x => x.user_phone_no.toString().toLowerCase().includes(filterText.toLowerCase()))

             
                else if(showOfficeNumber)
                return items.filter(x => x.all_order_subtotal_after_discount_plus_payment_charge.toString().toLowerCase().includes(filterText.toLowerCase()))

                else if(showEmail)
                return items.filter(x => x.user_email.toString().toLowerCase().includes(filterText.toLowerCase()))

              
                else if(showAddress)
                return items.filter(x => x.orderaddress.toString().toLowerCase().includes(filterText.toLowerCase()))

                else if(showCity)
                return items.filter(x => x.ordercity.toString().toLowerCase().includes(filterText.toLowerCase()))

                else if(showState)
                return items.filter(x => x.orderstate.toString().toLowerCase().includes(filterText.toLowerCase()))

                else if(showZipCode)
                return items.filter(x => x.orderzipcode.toString().toLowerCase().includes(filterText.toLowerCase()))
                
                
                else 
                return items;
                
              }
              else if(showDateAdded){
                
                var mystr = filterText;
                mystr = moment(mystr).format('YYYY-MM-DD');
                return items.filter(x => x.orderdate.toLowerCase().includes(mystr))
              
              }
              else{
                return items;
              }
            }
                
        }
    })
    setPageToNewOne();
}
  return <><Box>
   
    <Typography sx={mainThemeStyle.lablefilter}>Filter</Typography>
  
  <Box sx={{position: 'absolute',
           width:'680px',
           height: '280px',
           top:'160px',
           left: '38px',
           display: { xs: "none", md: "flex" },
           right: '38px',
           flexGrow: 1,
          
           }}>
             
           <Stack direction="row" spacing={2} >
                  
                   <NestedButtonDropdown
            itemId={FilterMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.filterButton}
            data={FilterMenu}
          /> {shownameType  &&
           
          <Typography sx={mainThemeStyle.lableContains}>Contains</Typography>}
              
                   
{shownameType &&
                   
                   <TextField
                        id="input-location"
                        ref={myContainer}
                        type= 'text'
                        name="text"
                        onChange={handleChangeText}
                        sx={mainThemeStyle.InputFilter}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                           
                            sx: {
                                
                                color: 'rgba(255, 255, 255, 1)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#171E29',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '5px 0 5px 0',
                                textIndent: '15px !important',
                                border: `1px solid rgba(31, 39, 54, 1)`,
                               
                                
                            },
                        }}
                       
                    />}
                    {showDateAdded  && <TextField
        id="date"
        type="date"
        hiddenLabel
        sx={mainThemeStyle.InputFilter}
        onChange={handleChangeText}
        InputLabelProps={{
          shrink: true,
        }}
        variant="filled"
        InputProps={{
                           
          sx: {
              
              color: 'rgba(255, 255, 255, 1)',
              height:'33px',
              borderRadius: '5px',
              backgroundColor: '#171E29',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '5px 0 5px 0',
              textIndent: '15px !important',
              border: `1px solid rgba(31, 39, 54, 1)`,
             
              
          },
      }}/>}
                   
                   <Button sx={mainThemeStyle.filterResetButton} onClick={handleSearch}>Go</Button>
                   
                   <Button sx={mainThemeStyle.filterResetButton} onClick={handleReset}>Reset Filters</Button>
                  </Stack>
                  </Box>
          
  <Box sx={{position: 'absolute',
           width:'auto',
           height: '280px',
           top:'160px',
           left: '38px',
           display: { xs: "flex", md: "none" },
           right: '38px',
           flexGrow: 1,
          
           }}>
             <Grid container  direction="column">
             <Grid item >
           <Stack direction="row" spacing={2} >
                  
                   <NestedButtonDropdown
            itemId={FilterMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.filterButton}
            data={FilterMenu}
          /> {/* {shownameType  &&
           
          <Typography sx={mainThemeStyle.lableContains}>Contains</Typography>} */}
                
                  

{shownameType &&
                   
                   <TextField
                        id="input-location"
                        ref={myContainer}
                        type= 'text'
                        name="text"
                        onChange={handleChangeText}
                        sx={mainThemeStyle.InputFilterSmall}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                           
                            sx: {
                                
                                color: 'rgba(255, 255, 255, 1)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#171E29',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '5px 0 5px 0',
                                textIndent: '15px !important',
                                border: `1px solid rgba(31, 39, 54, 1)`,
                               
                                
                            },
                        }}
                       
                    />}
                    {showDateAdded  && <TextField
        id="date"
        type="date"
        hiddenLabel
        sx={mainThemeStyle.InputFilter}
        onChange={handleChangeText}
        InputLabelProps={{
          shrink: true,
        }}
        variant="filled"
        InputProps={{
                           
          sx: {
              
              color: 'rgba(255, 255, 255, 1)',
              height:'33px',
              borderRadius: '5px',
              backgroundColor: '#171E29',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '5px 0 5px 0',
              textIndent: '15px !important',
              border: `1px solid rgba(31, 39, 54, 1)`,
             
              
          },
      }}/>}
                    </Stack></Grid>
                   <Grid >
                   <Stack direction="row" >
                   <Button sx={mainThemeStyle.filterResetButtonSmall} onClick={handleSearch}>Go</Button>
                   
                   <Button sx={mainThemeStyle.filterResetButtonSmall} onClick={handleReset}>Reset Filters</Button>
                   </Stack>
                 </Grid></Grid></Box>
  
  
                 <Typography sx={mainThemeStyle.lableJump}>Jump</Typography>
                 
                 <ButtonGroup variant="text"  sx={{position: 'absolute',
           maxWidth:'250px',
           height: 'auto',
           top:'250px',
           left: '38px',
           display: { xs: "none", md: "flex" }
           }}>

          

          {
            Alphabet.map(item => (
              checkAtOrNot(item) > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  onClick={() => {jumpToAlphabet(item)}}>{item}</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>{item}</Button>
            ))
          }

    </ButtonGroup>
    
   
   <Box sx={mainThemeStyle.mainTablebox} backgroundColor="menubarprimary.main">
   <BelowTblPagination ></BelowTblPagination>
    <TblContainer>
                    <TblHead />
                    <TableBody>
                        {
                            recordsAfterPagingAndSorting().map(item =>
                                ( <TableRow
                                  key={item.id}
                                  sx={{ borderBottom: "1.5px solid black",'&:last-child td, &:last-child th': { border: 0 },
                                   }}
                                >


 
                                    <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.ordernumber}</TableCell>
                                    <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.company_name}</TableCell>
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.user_property_name}</TableCell>
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.user_first_name + " " +item.user_last_name}</TableCell>
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.user_phone_no}</TableCell>
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.user_email}</TableCell>
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.orderdate}</TableCell>
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.ordercity}</TableCell>
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.orderaddress}</TableCell>
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.orderstate}</TableCell>
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.orderzipcode}</TableCell>
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>$ {item.all_order_subtotal_after_discount_plus_payment_charge}</TableCell>
                                  {
                                    getDataforVal(item.payment_transaction) == false && 
                                    <TableCell>
                                
            
                                    <IconButton sx={{ backgroundColor: '#2A2F3B'}}  size="small" color="primary"  onClick={() => {ChangeId(item.id,1)}}><PaymentIcon /></IconButton>
                                    
                                  </TableCell>
                                  }
                                <TableCell>
                                
            
                                    <IconButton sx={{ backgroundColor: '#2A2F3B'}}  size="small" color="primary"  onClick={() => {ChangeId(item.id,0)}}><EditIcon /></IconButton>
                                    
                                  </TableCell>
                                </TableRow>)
                            )
                        }
                    </TableBody>
                   
                </TblContainer>
                <Box sx={{marginTop:'10px'}}>
                <PuffLoader color="#878787" loading={loading} cssOverride={{display: "block",
    margin: "0 auto",
    marginTop:"50px",
    marginBottom:"50px",}} />



</Box>
{  <Popup
                title={"ORDER # " + orderRefNumber +" PAYMENT" }
                openPopup={openPopupPayment}
                setOpenPopup={setOpenPopupPayment}
                noclose={true}
            >
             
             <><Box sx={{
        display:  "flex" , alignContent: "center"
        }}>
      <Cards
         cvc={values.cardSecurityCode}
         expiry={values.cardExpiration}
         focused={values.focus}
         name={values.cardName}
         number={values.cardNumber}
      />
      </Box>
      <Box sx={{
      display:  "flex" ,
      width : "auto",
      height : "20px"
      }}></Box>
       
        <Box sx={{
      display:  "flex" ,
      width : "auto",
      
                justifyContent: 'center',
                alignItems: 'center',
      }}><Form onSubmit={handleSubmitCard} >
      <Stack spacing={2} direction="column" justifyContent="center"> 
      
            <Form.Group>
              <Form.Control
                type="text"
                id="cardName"
                data-testid="cardName"
                name="cardName"
                placeholder="Cardholder Name"
                value={values.cardName}
                onChange={handleChange}
                onFocus={handleFocus}
                isValid={errors.cname}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="tel"
                id="cardNumber"
                data-testid="cardNumber"
                name="cardNumber"
                placeholder="Card Number"
                pattern="[\d| ]{16,22}"
                value={values.cardNumber}
                onChange={handleChange}
                onFocus={handleFocus}
                isValid={errors.cnumber}
              />
            </Form.Group>
            
            <Stack spacing={2} direction="row" justifyContent="center"> 
                <Form.Group>
                  <Form.Control
                    type="tel"
                    id="cardExpiration"
                    data-testid="cardExpiration"
                    name="cardExpiration"
                    placeholder="Expiration Date"
                    pattern="\d\d/\d\d"
                    value={values.cardExpiration}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    isValid={errors.cexp}
                  />
                </Form.Group>
              
            
                <Form.Group>
                  <Form.Control
                    type="tel"
                    id="cardSecurityCode"
                    data-testid="cardSecurityCode"
                    name="cardSecurityCode"
                    placeholder="Security Code"
                    pattern="\d{3,4}"
                    value={values.cardSecurityCode}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    isValid={errors.ccvv}
                  />
                </Form.Group>
                </Stack>
                
               
            <LoadingButton
         
         sx={mainThemeStyle.normalBigButton}
         loading={loading}
       
         variant="outlined"
         type="submit"
       >
         { " Pay  $ "+ orderAmount }
       </LoadingButton> 
      
       
       <LoadingButton
         
         sx={mainThemeStyle.normalBigButtonRed}
         loading={loading}
       
         variant="outlined"
         onClick={hanldleCancelPaymentPressed}
       >
         Cancel
       </LoadingButton> 
        
      </Stack> </Form>
      
      </Box>
        </>
               
            </Popup> }

</Box>
                 </Box>
                 <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={severityData} sx={{ width: '100%' }}>
          {openSnackData}
        </Alert>
      </Snackbar>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          padding: '16px',
      
         backgroundColor:'#00000000',
         
      }}
      >
        <DialogTitle id="alert-dialog-title" sx={{
        
      
         backgroundColor:'#2A2F3B',
         color:'white'
         
      }}>
          {titleforalert}
        </DialogTitle>
        <DialogContent sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          
        </DialogContent>
        <DialogActions sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          <Button onClick={handleClose} sx={mainThemeStyle.normalBigButton}>Cancel</Button>
          <Button onClick={handleCloseActive} autoFocus sx={mainThemeStyle.normalBigButton}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          padding: '16px',
      
         backgroundColor:'#00000000',
         
      }}
      >
        <DialogTitle id="alert-dialog-title" sx={{
        
      
         backgroundColor:'#2A2F3B',
         color:'white'
         
      }}>
          {titleforalert}
        </DialogTitle>
        <DialogContent sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          
        </DialogContent>
        <DialogActions sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          <Button onClick={handleCloseDelete} sx={mainThemeStyle.normalBigButton}>Cancel</Button>
          <Button onClick={handleCloseActiveDelete} autoFocus sx={mainThemeStyle.normalBigButtonRed}>
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog></> 
          
          
          
          
          ;
};

export default ManageOrder;