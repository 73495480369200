import * as React from "react";
import { Box } from "@mui/system";
import { Button,Checkbox, Switch,Grid, InputLabel, List,Divider, FormControlLabel, Typography,ButtonGroup,Snackbar ,Alert ,IconButton, Icon,Dialog,DialogTitle,DialogContent,DialogActions,DialogContentText, cardMediaClasses } from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { faker } from '@faker-js/faker';
import { format } from 'date-fns';
import { useState,useRef,useEffect } from "react";
import useTable from "./components/useTable";
import SvgIcon from "@mui/material/SvgIcon";
import NoImage from './asset/noimage.png';
import Popup from "./components/Popup";
import _ from "lodash";
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { styled } from '@mui/material/styles';
import GlobalAlert from "./GlobalAlert";
import axios from "./axios";
import useInnerTable from "./components/useInnerTable";
import LoadingButton from '@mui/lab/LoadingButton';
import GlobalRoleAccess from "./GlobalRoleAccess";
import GlobalRoleAccessInner from "./GlobalRoleAccessInner";
import PuffLoader from "react-spinners/PuffLoader"; 
import useToken from "./useToken";
import TimezoneSelect from 'react-timezone-select'
import { LocationData } from "./components/header/header.data";
import { BreakfastDiningOutlined } from "@mui/icons-material";
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


function PINBlackList ( props){
  const {token} = useToken();
  const [EnLog,setEnLog] = useState([]);
  const {RefreshToken} = props;
  const { getAlertTitle} = GlobalAlert();
  let [loading, setLoading] = useState(true);
  const [LocationDataMenu,setLocationDataMenu] =useState( {
    id: "1",
    label: "Select Location",
    items: []
  });
  let [loadingbtn, setloadingbtn] = useState(false);
  const [records,setRecords] = useState([]);
  const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });
  const headCells = [
    { id: 'pin', label: 'PIN Blacklist' },
    { id: 'note', label: 'Note'},
    { id: 'date', label: 'Date Added'},
    { id: 'access', label: 'Action', disableSorting: true },
  ];
  const {getRoleAccessVal} = GlobalRoleAccess();
  const {getRoleAccessInnerVal} = GlobalRoleAccessInner();
  
  const [blackpindata,setblackpindata] = useState("");
  const [apiValue, setAPIValue] = useState({id:"",pin_blaacklist:""});
  const [currentPinLength, setCurrentPinLength] = useState();
  const [currentLocationId, setCurrentLocationId] = useState();
  const [currentLocationName, setCurrentLocationName] = useState();
  const [showUpdateButton, setshowUpdateButton] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [openSnackData, setOpenSnackData] = React.useState("This is Success Data");
  const [severityData, setSeverityData] = React.useState("success");
  const [openDelete, setOpenDelete] = React.useState(false);
  const [titleforalert, setTitleforalert] = React.useState("");
  const handleCloseSnack= (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
 /*  const handleChangeBlackLP = (event) => {
    //var index = records.indexOf(event.target.name); 
    let blck = '';
    let anyZero = 0;
    for(let lt = 0; lt < records.length ; lt ++){
      if((records[lt].trim()).length == 0){
        anyZero = 1;
        break;
      }
      else{
        if(lt == records.length -1){
          blck = blck + records[lt] ;
        }else{
        blck = blck + records[lt] + ",";
      }
      }
    }
    if(anyZero == 0){
    setAPIValue({id:currentLocationId,pin_blaacklist:blck});
    console.log(blck);
    updateMyPostData();
    }
    else{
      setOpenSnackData("LP Blacklist Can't Be Null, Please Enter LP Blacklist");
      setSeverityData("error");
      setOpenSnack(true);
    }
  } */
  const [currentDeleteId, setCurrentDeleteId] = useState(0);
  const [deleteBLPIN, setDeleteBLPIN] = useState('');

  const getDisableValueEdit = () => {
    if(token.role == 'superadmin' || token.name === 'SUPER ADMIN'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("60").administrator_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("60").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'distributor' || token.name === 'SUPER DISTRIBUTOR'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("60").distributor_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("60").distributor_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'dealer' || token.name === 'SUPER DEALER'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("60").dealer_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("60").dealer_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'admin'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("60").admin_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("60").admin_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
    
    
  }
  const getDisableValueAdd = () => {
    if(token.role == 'superadmin' || token.name === 'SUPER ADMIN'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("59").administrator_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("59").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'distributor' || token.name === 'SUPER DISTRIBUTOR'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("59").distributor_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("59").distributor_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'dealer' || token.name === 'SUPER DEALER'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("59").dealer_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("59").dealer_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'admin'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("59").admin_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("59").admin_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
    
    
  }
  const getDisableValueDelete = () => {
    if(token.role == 'superadmin' || token.name === 'SUPER ADMIN'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("61").administrator_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("61").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'distributor' || token.name === 'SUPER DISTRIBUTOR'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("61").distributor_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("61").distributor_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'dealer' || token.name === 'SUPER DEALER'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("61").dealer_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("61").dealer_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'admin'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("61").admin_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("61").admin_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
    
    
  }
  const handleDelete = (event) => {
    console.log(event.target.name);
    setCurrentDeleteId(event.target.name);
    setDeleteBLPIN(event.target.id);
    setTitleforalert("Are you sure you want to delete Blacklist PIN "+event.target.id + " ?");
    setOpenDelete(true);


  }
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleCloseActiveDelete =() =>{
    setOpenDelete(false);
    setloadingbtn(true);
    deleteMyPostData();
  }
  const deleteMyPostData = async () => {
    try {
      const res = await axios.delete("/admin/deleteblacklistpin/"+token.id+"/"+currentDeleteId,
      {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      'token' : token.token,
      'tokenid' : token.email,
      'issub':token.issub,
      'subId':token.subid,
      'pin':deleteBLPIN }});
      console.log( 'delete data'+res.data);
      setOpenSnackData(getAlertTitle('BPINDS'));
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      setloadingbtn(false);
      getMyPostData();
    } catch (error) {
      console.log(error);
      setLoading(false);
      setOpenSnackData(getAlertTitle('BPINDF'));
      setSeverityData("error");
      setloadingbtn(false);
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  /* const handleDelete = (event) => {
    
    var index = event.target.name; 
    console.log('event name'+event.target.name + " index is "+ index);
    console.log('event name'+records);
    
    if(index > -1){
      records.splice(index,1);
    }

    console.log(records);
    let blck = '';
    for(let lt = 0; lt < records.length ; lt ++){
      if(lt == records.length -1){
        blck = blck + records[lt] ;
      }else{
      blck = blck + records[lt] + ",";
    }
    }
    apiValue.pin_blaacklist = blck;
    apiValue.id = currentLocationId;
    setAPIValue({id:currentLocationId,pin_blaacklist:blck});
    console.log(blck);
    updateMyPostData();


  } */
  
  const mainRecordArray = [];
  const [currentId,setCurrentId] = useState();
  
  const getMyPostData = async () => {
    try {
      const res = await axios.get("/admin/blacklistpin/"+token.id,{
        headers: { 'Content-Type':  'application/json',
        'token' : token.token,
        'tokenid' : token.email },
      });
      console.log("data format"+JSON.stringify(res.data));
      setEnLog(res.data);
      LocationDataMenu.items = [];
      //mainRecordArray = [];
      setDataOfAdd('');
      setDataOfNote('');
      let itemsToFeed = [];
      res.data.forEach(element => {
        itemsToFeed.push( {label: element.loc_name, callback: () =>  {
          setblackpindata(element.pin_blaacklist);
          setCurrentId(element.id);
          setRecords(element.pin_blaacklist != ""?element.pin_blaacklist:[]);
          //mainRecordArray.push(element.pin_blaacklist != ""?(element.pin_blaacklist).split(','):[]);
          setCurrentPinLength(element.pin_length);
          setCurrentLocationName(element.loc_name);
          setCurrentLocationId(element.id);
          setshowUpdateButton(false);
        }});
        console.log(element.loc_name);
      });
      LocationDataMenu.items = itemsToFeed;
      setLocationDataMenu(LocationDataMenu);
      console.log(LocationDataMenu);
      console.log('current id is '+ currentId);
      if(currentLocationId >= 0){
        for(let ls = 0; ls < (res.data).length ; ls ++){
          let element = res.data[ls];
          console.log(' we  element id is '+ element.id);
          if(element.id == currentLocationId){
            console.log(' we fount current id is '+ currentId);
        setRecords(element.pin_blaacklist != ""?element.pin_blaacklist:[]);
        setCurrentPinLength(element.pin_length);
        setCurrentLocationName(element.loc_name);
      }
       // setCurrentLocationId(res.data[currentId].id);
        
      }
    }
    } catch (error) {
      console.log(error);
      setLoading(false);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  useEffect(() => {
    getMyPostData();
  }, []);
  const updateMyPostData = async (val) => {
    //console.log(apiValue);
    setloadingbtn(true);
    try {
      const res = await axios.post("/admin/blacklistpin/"+token.id,val, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      'token' : token.token,
      'tokenid' : token.email }});
      console.log( res.data);
      setOpenSnackData("Blacklist PIN datails successfully added for Location "+currentLocationName);
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      setloadingbtn(false);
      setshowUpdateButton(false);
      getMyPostData();
    } catch (error) {
      console.log(error);
      setLoading(false);
      setloadingbtn(false);
      setOpenSnackData(getAlertTitle('BPINAF'));
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  const handleInputChange=(event) =>{
    //console.log(apiValue);
    //setshowUpdateButton(true);
    //setblackpindata(event.target.value);
    //setAPIValue({id:currentLocationId,pin_blaacklist:event.target.value});
    var index = event.target.name; 
    records[index] = event.target.value;
    setRecords(records);
    console.log(records);
  }
  const [dataOfAdd,setDataOfAdd] = useState('');
  const handleLPBlackChange = (event) => {
    setDataOfAdd(event.target.value);
  }
  const [dataOfNote,setDataOfNote] = useState('');
  const handleLPBlackChangeNOte = (event) => {
    setDataOfNote(event.target.value);
  }
  const handleAddBlackLP = () => {

    if(currentLocationId >= 0){
    let datatype = 0;
   
    if(isNaN(parseFloat(dataOfAdd))) {
        datatype = 1;
       
    }
    if(dataOfAdd.length != parseInt(currentPinLength)){
        datatype = 2;
    }
    
    if(datatype == 1){
      setOpenSnackData(getAlertTitle('BPINND'));
      setSeverityData("error");
      setOpenSnack(true);
    }
    else if(datatype == 2){
      setOpenSnackData("Blacklist PIN length for Location " + currentLocationName + " should be "+currentPinLength+" digit only.");
      setSeverityData("error");
      setOpenSnack(true);
    }else{
      /*let blck = '';
      for(let lt = 0; lt < records.length ; lt ++){
        
        blck = blck + records[lt] + ",";
      
      }
      blck = blck + dataOfAdd;
      apiValue.pin_blaacklist = blck;
      apiValue.id = currentLocationId;
     // setAPIValue(apiValue);
     setAPIValue({id:currentLocationId,pin_blaacklist:blck});
      console.log(blck);*/
      updateMyPostData({pin:dataOfAdd,loc_name:currentLocationName,pin_length:currentPinLength,note:dataOfNote,loc_id:currentLocationId,user_id:token.id,"issub":token.issub,"subId":token.subid});
    }
  }
  else{
    setOpenSnackData(getAlertTitle('BPINLNS'));
    setSeverityData("error");
    setOpenSnack(true);
  }
   
  
  }
  const {
    TblContainer,
    TblHead,
    TblPagination ,
    BelowTblPagination,
    recordsAfterPagingAndSorting ,
    setPageToNewOne ,
  } = useInnerTable(records, headCells, filterFn,5,650);
 /*  const handleSaveCardFormat = () => {
    let array = blackpindata.split(',');
    let datatype = 0;
    array.forEach(element => {
      if(isNaN(parseFloat(element))) {
        datatype = 1;
        return;
      }
      if(element.length != parseInt(currentPinLength)){
        datatype = 2;
        return;
      }
    });
    //console.log('datatype '+datatype);
    if(datatype == 1){
      setOpenSnackData("Blacklist PIN should be digit only");
      setSeverityData("error");
      setOpenSnack(true);
    }
    else if(datatype == 2){
      setOpenSnackData("Blacklist PIN length for Location " + currentLocationName + " should be "+currentPinLength+" digit only.");
      setSeverityData("error");
      setOpenSnack(true);
    }else{
      updateMyPostData();
    }
  } */
  return <> <Box sx={mainThemeStyle.boxTopHeader}>
  
</Box>
<Box sx={mainThemeStyle.boxHeaderBelow}>
<Box  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px',display: { xs: "none", md: "flex" }}}>
               <div style={{ display: 'flex' }}>
                <Stack direction='row' spacing={2} padding={2}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Add Blacklist PIN
                   </Typography>
                   <NestedButtonDropdown
            itemId={LocationDataMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            disabled = {getDisableValueAdd()}
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'34px',
            borderRadius: '5px',
            marginTop:'15px',
            marginLeft:'5px',
            marginRight:'5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            textIndent: '15px !important',}}
            data={LocationDataMenu}
          />
                   <TextField
        id="text"
        type="number"
      placeholder="Blacklist PIN"
      value={dataOfAdd}
      disabled = {getDisableValueAdd()}
       onChange={handleLPBlackChange}
       variant="filled"
                        InputLabelProps={{ shrink: true }
                      }
                          style={{paddingTop: '16px'}}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                               
                                
                            },
                        }}
                       
      />
        <TextField
        id="text"
        type="text"
      placeholder="Note"
      value={dataOfNote}
       onChange={handleLPBlackChangeNOte}
       disabled = {getDisableValueAdd()}
       variant="filled"
                        InputLabelProps={{ shrink: true }
                      }
                          style={{paddingTop: '16px'}}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                               
                                
                            },
                        }}
                       
      />
      <LoadingButton sx={mainThemeStyle.normalButtonDown} loading={loadingbtn} onClick={handleAddBlackLP} disabled = {getDisableValueAdd()}> Add </LoadingButton>
      </Stack>
               </div>
              
           </Box>

           <Box  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px',display: { xs: "flex", md: "none" }}}>
               <Stack direction='column' spacing={0.1} padding={2}>
                <Stack direction='row' spacing={2} padding={2}>
                   <Typography sx={mainThemeStyle.popUpLable}>
                       Add Blacklist PIN
                   </Typography>
                   <NestedButtonDropdown
            itemId={LocationDataMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            disabled = {getDisableValueAdd()}
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'34px',
            borderRadius: '5px',
            marginTop:'0px',
            marginLeft:'5px',
            marginRight:'5px',
            lineHeight:'0.93rem',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            textIndent: '15px !important',}}
            data={LocationDataMenu}
          />
          </Stack><Stack direction='row' spacing={2} padding={2}>
                   <TextField
        id="text"
        type="number"
      placeholder="Blacklist PIN"
      value={dataOfAdd}
       onChange={handleLPBlackChange}
       variant="filled"
       disabled = {getDisableValueAdd()}
                        InputLabelProps={{ shrink: true }
                      }
                       
                        InputProps={{
                           
                            sx: {
                              marginTop:'-1rem',
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                               
                                
                            },
                        }}
                       
      />
        <TextField
        id="text"
        type="text"
      placeholder="Note"
      value={dataOfNote}
       onChange={handleLPBlackChangeNOte}
       variant="filled"
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValueAdd()}
                        InputProps={{
                           
                            sx: {
                                marginTop:'-1rem',
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                                
                            },
                        }}
                       
      /> </Stack>
      <LoadingButton sx={{
          width: 'auto',
          height: '2.06rem',
          
          
          fontWeight: '700',
          fontSize: '0.93rem',
          
          
          alignItems: 'center',
          color: '#FFFFFF',
          fontFamily: "Lato",
          borderRadius:'0.31rem',
         '&:hover': {
          backgroundColor: 'rgba(109, 195, 252, 1)',
         }
      }} loading={loadingbtn} onClick={handleAddBlackLP} disabled = {getDisableValueAdd()}> Add </LoadingButton>
      
               </Stack>
              
           </Box>
           <Divider sx={{backgroundColor:'white'}} />
           {/* <Typography sx={mainThemeStyle.popUpWarner} height={20}> Note :-  Only {currentPinLength} Digit PINs Allowed.</Typography>
           <Typography sx={mainThemeStyle.popUpWarner} height={20}> Global Blacklist PIN :-  1111,2222...9999, 111111,222222...999999, 1234,9876, 123456,987654</Typography>
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>Global Blacklist PIN</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Location</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Blacklist PIN</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}></Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}></Typography>
      {showUpdateButton &&  <Button sx={mainThemeStyle.normalButton} onClick={handleSaveCardFormat}>Save Blacklist PINs</Button>} 
      </Stack>
      </Grid>
      
      <Grid item xs={4}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLableAnswer}height={40}>1111,2222...9999, 111111,222222...999999, 1234,9876, 123456,987654</Typography>
                    
     
                    
                    <TextField
         
          multiline
          rows={4}
          
          value={blackpindata}
          variant="filled"
          onChange={handleInputChange}
          InputProps={{
                           
            sx: {
                
                color:'rgba(135, 135, 135, 0.5)',
               
                borderRadius: '5px',
                backgroundColor: '#121212',
                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                padding: '5px 0 5px 0',
                textIndent: '15px !important',
              
               
                
            },
        }}
        />
      </Stack>
    
      
      </Grid>
     
     
      
      </Grid> */}
      <TblContainer>
                    <TblHead />

        <TableBody>
         {recordsAfterPagingAndSorting().map(items => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              
              <TableCell align="left"  sx={mainThemeStyle.cellRow}>  {items.pin}</TableCell>
              <TableCell align="left"  sx={mainThemeStyle.cellRow}>  {items.note}</TableCell>
              <TableCell align="left"  sx={mainThemeStyle.cellRow}>  {items.date}</TableCell>
              <TableCell align="left">
               {/*  <Stack direction='row' spacing={2}>
                  <Typography></Typography> */}
              {/* <LoadingButton sx={mainThemeStyle.viewLogButton} loading={loadingbtn} onClick={handleChangeBlackLP}> Save Changes </LoadingButton> */}
                <LoadingButton loading={loadingbtn} name={items.id} id={items.pin} sx={mainThemeStyle.viewLogButtonRed} disabled = {getDisableValueDelete()} onClick={handleDelete} >Delete</LoadingButton>
                {/* </Stack> */} </TableCell>
            </TableRow>
          ))}
        </TableBody>
      
    </TblContainer>
    <BelowTblPagination ></BelowTblPagination>



</Box>
<Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          padding: '16px',
      
         backgroundColor:'#00000000',
         
      }}
      >
        <DialogTitle id="alert-dialog-title" sx={{
        
      
         backgroundColor:'#2A2F3B',
         color:'white'
         
      }}>
          {titleforalert}
        </DialogTitle>
        <DialogContent sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          
        </DialogContent>
        <DialogActions sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          <Button onClick={handleCloseDelete} sx={mainThemeStyle.normalBigButton}>Cancel</Button>
          <Button onClick={handleCloseActiveDelete} autoFocus sx={mainThemeStyle.normalBigButtonRed}>
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>
<Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={severityData} sx={{ width: '100%' }}>
          {openSnackData}
        </Alert>
      </Snackbar>
 </> 
          
          
          
          
          ;
};

export default PINBlackList;