import * as React from "react";
import { Box } from "@mui/system";
import { Button,Checkbox, Switch,Grid, InputLabel,Select, List,Divider,MenuItem, FormControlLabel, Typography,ButtonGroup,Snackbar ,Alert ,IconButton, Icon,Dialog,DialogTitle,DialogContent,DialogActions,DialogContentText } from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { faker } from '@faker-js/faker';
import { format } from 'date-fns';
import { useState,useRef,useEffect } from "react";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import GlobalRoleAccess from "./GlobalRoleAccess";
import GlobalRoleAccessInner from "./GlobalRoleAccessInner";
import axios from "./axios";
import useTable from "./components/useTable";
import SvgIcon from "@mui/material/SvgIcon";
import NoImage from './asset/noimage.png';
import Popup from "./components/Popup";
import _ from "lodash";
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { styled } from '@mui/material/styles';
import GlobalAlert from "./GlobalAlert";
import useToken from "./useToken";
import TimezoneSelect from 'react-timezone-select'
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


function EditDealer ( {idOfDealer,dataOfDistributor,RefreshToken,...props}){

  let [dataOfDealer,setDataOfDealer] = useState(idOfDealer);

  const [openSnack, setOpenSnack] = React.useState(false);
  const [openSnackData, setOpenSnackData] = React.useState("This is Success Data");
  const [severityData, setSeverityData] = React.useState("success");
  const { getAlertTitle,globalAlertData,} = GlobalAlert();
  console.log('alert datal'+JSON.stringify(globalAlertData));
  const {token} = useToken();
  const handleCloseSnack= (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
  const [showUpdateButton,setshowUpdateButton] = useState(false);
  const {getRoleAccessVal} = GlobalRoleAccess();
  const {getRoleAccessInnerVal} = GlobalRoleAccessInner();
  const [loading,setLoading] = useState(false);
  const [isVisible,setIsVisibile] = useState(false);
  const onClickTogglePassword = () =>{
    setIsVisibile(!isVisible);
  }
  const setDealerData = () => {
    setshowUpdateButton(true);
    setDataOfDealer({
      del_id: dataOfDealer.del_id,
      pid: dataOfDealer.pid,
      dis_id: dataOfDealer.dis_id,
      company_name: dataOfDealer.company_name,
      contact_name: dataOfDealer.contact_name,
      street_add: dataOfDealer.street_add,
      city: dataOfDealer.city,
      state: dataOfDealer.state,
      zip_code: dataOfDealer.zip_code,
      telephone: dataOfDealer.telephone,
      email: dataOfDealer.email,
      password: dataOfDealer.password,
      unit_cost:dataOfDealer.unit_cost,
      total_unit:dataOfDealer.total_unit,
      start_date:dataOfDealer.start_date,
      accept_payment:dataOfDealer.accept_payment,
      issub:token.issub,
      subId:token.subid

    });
  }
  const changeTimeZone  =  (date, timeZone)=> {
    const laDate = moment.utc(date).format();
    //if (typeof date === 'string') {
      const ldated =  new Date(
        new Date(laDate).toLocaleString('en-US', {
          timeZone,
        }),
      );
      const mystr = moment(ldated).format('YYYY-MM-DD');
      return mystr;
   /* }
  
    return new Date(
      date.toLocaleString('en-US', {
        timeZone,
      }),
    );*/
  }
  const handleInputChange = (event) =>{
    let ds = event.target.name;
    dataOfDealer[ds] = event.target.value;
    setDealerData();
  }
  const handlecheckboxCheck=(event) =>{

    let ds = event.target.name;

    dataOfDealer[ds] = event.target.checked == true ? "1" : "0";

   // setshowUpdateButton(true);

   setDealerData();

  }
  const getDisableValue = (val) => {
    if(token.role == 'superadmin'){
      if(token.sub_type == "1"){
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("5");
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].access_name == val ){
            idData = i;
            break;
          }
        }
        let valOfDisable = valOfDisableData[idData].administrator_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("5");
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].access_name == val ){
            idData = i;
            break;
          }
        }
        let valOfDisable = valOfDisableData[idData].administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
   
    
  }
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const handleUpdateDistributor = () =>{
    if(dataOfDealer.contact_name == ""){
      setOpenSnackData(getAlertTitle('EDNNE'));
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    if(dataOfDealer.company_name == ""){
      setOpenSnackData(getAlertTitle('EDCNNE'));
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    if(dataOfDealer.email == ""){
      setOpenSnackData(getAlertTitle('EDENE'));
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    if(!isValidEmail(dataOfDealer.email)){
      setOpenSnackData(getAlertTitle('EDENV'));
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    if(dataOfDealer.password.length  < 1 ){
      setOpenSnackData(getAlertTitle('EDPNE'));
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    updateMyPostData();
  }
  const updateMyPostData = async () => {
    setLoading(true);
    try {
      const res = await axios.patch("/administrator/dealer/",dataOfDealer, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8' ,
      'token' : token.token,
      'tokenid' : token.email}});
      console.log( res.data);
      console.log(JSON.stringify(globalAlertData));
      setOpenSnackData(getAlertTitle('EDDUS'));
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      setshowUpdateButton(false);
    } catch (error) {
      console.log(error.response.data);
      setLoading(false);
      setOpenSnackData(getAlertTitle('EDDUF'));
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  const GetDistributorCompany = () => {
    for(let i = 0; i < dataOfDistributor.length ; i++){
      if(dataOfDistributor[i].dis_id == dataOfDealer.dis_id){
        return dataOfDistributor[i].company_name;
      }
    }

  };
  const handleSetDistributor = (event) => {
   
    for(let i = 0; i < dataOfDistributor.length ; i++){
      if(dataOfDistributor[i].company_name == event.target.value){
        console.log("evetm "+ event.target.value);
        dataOfDealer.dis_id = dataOfDistributor[i].dis_id;
        setDealerData();
        break;
      }
    }
   
  };
  const MenuProps = {
    PaperProps: {
      style: {
        backgroundColor: "#1E1E1E",
          borderRadius: 0,
          maxHeight:'250px',
          borderRadius: 10,
          padding:0
      },
    },
  };
 
  return <> <Box sx={mainThemeStyle.boxTopHeader}>
     
            
</Box>
<Box sx={mainThemeStyle.boxHeaderBelow}>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                      Edit Dealer Details
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           <Box sx={{display: { xs: "none", md: "flex" }}}>
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>Contact Name </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Company Name </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Distributor Company</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Address </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>City </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> State </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> ZipCode </Typography>
      
      </Stack>
      </Grid>
      
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
         <TextField
       
       type="text"
       name = "contact_name"
       onChange={handleInputChange}
       value={dataOfDealer.contact_name}
       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     disabled = {getDisableValue("Contact Name")}
                         variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                              
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                             
                           },
                       }}
                      
     />
         <TextField
      
       type="text"
       name = "company_name"
       onChange={handleInputChange}
       value={dataOfDealer.company_name}
       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     disabled = {getDisableValue("Company Name")}
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                             
                              
                               
                           },
                       }}
                      
     />
  <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "app_unlock_mode"
            disabled = {getDisableValue("Distributor Company")}
            value={GetDistributorCompany()}
          label="Age"
          onChange={handleSetDistributor}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            { dataOfDistributor.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.company_name}>
                      {menuItem.company_name}
                    </MenuItem>
                  );
                })} 
          </Select>
         <TextField
      
       type="text"
       name = "street_add"
       onChange={handleInputChange}
       value={dataOfDealer.street_add}
       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     disabled = {getDisableValue("Address")}
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                             
                              
                               
                           },
                       }}
                      
     />
      <TextField
       
       type="text"
       name = "city"
       onChange={handleInputChange}
       value={dataOfDealer.city}
       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     disabled = {getDisableValue("City")}
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                             
                               
                           },
                       }}
                      
     />
     <TextField
                      
                      height={40}
                      type= 'text'
                      name = "state"
                       onChange={handleInputChange}
                      value={dataOfDealer.state}
                      sx={mainThemeStyle.InputMenuItem}
                      InputLabelProps={{ shrink: true }
                    }
                    disabled = {getDisableValue("State")}
                    variant="filled"
                      InputProps={{
                         
                          sx: {
                              
                              color:'rgba(135, 135, 135, 0.5)',
                              height:'33px',
                              borderRadius: '5px',
                              backgroundColor: '#121212',
                              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                              padding: '0 0 15px 0',
                              textIndent: '15px !important',
                              '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                             
                             
                              
                          },
                      }}
                     
                  />
                   <TextField
                       
                       height={40}
                       type= 'text'
                       name = "zip_code"
                       onChange={handleInputChange}
                       value={dataOfDealer.zip_code}
                       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     disabled = {getDisableValue("ZipCode")}
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                             
                               
                           },
                       }}
                      
                   />
      </Stack>
      </Grid>
      <Grid item xs={1}>
    
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        <Typography sx={mainThemeStyle.popUpLable} height={40}>Cell Phone #</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>Email</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>Password</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>Customer Since</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>Can Process Payment</Typography>
     {/*  <Typography sx={mainThemeStyle.popUpLable} height={40}>Unit Cost</Typography> */}
     {/*  <Typography sx={mainThemeStyle.popUpLable} height={40}>Total Unit</Typography>
      */}
      </Stack>
      </Grid>
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
        
      
      
                     
        <TextField
           
           height={40}
           type= 'number'
           name = "telephone"
           onChange={handleInputChange}
           value={dataOfDealer.telephone}
           sx={mainThemeStyle.InputMenuItem}
           InputLabelProps={{ shrink: true }
         }
         disabled = {getDisableValue("Cell Phone #")}
         variant="filled"
           InputProps={{
              
               sx: {
                   
                   color:'rgba(135, 135, 135, 0.5)',
                   height:'33px',
                   borderRadius: '5px',
                   backgroundColor: '#121212',
                   boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                   padding: '0 0 15px 0',
                   textIndent: '15px !important',
                 
                   '&.Mui-disabled': {
             

                    background: 'rgba(135, 135, 135, 0.5)',
                  },
                  
                  "& input.Mui-disabled": {
                    color: "white"
                  }
                 
                   
               },
           }}
          
       />
        <TextField
           
           height={40}
           type= 'text'
           name = "email"
           onChange={handleInputChange}
           value={dataOfDealer.email}
           sx={mainThemeStyle.InputMenuItem}
           InputLabelProps={{ shrink: true }
         }
         disabled = {getDisableValue("Email")}
         variant="filled"
           InputProps={{
              
               sx: {
                   
                   color:'rgba(135, 135, 135, 0.5)',
                   height:'33px',
                   borderRadius: '5px',
                   backgroundColor: '#121212',
                   boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                   padding: '0 0 15px 0',
                   textIndent: '15px !important',
                   '&.Mui-disabled': {
             

                    background: 'rgba(135, 135, 135, 0.5)',
                  },
                  
                  "& input.Mui-disabled": {
                    color: "white"
                  }
                 
                  
                   
               },
           }}
          
       />
       <TextField
           
           height={40}
           type= {isVisible ? 'text' : 'password'}
           name = "password"
           onChange={handleInputChange}
           value={dataOfDealer.password}
           margin="normal"
           variant = "filled"
           disabled = {getDisableValue("Password")}
           InputLabelProps={{
               shrink: true,
               style: {
               '&::placeholder': {
                   textOverflow: 'ellipsis !important',
                   color: 'blue'
                 },
               }
           }}
           
           InputProps={{
              
               sx: {
                   
                 color:'rgba(135, 135, 135, 0.5)',
                   height:'33px',
                   borderRadius: '5px',
                   backgroundColor: '#121212',
                   boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                   padding: '0 0 18px 0',
                   textIndent: '15px !important',
                   '&.Mui-disabled': {
             

                    background: 'rgba(135, 135, 135, 0.5)',
                  },
                  
                  "& input.Mui-disabled": {
                    color: "white"
                  }
                 
                   
               },
               
               endAdornment: (
                   <InputAdornment position="end">
                       <IconButton
                           style={{
                               color:
                               'rgba(135, 135, 135, 0.5)',
                               padding: '18px 5px 0 0',
                           }}
                           aria-label={
                               'visibility token'
                           }
                           onClick={onClickTogglePassword}
                       >
                          
                          {isVisible ? (
                           <VisibilityOff />
                       ) : (
                           <Visibility />
                       )}
                           
                       </IconButton>
                   </InputAdornment>
               ),
           }}
          
       />
        <TextField
           
           height={40}
           type= 'date'
           name = "start_date"
           onChange={handleInputChange}
           value={changeTimeZone(dataOfDealer.start_date, 'America/New_York')}
           sx={mainThemeStyle.InputMenuItem}
           InputLabelProps={{ shrink: true }
         }
        /*  disabled = {getDisableValue("Website URL")} */
         variant="filled"
           InputProps={{
              
               sx: {
                   
                   color:'rgba(135, 135, 135, 0.5)',
                   height:'33px',
                   borderRadius: '5px',
                   backgroundColor: '#121212',
                   boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                   padding: '0 0 15px 0',
                   textIndent: '15px !important',
                 
                   '&.Mui-disabled': {
             

                    background: 'rgba(135, 135, 135, 0.5)',
                  },
                  
                  "& input.Mui-disabled": {
                    color: "white"
                  }
                   
               },
           }}
          
       />
       <FormControlLabel  sx={mainThemeStyle.popUpLable}

control={<IOSSwitch sx={{ m: 1 }} name="accept_payment" onChange={handlecheckboxCheck} checked={dataOfDealer.accept_payment == "1" ? true : false } />}

label=""

/>
       {/*  <TextField
           
           height={40}
           type= 'number'
           name = "unit_cost"
           onChange={handleInputChange}
           value={dataOfDealer.unit_cost}
           sx={mainThemeStyle.InputMenuItem}
           InputLabelProps={{ shrink: true }
         }
         
         variant="filled"
           InputProps={{
              
               sx: {
                   
                   color:'rgba(135, 135, 135, 0.5)',
                   height:'33px',
                   borderRadius: '5px',
                   backgroundColor: '#121212',
                   boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                   padding: '0 0 15px 0',
                   textIndent: '15px !important',
                 
                  
                   
               },
           }}
          
       /> */}
        {/* <TextField
           
           height={40}
           type= 'number'
           name = "total_unit"
           onChange={handleInputChange}
           value={dataOfDealer.total_unit}
           sx={mainThemeStyle.InputMenuItem}
           InputLabelProps={{ shrink: true }
         }
         
         variant="filled"
           InputProps={{
              
               sx: {
                   
                   color:'rgba(135, 135, 135, 0.5)',
                   height:'33px',
                   borderRadius: '5px',
                   backgroundColor: '#121212',
                   boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                   padding: '0 0 15px 0',
                   textIndent: '15px !important',
                 
                  
                   
               },
           }}
          
       /> */}
       
</Stack>
      </Grid>
     
      
      </Grid>
</Box>
<Box sx={{display: { xs: "flex", md: "none" }}}>
           <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={5}>
        <Stack direction="column"  spacing={2}>
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>Contact Name </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Company Name </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Distributor Company</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Address </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>City </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> State </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> ZipCode </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>Cell Phone #</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>Email</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>Password</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>Customer Since</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>Can Process Payment</Typography>
      </Stack>
      </Grid>
      
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
         <TextField
       
       type="text"
       name = "contact_name"
       onChange={handleInputChange}
       value={dataOfDealer.contact_name}
       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     disabled = {getDisableValue("Contact Name")}
                         variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                             
                              
                               
                           },
                       }}
                      
     />
         <TextField
      
       type="text"
       name = "company_name"
       onChange={handleInputChange}
       value={dataOfDealer.company_name}
       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     disabled = {getDisableValue("Company Name")}
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                             
                              
                               
                           },
                       }}
                      
     />
  <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "app_unlock_mode"
            
            value={GetDistributorCompany()}
          label="Age"
          onChange={handleSetDistributor}
          variant="filled"
          disabled = {getDisableValue("Distributor Company")}
          hiddenLabel
          MenuProps={MenuProps}>
            { dataOfDistributor.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.company_name}>
                      {menuItem.company_name}
                    </MenuItem>
                  );
                })} 
          </Select>
         <TextField
      
       type="text"
       name = "street_add"
       onChange={handleInputChange}
       value={dataOfDealer.street_add}
       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     disabled = {getDisableValue("Address")}
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                             
                               
                           },
                       }}
                      
     />
      <TextField
       
       type="text"
       name = "city"
       onChange={handleInputChange}
       value={dataOfDealer.city}
       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     disabled = {getDisableValue("City")}
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                             
                               
                           },
                       }}
                      
     />
     <TextField
                      
                      height={40}
                      type= 'text'
                      name = "state"
                       onChange={handleInputChange}
                      value={dataOfDealer.state}
                      sx={mainThemeStyle.InputMenuItem}
                      InputLabelProps={{ shrink: true }
                    }
                    disabled = {getDisableValue("State")}
                    variant="filled"
                      InputProps={{
                         
                          sx: {
                              
                              color:'rgba(135, 135, 135, 0.5)',
                              height:'33px',
                              borderRadius: '5px',
                              backgroundColor: '#121212',
                              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                              padding: '0 0 15px 0',
                              textIndent: '15px !important',
                              '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                             
                             
                              
                          },
                      }}
                     
                  />
                   <TextField
                       
                       height={40}
                       type= 'text'
                       name = "zip_code"
                       onChange={handleInputChange}
                       value={dataOfDealer.zip_code}
                       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     disabled = {getDisableValue("ZipCode")}
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                             
                              
                               
                           },
                       }}
                      
                   />
                   <TextField
           
           height={40}
           type= 'number'
           name = "telephone"
           onChange={handleInputChange}
           value={dataOfDealer.telephone}
           sx={mainThemeStyle.InputMenuItem}
           InputLabelProps={{ shrink: true }
         }
         disabled = {getDisableValue("Cell Phone #")}
         variant="filled"
           InputProps={{
              
               sx: {
                   
                   color:'rgba(135, 135, 135, 0.5)',
                   height:'33px',
                   borderRadius: '5px',
                   backgroundColor: '#121212',
                   boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                   padding: '0 0 15px 0',
                   textIndent: '15px !important',
                 
                   '&.Mui-disabled': {
             

                    background: 'rgba(135, 135, 135, 0.5)',
                  },
                  
                  "& input.Mui-disabled": {
                    color: "white"
                  }
                 
                   
               },
           }}
          
       />
        <TextField
           
           height={40}
           type= 'text'
           name = "email"
           onChange={handleInputChange}
           value={dataOfDealer.email}
           sx={mainThemeStyle.InputMenuItem}
           InputLabelProps={{ shrink: true }
         }
         disabled = {getDisableValue("Email")}
         variant="filled"
           InputProps={{
              
               sx: {
                   
                   color:'rgba(135, 135, 135, 0.5)',
                   height:'33px',
                   borderRadius: '5px',
                   backgroundColor: '#121212',
                   boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                   padding: '0 0 15px 0',
                   textIndent: '15px !important',
                   '&.Mui-disabled': {
             

                    background: 'rgba(135, 135, 135, 0.5)',
                  },
                  
                  "& input.Mui-disabled": {
                    color: "white"
                  }
                 
                  
                   
               },
           }}
          
       />
       <TextField
           
           height={40}
           type= {isVisible ? 'text' : 'password'}
           name = "password"
           onChange={handleInputChange}
           value={dataOfDealer.password}
           margin="normal"
           variant = "filled"
           disabled = {getDisableValue("Password")}
           InputLabelProps={{
               shrink: true,
               style: {
               '&::placeholder': {
                   textOverflow: 'ellipsis !important',
                   color: 'blue'
                 },
               }
           }}
           
           InputProps={{
              
               sx: {
                   
                 color:'rgba(135, 135, 135, 0.5)',
                   height:'33px',
                   borderRadius: '5px',
                   backgroundColor: '#121212',
                   boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                   padding: '0 0 18px 0',
                   textIndent: '15px !important',
                  
                   '&.Mui-disabled': {
             

                    background: 'rgba(135, 135, 135, 0.5)',
                  },
                  
                  "& input.Mui-disabled": {
                    color: "white"
                  }
                 
               },
               
               endAdornment: (
                   <InputAdornment position="end">
                       <IconButton
                           style={{
                               color:
                               'rgba(135, 135, 135, 0.5)',
                               padding: '18px 5px 0 0',
                           }}
                           aria-label={
                               'visibility token'
                           }
                           onClick={onClickTogglePassword}
                       >
                          
                          {isVisible ? (
                           <VisibilityOff />
                       ) : (
                           <Visibility />
                       )}
                           
                       </IconButton>
                   </InputAdornment>
               ),
           }}
          
       />
        <TextField
           
           height={40}
           type= 'date'
           name = "start_date"
           onChange={handleInputChange}
           value={changeTimeZone(dataOfDealer.start_date, 'America/New_York')}
           sx={mainThemeStyle.InputMenuItem}
           InputLabelProps={{ shrink: true }
         }
        /*  disabled = {getDisableValue("Website URL")} */
         variant="filled"
           InputProps={{
              
               sx: {
                   
                   color:'rgba(135, 135, 135, 0.5)',
                   height:'33px',
                   borderRadius: '5px',
                   backgroundColor: '#121212',
                   boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                   padding: '0 0 15px 0',
                   textIndent: '15px !important',
                 
                   '&.Mui-disabled': {
             

                    background: 'rgba(135, 135, 135, 0.5)',
                  },
                  
                  "& input.Mui-disabled": {
                    color: "white"
                  }
                   
               },
           }}
          
       />
         <FormControlLabel  sx={mainThemeStyle.popUpLable}

control={<IOSSwitch sx={{ m: 1 }} name="accept_payment" onChange={handlecheckboxCheck} checked={dataOfDealer.accept_payment == "1" ? true : false } />}

label=""

/>
      </Stack>
      </Grid>
      
    
    
     
      
      </Grid>
</Box>
      {showUpdateButton &&  <Box sx= {mainThemeStyle.boxDown}><LoadingButton
         
         sx={mainThemeStyle.normalButtonRelative}
         loading={loading}
         onClick={handleUpdateDistributor}
         variant="outlined"
         startIcon={<SaveIcon />}
         loadingPosition="start"
       >
         Save Dealer Details
       </LoadingButton>
    <Typography></Typography>
      </Box>}
</Box>
<Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={severityData} sx={{ width: '100%' }}>
          {openSnackData}
        </Alert>
      </Snackbar>


 </> 
          
          
          
          
          ;
};

export default EditDealer;