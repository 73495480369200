import React from "react";
import {  ThemeProvider } from '@mui/material/styles';
import Theme from './components/ItemTheme'
import { Box } from "@mui/system";
import { Button, Grid, List, Typography } from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';

import { useParams,useNavigate  } from "react-router-dom";

const SetUp = props => {
  console.log("IN location Page");
  const navigate = useNavigate();
 
  const theme = Theme;
  
 const handleRFID = () =>{
  navigate('../');
 }
 

  return (
<ThemeProvider theme={theme} >

    <Box sx={mainThemeStyle.mainbox} backgroundColor="menubarprimary.main">
    <Box sx={mainThemeStyle.box} >
   <Typography sx={mainThemeStyle.lable}>Page Not Found</Typography>
   
    
    
    </Box>
    <Button sx={mainThemeStyle.activityButton} onClick={handleRFID} >Go Home</Button>
   
    </Box>
   


   
  
</ThemeProvider>);
};

export default SetUp;