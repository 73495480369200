import React from 'react';
import { createTheme  } from '@mui/material/styles';
import BrivoLogo from './components/Logo';
import Grid from '@mui/material/Grid';
import LoginForm from './components/LoginForm.js';
import ForgotPasswordForm from './PortalForgotPasswordForm';
import PortalUserLogin from './PortalUserLogin';
import PortalOtpLogin from './PoratlOtpLogin';

const PortalLogin = ({
    showForgotPasswordForm,
    showAdminLoginForm,
    showOTPLoginForm,
    errorMessage,
    successMessage,
    email,
    disableForgotPasswordSubmit,
    username,
    password,
    showPassword,
    disableSubmit,
    onChangeEmail,
    onForgotPasswordSubmit,
    onClickTogglePassword,
    onChangeUsername,
    onBlurEmail,
    onChangePassword,
    onLoginSubmit,
    onClickForgotPassword,
    phonenumber,
    disableUserSubmit,
    onUserSubmit,
    onClickAdminLogin,
    onChangePhoneNumber,
    onClickShowPreviousLogin,
    otp,
    onChangeOTP,
    onSubmitOTP,
    disableOTPSubmit,
    onClickPreviousFromOTP,
    t
    
}) => {

    return (
        <div>
            <Grid
                container
                spacing={2}
            alignContent="center"
            alignItems="center"
            justify="center"
            >
                <Grid
                    item
                    xs={12}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                 {showOTPLoginForm ? (<PortalOtpLogin
                  errorMessage={errorMessage}
                  successMessage={successMessage}
                  otp={otp}
                  onChangeOtp={onChangeOTP}
                  onSubmitOTP={onSubmitOTP}
                  disableOTPSubmit={disableOTPSubmit}
                  onClickShowPrevious={onClickPreviousFromOTP}
                 >

                 </PortalOtpLogin>) :(<>{showAdminLoginForm ? ( <>{showForgotPasswordForm ? (
                    <ForgotPasswordForm
                        errorMessage={errorMessage}
                        successMessage={successMessage}
                        email={email}
                        disableForgotPasswordSubmit={disableForgotPasswordSubmit}
                        onChangeEmail={onChangeEmail}
                        onSubmit={onForgotPasswordSubmit}
                        onClickShowPrevious={onClickForgotPassword}
                       
                    />
                ) : (
                        <LoginForm
                             errorMessage={errorMessage}
                             username={username}
                             password={password}
                             isVisible={showPassword}
                             disableSubmit={disableSubmit}
                             onClickTogglePassword={onClickTogglePassword}
                             onChangeUsername={onChangeUsername}
                             onBlurEmail={onBlurEmail}
                             onChangePassword={onChangePassword}
                             onSubmit={onLoginSubmit}
                             onClickForgotPassword={onClickForgotPassword}
                             onClickShowPreviousLogin={onClickShowPreviousLogin}
                        />
                        )}</>) : (<PortalUserLogin
                 errorMessage={errorMessage}
                 phonenumber={phonenumber}
                 disableUserSubmit={disableUserSubmit}
                 onUserSubmit={onUserSubmit}
                 onClickAdminLogin={onClickAdminLogin}
                 onChangePhoneNumber={onChangePhoneNumber}
                 ></PortalUserLogin>)}</>)}
                 
                  
                </Grid>
             </Grid>
        </div>
    );
};

export default PortalLogin;
