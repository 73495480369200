import React from "react";
import { Box } from "@mui/system";
import { Button, Grid, InputLabel, List, Typography,ButtonGroup,Snackbar ,Alert ,IconButton, Icon,Dialog,DialogTitle,DialogContent,DialogActions,DialogContentText } from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';
import Tooltip from '@mui/material/Tooltip';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { faker } from '@faker-js/faker';
import { format } from 'date-fns';
import { useState,useRef,useEffect } from "react";
import useTable from "./components/useTable";
import GlobalAlert from "./GlobalAlert";
import GlobalRoleAccess from "./GlobalRoleAccess";
import GlobalRoleAccessInner from "./GlobalRoleAccessInner";
import SvgIcon from "@mui/material/SvgIcon";
import NoImage from './asset/noimage.png';
import Popup from "./components/Popup";
import _ from "lodash";
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import useToken from "./useToken";
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { useParams,useNavigate  } from "react-router-dom";
import axios from "./axios";
import PuffLoader from "react-spinners/PuffLoader"; 
const headCells = [
  { id: 'loc_name', label: 'Location Name' },
  { id: 'start_date', label: 'Date Added'},
  { id: 'unlock', label: 'Unlock', disableSorting: true},
  { id: 'cam_brand', label: 'Location Type' },
  { id: 'loc_search_code', label: 'Location Code' },
  { id: 'licence_key', label: 'License Key' },
  { id: 'operation', label: 'Operation', disableSorting: true },
]

//let EnLog = [];

//let TYPE = ['Sunell','ACTi','AXIS','Nortek','HikVision','ResidentLink'];
let Alphabet =['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
/*for (let i =0; i < 200; i++){
  EnLog[i] = {
    id: i,
    locationname:faker.company.name(),
    locationtype:TYPE[Math.floor(Math.random()*TYPE.length)],
    locationcode:faker.random.numeric(12),
    licensekey:faker.random.alphaNumeric(8),
  }
}*/


function ManageLocation ( props){
  let [loading, setLoading] = useState(true);
  const {token} = useToken();
  const {RefreshToken} = props;
  const { getAlertTitle} = GlobalAlert();
  const navigate = useNavigate();
  const myContainer = useRef(null);
  const [EnLog,setEnLog] = useState([]);
  const [shownameType, setShownameType] = useState(true);
  const [showLocationNameType, setShowLocationNameType] = useState(true);
  const [showLocationType, setShowLocationType] = useState(false);
  const [showLocationCodeType, setShowLocationCodeType] = useState(false);
  const [showLocationKeyType, setShowLocationKeyType] = useState(false);
  const [showDateAdded,setShowDateAdded] = useState(false);
  const [filterText, setfilterText] = useState('');
  const [resetClick, setResetClicked] = useState(false);
  const [currentId, setCurrentId] = useState(0);
  const [openPopup, setOpenPopup] = useState(false);
  const {getRoleAccessVal} = GlobalRoleAccess();
  const {getRoleAccessInnerVal} = GlobalRoleAccessInner();
  const changeTimeZone  =  (date, timeZone)=> {
    const laDate = moment.utc(date).format();
    //if (typeof date === 'string') {
      const ldated =  new Date(
        new Date(laDate).toLocaleString('en-US', {
          timeZone,
        }),
      );
      const mystr = moment(ldated).format('YYYY-MM-DD');
      return mystr;
   /* }
  
    return new Date(
      date.toLocaleString('en-US', {
        timeZone,
      }),
    );*/
  }
  const FilterMenu = {
    id: "1",
    label: "Location Name",
    items: [
      {
        label: "Location Name",
        callback: () =>  {
            setShownameType(true);
            setShowLocationNameType(true);
            setShowLocationType(false);
            setShowLocationCodeType(false);
            setShowLocationKeyType(false);
            setShowDateAdded(false);
         // myContainer.this.setState({ //value:''});
         if(document.getElementById('input-location') != null)
    document.getElementById('input-location').value ='';
    if(document.getElementById('date') != null)
          document.getElementById('date').value ='';
          setfilterText('');
          //console.log(format(new Date(), 'dd/MM/yyyy'));
        }
         
      },
      {
        label: "Location Type",
        callback: () =>  {
            setShownameType(false);
            setShowLocationNameType(false);
            setShowLocationType(true);
            setShowLocationCodeType(false);
            setShowLocationKeyType(false);
            setShowDateAdded(false);
         // myContainer.this.setState({ //value:''});
         if(document.getElementById('input-location') != null)
    document.getElementById('input-location').value ='';
    if(document.getElementById('date') != null)
          document.getElementById('date').value ='';
          setfilterText('');
          //console.log(format(new Date(), 'dd/MM/yyyy'));
        }
         
      },
      {
        label: "Location Code",
        callback: () =>  {
            setShownameType(true);
            setShowLocationNameType(false);
            setShowLocationType(false);
            setShowLocationCodeType(true);
            setShowLocationKeyType(false);
            setShowDateAdded(false);
         // myContainer.this.setState({ //value:''});
         if(document.getElementById('input-location') != null)
    document.getElementById('input-location').value ='';
    if(document.getElementById('date') != null)
          document.getElementById('date').value ='';
          setfilterText('');
          //console.log(format(new Date(), 'dd/MM/yyyy'));
        }
         
      },
      {
        label: "License Key",
        callback: () =>  {
            setShownameType(true);
            setShowLocationNameType(false);
            setShowLocationType(false);
            setShowLocationCodeType(false);
            setShowLocationKeyType(true);
            setShowDateAdded(false);
         // myContainer.this.setState({ //value:''});
         if(document.getElementById('input-location') != null)
    document.getElementById('input-location').value ='';
    if(document.getElementById('date') != null)
          document.getElementById('date').value ='';
          setfilterText('');
          //console.log(format(new Date(), 'dd/MM/yyyy'));
        }
         
      },
      {
        label: "Date Added",
        callback: () =>  {
          setShownameType(false);
          setShowLocationNameType(false);
          setShowLocationType(false);
          setShowLocationCodeType(false);
          setShowLocationKeyType(false);
          setShowDateAdded(true);
         if(document.getElementById('input-location') != null)
            document.getElementById('input-location').value ='';
            if(document.getElementById('date') != null)
        document.getElementById('date').value ='';
        setfilterText('');
      
        }
        
         
      }
    ]
  };
  
  const TypeMenu = {
    id: "1",
    label: "Select Type",
    items: [
      {
        label: "Sunell",
        callback: () =>  {setfilterText("Sunell")}
       
      },
      {
        label: "ACTi",
        callback: () =>  {setfilterText("ACTi")}
       
      },
      
     /* {
        label: "AXIS",
        callback: () =>  {setfilterText("AXIS")}
      },
      {
        label: "Nortek",
        callback: () => {setfilterText("Nortek")}
      },*/
      {
        label: "HikVision",
        callback: () => {setfilterText("HikVision")}
      },
      {
        label: "ResidentVue",
        callback: () => {setfilterText("ResidentLink")}
      },
      {
        label: "Virtual Panel",
        callback: () => {setfilterText("Virtual Panel")}
      },
    ]
  };
 
  const getDisableValueEdit = () => {
    if(token.role == 'superadmin' || token.name === 'SUPER ADMIN'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("39").administrator_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("39").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'distributor' || token.name === 'SUPER DISTRIBUTOR'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("39").distributor_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("39").distributor_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'dealer' || token.name === 'SUPER DEALER'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("39").dealer_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("39").dealer_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'admin'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("39").admin_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("39").admin_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
    
    
  }
  const getDisableValueDelete = () => {
    if(token.role == 'superadmin' || token.name === 'SUPER ADMIN'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("40").administrator_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("40").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'distributor' || token.name === 'SUPER DISTRIBUTOR'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("40").distributor_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("40").distributor_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'dealer' || token.name === 'SUPER DEALER'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("40").dealer_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("40").dealer_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'admin'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("40").admin_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("40").admin_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
    
    
  }
  const [relaynametoupload,setRealyNameToUpload] = React.useState("");
  const [records, setRecords] = useState(EnLog)
  const [filterFn, setFilterFn] = useState({ fn: items => { return items; } })
  const handleChangeText = e => {
    console.log(e.target.value);
    let value = e.target.value;
    setfilterText(value);
    console.log(filterText);
}
  const {
      TblContainer,
      TblHead,
      TblPagination,
      BelowTblPagination,
      recordsAfterPagingAndSorting,
      setPageToNewOne
  } = useTable(records, headCells, filterFn,"Download Location");

  const getMyPostData = async () => {
    try {
      const res = await axios.get("/admin/location/"+token.id,{
        headers: { 'Content-Type':  'application/json',
        'token' : token.token,
        'tokenid' : token.email },
      });
      console.log(res.data);
      for(let i = 0; i < (res.data).length ; i++){
      if(res.data[i].elevator_access == ""){
        res.data[i].elevator_access = {elevatorcontrol:"0",ipaddress:"",totalrelays:"",elduration:"",flduration:"",diselevator:"",disrelease:"",diselapp:"",program:[null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null]};
      }else{
      res.data[i].elevator_access = JSON.parse(res.data[i].elevator_access);
      }
      if(res.data[i].hold_open == ""){
        res.data[i].hold_open = {holdopen:"0",indefinite:"1",definite:"0",finite:"0",expiry:"",time_on:"12:00 AM",time_off:"12:00 AM",relay1:"0",relay2:"0",table:[{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"}]};
      }else{
      res.data[i].hold_open = JSON.parse(res.data[i].hold_open);
      }
      if(res.data[i].brivo_access == ""){
        res.data[i].brivo_access = {brivoprimeid:"",brivoprimename:"",brivofloors:"",program:[null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null]};
      }else{
      res.data[i].brivo_access = JSON.parse(res.data[i].brivo_access);
      }
      if(res.data[i].pdk_access == ""){
        res.data[i].pdk_access ={primeid:"",primename:"",secondid:"",secondname:""};
      }else{
      res.data[i].pdk_access = JSON.parse(res.data[i].pdk_access);
      }
    }
    console.log(res.data);
      setEnLog(res.data);
      setRecords(res.data);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
      }
    }
  };
  const getBrivoValue = (idOfunit , mainarray) => {
    let nameOfunit;
    let brivoarr = mainarray.brivo_array;
   // let ads = JSON.stringify(dataOfLocation.brivo_access);
    console.log("brivo array is"+mainarray.brivo_array);
    if(brivoarr != null){
    brivoarr.forEach(element => {
      if(element.id == mainarray.brivo_access.program[idOfunit]){
        nameOfunit = element.name;
      }
    });
    }
    return nameOfunit;
  }

  // NOTE:  calling the function
  useEffect(() => {
    getMyPostData();
  }, []);
 /*function setDtaForReact(){
 
}*/
/*useEffect(() => {  console.log('use effect clicked'); if(resetClick){
  console.log('reset clicked');
  setResetClicked(false);
  setfilterText('');
  if(document.getElementById('input-location') != null)
  document.getElementById('input-location').value ='';
  if(document.getElementById('date') != null)
        document.getElementById('date').value ='';
       
  handleSearch();
}}, []);*/
  const handleReset =() =>{
    setfilterText('');
    if(document.getElementById('input-location') != null)
    document.getElementById('input-location').value ='';
    if(document.getElementById('date') != null)
          document.getElementById('date').value ='';
    
          setFilterFn({
            fn: items => {
              return items;
                    
            }
        })
        setPageToNewOne();
  }
  const checkAtOrNot = (value) =>{
     let ad = records.filter(x => x.loc_name.toLowerCase().startsWith(value.toLowerCase()));
     console.log(ad.length);
     return ad.length;
  }

  const jumpToAlphabet = (val) =>{
    console.log('value is '+val);
    setFilterFn({
      fn: items => {
        return items.filter(x => x.loc_name.toLowerCase().startsWith(val.toLowerCase()))
              
      }
  })
  setPageToNewOne();
  }
 
  const getItemById = (id) => {
    
      let idofItem =  records.filter(x => x.id == id);
     
      return idofItem[0];
     // console.log('value is '+idofItem[0].id);
  }
  const deleteMyPostData = async () => {
    try {
      const res = await axios.delete("/admin/location/"+token.id+"/"+currentId,
      {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      'token' : token.token,
      'tokenid' : token.email,
      'issub':token.issub,
      'subId':token.subid,
      'locname':currentLocName }});
      console.log( res.data);
      setOpenSnackData(getAlertTitle('MLLDS'));
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      getMyPostData();
    } catch (error) {
      console.log(error.response);
      setLoading(false);
      setOpenSnackData(getAlertTitle('MLLDF'));
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
      }
    }
  };
  const [currentLocName, setCurrentLocName] = useState('');
  const [currentLocId, setCurrentLocId] = useState('');
  const [currentUnlockType, setCurrentUnlockType] = useState('');
  const postRelayLog = async (val) => {
    try {
      const res = await axios.patch("/admin/relaylog/"+token.id,{'issub':token.issub,
      'subId':token.subid,
      'loc_name':currentLocName,
      'token' : token.token,
      'relay':relaynametoupload,
      'tokenid' : token.email}, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8' ,
      'token' : token.token,
      'tokenid' : token.email}});
      console.log('data response'+ JSON.stringify (res.data.rows));
     
     // getMyPostData();
    } catch (error) {
      console.log(error.response);
     
    }
  };

  const openLockDtata= async () => {
    const requestBody = {
      'loc_code' : currentLocId,
      'type' :currentUnlockType,
      'username' : ''
  };
  console.log("request body"+JSON.stringify(requestBody));
  const requestOptions = {
      method: 'POST',
      headers: {
          Accept:  'application/json',
          'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      },
      body: JSON.stringify(requestBody)
  };
  fetch("https://mdvaccess.com/php/send_notification.php?loc_code="+currentLocId+"&type="+currentUnlockType+"&username=", requestOptions
      ).then( async  response => {
          const data = await response;
         /* console.log(response);
          if(!response.ok){
              throw new Error();
          }
          else{*/
             
            setOpenSnackData(getAlertTitle('MLLLUS'));
            setSeverityData("success");
            setOpenSnack(true);
            setLoading(false);
            getMyPostData();
            postRelayLog();
          //}
              
      }).catch(error => {
        setLoading(false);
        setOpenSnackData(getAlertTitle('MLLLUF'));
        setSeverityData("error");
        setOpenSnack(true);
      }).finally(() => {
         
      });

  }
  const reloadEsperDeviceData = async (val,val2,val3) => {
    console.log('reloadEsperDeviceData');
    try {
      const res = await axios.patch("/admin/rebootdeviceesper/"+token.id+"/"+val+"/"+val2,{'issub':token.issub,
      'subId':token.subid,
      'loc_name':val3,
      'token' : token.token,
      'tokenid' : token.email}, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8' ,
      'token' : token.token,
      'tokenid' : token.email}});
      console.log('data response'+ JSON.stringify (res.data));
      if(res.data.rows == '0'){
        setLoading(false);
        setOpenSnackData(getAlertTitle('MLLDNS'));
        setSeverityData("error");
        setOpenSnack(true);
      }else{
      setOpenSnackData(getAlertTitle('MLLDRS'));
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      }
     // getMyPostData();
    } catch (error) {
      console.log(error.response);
      setLoading(false);
      setOpenSnackData(getAlertTitle('MLLDRF'));
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
      }
    }
  }
  const reloadDeviceData = async (val,val2) => {
    try {
      const res = await axios.get("/admin/rebootdevice/"+token.id+"/"+val,{'issub':token.issub,
      'subId':token.subid,
      'loc_name':val2,
      'token' : token.token,
      'tokenid' : token.email}, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8' ,
      'token' : token.token,
      'tokenid' : token.email}});
      console.log( res.data);
      if(res.data == '0'){
        setLoading(false);
        setOpenSnackData(getAlertTitle('MLLDNS'));
        setSeverityData("error");
        setOpenSnack(true);
      }else{
      setOpenSnackData(getAlertTitle('MLLDRS'));
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      }
     // getMyPostData();
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      setOpenSnackData(getAlertTitle('MLLDRF'));
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
      }
    }
  };
  const [currentDeviceId, setCurrentDeviceId]  = useState('');
  const ChangeId = (val,val2) =>{
    console.log(currentId);
    if(val2 == 0){
      let disableval = getDisableValueEdit();
      if(disableval == true){
        setOpenSnackData("You don't have access to perform this action");
        setSeverityData("error");
        setOpenSnack(true);
      }
      else{
      let idofItem =  records.filter(x => x.id == val);
      let itemtopass = idofItem[0];
      navigate('../location/editlocation', {state:{editlocation : itemtopass} });
    //console.log('value is '+val);
    getItemById(val);
      }
    }else if(val2 == 2){
      let disableval = getDisableValueDelete();
      if(disableval == true){
        setOpenSnackData("You don't have access to perform this action");
        setSeverityData("error");
        setOpenSnack(true);
      }
      else{
      setCurrentId(val);
      
      let deletid;
      for(let i = 0; i < EnLog.length ; i++){
          if(EnLog[i].id == val ){
              deletid = i;
              break;
          }
      }
      setCurrentLocName(EnLog[deletid].loc_name);
      /*EnLog.splice(deletid,1);
      setFilterFn({
          fn: items => {
            return items;
                  
          }
      })*/
      setTitleforalert("Are you sure you want to delete location "+ EnLog[deletid].loc_name + " ?");
      setOpenDelete(true);
    }
    }
    else if(val2 == 1){
     // setOpenSnack(true);
     let idofItem =  records.filter(x => x.id == val);
      let itemtopass = idofItem[0];
      console.log('device id is '+ JSON.stringify(itemtopass.device_id));
      //setCurrentDeviceId(itemtopass.device_id);
    // reloadDeviceData(itemtopass.device_id);
     let mdm_type = itemtopass.mdm_type;
      if(mdm_type == "1"){
      //  setCurrentLocName(itemtopass.loc_name);
        reloadEsperDeviceData(itemtopass.device_id,itemtopass.id,itemtopass.loc_name);
      }else{
       //  setCurrentLocName(EnLog[deletid].loc_name);
         reloadDeviceData(itemtopass.device_id,itemtopass.loc_name);
      }
    }
  }
  const DeletId = (val) =>{
    setCurrentId(val);
    let deletid;
    for(let i = 0; i < EnLog.length ; i++){
        if(EnLog[i].id == val ){
            deletid = i;
            break;
        }
    }
    EnLog.splice(deletid,1);
    setFilterFn({
        fn: items => {
          return items;
                
        }
    })
  }
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openSnackData, setOpenSnackData] = React.useState("This is Success Data");
  const [openSnack, setOpenSnack] = React.useState(false);
  const [severityData, setSeverityData] = React.useState("success");
  const [titleforalert, setTitleforalert] = React.useState("");

  

  const handleClickOpen = (val,id,relayname) => {
    let deletid;
    for(let i = 0; i < EnLog.length ; i++){
      if(EnLog[i].id == id ){
          deletid = i;
          break;
      }
  }
    setCurrentLocId(EnLog[deletid].loc_search_code);
    setRealyNameToUpload(relayname);
    setCurrentLocName(EnLog[deletid].loc_name);
    if(val == 0){
    setTitleforalert("Are you sure you want to enable "+ relayname+"?");
    setCurrentUnlockType('web1');
    }
    if(val == 1){
    setTitleforalert("Are you sure you want to enable "+ relayname+"?");
    setCurrentUnlockType('web2');
    }
    if(val == 2){
    setTitleforalert("Are you sure you want to enable "+ relayname+"?");
    setCurrentUnlockType('webp');
  }
    if(val == 3){
    setTitleforalert("Are you sure you want to Unlock "+ relayname+"?");
    setCurrentUnlockType('webbrivo');
  }
  if(val == 4){
    setTitleforalert("Are you sure you want to Unlock "+ relayname+"?");
    setCurrentUnlockType('webpdk1');
  }
  if(val == 5){
    setTitleforalert("Are you sure you want to Unlock "+ relayname+"?");
    setCurrentUnlockType('webpdk2');
  }
  if(val == 6){
    setTitleforalert("Are you sure you want to Unlock "+ relayname+"?");
    setCurrentUnlockType('webpdkp');
  }
  setOpen(true);
 
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleCloseActiveDelete =() =>{
    setOpenDelete(false);
    deleteMyPostData();
  }
  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseActive =() =>{
    setOpen(false);
    //setOpenSnack(true);
    openLockDtata();
  }
  const handleCloseSnack= (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
  /*useEffect(()=>{
    setOpenPopup(true);
  },[currentId]);*/
  /*const ShowLog = () =>{
    return(<Grid container spacing={2}> 
      <Grid item >
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLableAnswer}> id </Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} > Location Code </Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} > Location Name </Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer} height={200}> Entry Captured Photo </Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer} >User Name </Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer}  >Guest Name </Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer} >User Type </Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer} >PIN Type </Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer} >Date Added </Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer} >Status </Typography>
      </Stack>
      </Grid>
      <Grid item >
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable}>12352</Typography>
      <Typography sx={mainThemeStyle.popUpLable}> 85622300230 </Typography>
      <Typography sx={mainThemeStyle.popUpLable}> {EnLog[currentId].locationname} </Typography>
      <img  src={NoImage} height={200} width={200}></img>
      <Typography  sx={mainThemeStyle.popUpLable}>{ EnLog[currentId].residentname} </Typography>
      <Typography  sx={mainThemeStyle.popUpLable}> {EnLog[currentId].guestname}</Typography>
      <Typography sx={mainThemeStyle.popUpLable}>{ EnLog[currentId].usertype}</Typography>
      <Typography  sx={mainThemeStyle.popUpLable}>{ EnLog[currentId].entrytype}</Typography>
      <Typography  sx={mainThemeStyle.popUpLable}> {EnLog[currentId].dateadded}  {EnLog[currentId].timestamp}</Typography>
      <Typography  sx={mainThemeStyle.popUpLable}> Success</Typography>
      </Stack>
      </Grid>
      </Grid>)
  }*/
  const [mouseHover,setMouseHover] = useState(false);
  const handleSearch = () => {
    //let target = e.target;
    setFilterFn({
        fn: items => {
            if (filterText == "")
                return items;
            else{
              if(shownameType){
                console.log(shownameType);
                if(showLocationNameType)
                return items.filter(x => x.loc_name.toString().toLowerCase().includes(filterText.toLowerCase()))

                else if(showLocationCodeType)
                return items.filter(x => x.loc_search_code.toString().toLowerCase().includes(filterText.toLowerCase()))

                else if(showLocationKeyType)
                return items.filter(x => x.licence_key.toString().toLowerCase().includes(filterText.toLowerCase()))
                
                else 
                return items;
                
              }
              
              else if(showLocationType){
                if(filterText === 'Sunell' || filterText === 'ACTi' || filterText === 'AXIS' || filterText === 'Nortek' || filterText === 'HikVision'|| filterText === 'ResidentLink' || filterText === "Virtual Panel"){
                  return items.filter(x => x.cam_brand.toString().toLowerCase() === (filterText.toLowerCase()))
                }
                else{
                  return items;
                }
              }
              else if(showDateAdded){
                
                var mystr = filterText;
                mystr = moment(mystr).format('YYYY-MM-DD');
                return items.filter(x => changeTimeZone(x.start_date, 'America/New_York').toLowerCase().includes(mystr))
              
              }
            }
                
        }
    })
    setPageToNewOne();
}
  return <><Box><Typography sx={mainThemeStyle.lablefilter}>Filter</Typography>
  
  <Box sx={{position: 'absolute',
           width:'680px',
           height: '280px',
           top:'160px',
           left: '38px',
           display: { xs: "none", md: "flex" },
           right: '38px',
           flexGrow: 1,
          
           }}>
             
           <Stack direction="row" spacing={2} >
                  
                   <NestedButtonDropdown
            itemId={FilterMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.filterButton}
            data={FilterMenu}
          /> {shownameType  &&
           
          <Typography sx={mainThemeStyle.lableContains}>Contains</Typography>}
                 {showLocationType  && 
                   <Typography sx={mainThemeStyle.lableContains}>Equals</Typography>}
                   {showLocationType  &&  
                   <NestedButtonDropdown
            itemId={TypeMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.filterButton}
            data={TypeMenu}
          />
                  }

{shownameType &&
                   
                   <TextField
                        id="input-location"
                        ref={myContainer}
                        type= 'text'
                        name="text"
                        onChange={handleChangeText}
                        sx={mainThemeStyle.InputFilter}
                        InputLabelProps={{ shrink: true }}
                        hiddenLabel
                        variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color: 'rgba(255, 255, 255, 1)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#171E29',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '5px 0 5px 0',
                                textIndent: '15px !important',
                                border: `1px solid rgba(31, 39, 54, 1)`,
                                
                                
                            },
                        }}
                       
                    />}
                     {showDateAdded  && <TextField
        id="date"
        type="date"
        hiddenLabel
        sx={mainThemeStyle.InputFilter}
        onChange={handleChangeText}
        InputLabelProps={{
          shrink: true,
        }}
        variant="filled"
        InputProps={{
                           
          sx: {
              
              color: 'rgba(255, 255, 255, 1)',
              height:'33px',
              borderRadius: '5px',
              backgroundColor: '#171E29',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '5px 0 5px 0',
              textIndent: '15px !important',
              border: `1px solid rgba(31, 39, 54, 1)`,
             
              
          },
      }}/>}
                   <Button sx={mainThemeStyle.filterResetButton} onClick={handleSearch}>Go</Button>
                   
                   <Button sx={mainThemeStyle.filterResetButton} onClick={handleReset}>Reset Filters</Button>
                  </Stack>
                  </Box>
          
  <Box sx={{position: 'absolute',
           width:'auto',
           height: '280px',
           top:'160px',
           left: '38px',
           display: { xs: "flex", md: "none" },
           right: '38px',
           flexGrow: 1,
          
           }}>
             <Grid container  direction="column">
             <Grid item >
           <Stack direction="row" spacing={2} >
                  
                   <NestedButtonDropdown
            itemId={FilterMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.filterButton}
            data={FilterMenu}
          /> 
                   {showLocationType  &&  
                   <NestedButtonDropdown
            itemId={TypeMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.filterButton}
            data={TypeMenu}
          />
                  }
  {showDateAdded  && <TextField
        id="date"
        type="date"
        hiddenLabel
        sx={mainThemeStyle.InputFilter}
        onChange={handleChangeText}
        InputLabelProps={{
          shrink: true,
        }}
        variant="filled"
        InputProps={{
                           
          sx: {
              
              color: 'rgba(255, 255, 255, 1)',
              height:'33px',
              borderRadius: '5px',
              backgroundColor: '#171E29',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '5px 0 5px 0',
              textIndent: '15px !important',
              border: `1px solid rgba(31, 39, 54, 1)`,
             
              
          },
      }}/>}
{shownameType &&
                   
                   <TextField
                        id="input-location"
                        ref={myContainer}
                        type= 'text'
                        name="text"
                        onChange={handleChangeText}
                        variant="filled"
                        hiddenLabel
                        sx={mainThemeStyle.InputFilterSmall}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                           
                            sx: {
                                
                                color: 'rgba(255, 255, 255, 1)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#171E29',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '5px 0 5px 0',
                                textIndent: '15px !important',
                                border: `1px solid rgba(31, 39, 54, 1)`,
                               
                                
                            },
                        }}
                       
                    />}</Stack></Grid>
                   <Grid item>
                   <Stack direction="row" >
                   <Button sx={mainThemeStyle.filterResetButtonSmall} onClick={handleSearch}>Go</Button>
                   
                   <Button sx={mainThemeStyle.filterResetButtonSmall} onClick={handleReset}>Reset Filters</Button>
                   </Stack>
                 </Grid></Grid>
                 
                 
                 </Box>
  
  
                 <Typography sx={mainThemeStyle.lableJump}>Jump</Typography>
                 
                 <ButtonGroup variant="text"  sx={{position: 'absolute',
           maxWidth:'250px',
           height: 'auto',
           top:'250px',
           left: '38px',
           display: { xs: "none", md: "flex" }
           }}>

          

          {
            Alphabet.map(item => (
              checkAtOrNot(item) > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  onClick={() => {jumpToAlphabet(item)}}>{item}</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>{item}</Button>
            ))
          }

        {/* { checkAtOrNot('A') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >A</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>A</Button>}
        { checkAtOrNot('B') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >B</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>B</Button>}
        { checkAtOrNot('C') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >C</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>C</Button>}
        { checkAtOrNot('D') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >D</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>D</Button>}
        { checkAtOrNot('E') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >E</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>E</Button>}
        { checkAtOrNot('F') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >F</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>F</Button>}
        { checkAtOrNot('G') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >G</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>G</Button>}
        { checkAtOrNot('H') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >H</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>H</Button>}
        { checkAtOrNot('I') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >I</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>I</Button>}
        { checkAtOrNot('A') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >A</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>A</Button>}
        { checkAtOrNot('A') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >A</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>A</Button>}
        { checkAtOrNot('A') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >A</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>A</Button>}
        { checkAtOrNot('A') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >A</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>A</Button>}
        { checkAtOrNot('A') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >A</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>A</Button>}
        { checkAtOrNot('A') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >A</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>A</Button>}
        { checkAtOrNot('A') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >A</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>A</Button>}
        { checkAtOrNot('A') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >A</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>A</Button>}
        { checkAtOrNot('A') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >A</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>A</Button>}
        { checkAtOrNot('A') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >A</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>A</Button>}
        { checkAtOrNot('A') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >A</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>A</Button>}
        { checkAtOrNot('A') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >A</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>A</Button>}
        { checkAtOrNot('A') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >A</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>A</Button>}
        { checkAtOrNot('A') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >A</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>A</Button>}
        { checkAtOrNot('A') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >A</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>A</Button>}
        { checkAtOrNot('A') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >A</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>A</Button>}
        { checkAtOrNot('A') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >A</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>A</Button>} */}
     

    </ButtonGroup>
    
   
   <Box sx={mainThemeStyle.mainTablebox} backgroundColor="menubarprimary.main">
   <BelowTblPagination ></BelowTblPagination>
    <TblContainer>
                    <TblHead />
                    <TableBody>
                        {
                            recordsAfterPagingAndSorting().map(item =>
                                ( <TableRow
                                  key={item.id}
                                  sx={{ borderBottom: "1.5px solid black",'&:last-child td, &:last-child th': { border: 0 },
                                   }}
                                >
                                  <TableCell align="left" >
                                   
                                    <Typography /* sx={mouseHover ? mainThemeStyle.cellRowTypoGreen :mainThemeStyle.cellRowTypo} height={40} onMouseOver={() => {
          setMouseHover(true);
          
      }} */ sx={ mainThemeStyle.cellRowTypo} onClick={() => {ChangeId(item.id,0)}}/*  onMouseOut={() => {
        setMouseHover(false);
      }} */> {item.loc_name}</Typography>
                                    </TableCell> 
                                    <TableCell align="left" sx={mainThemeStyle.cellRow}>{changeTimeZone(item.start_date, 'America/New_York')}</TableCell>
                              
                                  <TableCell>
                                  { item.cam_brand == "ResidentLink"  && item.device_id != "" &&  item.unlocking_mode != '3'  &&  item.unlocking_mode != '4' &&
                                 <Stack direction="row" spacing={2}>
                                    <Typography></Typography>
                                    <Button  sx={mainThemeStyle.viewLogButton} onClick={() => {handleClickOpen(0, item.id,item.relay_one_fname == "" ? "Relay 1" :  item.relay_one_fname)}}>
                                     {item.relay_one_fname == "" ? "Relay 1" :  item.relay_one_fname}
                                      </Button>
                                    <Button  sx={mainThemeStyle.viewLogButton} onClick={() => {handleClickOpen(1, item.id,item.relay_two_fname == "" ? "Relay 2" :  item.relay_two_fname)}}>
                                    {item.relay_two_fname == "" ? "Relay 2" :  item.relay_two_fname} 
                                      </Button> <Button  sx={mainThemeStyle.viewLogButton} onClick={() => {handleClickOpen(2, item.id, 'Dual Relays')}}>Dual Relays</Button>
                                      </Stack>
                                  }
                                  { item.cam_brand == "ResidentLink" && item.unlocking_mode == '3' && item.brivo_access.brivoprimeid != "" &&
                                    <Stack direction="row" spacing={2}>
                                    <Typography></Typography>
                                    <Button  sx={mainThemeStyle.viewLogButton} onClick={() => {handleClickOpen(3, item.id,item.brivo_access.brivoprimename == null ? "Brivo Lock" :  item.brivo_access.brivoprimename)}}>
                                      {item.brivo_access.brivoprimename == null ? "Unlock Brivo" :  item.brivo_access.brivoprimename} 
                                    </Button> </Stack>
                                  }
                                  
                                     { item.cam_brand == "ResidentLink" && item.unlocking_mode == '4' && item.pdk_access.primeid != "" &&
                                   <Stack direction="row" spacing={2}>
                                   <Typography></Typography>
                                   <Button  sx={mainThemeStyle.viewLogButton} onClick={() => {handleClickOpen(4,  item.id,item.pdk_access.primename == null ? "PDK Primary Lock" :  item.pdk_access.primename)}}>
                                   {item.pdk_access.primename == null ? "Unlock Primary PDK" :  item.pdk_access.primename} 
                                   </Button> 
                                { item.pdk_access.secondid != "" &&
                                   <Button  sx={mainThemeStyle.viewLogButton} onClick={() => {handleClickOpen(5,  item.id,item.pdk_access.secondname == null ? "PDK Secondary Lock" :  item.pdk_access.secondname)}}>
                                   {item.pdk_access.secondname == null ? "Unlock Secondary PDK" :  item.pdk_access.secondname} 
                                   </Button> }
                                   { item.pdk_access.secondid != "" &&
                                   <Button  sx={mainThemeStyle.viewLogButton} onClick={() => {handleClickOpen(6, item.id, 'Dual PDK Lock')}}>Unlock Dual PDK</Button>}
                                 
                                   </Stack>
                                  }

                                  { item.cam_brand == "Virtual Panel" && item.unlocking_mode == '4' && item.pdk_access.primeid != "" &&
                                   <Stack direction="row" spacing={2}>
                                   <Typography></Typography>
                                   <Button  sx={mainThemeStyle.viewLogButton} onClick={() => {handleClickOpen(4,  item.id,item.pdk_access.primename == null ? "PDK Primary Lock" :  item.pdk_access.primename)}}>
                                   {item.pdk_access.primename == null ? "Unlock Primary PDK" :  item.pdk_access.primename} 
                                   </Button> 
                                   { item.pdk_access.secondid != "" && <Button  sx={mainThemeStyle.viewLogButton} onClick={() => {handleClickOpen(5,  item.id,item.pdk_access.secondname == null ? "PDK Secondary Lock" :  item.pdk_access.secondname)}}>
                                   {item.pdk_access.secondname == null ? "Unlock Secondary PDK" :  item.pdk_access.secondname} 
                                   </Button> 
                                   }
                                    { item.pdk_access.secondid != "" &&
                                   <Button  sx={mainThemeStyle.viewLogButton} onClick={() => {handleClickOpen(6, item.id, 'Dual PDK Lock')}}>Unlock Dual PDK</Button>}
                                    </Stack>
                                  }
                                  
{ item.cam_brand == "ResidentLink" && item.device_id == "" && item.brivo_access.brivoprimeid == ""  && item.unlocking_mode != '4'  &&
                                    <Stack direction="row" spacing={2}>
                                    <Typography></Typography> <Typography align="left" sx={mainThemeStyle.cellRow}>N/A</Typography>
                                    </Stack> }
                                    { item.cam_brand == "ResidentLink"    && item.unlocking_mode == '4'  && item.pdk_access.primeid  == '' &&
                                    <Stack direction="row" spacing={2}>
                                    <Typography></Typography> <Typography align="left" sx={mainThemeStyle.cellRow}>N/A</Typography>
                                    </Stack> }
                                    { item.cam_brand == "Virtual Panel"  && item.unlocking_mode != "1" && item.unlocking_mode != "4"  &&
                                 <Stack direction="row" spacing={2}>
                                    <Typography></Typography>
                                    <Button  sx={mainThemeStyle.viewLogButton} onClick={() => {handleClickOpen(0, item.id,item.relay_one_fname == "" ? "Relay 1" :  item.relay_one_fname)}}>
                                     {item.relay_one_fname == "" ? "Relay 1" :  item.relay_one_fname}
                                      </Button>
                                    {/* <Button  sx={mainThemeStyle.viewLogButton} onClick={() => {handleClickOpen(1, item.id,item.relay_two_fname == "" ? "Relay 2" :  item.relay_two_fname)}}>
                                    {item.relay_two_fname == "" ? "Relay 2" :  item.relay_two_fname} 
                                      </Button> <Button  sx={mainThemeStyle.viewLogButton} onClick={() => {handleClickOpen(2, item.id, 'Dual Relays')}}>Dual Relays</Button>
                                    */}   </Stack>
                                  }
                                  { item.cam_brand == "Virtual Panel"  && item.unlocking_mode == "1" &&
                                 <Stack direction="row" spacing={2}>
                                    <Typography></Typography>
                                  {/*   {item.brivo_access.brivoprimename != null && <Button  sx={mainThemeStyle.viewLogButton} onClick={() => {handleClickOpen(0, item.id,item.brivo_access.brivoprimename == null ? "Relay 1" :  item.brivo_access.brivoprimename)}}>
                                     {item.brivo_access.brivoprimename == null ? "Relay 1" :  item.brivo_access.brivoprimename}
                                      </Button>}
                                     { item.brivo_access.program[0] != null   && <Button  sx={mainThemeStyle.viewLogButton} onClick={() => {handleClickOpen(1, item.id,item.brivo_access.program[0] == null ? "Relay 2" :  getBrivoValue(0,item))}}>
                                    {item.brivo_access.program[0] == null ? "Relay 2" :  getBrivoValue(0,item)} 
                                      </Button> }
                                      { item.brivo_access.program[0] != null && item.brivo_access.brivoprimename != null  && <Button  sx={mainThemeStyle.viewLogButton} onClick={() => {handleClickOpen(2, item.id, 'Dual Relays')}}>Dual Relays</Button>}
                                 */}     
                                  <Button  sx={mainThemeStyle.viewLogButton} onClick={() => {handleClickOpen(3, item.id,item.brivo_access.brivoprimename == null ? "Brivo Lock" :  (item.brivo_access.brivoprimename.length > 0 ? item.brivo_access.brivoprimename :"Brivo Lock"))}}>
                                      {item.brivo_access.brivoprimename == null ? "Unlock Brivo" :   (item.brivo_access.brivoprimename.length > 0 ? item.brivo_access.brivoprimename :"Unlock Brivo")} 
                                    </Button>
                                  </Stack>
                                  }
                                  { item.cam_brand != "ResidentLink" && item.cam_brand != "Virtual Panel" && <Stack direction="row" spacing={2}>
                                    <Typography></Typography>
                                   <Typography align="left" sx={mainThemeStyle.cellRow}>N/A</Typography></Stack>
                                  }
                                  </TableCell> 
                                  <TableCell align="left" sx={mainThemeStyle.cellGreen}>
                                  {item.cam_brand == "ResidentLink" ? "ResidentVue" : item.cam_brand} </TableCell>
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.loc_search_code}</TableCell>
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.licence_key}</TableCell>
                                  <TableCell>
                                  <Stack direction="row" spacing={2}>
                                  <Tooltip title="Edit">
                                    <IconButton sx={{ backgroundColor: '#2A2F3B'}}  size="small" color="primary"  onClick={() => {ChangeId(item.id,0)}}><EditIcon /></IconButton>
                                    </Tooltip>
                                    { item.cam_brand == "ResidentLink"  && 
                                    <Tooltip title="Reboot">
                                    <IconButton sx={{ backgroundColor: '#2A2F3B'}} size="small" color="success"  onClick={() => {ChangeId(item.id,1)}}><RestartAltIcon /></IconButton></Tooltip>}
                                     <Tooltip title="Delete"><IconButton sx={{ backgroundColor: '#2A2F3B'}}  size="small" color="redwarning"  onClick={() => {ChangeId(item.id,2)}}><DeleteIcon /></IconButton>
                                    
                                    </Tooltip></Stack>
                                  </TableCell>
                                </TableRow>)
                            )
                        }
                    </TableBody>
                   
                </TblContainer>
                <Box sx={{marginTop:'10px'}}>
                <PuffLoader color="#878787" loading={loading} cssOverride={{display: "block",
    margin: "0 auto",
    marginTop:"50px",
    marginBottom:"50px",}} />



</Box></Box>
                 </Box>
                 <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={severityData} sx={{ width: '100%' }}>
          {openSnackData}
        </Alert>
      </Snackbar>
                 <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          padding: '16px',
      
         backgroundColor:'#00000000',
         
      }}
      >
        <DialogTitle id="alert-dialog-title" sx={{
        
      
         backgroundColor:'#2A2F3B',
         color:'white'
         
      }}>
          {titleforalert}
        </DialogTitle>
        <DialogContent sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          
        </DialogContent>
        <DialogActions sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          <Button onClick={handleClose} sx={mainThemeStyle.normalBigButton}>Cancel</Button>
          <Button onClick={handleCloseActive} autoFocus sx={mainThemeStyle.normalBigButton}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      
      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          padding: '16px',
      
         backgroundColor:'#00000000',
         
      }}
      >
        <DialogTitle id="alert-dialog-title" sx={{
        
      
         backgroundColor:'#2A2F3B',
         color:'white'
         
      }}>
          {titleforalert}
        </DialogTitle>
        <DialogContent sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          
        </DialogContent>
        <DialogActions sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          <Button onClick={handleCloseDelete} sx={mainThemeStyle.normalBigButton}>Cancel</Button>
          <Button onClick={handleCloseActiveDelete} autoFocus sx={mainThemeStyle.normalBigButtonRed}>
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>
          {/* <Popup
                title="Entry Log"
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
            >
             
      <ShowLog/>
               
          
            </Popup> */}
          </> 
          
          
          
          ;
};

export default ManageLocation;