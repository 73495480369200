import * as React from "react";
import { Box } from "@mui/system";
import { Button,Checkbox, Switch,Grid,Select,MenuItem, InputLabel, List,Divider, FormControlLabel, Typography,ButtonGroup,Snackbar ,Alert ,IconButton, Icon,Dialog,DialogTitle,DialogContent,DialogActions,DialogContentText, cardMediaClasses } from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { faker } from '@faker-js/faker';
import { format } from 'date-fns';
import { useState,useRef,useEffect } from "react";
import useTable from "./components/useTable";
import useInnerTable from "./components/useInnerTable";
import SvgIcon from "@mui/material/SvgIcon";
import NoImage from './asset/noimage.png';
import Popup from "./components/Popup";
import _ from "lodash";
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { styled } from '@mui/material/styles';
import useToken from "./useToken";
import TimezoneSelect from 'react-timezone-select';
import axios from "./axios";
import PuffLoader from "react-spinners/PuffLoader"; 
import GlobalAlert from "./GlobalAlert";
import LoadingButton from '@mui/lab/LoadingButton';
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const MenuProps = {
  PaperProps: {
    style: {
      backgroundColor: "#1E1E1E",
        borderRadius: 0,
        maxHeight:'250px',
        borderRadius: 10,
        padding:0
    },
  },
};
function UserLicense ( props){
  const {token} = useToken();
  const { getAlertTitle} = GlobalAlert();
  const [EnLog,setEnLog] = useState([]);
  const {RefreshToken} = props;
  let [loading, setLoading] = useState(true);
  let [loadingbtn,setloadingbtn] = useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [openSnackData, setOpenSnackData] = React.useState("This is Success Data");
  const [severityData, setSeverityData] = React.useState("success");
  const [arrayOfChange, setArrayOfChange] = useState(EnLog);
  const handleCloseSnack= (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
  const getMyPostData = async () => {
    try {
      const res = await axios.get("/enduser/license/"+token.phone,{
        headers: { 'Content-Type':  'application/json',
        'token' : token.token,
        'tokenid' : token.phone },
      });
      console.log(res.data);
      setEnLog(res.data);
      setArrayOfChange(res.data);
      setLoading(false);
      setloadingbtn(false);

    } catch (error) {
      console.log(error.message);
      setLoading(false);
      setloadingbtn(false);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });
  const headCells = [
    { id: 'lic_state', label: 'License State' },
    { id: 'lic_number', label: 'License Number'},
    { id: 'action', label: 'Action', disableSorting: true },
  ];
  const {
    TblContainer ,
    TblHead ,
    TblPagination ,
    BelowTblPagination,
    recordsAfterPagingAndSorting ,
    setPageToNewOne 
  } = useInnerTable(EnLog, headCells, filterFn,8,650);

  // NOTE:  calling the function
  useEffect(() => {
    getMyPostData();
  }, []);
  const LicState = [
    "AL","AK","AS","AZ","AR","CA","CO","CT","DE","DC","FM","FL","GA","GU","HI","ID","IL","IN","IA","KS","KY",
    "LA","ME","MH","MD","MA","MI","MN","MS","MO","MT","NE","NV","NH","NJ","NM","NY","NC","ND","MP","OH","OK",
    "OR","PW","PA","PR","RI","SC","SD","TN","TX","UT","VT","VI","VA","WA","WV","WI","WY"
  ];
  
  const [currentLicenseState,setCurrentLicenseState] = useState('AL');
  const [currentLicensePlate,setCurrentLicensePlate] = useState('');
  const handleAddLicense = (val) => {
    if(currentLicensePlate.length > 0){
       addLicesnePost();
    }
    else{
      setOpenSnackData("Please Enter License Plate Number");
      setSeverityData("error");
      setOpenSnack(true);
    }

  }
  const addLicesnePost = async () => {
    console.log("In update post data");
    setLoading(true);
    try {
      const res = await axios.post("/enduser/addlicense/"+token.name,{lic_number:currentLicensePlate,lic_state:currentLicenseState,user_phone:token.phone,pid:'28',userfname:token.fname,userlname:token.lname,user_type:token.type}, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      'token' : token.token,
      'tokenid' : token.phone }});
      console.log( res.data);
      setOpenSnackData(res.data.rows);
      setSeverityData("success");
      setOpenSnack(true);
      //setLoading(false);
      //setshowUpdateButton(false);
      getMyPostData();
     
    } catch (error) {
      console.log(error.response.data);
      setLoading(false);
      setOpenSnackData(getAlertTitle('EULPAF'));
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  const handleChangeLicenseState = (event) => {
    setCurrentLicenseState(event.target.value);
  }
  const handleLicensePlateChange= (event) => {
    setCurrentLicensePlate(event.target.value);
  }
  const [currentGroupName,setCurrentGroupName] = useState('');
  const [titleforalert, setTitleforalert] = React.useState("");
  const [currentDeleteID, setCurrentDeleteID] = useState('');
  
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseActive =() =>{
    setOpen(false);
    deleteMyLicensePostData();
  }
 
  const handleLicenseDelete= (val,val2) =>{
    setTitleforalert("Are you sure you want to delete License Plate "+ val2 + " ?");
    setCurrentDeleteID(val);
    console.log(val);
    setOpen(true);
  }
  const deleteMyLicensePostData = async () => {
    setLoading(true);
    try {
      const res = await axios.delete("/enduser/deletelicense/"+token.name+"/"+currentDeleteID,
       {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
       'token' : token.token,
       'tokenid' : token.phone }});
      console.log( res.data);
      setOpenSnackData(getAlertTitle('EULPDS'));
      setSeverityData("success");
      setOpenSnack(true);
      //setLoading(false);
      getMyPostData();
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      setOpenSnackData(getAlertTitle('EULPDF'));
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
 

  
  return <> <Box sx={mainThemeStyle.boxTopHeader}>

</Box>

<Box sx={mainThemeStyle.boxHeaderBelow}>

<Box  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px', display:{xs:'none',md:'flex'}}}>
               <div style={{ display: 'flex' }}>
                <Stack direction='row' spacing={2} padding={2}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',flexGrow: 1,color:'#fff' }}>
                   Add Vehicle
                   </Typography>
                   <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            top:'1px',
            bottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "lic-state"
          label="Age"
          onChange={handleChangeLicenseState}
          variant="filled"
          value ={currentLicenseState}
          hiddenLabel
         
          MenuProps={MenuProps}>
            
            {LicState.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem}>
                      {menuItem}
                    </MenuItem>
                  );
                })}
          </Select>
          <TextField
                       
                   height={40}
                   type= 'text'
                   name="text"
                   placeholder="License Plate"
                   variant = "filled"
                   value={currentLicensePlate}
                   onChange={handleLicensePlateChange}
                   sx={mainThemeStyle.InputMenuItem}
                   InputLabelProps={{ shrink: true }
                 }
                     
                   InputProps={{
                      
                       sx: {
                           
                           color:'rgba(135, 135, 135, 0.5)',
                           height:'33px',
                           borderRadius: '5px',
                           backgroundColor: '#121212',
                           boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                           padding: '0 0 15px 0',
                           textIndent: '15px !important',
                         
                          
                           
                       },
                   }}
                  
               />
         <LoadingButton sx={mainThemeStyle.normalButton} onClick={handleAddLicense} loading={loading}>Add</LoadingButton>
      </Stack>
               </div>
              
           </Box>

           <Box  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px', display:{xs:'flex',md:'none'}}}>
              <Grid container>
              <Grid item>
                <Stack direction='row' spacing={2} padding={2}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',flexGrow: 1,color:'#fff' }}>
                   Add Vehicle
                   </Typography>
                   <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            top:'1px',
            bottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "lic-state"
          label="Age"
          onChange={handleChangeLicenseState}
          variant="filled"
          value ={currentLicenseState}
          hiddenLabel
         
          MenuProps={MenuProps}>
            
            {LicState.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem}>
                      {menuItem}
                    </MenuItem>
                  );
                })}
          </Select></Stack></Grid>
          <Grid item><Stack direction='row' spacing={2} padding={2}>
          <TextField
                       
                   height={40}
                   type= 'text'
                   name="text"
                   placeholder="License Plate"
                   variant = "filled"
                   value={currentLicensePlate}
                   onChange={handleLicensePlateChange}
                   sx={mainThemeStyle.InputMenuItem}
                   InputLabelProps={{ shrink: true }
                 }
                     
                   InputProps={{
                      
                       sx: {
                           
                           color:'rgba(135, 135, 135, 0.5)',
                           height:'33px',
                           borderRadius: '5px',
                           backgroundColor: '#121212',
                           boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                           padding: '0 0 15px 0',
                           textIndent: '15px !important',
                         
                          
                           
                       },
                   }}
                  
               />
         <LoadingButton sx={mainThemeStyle.normalButton} onClick={handleAddLicense} loading={loading}>Add</LoadingButton>
         </Stack></Grid>
      
      </Grid>
              
           </Box>
           <Divider sx={{backgroundColor:'white'}} />
   
           <TblContainer>
           <TblHead />
        <TableBody>
         {EnLog.map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
               <TableCell  align="left" sx={mainThemeStyle.cellRow}> {row.lic_state}</TableCell>
              <TableCell   align="left" sx={mainThemeStyle.cellRow}> {row.lic_number}</TableCell>
              <TableCell align="left"><LoadingButton  sx={mainThemeStyle.viewLogButton} loading={loading} onClick={() => {handleLicenseDelete(row.id,row.lic_number)}}>Delete</LoadingButton></TableCell>
              
            </TableRow>
          ))}
        </TableBody>
     
    </TblContainer>
<BelowTblPagination></BelowTblPagination>
    <PuffLoader color="#878787" loading={loading} cssOverride={{display: "block",
    margin: "0 auto",
    marginTop:"50px",
    marginBottom:"50px",}} />
</Box>
<Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={severityData} sx={{ width: '100%' }}>
          {openSnackData}
        </Alert>
      </Snackbar>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          padding: '16px',
      
         backgroundColor:'#00000000',
         
      }}
      >
        <DialogTitle id="alert-dialog-title" sx={{
        
      
         backgroundColor:'#2A2F3B',
         color:'white'
         
      }}>
          {titleforalert}
        </DialogTitle>
        <DialogContent sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          
        </DialogContent>
        <DialogActions sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          <Button onClick={handleClose} sx={mainThemeStyle.normalBigButton}>Cancel</Button>
          <Button onClick={handleCloseActive} autoFocus sx={mainThemeStyle.normalBigButtonRed}>
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>
 </> 
          
          
          
          
          ;
};

export default UserLicense;