
import * as React from "react";
import { Box } from "@mui/system";
import { Button,Checkbox, Switch,Grid, InputLabel, List,Divider, FormControlLabel, Typography,ButtonGroup,Snackbar ,Alert ,IconButton, Icon,Dialog,DialogTitle,DialogContent,DialogActions,DialogContentText, cardMediaClasses } from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Paper from '@mui/material/Paper';
import { faker } from '@faker-js/faker';
import { format } from 'date-fns';
import { useState,useRef,useEffect } from "react";
import useInnerTable from "./components/useInnerTable";
import InfoIcon from '@mui/icons-material/Info';
import useTable from "./components/useTable";
import SvgIcon from "@mui/material/SvgIcon";
import NoImage from './asset/noimage.png';
import Popup from "./components/Popup";
import _ from "lodash";
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { styled } from '@mui/system';
import axios from "./axios";
import PuffLoader from "react-spinners/PuffLoader"; 
import Autocomplete from '@mui/material/Autocomplete';
import { useAutocomplete } from '@mui/base/AutocompleteUnstyled';
import useToken from "./useToken";
import { useNavigate } from "react-router-dom";
import GlobalAlert from "./GlobalAlert";
import TimezoneSelect from 'react-timezone-select'
import LoadingButton from "@mui/lab/LoadingButton";
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


function MasterSearch ( props){
  const [EnLog,setEnLog] = useState([]);
  const {token} = useToken();
  const {RefreshToken} = props;
  const { getAlertTitle} = GlobalAlert();
  let [loading, setLoading] = useState(false);
  const myContainer = useRef(null);
  const navigate = useNavigate();
  const getMyPostData = async () => {
    try {
      const res = await axios.get("/admin/mastersearch/"+token.id+"/"+showTable+"/"+filterText,{
        headers: { 'Content-Type':  'application/json',
        'token' : token.token,
        'tokenid' : token.email },
      });
      console.log(res.data);
      setEnLog(res.data);
      setLoading(false);
      setloadingbtn(false);
    } catch (error) {
      console.log(error.response);
      setLoading(false);
      setloadingbtn(false);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  
  const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });
  const [filterText, setfilterText] = useState('');
  
  const headCellsPIN = [
    { id: 'fname', label: 'Name' },
    { id: 'type', label: 'Device Type' },
    { id: 'door_pin', label: 'PIN#' },
    { id: 'phone_no', label: 'Contact Phone#' },
    { id: 'user_email', label: 'Email' },
  ];
  const headCellsRFID = [
    { id: 'fname', label: 'Name' },
    { id: 'type', label: 'Device Type' },
    { id: 'generic', label: 'Generic#' },
    { id: 'weigand', label: 'Wiegand#' },
    { id: 'phone_no', label: 'Contact Phone#' },
    { id: 'user_email', label: 'Email' },
  ];
  const headCellsLicense = [
    { id: 'fname', label: 'Name' },
    { id: 'type', label: 'Device Type' },
    { id: 'license', label: 'License Plate#' },
    { id: 'phone_no', label: 'Contact Phone#' },
    { id: 'user_email', label: 'Email' },
  ];
  const [headCells,setHeadCells] = useState(headCellsPIN);
  const {
    TblContainer ,
    TblHead ,
    TblPagination ,
    BelowTblPagination,
    recordsAfterPagingAndSorting ,
    setPageToNewOne 
  } = useInnerTable(EnLog, headCells, filterFn,8,650);
  
  // NOTE:  calling the function
  useEffect(() => {
    //getMyPostData();
  }, []);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [showTable, setShowTable] = useState(0);
  const handleCloseSnack= (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
 
  
 const showTableOfSelcted = {
    id: "1",
    label: "PIN",
    items: [
      {
        label: "PIN",
        callback: () =>  {
        setfilterText('');
        setHeadCells(headCellsPIN);
        setEnLog([]);
        setPageToNewOne();
        setShowTable(0);
        
        },
      },
      {
        label: "License Plate",
        callback: () =>  {
        setfilterText('');
        setHeadCells(headCellsLicense);
        setEnLog([]);
        setPageToNewOne();
        setShowTable(1);
       // 
        },
      },
      {
        label: "RFID Card",
        callback: () =>  {
        setfilterText('');
        setHeadCells(headCellsRFID);
        setEnLog([]);
        setPageToNewOne();
        setShowTable(2);
       
        },
      },
      
      
    ]
  };
  const handleChangeText = e => {
    console.log(e.target.value);
    let value = e.target.value;
    setfilterText(value);
    console.log(filterText);
}
  const MenuProps = {
    PaperProps: {
      style: {
        backgroundColor: "#1E1E1E",
          borderRadius: 0,
          maxHeight:'250px',
          borderRadius: 10,
          padding:0
      },
    },
  };
  let [loadingbtn,setloadingbtn] = useState(false);
  const [titleforalert, setTitleforalert] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [openadd, setOpenAdd] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  
  const handleSearch = () => {
    if(filterText.length > 0){
        setLoading(true);
        setEnLog([]);
        setPageToNewOne();
        getMyPostData();
    }
    else{
        setOpenSnackData('Please enter something to search for');
        setSeverityData("error");
        setOpenSnack(true);
    }
  }
  
  
  
  const [openSnackData, setOpenSnackData] = React.useState("This is Success Data");
  const [severityData, setSeverityData] = React.useState("success");
 
  const ChangeId = (val) =>{
      let idofItem =  EnLog.filter(x => x.id == val);
      let itemtopass = idofItem[0];
      //navigate('../location/editlocation', {state:{editlocation : itemtopass}, replace: true });
      navigate('../user/edituser', {state:{edituser : itemtopass}});
   
  }
  
  return <> <Box sx={mainThemeStyle.boxTopHeader}>
  
</Box>
<Box sx={mainThemeStyle.boxHeaderBelow}>


 <Box sx={{ display: { xs: "none", md: "flex" }}}>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                <Stack direction='row' spacing={2} padding={2}>
                <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Search
                   </Typography>
                <NestedButtonDropdown
            itemId={showTableOfSelcted.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.filterButtonCenter}
            data={showTableOfSelcted}
          />
           {/*  <TextField
                        id="input-location"
                        ref={myContainer}
                        type= 'text'
                        name="text"
                        onChange={handleChangeText}
                        sx={mainThemeStyle.InputFilter}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                           
                            sx: {
                                
                                color: 'rgba(255, 255, 255, 1)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#171E29',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '5px 0 5px 0',
                                textIndent: '15px !important',
                                border: `1px solid rgba(31, 39, 54, 1)`,
                               
                                
                            },
                        }}
                       
                    /> */}
                    <TextField
        id="text"
        type="text"
        ref={myContainer}
        value={filterText}
      onChange={handleChangeText}
       variant="filled"
       sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                          style={{paddingTop: '16px'}}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      />
          
                   <LoadingButton loading={loadingbtn} sx={mainThemeStyle.normalButtonDown} onClick={handleSearch}> Search </LoadingButton>
                  
                   </Stack>
 </div> </div></Box>
 <Box sx={{ display: { xs: "flex", md: "none" }}}>
<Stack direction='column' paddingLeft={2} paddingRight={2}>
                <Stack direction='row' spacing={2} padding={2}>
                <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Search
                   </Typography>
                <NestedButtonDropdown
            itemId={showTableOfSelcted.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.filterButtonCenter}
            data={showTableOfSelcted}
          />
           </Stack>
            <Stack direction='row' spacing={2} paddingBottom={2} paddingLeft={2} paddingRight={2}>
                    <TextField
        id="text"
        type="text"
        ref={myContainer}
        value={filterText}
      onChange={handleChangeText}
      sx={mainThemeStyle.InputMenuItem}
       variant="filled"
                        InputLabelProps={{ shrink: true }
                      }
                         
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      />
          
                   <LoadingButton loading={loadingbtn} sx={{
                    width: 'auto',
                    height: '2.06rem',
                    
                    
                    fontWeight: '700',
                    fontSize: '0.93rem',
                    
                    
                    alignItems: 'center',
                    color: '#FFFFFF',
                    fontFamily: "Lato",
                    borderRadius:'0.31rem',
                   '&:hover': {
                    backgroundColor: 'rgba(109, 195, 252, 1)',
                   }
                   }} onClick={handleSearch}> Search </LoadingButton>
                   </Stack>
                   </Stack>
 </Box>
           <Divider sx={{backgroundColor:'white'}} />
           
     
   <TblContainer>
    <TblHead/>
        <TableBody>
                              
         {recordsAfterPagingAndSorting().map(item =>(
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
          
              <TableCell align="left" sx={mainThemeStyle.cellGreenClickable} onClick={() => {ChangeId(item.id)}}>
              {item.fname+" "+item.lname}
              </TableCell>
               <TableCell align="left" sx={mainThemeStyle.cellRow}>
              {item.type}
              </TableCell>
              {showTable == 0 &&  <TableCell align="left" sx={mainThemeStyle.cellRowLight}>
              {item.door_pin}
              </TableCell>}
              {showTable == 1 &&  <TableCell align="left" sx={mainThemeStyle.cellRowLight}>
              {item.license}
              </TableCell>}
              {showTable == 2 &&  <TableCell align="left" sx={mainThemeStyle.cellRowLight}>
              {item.generic}
              </TableCell>}
              {showTable == 2 &&  <TableCell align="left" sx={mainThemeStyle.cellRowLight}>
              {item.weigand}
              </TableCell>}
              <TableCell align="left" sx={mainThemeStyle.cellRow}>
              {item.phone_no}
              </TableCell>
              <TableCell align="left" sx={mainThemeStyle.cellRow}>
              {item.user_email}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      
      </TblContainer>
      <PuffLoader color="#878787" loading={loading} cssOverride={{display: "block",
    margin: "0 auto",
    marginTop:"50px",
    marginBottom:"50px",}} />
  
  <BelowTblPagination></BelowTblPagination>

</Box>
<Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={severityData} sx={{ width: '100%' }}>
          {openSnackData}
        </Alert>
      </Snackbar>
     
 </> 
          
          
          
          
          ;
};

export default MasterSearch;