import React , { useState }from 'react';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import { createTheme,ThemeProvider  } from '@mui/material/styles';
//import RefineLogo from './asset/RefineLogo.png';
import Logo from './components/Logo';
import Theme from './components/PortalTheme';

import { Box } from '@mui/system';

const PortalUserLogin = ({
    errorMessage,
    phonenumber,
    disableUserSubmit,
    onUserSubmit,
    onClickAdminLogin,
    onChangePhoneNumber
}) => {
    const theme = Theme;
    const [isUsernameSelected, selectUsernameField] = useState(false);
    const [isPasswordSelected, selectPasswordField] = useState(false);
    return (
        <ThemeProvider theme={theme}>
            <form
                id="form"
                noValidate
                autoComplete="off"
                className="form"
            >
                <FormGroup > 
                   
                     <Logo  width={170} height={65} />
                     <InputLabel   style={{
                         shrink:true,
                         fontSize: '14px',
      position: 'unset !important',
      color: 'rgba(255, 255, 255, 1) !important',
      paddingLeft: '23px',
      marginBottom: '-6px',
   }
  }>Phone Number</InputLabel>
                    <TextField
                        id="input-username"
                        type="text"
                        name="phonenumber"
                        placeholder="Your Mobile Number"
                        fullWidth
                        margin="normal"
                        
                        InputLabelProps={{ shrink: true }
                    }
                        InputProps={{
                           
                            style: {
                               
                                color: 'rgba(255, 255, 255, 1)',
                                height:'40px',
                                borderRadius: '19px',
                                backgroundColor: '#121212',
                                padding: '5px 0 5px 0',
                                textIndent: '15px !important',
                                marginLeft: '5px',
                                border: `1px solid ${theme.palette.inputBackgroundColor.main}`,
                                
                            },
                            
                        }}
                        value={phonenumber}
                        onChange={onChangePhoneNumber}
                    />
                </FormGroup>
              
                {/* <FormGroup row style={{ justifyContent: 'flex-end' }}>
                    <Link
                        id="button-forgot-password"
                        className="dark-on-hover"
                        onClick={onClickAdminLogin}
                        
                    >
                        {'Property Owner Login?'}
                    </Link>
                </FormGroup> */}
                <FormGroup row style={{ justifyContent: 'center' }}>
                <p
                    id="error-message"
                    style={{
                        width: '100%',
                        minHeight: '2rem',
                        marginBottom: '0',
                        color: theme.palette.error.main,
                        fontWeight: 'bold',
                        fontSize: '12px',
                        textAlign: 'center',
                    }}
                >
                     {errorMessage}
                </p>
            </FormGroup>
                <FormGroup row style={{ justifyContent: 'center' }}>
                    <Button
                        id="button-submit"
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={onUserSubmit}
                        disabled={disableUserSubmit}
                    >
                         {'Log In'}
                    </Button>
                </FormGroup>
            </form>
       
        </ThemeProvider>
    );
};
export default PortalUserLogin;
