import React from "react";
import {  ThemeProvider } from '@mui/material/styles';
import Theme from './components/ItemTheme'
import { Box } from "@mui/system";
import { Button, Grid, List, Typography } from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import  NestedButtonDropDownHeader  from "./components/header/NestedButtonDropDownHeader";
import ManageLocation from "./ManageLocation";
import CallLog from "./CallLog";
import AddIcon from '@mui/icons-material/Add';
import LpTags from "./LpTags";
import { useState } from "react";
import ManageUsers from "./ManageUsers";
import { useParams,useNavigate ,useLocation } from "react-router-dom";
import { useEffect } from "react";
import EditUser from "./EditUser";
import AddUser from "./AddUser";
import CallingGroup from "./CallingGroup";
import OfficeGroup from "./OfficeGroup";
import PuffLoader from "react-spinners/PuffLoader";
import UserGuestParking from "./userGuestParking";
import UserGuestPIN from "./userGuestPIN";
import UserLicense from "./userLicense";
import UserCallGroup from "./userCallGroup";
import useToken from "./useToken";
import axios from "./axios";
const UserUser = (props) => {
  console.log("IN location Page");
  const {token} = useToken();
  const navigate = useNavigate();
  const {RefreshToken} = props;
  const location = useLocation();
  const {id} = useParams();
  console.log('id is '+id);
  const theme = Theme;
  const[dataOfUser,setDataOfUser]  = useState('');
  const[dataOfLocation,setDataOfLocation]  = useState('');
 /*  const getMyPostData = async () => {
    try {
      const res = await axios.get("/admin/getuserdetails/"+token.id+"/"+location.state.edituser.id+"/" +location.state.edituser.type,{
        headers: { 'Content-Type':  'application/json' },
      });
      //console.log("data of user admin is "+res.data['custom_field'][0]['id']);
      

      setDataOfUser(res.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getLocationPostData = async () => {
    try {
      const res = await axios.get("/admin/getadduserdetails/"+token.id,{
        headers: { 'Content-Type':  'application/json' },
      });
      console.log("data of user admin is "+res.data);
      

      setDataOfLocation(res.data);
    } catch (error) {
      console.log(error.message);
    }
  };
 
  useEffect(()=>{
    if(id == 'edituser'){
      setDataOfUser('');
      getMyPostData();
     }
     else if(id == 'adduser'){
      setDataOfLocation('');
      getLocationPostData();
     }
  },[id]); */
  
  

const handleGuestParking = () => {
  navigate('../user/guestparking');
}
const handleGuestPIN = () => {
  navigate('../user/guestpin');
}
const handleLicense = ()=>{
  navigate('../user/license');
}

const handleCallGroup = ()=>{
  navigate('../user/callgroup');
}

  return (
<ThemeProvider theme={theme} >

    <Box sx={mainThemeStyle.mainbox} backgroundColor="menubarprimary.main">
    <Box sx={mainThemeStyle.box} >
   
    {(id === 'guestpin' &&  <Typography sx={mainThemeStyle.lable}>Guest PIN</Typography>)}
    {(id === 'guestparking' &&  <Typography sx={mainThemeStyle.lable}>Guest Parking</Typography>)}
    {(id === 'license' &&  <Typography sx={mainThemeStyle.lable}>License Plate</Typography>)}
    {(id === 'callgroup' &&  <Typography sx={mainThemeStyle.lable}>Call Group</Typography>)}
    </Box>
    
   
   {(id === 'guestpin' && <Button sx={mainThemeStyle.activityButton} onClick={handleGuestParking} >Guest Parking</Button>)}
   {(id === 'guestparking' && <Button sx={mainThemeStyle.activityButton} onClick={handleGuestPIN} >Guest PIN</Button>)}
   {(id === 'license' && <Button sx={mainThemeStyle.activityButton} onClick={handleCallGroup} >Call Group</Button>)}
   {(id === 'callgroup' && <Button sx={mainThemeStyle.activityButton} onClick={handleLicense} >License Plate</Button>)}
  
  
     {(id === 'guestpin' && <UserGuestPIN RefreshToken={RefreshToken}/>)}
     {(id === 'guestparking' && <UserGuestParking RefreshToken={RefreshToken}/>)}
    {(id === 'license' && <UserLicense RefreshToken={RefreshToken}/>)}
    {(id === 'callgroup' && <UserCallGroup RefreshToken={RefreshToken}/>)}
    </Box>
   


   
  
</ThemeProvider>);
};

export default UserUser;