import * as React from "react";
import { Box } from "@mui/system";
import { Button,Checkbox, Switch,Grid, InputLabel,Select,MenuItem, List,Divider, FormControlLabel, Typography,ButtonGroup,Snackbar ,Alert ,IconButton, Icon,Dialog,DialogTitle,DialogContent,DialogActions,DialogContentText, cardMediaClasses } from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';
import FilledInput from '@mui/material/FilledInput';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { faker } from '@faker-js/faker';
import { format } from 'date-fns';
import { useState,useRef,useEffect } from "react";
import useTable from "./components/useTable";
import SvgIcon from "@mui/material/SvgIcon";
import NoImage from './asset/noimage.png';
import Popup from "./components/Popup";
import _ from "lodash";
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { styled } from '@mui/material/styles';
import axios from "./axios";
import PuffLoader from "react-spinners/PuffLoader"; 
import LoadingButton from '@mui/lab/LoadingButton';
import useToken from "./useToken";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import useInnerTable from "./components/useInnerTable";
import InputAdornment from '@mui/material/InputAdornment';
import {  ThemeProvider } from '@mui/material/styles';
import Theme from './components/ItemTheme';
import GlobalAlert from "./GlobalAlert";
import GlobalRoleAccess from "./GlobalRoleAccess";
import GlobalRoleAccessInner from "./GlobalRoleAccessInner";
import TimezoneSelect from 'react-timezone-select'
import { useSSR } from "react-i18next";
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


function DistributorManager ( props){
  const handleCloseSnack= (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
  const { getAlertTitle} = GlobalAlert();
  const {RefreshToken} = props;
  const theme = Theme;
  const {token} = useToken();
  const [openDelete, setOpenDelete] = React.useState(false);
  const [titleforalert, setTitleforalert] = React.useState("");
  const [isVisible ,setIsVisibile] = useState(false);
  const [isVisibleCheck ,setIsVisibileCheck] = useState(false);
  const [isVisibleArray, setIsVisibileArray] = useState([]);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [openSnackData, setOpenSnackData] = React.useState("This is Success Data");
  const [severityData, setSeverityData] = React.useState("success");
  const [loadingbtn ,setloadingbtn] = React.useState(false);
  const [EnLog,setEnLog] = useState([]);
  const {getRoleAccessVal} = GlobalRoleAccess();
  const {getRoleAccessInnerVal} = GlobalRoleAccessInner();
  const [apiValue, setAPIValue] = useState();
  const [fullName,setFullName] = useState('');
  const [emailAddress,setEmailAddress] = useState('');
  const [phoneNumber,setPhoneNumber] = useState('');
  const [passwords,setPasswords] = useState('');
  const [showUpdateButton, setshowUpdateButton] = React.useState(false);
  let [loading, setLoading] = useState(true);
  const [currentDeleteID,setCurrentDeleteId] = useState();
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  const handleCloseActiveDelete =() =>{
    setOpenDelete(false);
    console.log("currentDeleteID"+currentDeleteID);
    deleteMyPostData();
  }
  const MenuProps = {
    PaperProps: {
      style: {
        backgroundColor: "#1E1E1E",
          borderRadius: 0,
          maxHeight:'250px',
          borderRadius: 10,
          padding:0
      },
    },
  };
  const deleteMyPostData = async () => {
    setloadingbtn(true);
     try {
       const res = await axios.delete("/distributor/deletemanager/"+token.id+"/"+currentDeleteID,
       {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
       'token' : token.token,
       'tokenid' : token.email,
       'issub':token.issub,
       'subId':token.subid,
       'name':currentManagerName}});
       console.log( res.data);
       setOpenSnackData(getAlertTitle('DSMDS'));
       setSeverityData("success");
       setOpenSnack(true);
       setLoading(false);
       
       getMyPostData();
     } catch (error) {
       console.log(error.message);
       setLoading(false);
       setOpenSnackData(getAlertTitle('DSMDF'));
       setSeverityData("error");
       setOpenSnack(true);
       setloadingbtn(false);
       if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
     }
   };
  const getMyPostData = async () => {
    try {
      const res = await axios.get("/distributor/getmanagerdetails/"+token.id,{
        headers: { 'Content-Type':  'application/json',
        'token' : token.token,
        'tokenid' : token.email },
      });
      console.log("data format"+JSON.stringify( res.data));
      setEnLog(res.data[0]);
      //let ad  = apiValue;
      //ad.contacts = res.data[0].contacts;
      let adsArray = [];
      setRecords( res.data);
      if(res.data.length > 0){
        //let rd = JSON.parse(res.data);

        for(let ist = 0; ist < res.data.length ; ist ++){
          adsArray.push(false);
        }
        setIsVisibileArray(adsArray);
      }

      console.log("is visible array"+adsArray);
      //setAPIValue(ad);
      setLoading(false);
      setloadingbtn(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setloadingbtn(false);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };

  const getDisableValueEdit = (val) => {
    if(token.role == 'superadmin' || token.name === 'SUPER ADMIN'){
      console.log("token sub type" + token.sub_type);
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("29").administrator_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let valOfDisable = getRoleAccessVal("29").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'distributor'){
      console.log("token sub type" + token.sub_type);
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("29").distributor_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let valOfDisable = getRoleAccessVal("29").distributor_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
    
    
  }
  const getDisableValueDelete = (val) => {
    if(token.role == 'superadmin'|| token.name === 'SUPER ADMIN'){
      console.log("token sub type" + token.sub_type);
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("30").administrator_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let valOfDisable = getRoleAccessVal("30").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'distributor'){
      console.log("token sub type" + token.sub_type);
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("30").distributor_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let valOfDisable = getRoleAccessVal("30").distributor_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
    
    
  }
  const getDisableValueAdd = (val) => {
    if(token.role == 'superadmin' || token.name === 'SUPER ADMIN'){
      console.log("token sub type" + token.sub_type);
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("28").administrator_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let valOfDisable = getRoleAccessVal("28").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'distributor'){
      console.log("token sub type" + token.sub_type);
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("28").distributor_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let valOfDisable = getRoleAccessVal("28").distributor_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
    
    
  }


const getDisableValue = (val) => {
    if(getDisableValueEdit("") == false){
    if(token.role == 'superadmin' || token.name === 'SUPER ADMIN'){
      if(token.sub_type == "1"){
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("29");
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
        let valOfDisable = valOfDisableData[idData].administrator_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("29");
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
        let valOfDisable = valOfDisableData[idData].administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'distributor'){
      if(token.sub_type == "1"){
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("29");
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
        let valOfDisable = valOfDisableData[idData].distributor_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("29");
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
        let valOfDisable = valOfDisableData[idData].distributor_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
  }else{
    return true;
  }
    
  }
  useEffect(() => {
    getMyPostData();
  }, []);
  const handleInputChange=(event) =>{
    console.log(apiValue);
    setshowUpdateButton(true);
    setAPIValue({contacts:event.target.value,});
  }
  const [records,setRecords] = useState([]);
  const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });
  const headCells = [
    { id: 'name', label: 'Name', disableSorting: true },
    { id: 'email', label: 'Email', disableSorting: true },
    { id: 'phone_number', label: 'Phone', disableSorting: true },
    { id: 'sub_type', label: 'Access Type', disableSorting: true },
    { id: 'access', label: 'Action', disableSorting: true },
  ];
  const {
    TblContainer,
    TblHead,
    TblPagination ,
    BelowTblPagination,
    recordsAfterPagingAndSorting ,
    setPageToNewOne ,
  } = useInnerTable(records, headCells, filterFn,5,650);
  const updateMyPostPasswordData = async (val) => {
    console.log(apiValue);
    setloadingbtn(true);
    try {
      const res = await axios.patch("/distributor/updateproeprtymanagerpassword/"+token.id,val, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8' ,
      'token' : token.token,
      'tokenid' : token.email}});
      console.log( res.data);
      setOpenSnackData(getAlertTitle('DSMUPS'));
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      setshowUpdateButton(false);
      setFullName('');
      setEmailAddress('');
      setPhoneNumber('');
      getMyPostData();
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      setOpenSnackData(getAlertTitle('DSMUPF'));
      setSeverityData("error");
      setOpenSnack(true);
      setloadingbtn(false);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  const updateMyPostData = async (val) => {
    console.log(apiValue);
    setloadingbtn(true);
    try {
      const res = await axios.patch("/distributor/updateproeprtymanager/"+token.id,val, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8' ,
      'token' : token.token,
      'tokenid' : token.email}});
      console.log( res.data);
      setOpenSnackData(getAlertTitle('DSMUS'));
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      setshowUpdateButton(false);
      setFullName('');
      setPhoneNumber('');
      setEmailAddress('');
      setPasswords('');
      getMyPostData();
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      setOpenSnackData(getAlertTitle('DSMUF'));
      setSeverityData("error");
      setOpenSnack(true);
      setloadingbtn(false);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  const addMyPostData = async (val) => {
    console.log(apiValue);
    setloadingbtn(true);
    try {
      const res = await axios.post("/distributor/addmanager/"+token.id,val, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      'token' : token.token,
      'tokenid' : token.email }});
      console.log( res.data);
      if(res.data.rows == '1'){
      setOpenSnackData(getAlertTitle('DSMMAS'));
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      setshowUpdateButton(false);
      setFullName('');
      setEmailAddress('');
      setPhoneNumber('');
      setPasswords('');
      getMyPostData();
      }else{
        setOpenSnackData(getAlertTitle('DSMMAEAE'));
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      setshowUpdateButton(false);
      }
      setloadingbtn(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setOpenSnackData(getAlertTitle('DSMMAF'));
      setSeverityData("error");
      setOpenSnack(true);
      setloadingbtn(false);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  const handleSaveCardFormat = () => {
    updateMyPostData();
  }
  const handleTableNameChange = (event) => {
    let idIn;
    //console.log('record email'+records[0]  );
    for(let i = 0; i < records.length ; i++){
      let item = records[i];
      console.log('record email'+item.email  );
      console.log(' email'+ event.target.name );
      if(item.id == event.target.name ){
       
          idIn = i;
          break;
      }
    }
    console.log(event.target.name);
    console.log(idIn );
    records[idIn].username = event.target.value;
    setRecords(records);
  }
  const handleTablePhoneNumberChange= (event) => {
    let idIn;
    //console.log('record email'+records[0]  );
    for(let i = 0; i < records.length ; i++){
      let item = records[i];
      console.log('record email'+item.email  );
      console.log(' email'+ event.target.name );
      if(item.id == event.target.name ){
       
          idIn = i;
          break;
      }
    }
    console.log(event.target.name);
    console.log(idIn );
    records[idIn].phone_number = event.target.value;
    setRecords(records);
  }
  const handleTablePasswordChange = (event) => {
    let idIn;
    for(let i = 0; i < records.length ; i++){
      if(records[i].id == event.target.name ){
          idIn = i;
          break;
      }
    }
    console.log(event.target.name );
    console.log(idIn );
    records[idIn].password = event.target.value;
    setRecords(records);
  }
  const AccessType = {
    id: "1",
    label: "Distributor Sub-User",
    items: [
      {
        label: "Distributor",
        callback: () =>  {}
      },
      {
        label: "Distributor Sub-User",
        callback: () =>  {}
      }
    ]
  }

  const handleChangeAccessType = (event) => {
    let ds = event.target.value;
    let idIn;
    //console.log('record email'+records[0]  );
    for(let i = 0; i < records.length ; i++){
      let item = records[i];
      console.log('record email'+item.email  );
      console.log(' email '+ event.target.name );
      if(item.id == event.target.name){
       
          idIn = i;
          break;
      }
    }
    console.log(ds);
    console.log(idIn );
    if(ds == "Distributor"){
      records[idIn].sub_type = "1";
    }else{
      records[idIn].sub_type = "0";
    }
    
    setRecords(records);
    
  }
  const [currentManagerName,setCurrentManagerName] = useState('');
  const handleDelete = (event) =>{
   /* let idIn;
    for(let i = 0; i < records.length ; i++){
      if(records[i].email == event.target.name ){
          idIn = i;
          break;
      }
    }
    records.splice(idIn,1);
    setRecords(records);
    apiValue.contacts= JSON.stringify(records);
    setAPIValue(apiValue);
    updateMyPostData();*/
    /*if(token.issub == true){
      setOpenSnackData("You don't have access to perform any action on this page");
      setSeverityData("error");
      setOpenSnack(true);
      return;
    }*/
    let idofItem =  records.filter(x => x.id == event.target.name);
    setCurrentDeleteId(event.target.name);
    setCurrentManagerName(idofItem[0].username);
    setTitleforalert("Are you sure you want to delete manager "+ idofItem[0].username + " ?");
    setOpenDelete(true);
  }
  const getVisibleEvent = (event) => {
    let idIn;
    for(let i = 0; i < records.length ; i++){
      if(records[i].id == event){
          idIn = i;
          break;
      }
    }
    return isVisibleArray[idIn];
  }
  const handleNameChange = (event) => {
    setFullName(event.target.value);
  }
  const handleEmailChange = (event) => {
    setEmailAddress(event.target.value);
  }
  const handlePhoneChange = (event) => {
    setPhoneNumber(event.target.value);
  }
  const handlePasswordChange = (event) => {
    setPasswords(event.target.value);
  }
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }


  const onClickTogglePassword = () => {
    setIsVisibile(!isVisible);
  }
  const handlePropertyReset = (event) => {
    /*if(token.issub == true){
      if(token.name != 'SUPER ADMIN'){
      setOpenSnackData("You don't have access to perform any action on this page");
      setSeverityData("error");
      setOpenSnack(true);
      return;
      }
    }*/
    let idIn;
    for(let i = 0; i < records.length ; i++){
      if(records[i].id == event.target.name){
          idIn = i;
          break;
      }
    }
    let asv = { username:records[idIn].username,email:records[idIn].email,user_type:records[idIn].user_type,password:records[idIn].password,phone_number:records[idIn].phone_number,id:records[idIn].id,user_id:records[idIn].user_id,sub_type:records[idIn].sub_type,issub:token.issub,
      subId:token.subid};
    updateMyPostPasswordData(asv);
  }
  const handlePropertyModify = (event) => {

    /*if(token.issub == true){
      if(token.name != 'SUPER ADMIN'){
      setOpenSnackData("You don't have access to perform any action on this page");
      setSeverityData("error");
      setOpenSnack(true);
      return;
      }
    }*/
    
    let idIn;
    for(let i = 0; i < records.length ; i++){
      if(records[i].id == event.target.name){
          idIn = i;
          break;
      }
    }
   
     
        if((records[idIn].username.trim()).length <= 0){
          setOpenSnackData(getAlertTitle('DSMMNNE'));
          setSeverityData("error");
          setOpenSnack(true);
          return;
         
        }
        /* if((records[idIn].email.trim()).length <= 0 ){
          setOpenSnackData("Email Address Is Required To Add Property Manager. Please Enter Email Address");
           setSeverityData("error");
          setOpenSnack(true);
           return;
        } */
        /* if((records[idIn].password.trim()).length <= 0 ){
          setOpenSnackData("Password Can't Be Null. Please Enter Password");
          setSeverityData("error");
          setOpenSnack(true);
          return;
         
        } */
        /* if(!isValidEmail(records[idIn].email)){
          setOpenSnackData("Please Enter Valid Email Address");
          setSeverityData("error");
          setOpenSnack(true);
          return;
         
        } */

        
      
    
   /* else if(error == 5){
      
    }*/
    //apiValue.contacts= JSON.stringify(records);
    //setAPIValue(apiValue);
    let asv = { username:records[idIn].username,user_type:records[idIn].user_type,email:records[idIn].email,password:records[idIn].password,phone_number:records[idIn].phone_number,id:records[idIn].id,user_id:records[idIn].user_id,sub_type:records[idIn].sub_type,issub:token.issub,
      subId:token.subid};
    updateMyPostData(asv);
  }

  const handlePropertyAdd = () => {
   /* if(token.issub == true){
      if(token.name != 'SUPER ADMIN'){
      setOpenSnackData("You don't have access to perform any action on this page");
      setSeverityData("error");
      setOpenSnack(true);
      return;
      }
    }*/
    if((fullName.trim()).length <= 0){
      setOpenSnackData(getAlertTitle('DSMMNNE'));
      setSeverityData("error");
      setOpenSnack(true);
      return;
    }
    if((emailAddress.trim()).length <= 0 ){
      setOpenSnackData(getAlertTitle('DSMMENE'));
      setSeverityData("error");
      setOpenSnack(true);
      return;
    }
    /* if((passwords.trim()).length <= 0 ){
      setOpenSnackData("Password Can't Be Null. Please Enter Password");
      setSeverityData("error");
      setOpenSnack(true);
      return;
    } */
    if(!isValidEmail(emailAddress)){
      setOpenSnackData(getAlertTitle('DSMMENV'));
      setSeverityData("error");
      setOpenSnack(true);
      return;
    }
    for(let j = 0; j < records.length ; j++){
      
        if(records[j].email == emailAddress ){
          setOpenSnackData(getAlertTitle('DSMMAEAE'));
          setSeverityData("error");
          setOpenSnack(true);
          return;
        }
      
    }
    let asv = '';
    if(token.issub  == true){
      asv = {username:fullName,email:emailAddress,user_type:'distributor',phone_number:phoneNumber,account_name:token.fname,issub:token.issub,subId:token.subid};
    
    }else{
     asv = {username:fullName,email:emailAddress,user_type:'distributor',phone_number:phoneNumber,account_name:token.name,issub:token.issub,subId:token.subid};
    }
    console.log(asv);
    //records.push(asv);
   // apiValue = JSON.stringify(records);
   // setAPIValue(apiValue);
    //setRecords(records);
   // console.log(records);
   addMyPostData(asv);

  }
  let rowOfOne = -1;
  return <>
  
   <Box sx={mainThemeStyle.boxTopHeader}>
  
</Box>
<Box sx={mainThemeStyle.boxHeaderBelow}>
<Box  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px',display: { xs: "none", md: "flex" }}}>
               <div style={{ display: 'flex' }}>
                <Stack direction='row' spacing={2} padding={2}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Add Distributor Manager
                   </Typography>
                   <TextField
        id="text"
        type="text"
      placeholder="Full Name"
      value={fullName}
       variant="filled"
       sx={mainThemeStyle.InputMenuItem}
       onChange={handleNameChange}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValueAdd("")}
                          style={{paddingTop: '16px'}}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      />
       <TextField
        id="text"
        type="text"
      placeholder="Email Address"
      value={emailAddress}
       variant="filled"
       sx={mainThemeStyle.InputMenuItem}
       onChange={handleEmailChange}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValueAdd("")}
                          style={{paddingTop: '16px'}}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      />
      <TextField
        id="text"
        type="tel"
      placeholder="Mobile Number"
      value={phoneNumber}
       variant="filled"
       sx={mainThemeStyle.InputMenuItem}
       onChange={handlePhoneChange}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValueAdd("")}
                          style={{paddingTop: '16px'}}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      />
      {/* <TextField
        id="text"
        type="text"
      placeholder="Passwords"
      value={password}
       variant="filled"
       onChange={handlePasswordChange}
                        InputLabelProps={{ shrink: true }
                      }
                          style={{paddingTop: '16px'}}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /> */}
     {/*  <TextField
                        id="input-password"
                        type= {isVisible ? 'text' : 'password'}
                        name="password"
                        placeholder="Passwords"
                        style={{paddingTop: '16px'}}
                        margin="normal"
                        value={passwords}
                        variant = "filled"
                        onChange={handlePasswordChange}
                        InputLabelProps={{
                            shrink: true,
                            style: {
                            '&::placeholder': {
                                textOverflow: 'ellipsis !important',
                                color: 'blue'
                              },
                            }
                        }}
                        
                        InputProps={{
                           
                            style: {
                                
                              color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                               
                                
                            },
                            
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        style={{
                                            color:
                                            'rgba(135, 135, 135, 0.5)',
                                            padding: '18px 5px 0 0',
                                        }}
                                        aria-label={
                                            'visibility token'
                                        }
                                        onClick={onClickTogglePassword}
                                    >
                                       
                                       {isVisible ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )}
                                        
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                       
                    /> */}
      <LoadingButton sx={mainThemeStyle.normalButtonDown} onClick={handlePropertyAdd}   disabled = {getDisableValueAdd("")} loading={loadingbtn} > Add </LoadingButton>
      </Stack>
               </div>
              
           </Box>
           <Box  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px',display: { xs: "flex", md: "none" }}}>
               <Stack direction='column' spacing={0.1} padding={2}>
                <Stack direction='row' spacing={2} padding={2}>
                   <Typography sx={mainThemeStyle.popUpLable}>
                   Add Distributor Manager
                   </Typography>
                   <TextField
        id="text"
        type="text"
      placeholder="Full Name"
      value={fullName}
       variant="filled"
       onChange={handleNameChange}
       sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValueAdd("")}
                          style={{paddingTop: '16px'}}
                        InputProps={{
                           
                            sx: {
                              marginTop:'-0.5rem',
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                                
                               
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                            },
                        }}
                       
      /></Stack> <Stack direction='row' spacing={2} padding={2}>
       <TextField
        id="text"
        type="text"
      placeholder="Email Address"
      value={emailAddress}
       variant="filled"
       onChange={handleEmailChange}
       sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValueAdd("")}
                        InputProps={{
                           
                            sx: {
                              marginTop:'-1rem',
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                              
                               
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                            },
                        }}
                       
      />
      <TextField
        id="text"
        type="tel"
      placeholder="Mobile Number"
      value={phoneNumber}
       variant="filled"
       onChange={handlePhoneChange}
       sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValueAdd("")}
                        InputProps={{
                           
                            sx: {
                              marginTop:'-1rem',
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      />
    </Stack> 
      <LoadingButton sx={{
          width: 'auto',
          height: '2.06rem',
          
          
          fontWeight: '700',
          fontSize: '0.93rem',
          
          
          alignItems: 'center',
          color: '#FFFFFF',
          fontFamily: "Lato",
          borderRadius:'0.31rem',
         '&:hover': {
          backgroundColor: 'rgba(109, 195, 252, 1)',
         }
      }} onClick={handlePropertyAdd}    disabled = {getDisableValueAdd("")} loading={loadingbtn} > Add </LoadingButton>
      </Stack>
              
              
           </Box>
           <Divider sx={{backgroundColor:'white'}} />
         {/*   <Typography sx={mainThemeStyle.popUpWarner} height={30}> Note :- Enter Email Ids Separated By Comma ' , '.</Typography>
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Property Emails</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> </Typography>
      {showUpdateButton && <Button sx={mainThemeStyle.normalButton} onClick={handleSaveCardFormat}>Update Emails</Button>
 } </Stack>
      </Grid>
      
      <Grid item xs={3}>
      <Stack direction="column"  spacing={2}>
      
                    <TextField
         
          multiline
          rows={4}
          
          value={apiValue.contacts}
          variant="filled"
          onChange={handleInputChange}
          InputProps={{
                           
            sx: {
                
                color:'rgba(135, 135, 135, 0.5)',
               
                borderRadius: '5px',
                backgroundColor: '#121212',
                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                padding: '5px 0 5px 0',
                textIndent: '15px !important',
              
               
                
            },
        }}
        />
      </Stack>
    
      
      </Grid>
     
     
      
      </Grid>
 */}
<TblContainer>
                    <TblHead />

        <TableBody>
         {recordsAfterPagingAndSorting().map(item => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              {() =>{rowOfOne++; console.log(rowOfOne)}}
              <TableCell align="left"> <TextField
        id="text"
        type="text"
        defaultValue={item.username}
        name = {item.id}
       onChange={handleTableNameChange}
       sx={mainThemeStyle.InputMenuItem}
          variant = "filled"
                        InputLabelProps={{ shrink: true }
                      }
                      disabled={getDisableValue("109")}
                        InputProps={{
                          
                            sx: {
                              minWidth:'150px',
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell align="left"  sx={mainThemeStyle.cellRowBig}> {item.email}</TableCell>
      <TableCell align="left"> <TextField
        id="text"
        type="tel"
        defaultValue={item.phone_number}
        name = {item.id}
       onChange={handleTablePhoneNumberChange}
       sx={mainThemeStyle.InputMenuItem}
          variant = "filled"
                        InputLabelProps={{ shrink: true }
                      }
                      disabled={getDisableValue("110")}
                        InputProps={{
                          
                            sx: {
                              minWidth:'120px',
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell align="left"  sx={mainThemeStyle.cellRowBig}>
       <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
           
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {item.id}
            defaultValue ={item.sub_type == '1' ? "Distributor" : "Distributor Sub-User"}
          onChange={handleChangeAccessType}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
             {AccessType.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}</Select>
       </TableCell>
      {/* <TableCell align="left" >  <TextField
        id="text"
        type="text"
        value={item.email}
        
       
       
          variant = "filled"
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                           
                        }}
                       
      /> </TableCell> */}
     {/*  <TableCell align="left"> <TextField
        id="text"
        type= { getVisibleEvent(item.id)? 'text' : 'password'}
        name = {item.id}
        onChange = {handleTablePasswordChange}
        
        defaultValue={item.password}
       
          variant = "filled"
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                  <IconButton
                                      style={{
                                          color:
                                          'rgba(135, 135, 135, 0.5)',
                                          padding: '15px 5px 0 0',
                                      }}
                                      aria-label={
                                          'visibility token'
                                      }
                                      onClick={(e) => {
                                        let idIn;
                                        for(let i = 0; i < records.length ; i++){
                                          if(records[i].id == item.id ){
                                              idIn = i;
                                              break;
                                          }
                                        }
                                        let ads = isVisibleArray[idIn];
                                        isVisibleArray[idIn] =  !ads;
                                        setIsVisibileCheck(!isVisibleCheck);
                                        setIsVisibileArray(isVisibleArray);
                                        console.log(isVisibleArray);
                                        //e.target.type = 'text' ;
                                        setRecords(records);
                                      }}
                                  >
                                     {   getVisibleEvent(item.id) ?   (
                                          <VisibilityOff />
                                      ) : (
                                          <Visibility />
                                      )
                                       
                                      }
                                     
                                      
                                  </IconButton>
                              </InputAdornment>
                          ),
                        }}
                       
      /></TableCell> */}
              
              <TableCell align="left">
                  <Stack direction='row' spacing={2}>
                  <Typography></Typography>  
               <LoadingButton  sx={mainThemeStyle.viewLogButton} name={item.id} loading={loadingbtn} onClick={handlePropertyModify} disabled = {getDisableValueEdit("")}> Save Changes </LoadingButton>
               <LoadingButton sx={mainThemeStyle.viewLogButton} name={item.id} loading={loadingbtn} onClick={handlePropertyReset}  disabled={getDisableValue("111")}> Reset Password </LoadingButton>    
                <LoadingButton loading={loadingbtn} name={item.id} sx={mainThemeStyle.viewLogButtonRed} onClick={handleDelete} disabled = {getDisableValueDelete("")}>Delete</LoadingButton>
                  </Stack>  </TableCell>
            </TableRow>
           ))}
        </TableBody>
        </TblContainer>
        <BelowTblPagination></BelowTblPagination>
</Box>
<Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={severityData} sx={{ width: '100%' }}>
          {openSnackData}
        </Alert>
      </Snackbar>
      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          padding: '16px',
      
         backgroundColor:'#00000000',
         
      }}
      >
        <DialogTitle id="alert-dialog-title" sx={{
        
      
         backgroundColor:'#2A2F3B',
         color:'white'
         
      }}>
          {titleforalert}
        </DialogTitle>
        <DialogContent sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          
        </DialogContent>
        <DialogActions sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          <Button onClick={handleCloseDelete} sx={mainThemeStyle.normalBigButton}>Cancel</Button>
          <Button onClick={handleCloseActiveDelete} autoFocus sx={mainThemeStyle.normalBigButtonRed}>
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>
 </> 
          
          
          
          
          ;
};

export default DistributorManager;