import React from "react";
import {  ThemeProvider } from '@mui/material/styles';
import Theme from './components/ItemTheme'
import { Box } from "@mui/system";
import { Button, Grid, List, Typography } from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import  NestedButtonDropDownHeader  from "./components/header/NestedButtonDropDownHeader";
import ManageLocation from "./ManageLocation";
import CallLog from "./CallLog";
import AddIcon from '@mui/icons-material/Add';
import LpTags from "./LpTags";
import { useState } from "react";
import AddDealer from "./AddDealer";
import ManageDealer from "./ManageDealer";
import EditDealer from "./EditDealer";
import EditDistributor from "./EditDistributor";
import { useParams,useNavigate,useLocation  } from "react-router-dom";
import { useEffect } from "react";
import useToken from "./useToken";
import PuffLoader from "react-spinners/PuffLoader";
import axios from "./axios";
const Dealer = (props) => {
  console.log("IN location Page");
  const {token} = useToken();
  const {RefreshToken,switchToSubUser} = props;
  const navigate = useNavigate();
  const {id} = useParams();
  console.log('id is '+id);
  const theme = Theme;
  const location = useLocation();
  const [dataOfDistributor,setDataOfDistributor] = useState('');

  const getMyDealerData = async () => {
    try {
      const res = await axios.get("/administrator/distributor/",{
        headers: { 'Content-Type':  'application/json',
        'token' : token.token,
        'tokenid' : token.email },
      });
      //console.log("data of user admin is "+res.data['custom_field'][0]['id']);
      

      setDataOfDistributor(res.data);
    } catch (error) {
      console.log(error.message);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };


  useEffect(()=>{
    if(id == 'editdealer'){
      setDataOfDistributor('');
      getMyDealerData();
     }
     if(id == 'adddealer'){
      setDataOfDistributor('');
      getMyDealerData();
     }
  },[id]);
  
  
 const handleAdd = () =>{
  navigate('../dealer/adddealer');
 }
 const handleEdit = () =>{
  navigate('../dealer/managedealer');
}


  return (
<ThemeProvider theme={theme}>

    <Box sx={mainThemeStyle.mainbox} backgroundColor="menubarprimary.main">
    <Box sx={mainThemeStyle.box}>
    {(id === 'managedealer' &&  <Typography sx={mainThemeStyle.lable}>Dealer</Typography>)}
    {(id === 'adddealer' &&  <Typography sx={mainThemeStyle.lable}>Add Dealer</Typography>)}
    {(id === 'editdealer' &&  <Typography sx={mainThemeStyle.lable}> Dealer : {location.state.editdealer.company_name}</Typography>)}
    </Box>
    
    {/* {(id === 'managedealer' &&
    <Button startIcon={<AddIcon />} sx={mainThemeStyle.activityButton} onClick={handleAdd} >Add Dealer</Button>)}
   {(id === 'adddealer' && <Button sx={mainThemeStyle.activityButton} onClick={handleEdit} >View Dealer</Button>)}
   {(id === 'editdealer' &&  <Button sx={mainThemeStyle.activityButton} onClick={handleEdit} >View Dealer</Button>)}
     */}
    {(id === 'managedealer' && <ManageDealer RefreshToken={RefreshToken} switchToSubUser={switchToSubUser}/>)}
    {(id === 'adddealer' && dataOfDistributor!= '' && <AddDealer  dataOfDistributor={dataOfDistributor} RefreshToken={RefreshToken}/>)}
    {(id === 'adddealer'  && dataOfDistributor == '' &&<PuffLoader color="#878787" loading cssOverride={{display: "block",
    margin: "0 auto",
    marginTop:"250px"}} />)}
    {(id === 'editdealer' && dataOfDistributor!= '' &&<EditDealer idOfDealer={location.state.editdealer} dataOfDistributor={dataOfDistributor} RefreshToken={RefreshToken}/>)}
   {(id === 'editdealer'  && dataOfDistributor == '' &&<PuffLoader color="#878787" loading cssOverride={{display: "block",
    margin: "0 auto",
    marginTop:"250px"}} />)}
    </Box>
   


   
  
</ThemeProvider>);
};

export default Dealer;