import { useState,useRef,useEffect } from "react";
 



  export default function GlobalRoleAccess() {

    const getRoleAccessVal = val => {
        let idData ;
        for(let i = 0; i < globalRoleAccessData.length ; i++){
          if(globalRoleAccessData[i].id == val ){
              idData = i;
              break;
          }
      }
      return globalRoleAccessData[idData];
  
    };
    const getGlobalRoleAccessData = () => {
      //localStorage.setItem('token', '{}');
      //localStorage.clear();
      const alertString = localStorage.getItem('role_access');
     
      const userAlert = JSON.parse(alertString);
  
     // console.log('user token is '+userToken.name);
      return userAlert;
  
    };
    const [globalRoleAccessData,setGlobalRoleAccessData] = useState(getGlobalRoleAccessData());
  
    
  
    
  
    const setGlobalRoleAccessDataD = userData => {
      localStorage.setItem('role_access', JSON.stringify(userData));
        
      setGlobalRoleAccessData(userData);
  
    };
  
    
  
    return {
  
    setGlobalRoleAccessData: setGlobalRoleAccessDataD,
  
    globalRoleAccessData,
  
    getRoleAccessVal : getRoleAccessVal,
  
    }
  
  }

