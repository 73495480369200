import * as React from "react";
import { Box } from "@mui/system";
import { Button,Checkbox, Switch,Grid, InputLabel, List,Divider, FormControlLabel,Typography,ButtonGroup,Snackbar ,Alert ,IconButton, Icon,Dialog,DialogTitle,DialogContent,DialogActions,DialogContentText } from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { faker } from '@faker-js/faker';
import { format } from 'date-fns';
import ReactPlayer from "react-player";
import { useState,useRef,useEffect } from "react";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import axios from "./axios";
import useTable from "./components/useTable";
import useInnerTable from "./components/useInnerTable";
import SvgIcon from "@mui/material/SvgIcon";
import PuffLoader from "react-spinners/PuffLoader";
import NoImage from './asset/noimage.png';
import Popup from "./components/Popup";
import _ from "lodash";
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { styled } from '@mui/material/styles';
import GlobalAlert from "./GlobalAlert";
import {  ThemeProvider } from '@mui/material/styles';
import useToken from "./useToken";
import Theme from './components/ItemTheme';
import { SxProps } from "@mui/material";
import TimezoneSelect from 'react-timezone-select'
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const {Device} = require('twilio-client');
const { connect } = require('twilio-video');
const {LocalDataTrack} = require('twilio-video');

function HomeWebViewer (props) {
   // const {token} = useToken();
   const { RefreshToken,token,switchU } = props;
  const theme = Theme;
  const sx: SxProps = {
    "& .MuiDialog-container": {
      alignItems: "flex-end"
    }
 };

  let [dataTrack,setDataTrack] = useState(null);
  //const {RefreshToken} = props;
  let [dataOfWebViewer,setDataOfWebViewer] = useState([]);
  const [userResponded,setUserResponded] = useState(false);
  let [deviceToken,setDeviceToken] = useState('');
  let [vidoToken,setVideoToken] = useState('');
  const { getAlertTitle} = GlobalAlert();
  const [openSnack, setOpenSnack] = React.useState(false);
  const [openSnackData, setOpenSnackData] = React.useState("This is Success Data");
  const [severityData, setSeverityData] = React.useState("success");

  async function notifyUser(title,desc){
    if(!"Notification" in window){
      alert("Desktop notifications not available in your browser..");
    }
    else if (Notification.permission === "granted"){
      console.log("in notificatin");
      var options = {
        body: desc,
       // icon: 'https://mdvaccess.com/webviewer/images/icon.png',
        requireInteraction: true,
      };
  
      const notification = new Notification(title,options);
  
      notification.onclick = () => {
      
        window.focus();
        notification.close();
        setOpen(true);
      }
    }
    else if(Notification.permission !== "denied"){
      Notification.requestPermission().then((permission) => {
        if(permission == "granted"){
          var options = {
            body: desc,
           // icon: 'https://mdvaccess.com/webviewer/images/icon.png',
            requireInteraction: true,
          };
      
          const notification = new Notification(title,options);
          notification.onclick = () => {
       
            window.focus();
            notification.close();
          }
        }
  
      })
  
    }
  }
  
  const getMyPostData = async () => {
    setLoading(true);
    try {
      const res = await axios.get("/webviewer/location/"+token.id,{
        headers: { 'Content-Type':  'application/json',
        'token' : token.token,
        'tokenid' : token.email },
      });
      console.log("data format"+JSON.stringify( res));
      setDataOfWebViewer(res.data.location);
      setDeviceToken(res.data.voiceToken);
      setVideoToken(res.data.videoToken);
      setLoading(false);
      //if(device != null)
      //device.setup(res.data.voiceToken);
      
    } catch (error) {
      console.log(error.response);
      setLoading(false);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };

  const [open, setOpen] = React.useState(false);
  const [openEndCall,setOpenEndCall] = React.useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [titleforalert, setTitleforalert] = React.useState("Would you like to enable notifications?");
  const handleClose = async () => {
    //setOpen(false);
    /*await notifyUser('Notification Enable','Enabling Your Notification').then(()=>{
      setUserResponded(true);
    });
    //Notification.requestPermission();*/

    Notification.requestPermission().then((permission) => {
      console.log(permission);
    });
    setUserResponded(true);
    
  };
  const handleCloseActive =() =>{
    //setOpen(false);
    setUserResponded(true);
    
  }

  const handleEnd=() =>{
      setOpen(false);
      if(currentConnection != null){
        currentConnection.reject();
        setcurrentConnection(null);
      }
  }

  const handleAccept=() =>{
    setOpen(false);
    if(currentConnection != null){
      console.log('it is called');
      currentConnection.accept();
      setOpenPopup(true);
      setOpenEndCall(true);
    }
  }

  const handleEndCall = () =>{
    setOpenEndCall(false);
    if(currentConnection != null){
      currentConnection.disconnect();
      setcurrentConnection(null);
    }
  }

  const handleUnlock = () =>{
    if(currentConnection != null){
      if(callLocCamBrand == "ResidentLink" || callLocCamBrand == "Virtual Panel"){
        dataTrack.send("Unlock");
      }
      else{
      currentConnection.sendDigits(callSendDTMFCode);
      }
    }
  }
  const headCells = [
    { id: 'loc_photo', label: 'Image', disableSorting: true },
    { id: 'loc_name', label: 'Location Name'},
   
  ];
  const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });
 /* const {
    TblContainer,
    TblHead,
    TblPagination,
    BelowTblPagination,
    recordsAfterPagingAndSorting,
    setPageToNewOne
} = useTable(dataOfWebViewer, headCells, filterFn,"Download Location");*/
const [currentConnection,setcurrentConnection] = useState(null);
const {
    TblContainer ,
    TblHead ,
    TblPagination ,
    BelowTblPagination,
    recordsAfterPagingAndSorting ,
    setPageToNewOne 
  } = useInnerTable(dataOfWebViewer, headCells, filterFn,5,350);
  const [callLocCamBrand, setCallLogCamBrand] = useState('');
  const [callSendDTMFCode, setCallSendDTMFCode] = useState('');

  const [callCamIp, setCallCamIp] = useState('');
  const [callUserName, setCallUserName] = useState('');
  const [callPassword, setCallPassword] = useState('');
  const [callLocation, setCallLocation] = useState('');

  useEffect(() => {
   // getMyPostData();
   if(deviceToken != ""){
    const device = new Device();
    
   device.setup(deviceToken);
    device.on('incoming', connection => {
      // immediately accepts incoming connection
      //connection.accept();
      
      setcurrentConnection(connection);
      console.log(connection);
      let from = connection.parameters.From;
      console.log(from);
      let idOffind = 0;
      let  compare = from.slice(2);
      for(let i = 0; i < dataOfWebViewer.length ; i++){
        if(dataOfWebViewer[i].loc_no == compare ){
          idOffind = i;
            break;
        }
    }
    console.log('data web viewer data ' + JSON.stringify (dataOfWebViewer[idOffind]));
    let send = dataOfWebViewer[idOffind].loc_code;
    setCallSendDTMFCode(send);
    let loc_name = dataOfWebViewer[idOffind].loc_name;
    let loc_cambrand = dataOfWebViewer[idOffind].cam_brand;
    setCallLogCamBrand(loc_cambrand);

    let loc_camIp = dataOfWebViewer[idOffind].cam_ip;
    setCallCamIp(loc_camIp);
    let loc_username = dataOfWebViewer[idOffind].cam_username;
    setCallUserName(loc_username);
    let loc_password = dataOfWebViewer[idOffind].cam_password;
    setCallPassword(loc_password);

    let loc_no = dataOfWebViewer[idOffind].loc_no;
    setCallLocation(loc_no);
    let title = 'My Door View';
    let desc = 'Your incoming Call from '+loc_no;

    notifyUser(title,desc);

     
    });
  
    device.on('ready', device => {
      
    });
    device.on("cancel", () => {
      console.log('cancelled');
      setcurrentConnection(null);
      setOpen(false);
      setOpenEndCall(false);
      setOpenPopup(false);
      
      if(currentRoom != null){
        currentRoom.disconnect();
        setCurrentRoom(null);
        dataTrack = null;
        setDataTrack(dataTrack);
    
    }
    });
    device.on("reject", () => {
      console.log('rejected');
      setcurrentConnection(null);
      setOpen(false);
      setOpenEndCall(false);
      setOpenPopup(false);
      
      if(currentRoom != null){
        currentRoom.disconnect();
        setCurrentRoom(null);
        dataTrack = null;
        setDataTrack(dataTrack);
    
    }
    });
    device.on('error', error => {
      console.log('error log is '+JSON.stringify (error));
      if(currentConnection != null)
      currentConnection.disconnect();
      setcurrentConnection(null);
      setOpen(false);
      setOpenEndCall(false);
      setOpenPopup(false);
      
      if(currentRoom != null){
        currentRoom.disconnect();
        setCurrentRoom(null);
        dataTrack = null;
        setDataTrack(dataTrack);
    
    }
    });
    device.on('connect', connection => {
      console.log('connected');
      let from = connection.parameters.From;
      console.log(from);
      let idOffind = 0;
      let  compare = from.slice(2);
      for(let i = 0; i < dataOfWebViewer.length ; i++){
        if(dataOfWebViewer[i].loc_no == compare ){
          idOffind = i;
            break;
        }
    }
    console.log('data web viewer data ' + JSON.stringify (dataOfWebViewer[idOffind]));
    let send = dataOfWebViewer[idOffind].loc_code;
    setCallSendDTMFCode(send);
    console.log('dt,f cpde '+send);
    let loc_name = dataOfWebViewer[idOffind].loc_name;
    console.log('dt,f loc_name '+loc_name);
    let loc_cambrand = dataOfWebViewer[idOffind].cam_brand;
    setCallLogCamBrand(loc_cambrand);
    console.log('dt,f setCallLogCamBrand '+loc_cambrand);
      console.log('connected'+callLocCamBrand );
      let loc_no = dataOfWebViewer[idOffind].loc_no;
      if(loc_cambrand == "ResidentLink" || loc_cambrand == "Virtual Panel"){
        console.log("callLocCamBrand is "+loc_no );
      dataTrack = new LocalDataTrack();
      setDataTrack(dataTrack);
      connect(vidoToken, { name:loc_no ,tracks:[dataTrack]}).then(room => {
        console.log(`Successfully joined a Room: ${room}`);
        setCurrentRoom(room);
        room.on('participantConnected', participant => {
          console.log(`A remote Participant connected: ${participant}`);
        });
        room.participants.forEach(participant => {
          console.log(`Participant "${participant.identity}" is connected to the Room`);
          participant.tracks.forEach(publication => {
            if (publication.track) {
              publication.track.muted = true;
              //document.getElementById('remote-media-div').appendChild(publication.track.attach());
              const localMediaContainer = document.getElementById('local-media');
              // localMediaContainer.appendChild(track.attach());
              if(localMediaContainer != null){
                
                publication.track.attach(localMediaContainer);
                 
              }
            }
          });
          participant.on('trackSubscribed', track => {
            //document.getElementById('remote-media-div').appendChild(track.attach());
            const localMediaContainer = document.getElementById('local-media');
              // localMediaContainer.appendChild(track.attach());
              if(localMediaContainer != null){
                
                track.attach(localMediaContainer);
                 
              }
          });
        });
        
        // Log new Participants as they connect to the Room
        room.once('participantConnected', participant => {
          console.log(`Participant "${participant.identity}" has connected to the Room`);
        });
        
        // Log Participants as they disconnect from the Room
        room.once('participantDisconnected', participant => {
          console.log(`Participant "${participant.identity}" has disconnected from the Room`);
        });
      }, error => {
        console.error(`Unable to connect to Room: ${error.message}`);
      });
    }else{
      let imageurl = "";
      if(callLocCamBrand == "Hikvision"){

        if(callUserName == ""){
          imageurl = "http://"+callCamIp+"/Streaming/channels/102/httpPreview";
        }
        else{
          imageurl = "http://"+callUserName+":"+callPassword+"@"+callCamIp+"/Streaming/channels/102/httpPreview";
        }

      }
      if(callLocCamBrand == "ACTi"){

        if(callUserName == ""){
          imageurl = "http://"+callCamIp+"/cgi-bin/encoder?GET_STREAM";
        }
        else{
          imageurl = "http://"+callCamIp+"/cgi-bin/encoder?USER="+callUserName+"&PWD="+callPassword+"&GET_STREAM=VIDEO";
        }

      }
      if(callLocCamBrand == "AXIS"){

        if(callUserName == ""){
          imageurl = "http://"+callCamIp+"/axis-cgi/mjpg/video.cgi";
        }
        else{
          imageurl = "http://"+callUserName+":"+callPassword+"@"+callCamIp+"/Streaming/channels/102/httpPreview";
        }

      }
      if(callLocCamBrand == "Nortek"){

        if(callUserName == ""){
          imageurl = "http://"+callCamIp+"/cgi-bin/video.cgi&type=HTTP&cameraID=1&streamID=1&EncoderType=MJPEG";
        }
        else{
          
          imageurl = "http://"+callCamIp+"/cgi-bin/video.cgi?userName="+callUserName+"&password="+callPassword+"&type=HTTP&cameraID=1&streamID=1&EncoderType=MJPEG";
        }

      }
      if(callLocCamBrand == "Sunell"){

        if(callUserName == ""){
          imageurl = "http://"+callCamIp+"/cgi-bin/video.cgi&type=HTTP&cameraID=1&streamID=1&EncoderType=MJPEG";
        }
        else{
          
          imageurl = "http://"+callCamIp+"/cgi-bin/video.cgi?userName="+callUserName+"&password="+callPassword+"&type=HTTP&cameraID=1&streamID=1&EncoderType=MJPEG";
        }

      }
      setImageUrl(imageurl);
    }
     // setcurrentConnection(connection);
    });
  
    device.on('disconnect', connection => {
      console.log('discconnected');
      setcurrentConnection(null);
      setOpen(false);
      setOpenEndCall(false);
      setOpenPopup(false);
     
        if(currentRoom != null){
          currentRoom.disconnect();
          setCurrentRoom(null);
          dataTrack = null;
          setDataTrack(dataTrack);
      
      }
    });

    return () => {
      device.destroy();
      
    };
  }

   
  }, [deviceToken]);

 
  useEffect(() => {
    console.log('token changed');
    setDataOfWebViewer([]);
      setDeviceToken("");
    getMyPostData();
  }, [switchU]);
  const handleCloseSnack= (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
  const [imageurl,setImageUrl] = useState('');
  const [loading,setLoading] = useState(false);
  const [currentRoom,setCurrentRoom] = useState(null);
 
  const ShowLog = () =>{
    console.log("callLocCamBrand is "+callLocCamBrand );
    if(callLocCamBrand == "ResidentLink" || callLocCamBrand == "Virtual Panel"){
     
      
      return(<Box  id="remote-media-div"> 
         <video  
    id='local-media'
    width={473}
    height={710} style={{'objectFit': 'cover'}}/> 
        
        </Box>)
    }else{
      
      return(<Box> 
        
         
        <iframe  width={710}
        height={473} src={imageurl} frameborder='0' scrolling='no' allow='autoplay'
        />
        
       
        
        </Box>)

    }
   
  }

  return  <ThemeProvider theme={theme} ><> 
 

<Box sx={mainThemeStyle.mainbox} backgroundColor="menubarprimary.main">
<Box sx={mainThemeStyle.box} >

<Typography sx={mainThemeStyle.lable}>Home</Typography>
</Box>
{/* {(!(userResponded) && !(Notification.permission === "granted"))
? (setOpen(true)) :
((Notification.permission === "granted") ?
(notifyUser('test','dialogone')) :(<Box></Box>))} */}
{/* {(Notification.permission === "granted") && <LoadingButton  sx={mainThemeStyle.activityButton} onClick={() => notifyUser("check data", "it is notification")} >get Notification</LoadingButton>} */}
<LoadingButton  sx={mainThemeStyle.activityButton} onClick={getMyPostData} >Reload</LoadingButton>
<Box sx={{ position: 'absolute',
        width:'100%',
        top:'10rem',
        left: '0rem',
        display:'flex',
        flexDirection:'column',
        alignContent:'space-between',
        backgroundColor:"menubarprimary.main"}} backgroundColor="menubarprimary.main">

    <TblContainer>
                    <TblHead />
                    <TableBody>
                        {
                            recordsAfterPagingAndSorting().map(item =>
                                ( <TableRow
                                  key={item.id}
                                  sx={{ borderBottom: "1.5px solid black",'&:last-child td, &:last-child th': { border: 0 },
                                   }}
                                >
                                  
                                 
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}> <img  src={"https://mdvaccess.com/"+item.loc_photo} height={100} width={100} onError={(e) =>
          {
            e.target.onerror = null; // Remove the onerror handler to prevent recursion
            e.target.src = NoImage;  // Set the source to the fallback image
          }
        }></img></TableCell>
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.loc_name}</TableCell>
                                  
                                </TableRow>)
                            )
                        }
                    </TableBody>
                   
                </TblContainer>
                <Box sx={{marginTop:'10px'}}>
                <PuffLoader color="#878787" loading={loading} cssOverride={{display: "block",
    margin: "0 auto",
    marginTop:"50px",
    marginBottom:"50px",}} />



</Box>
                <BelowTblPagination ></BelowTblPagination>
               </Box>
<Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={severityData} sx={{ width: '100%' }}>
          {openSnackData}
        </Alert>
      </Snackbar>
      {!(userResponded) && !(Notification.permission === "granted") &&
       <Dialog
       open={!userResponded}
       onClose={handleCloseActive}
       aria-labelledby="alert-dialog-title"
       aria-describedby="alert-dialog-description"
       sx={{
         padding: '16px',
     
        backgroundColor:'#00000000',
        
     }}
     >
       <DialogTitle id="alert-dialog-title" sx={{
       
     
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
         {titleforalert}
       </DialogTitle>
       <DialogContent sx={{
       
     
       backgroundColor:'#2A2F3B',
       color:'white'
       
    }}>
         
       </DialogContent>
       <DialogActions sx={{
       
     
       backgroundColor:'#2A2F3B',
       color:'white'
       
    }}>
         <Button onClick={handleClose} sx={mainThemeStyle.normalBigButton}>Sure!</Button>
         <Button onClick={handleCloseActive} autoFocus sx={mainThemeStyle.normalBigButtonRed}>
           No Thanks!
         </Button>
       </DialogActions>
     </Dialog>}

     <Dialog
       open={open}
       onClose={handleEnd}
       sx={{
        
        backgroundColor:'#00000000',
        
     }}
     >
      
       <DialogActions sx={{
       
       //width:'500px',
       backgroundColor:'#2A2F3B',
       color:'white'
       
    }}>
       <Stack direction='column' spacing={2}> 
         <Button onClick={handleAccept} sx={mainThemeStyle.normalBigButton}>ANSWER DOOR CALLER</Button>
         <Button onClick={handleEnd} autoFocus sx={mainThemeStyle.normalBigButtonRed}>
         REJECT DOOR CALLER
         </Button>
          </Stack> 
       </DialogActions>
     </Dialog>

    
      </Box>
      <Popup
                title=""
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                noclose = {true}
            >
             
      <ShowLog/>
               
            </Popup>
            <Dialog
       open={openEndCall}
       
       
       sx={sx}
     >
      
       <DialogActions sx={{
       
     
       backgroundColor:'#2A2F3B',
       color:'white'
       
    }}>
         <Button onClick={handleUnlock} sx={mainThemeStyle.normalBigButton} disabled={callLocCamBrand == "ResidentLink" ? (dataTrack === null ? true : false) : false}>UNLOCK</Button>
         <Button onClick={handleEndCall} autoFocus sx={mainThemeStyle.normalBigButtonRed}>
         ENDCALL
         </Button>
       </DialogActions>
     </Dialog>
 </> 
          
 </ThemeProvider>
          
          
          ;
};

export default HomeWebViewer;