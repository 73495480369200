import React from "react";
import { Box } from "@mui/system";
import { Button, Grid, InputLabel, List, Typography,ButtonGroup,Snackbar ,InputAdornment,Alert ,IconButton, Icon,Dialog,DialogTitle,DialogContent,DialogActions,DialogContentText } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { LoadingButton } from "@mui/lab";
import KeyIcon from '@mui/icons-material/Key';
import {mainThemeStyle} from './components/MainTheme';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Paper from '@mui/material/Paper';
import { faker } from '@faker-js/faker';
import { format } from 'date-fns';
import { useState,useRef,useEffect } from "react";
import useTable from "./components/useTable";
import SvgIcon from "@mui/material/SvgIcon";
import NoImage from './asset/noimage.png';
import Popup from "./components/Popup";
import _ from "lodash";
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { useParams,useNavigate  } from "react-router-dom";
import axios from "./axios";
import useToken from "./useToken";
import PuffLoader from "react-spinners/PuffLoader"; 
import GlobalAlert from "./GlobalAlert";
import GlobalRoleAccess from "./GlobalRoleAccess";
import GlobalRoleAccessInner from "./GlobalRoleAccessInner";
//let EnLog = [], TYPE = ['Sunnel','AXIS','Nortek','HikVision','ResidentLink'];
let Alphabet =['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
/*for (let i =0; i < 200; i++){
  EnLog[i] = {
    id: i,
    preopertyname:faker.name.fullName(),
    comapnyname:faker.company.name(),
    distributorcomapnyname:faker.company.name(),
    email:faker.internet.email() ,
    password:faker.random.alphaNumeric(8) ,
    address:faker.address.streetAddress(),
    city:faker.address.cityName(),
    state:faker.address.state(),
    zipcode:faker.address.zipCode(),
    officenumber:faker.phone.number('(###) ###-####'),
  }
}*/


function ManageDealer ( props){
  const {token} = useToken();
  const navigate = useNavigate();
  const myContainer = useRef(null);
  const {RefreshToken,switchToSubUser} = props;
  const headCells = [
    { id: 'company_name', label: 'Company' },
    { id: 'start_date', label: 'Start Date'},
    { id: 'contact_name', label: 'Contact Name'},
    {id: 'distributor_company_name', label: 'Distributor Company Name'},
    { id: 'email', label: 'Email' },
    /* { id: 'password', label: 'Password' }, */
    { id: 'street_add', label: 'Address' },
    { id: 'city', label: 'City' },
    { id: 'state', label: 'State' },
    { id: 'zip_code', label: 'Zip' },
    { id: 'telephone', label: 'Cell #' },
    { id: 'total_unit', label: 'Total Unit' },
  { id: 'unit_cost_month', label: 'Monthly Cost' },
  { id: 'unit_cost_year', label: 'Yearly Cost' },
    { id: 'action', label: 'Action', disableSorting: true  },
  ]
  const [EnLog,setEnLog] = useState([]);
  const { getAlertTitle} = GlobalAlert();
  const [shownameType, setShownameType] = useState(true);
 
  const [showPropertyNameType, setShowPropertyNameType] = useState(true);
  const [showCompanyName, setShowCompanyName] = useState(false);
  const [showDealerCompany, setShowDealerCompany] = useState(false);
  const [showTotalUnit, setShowTotalUnit] = useState(false);
  const [showMonthlyCost, setShowMonthlyCost] = useState(false);
  const [showAnnualCost, setShowAnnualCost] = useState(false);
  const [showOfficeNumber, setShowOfficeNumber] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const [showDateAdded,setShowDateAdded] = useState(false);
  const [showCity, setShowCity] = useState(false);
  const [showState, setShowState] = useState(false);
  const [showZipCode, setShowZipCode] = useState(false);
  const [showLocationCount, setShowLocationCount] = useState(false);
  const {getRoleAccessVal} = GlobalRoleAccess();
  const {getRoleAccessInnerVal} = GlobalRoleAccessInner();
  const [filterText, setfilterText] = useState('');
  const [resetClick, setResetClicked] = useState(false);
  const [currentId, setCurrentId] = useState(0);
  const [openPopup, setOpenPopup] = useState(false);
  const FilterMenu = {
    id: "1",
    label: "Contact Name",
    items: [
      {
        label: "Contact Name",
        callback: () =>  {
            setShownameType(true);
            setShowPropertyNameType(true);
            setShowCompanyName(false);
            setShowDealerCompany(false);
            setShowTotalUnit(false);
            setShowMonthlyCost(false);
            setShowAnnualCost(false);
            setShowOfficeNumber(false);
            setShowEmail(false);
            setShowPassword(false);
            setShowAddress(false);
            setShowCity(false);
            setShowState(false);
            setShowZipCode(false);
            setShowLocationCount(false);
            setShowDateAdded(false);
         if(document.getElementById('input-location') != null)
            document.getElementById('input-location').value ='';
        }
         
      },
      {
        label: "Company Name",
        callback: () =>  {
            setShownameType(true);
            setShowPropertyNameType(false);
            setShowCompanyName(true);
            setShowDealerCompany(false);
            setShowTotalUnit(false);
            setShowMonthlyCost(false);
            setShowAnnualCost(false);
            setShowOfficeNumber(false);
            setShowEmail(false);
            setShowPassword(false);
            setShowAddress(false);
            setShowCity(false);
            setShowState(false);
            setShowZipCode(false);
            setShowLocationCount(false);
            setShowDateAdded(false);
         if(document.getElementById('input-location') != null)
            document.getElementById('input-location').value ='';
        }
         
      },
      {
        label: "Distributor Company Name",
        callback: () =>  {
            setShownameType(true);
            setShowPropertyNameType(false);
            setShowCompanyName(false);
            setShowDealerCompany(true);
            setShowTotalUnit(false);
            setShowMonthlyCost(false);
            setShowAnnualCost(false);
            setShowOfficeNumber(false);
            setShowEmail(false);
            setShowPassword(false);
            setShowAddress(false);
            setShowCity(false);
            setShowState(false);
            setShowZipCode(false);
            setShowLocationCount(false);
            setShowDateAdded(false);
         if(document.getElementById('input-location') != null)
            document.getElementById('input-location').value ='';
        }
         
      },
      {
        label: "Office Number",
        callback: () =>  {
            setShownameType(true);
            setShowPropertyNameType(false);
            setShowCompanyName(false);
            setShowDealerCompany(false);
            setShowTotalUnit(false);
            setShowMonthlyCost(false);
            setShowAnnualCost(false);
            setShowOfficeNumber(true);
            setShowEmail(false);
            setShowPassword(false);
            setShowAddress(false);
            setShowCity(false);
            setShowState(false);
            setShowZipCode(false);
            setShowLocationCount(false);
            setShowDateAdded(false);
         if(document.getElementById('input-location') != null)
            document.getElementById('input-location').value ='';
        }
         
      },
      {
        label: "Email",
        callback: () =>  {
            setShownameType(true);
            setShowPropertyNameType(false);
            setShowCompanyName(false);
            setShowDealerCompany(false);
            setShowTotalUnit(false);
            setShowMonthlyCost(false);
            setShowAnnualCost(false);
            setShowOfficeNumber(false);
            setShowEmail(true);
            setShowPassword(false);
            setShowAddress(false);
            setShowCity(false);
            setShowState(false);
            setShowZipCode(false);
            setShowLocationCount(false);
            setShowDateAdded(false);
         if(document.getElementById('input-location') != null)
            document.getElementById('input-location').value ='';
        }
         
      }/* ,
      {
        label: "Password",
        callback: () =>  {
            setShownameType(true);
            setShowPropertyNameType(false);
            setShowCompanyName(false);
            setShowDealerCompany(false);
            setShowTotalUnit(false);
            setShowMonthlyCost(false);
            setShowAnnualCost(false);
            setShowOfficeNumber(false);
            setShowEmail(false);
            setShowPassword(true);
            setShowAddress(false);
            setShowCity(false);
            setShowState(false);
            setShowZipCode(false);
            setShowLocationCount(false);
         if(document.getElementById('input-location') != null)
            document.getElementById('input-location').value ='';
        }
         
      } */,
      {
        label: "Address",
        callback: () =>  {
            setShownameType(true);
            setShowPropertyNameType(false);
            setShowCompanyName(false);
            setShowDealerCompany(false);
            setShowTotalUnit(false);
            setShowMonthlyCost(false);
            setShowAnnualCost(false);
            setShowOfficeNumber(false);
            setShowEmail(false);
            setShowPassword(false);
            setShowAddress(true);
            setShowCity(false);
            setShowState(false);
            setShowZipCode(false);
            setShowLocationCount(false);
            setShowDateAdded(false);
         if(document.getElementById('input-location') != null)
            document.getElementById('input-location').value ='';
        }
         
      },
      {
        label: "City",
        callback: () =>  {
            setShownameType(true);
            setShowPropertyNameType(false);
            setShowCompanyName(false);
            setShowDealerCompany(false);
            setShowTotalUnit(false);
            setShowMonthlyCost(false);
            setShowAnnualCost(false);
            setShowOfficeNumber(false);
            setShowEmail(false);
            setShowPassword(false);
            setShowAddress(false);
            setShowCity(true);
            setShowState(false);
            setShowZipCode(false);
            setShowLocationCount(false);
            setShowDateAdded(false);
         if(document.getElementById('input-location') != null)
            document.getElementById('input-location').value ='';
        }
         
      },
      {
        label: "State",
        callback: () =>  {
            setShownameType(true);
            setShowPropertyNameType(false);
            setShowCompanyName(false);
            setShowDealerCompany(false);
            setShowTotalUnit(false);
            setShowMonthlyCost(false);
            setShowAnnualCost(false);
            setShowOfficeNumber(false);
            setShowEmail(false);
            setShowPassword(false);
            setShowAddress(false);
            setShowCity(false);
            setShowState(true);
            setShowZipCode(false);
            setShowLocationCount(false);
            setShowDateAdded(false);
         if(document.getElementById('input-location') != null)
            document.getElementById('input-location').value ='';
        }
         
      },
      {
        label: "Zip",
        callback: () =>  {
            setShownameType(true);
            setShowPropertyNameType(false);
            setShowCompanyName(false);
            setShowDealerCompany(false);
            setShowTotalUnit(false);
            setShowMonthlyCost(false);
            setShowAnnualCost(false);
            setShowOfficeNumber(false);
            setShowEmail(false);
            setShowPassword(false);
            setShowAddress(false);
            setShowCity(false);
            setShowState(false);
            setShowZipCode(true);
            setShowLocationCount(false);
            setShowDateAdded(false);
         if(document.getElementById('input-location') != null)
            document.getElementById('input-location').value ='';
        }
         
      },
      {
        label: "Start Date",
        callback: () =>  {
          setShownameType(false);
          setShowPropertyNameType(false);
          setShowCompanyName(false);
          setShowDealerCompany(false);
          setShowTotalUnit(false);
          setShowMonthlyCost(false);
          setShowAnnualCost(false);
          setShowOfficeNumber(false);
          setShowEmail(false);
          setShowPassword(false);
          setShowAddress(false);
          setShowCity(false);
          setShowState(false);
          setShowZipCode(false);
          setShowLocationCount(false);
          setShowDateAdded(true);
         if(document.getElementById('input-location') != null)
            document.getElementById('input-location').value ='';
            if(document.getElementById('date') != null)
        document.getElementById('date').value ='';
        setfilterText('');
      
        }
        
         
      }
     
    ]
  };
  const [openPopupPassword, setOpenPopupPassword] = useState(false);
  const [isVisible ,setIsVisibile] = useState(false);
const [isVisibleReenter,setIsVisibleReenter] = useState(false);
const [passwords, setPasswords] = useState('');
const [currentEmail, setCurrentEmail] = useState('');
const [passwordsReEnter, setPasswordsReEnter] = useState('');

const handlePasswordReEnterChange= (event) => {
  setPasswordsReEnter(event.target.value);
}
const handlePasswordChange = (event) => {
  setPasswords(event.target.value);
}
const onClickTogglePassword = () => {
  setIsVisibile(!isVisible);
}
const changeTimeZone  =  (date, timeZone)=> {
  const laDate = moment.utc(date).format();
  //if (typeof date === 'string') {
    const ldated =  new Date(
      new Date(laDate).toLocaleString('en-US', {
        timeZone,
      }),
    );
    const mystr = moment(ldated).format('YYYY-MM-DD');
    return mystr;
 /* }

  return new Date(
    date.toLocaleString('en-US', {
      timeZone,
    }),
  );*/
}
const onClickToggleReEnterPassword = () => {
  setIsVisibleReenter(!isVisibleReenter);
}

const handleChangePassword = (event) => {

  if(passwords == ""){
    setOpenSnackData(getAlertTitle('PNE'));
    setSeverityData("error");
    setOpenSnack(true);
  }else if(passwordsReEnter == ""){
    setOpenSnackData(getAlertTitle('RPNE'));
    setSeverityData("error");
    setOpenSnack(true);
  }
  else if(passwords.length < 6){
    setOpenSnackData(getAlertTitle('PLST6E'));
    setSeverityData("error");
    setOpenSnack(true);
  }
  else if(passwords != passwordsReEnter){
    setOpenSnackData(getAlertTitle('PRPNS'));
    setSeverityData("error");
    setOpenSnack(true);
  }
  else{
    updateChangePassword();
  }
  
}

const getDisableValueEdit = () => {
  if(token.role == 'superadmin'){
    if(token.sub_type == "1"){
      let valOfDisable = getRoleAccessVal("5").administrator_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
    }
    else{
      let valOfDisable = getRoleAccessVal("5").administrator_sub_control;
      if(valOfDisable == "1"){
        return false;
      }
      else{
        return true;
      }
    }
  }
  else{
    return true;
  }
  
  
}
const getDisableValueDelete = () => {
  if(token.role == 'superadmin'){
    if(token.sub_type == "1"){
      let valOfDisable = getRoleAccessVal("6").administrator_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
    }
    else{
      let valOfDisable = getRoleAccessVal("6").administrator_sub_control;
      if(valOfDisable == "1"){
        return false;
      }
      else{
        return true;
      }
    }
  }
  else{
    return true;
  }
  
  
}

const updateChangePassword = async () => {
  setLoading(true);
  try {
    const res = await axios.post("/changePassword",{email:currentEmail,password:passwords}, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8' ,
    'token' : token.token,
    'tokenid' : token.email}});
    console.log( res.data);
    setOpenSnackData('Password Has Been Changed Successfully.');
    setSeverityData("success");
    setOpenSnack(true);
    setLoading(false);
  } catch (error) {
    console.log(error.response);
    setLoading(false);
    setOpenSnackData('Password Change Fail, Please Try Again Later.');
    setSeverityData("error");
    setOpenSnack(true);
    if(error.response.data.message == 'UnAuthorized'){
      RefreshToken();
    }
  }
};
 
 
  let [loading, setLoading] = useState(true);
  const getMyPostData = async () => {
    setLoading(true);
    try {
      const res = await axios.get("/administrator/dealer/",{
        headers: { 'Content-Type':  'application/json',
        'token' : token.token,
        'tokenid' : token.email},
      });
      console.log(res.data);
    console.log(res.data);
      setEnLog(res.data);
      setRecords(res.data);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  useEffect(() => {
    getMyPostData();
  }, []);
  const [records, setRecords] = useState(EnLog);
  const [filterFn, setFilterFn] = useState({ fn: items => { return items; } })
  const handleChangeText = e => {
    console.log(e.target.value);
    let value = e.target.value;
    setfilterText(value);
    console.log(filterText);
}
  const {
      TblContainer,
      TblHead,
      TblPagination,
      BelowTblPagination,
      recordsAfterPagingAndSorting,
      setPageToNewOne
  } = useTable(records, headCells, filterFn,"Download Dealer");
 /*function setDtaForReact(){
 
}*/
/*useEffect(() => {  console.log('use effect clicked'); if(resetClick){
  console.log('reset clicked');
  setResetClicked(false);
  setfilterText('');
  if(document.getElementById('input-location') != null)
  document.getElementById('input-location').value ='';
  if(document.getElementById('date') != null)
        document.getElementById('date').value ='';
       
  handleSearch();
}}, []);*/
  const handleReset =() =>{
    setfilterText('');
    if(document.getElementById('input-location') != null)
    document.getElementById('input-location').value ='';
    
          setFilterFn({
            fn: items => {
              return items;
                    
            }
        })
        setPageToNewOne();
  }
  const checkAtOrNot = (value) =>{
     let ad = records.filter(x => x.company_name.toLowerCase().startsWith(value.toLowerCase()));
     console.log(ad.length);
     return ad.length;
  }

  const jumpToAlphabet = (val) =>{
    console.log('value is '+val);
    setFilterFn({
      fn: items => {
        return items.filter(x => x.company_name.toLowerCase().startsWith(val.toLowerCase()))
              
      }
  })
  setPageToNewOne();
  }
  const [currentDealerName,setCurrentDealerName] = useState('');
  const ChangeId = (val,val2) =>{
    console.log(currentId);
    if(val2 == 0){
      let disableval = getDisableValueEdit();
      if(disableval == true){
        setOpenSnackData("You don't have access to perform this action");
        setSeverityData("error");
        setOpenSnack(true);
      }
      else{
      let idofItem =  records.filter(x => x.del_id == val);
      let itemtopass = idofItem[0];
      navigate('../dealer/editdealer',  {state:{editdealer : itemtopass}})
      }
    }else if(val2 == 2){
      let disableval = getDisableValueDelete();
      if(disableval == true){
        setOpenSnackData("You don't have access to perform this action");
        setSeverityData("error");
        setOpenSnack(true);
      }
      else{
      setCurrentId(val);
      let deletid;
      for(let i = 0; i < EnLog.length ; i++){
          if(EnLog[i].del_id == val ){
              deletid = i;
              break;
          }
      }
      setCurrentDealerName(EnLog[deletid].company_name);
      setTitleforalert("Are you sure you want to delete dealer "+ EnLog[deletid].company_name + "?");
      setOpenDelete(true);
      }
    }
    else if(val2 == 3){
      setCurrentId(val);
      let deletid;
      for(let i = 0; i < EnLog.length ; i++){
          if(EnLog[i].del_id == val ){
              deletid = i;
              break;
          }
      }
      setCurrentDealerName(EnLog[deletid].company_name);
      setTitleforalert("Do you want to manage the Dealer account for "+ EnLog[deletid].company_name + "?");
      setOpen(true);
    }
    else if(val2 == 4){
      let disableval = getDisableValueEdit();
      if(disableval == true){
        setOpenSnackData("You don't have access to perform this action");
        setSeverityData("error");
        setOpenSnack(true);
      }
      else{
      setCurrentId(val);
      let deletid;
      for(let i = 0; i < EnLog.length ; i++){
          if(EnLog[i].del_id == val ){
              deletid = i;
              break;
          }
      }
      setCurrentEmail(EnLog[deletid].email);
      setTitleforalert("Are you sure you want to change password for account "+ EnLog[deletid].company_name + "?");
      setOpenChangePassword(true);
    }
    }
    else if(val2 == 1){
      setOpenSnack(true);
    }
  }
  
  const DeletId = (val) =>{
    setCurrentId(val);
    let deletid;
    for(let i = 0; i < EnLog.length ; i++){
        if(EnLog[i].id == val ){
            deletid = i;
            break;
        }
    }
    EnLog.splice(deletid,1);
    setFilterFn({
        fn: items => {
          return items;
                
        }
    })
  }
  const [openDelete, setOpenDelete] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [openChangePassword, setOpenChangePassword] = React.useState(false);
  const [titleforalert, setTitleforalert] = React.useState("");
  const [openSnackData, setOpenSnackData] = React.useState("This is Success Data");
  const [severityData, setSeverityData] = React.useState("success");
  const handleClosePasswrod = () => {
    setOpenChangePassword(false);
  };

  const handleChangePasswordEnter = () => {
    setOpenChangePassword(false);
    setOpenPopupPassword(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseActive =() =>{
    setOpen(false);
    let deletid;
    for(let i = 0; i < EnLog.length ; i++){
        if(EnLog[i].del_id == currentId ){
            deletid = i;
            break;
        }
    }
    switchToSubUser({email:token.email,
      account_name:EnLog[deletid].company_name,username:'SUPER ADMIN',user_id:EnLog[deletid].del_id,role:'dealer', sub_type : token.sub_type});
 
  }
  const handleCloseSnack= (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleCloseActiveDelete =() =>{
    setOpenDelete(false);
    deleteMyPostData();
  }
  const deleteMyPostData = async () => {
    try {
      const res = await axios.delete("/administrator/deletedealer/"+currentId,
      {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      'token' : token.token,
      'tokenid' : token.email,
      'issub':token.issub,
      'subId':token.subid,
      'name':currentDealerName }});
      console.log( 'delete data'+res.data);
      setOpenSnackData(getAlertTitle('EDDDS'));
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      getMyPostData();
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      setOpenSnackData(getAlertTitle('EDDDF'));
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  
  const handleSearch = () => {
    //let target = e.target;
    setFilterFn({
        fn: items => {
            if (filterText == "")
                return items;
            else{
              if(shownameType){
               
                
                console.log(shownameType);
                if(showPropertyNameType)
                return items.filter(x => x.contact_name.toString().toLowerCase().includes(filterText.toLowerCase()))

                else if(showCompanyName)
                return items.filter(x => x.company_name.toString().toLowerCase().includes(filterText.toLowerCase()))

               
               
                else if(showOfficeNumber)
                return items.filter(x => x.telephone.toString().toLowerCase().includes(filterText.toLowerCase()))

                else if(showEmail)
                return items.filter(x => x.email.toString().toLowerCase().includes(filterText.toLowerCase()))

                else if(showPassword)
                return items.filter(x => x.password.toString().toLowerCase().includes(filterText.toLowerCase()))

                else if(showAddress)
                return items.filter(x => x.street_add.toString().toLowerCase().includes(filterText.toLowerCase()))

                else if(showCity)
                return items.filter(x => x.city.toString().toLowerCase().includes(filterText.toLowerCase()))

                else if(showState)
                return items.filter(x => x.state.toString().toLowerCase().includes(filterText.toLowerCase()))

                else if(showZipCode)
                return items.filter(x => x.zip_code.toString().toLowerCase().includes(filterText.toLowerCase()))
                
                else if (showDealerCompany)
                return items.filter(x => x.distributor_company_name.toString().toLowerCase().includes(filterText.toLowerCase()))

                else 
                return items;
                
              }
              else if(showDateAdded){
                
                var mystr = filterText;
                mystr = moment(mystr).format('YYYY-MM-DD');
                return items.filter(x => changeTimeZone(x.start_date, 'America/New_York').toLowerCase().includes(mystr))
              
              }
              
              else{
                return items;
              }
            }
                
        }
    })
    setPageToNewOne();
}
  return <><Box><Typography sx={mainThemeStyle.lablefilter}>Filter</Typography>
  
  <Box sx={{position: 'absolute',
           width:'680px',
           height: '280px',
           top:'160px',
           left: '38px',
           display: { xs: "none", md: "flex" },
           right: '38px',
           flexGrow: 1,
          
           }}>
             
           <Stack direction="row" spacing={2} >
                  
                   <NestedButtonDropdown
            itemId={FilterMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.filterButton}
            data={FilterMenu}
          /> {shownameType  &&
           
          <Typography sx={mainThemeStyle.lableContains}>Contains</Typography>}
              
                   
{shownameType &&
                   
                   <TextField
                        id="input-location"
                        ref={myContainer}
                        type= 'text'
                        name="text"
                        onChange={handleChangeText}
                        sx={mainThemeStyle.InputFilter}
                        InputLabelProps={{ shrink: true }
                      }
                          hiddenLabel
                          variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color: 'rgba(255, 255, 255, 1)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#171E29',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '5px 0 5px 0',
                                textIndent: '15px !important',
                                border: `1px solid rgba(31, 39, 54, 1)`,
                               
                                
                            },
                        }}
                       
                    />}
                   {showDateAdded  && <TextField
        id="date"
        type="date"
        hiddenLabel
        sx={mainThemeStyle.InputFilter}
        onChange={handleChangeText}
        InputLabelProps={{
          shrink: true,
        }}
        variant="filled"
        InputProps={{
                           
          sx: {
              
              color: 'rgba(255, 255, 255, 1)',
              height:'33px',
              borderRadius: '5px',
              backgroundColor: '#171E29',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '5px 0 5px 0',
              textIndent: '15px !important',
              border: `1px solid rgba(31, 39, 54, 1)`,
             
              
          },
      }}/>}
                   <Button sx={mainThemeStyle.filterResetButton} onClick={handleSearch}>Go</Button>
                   
                   <Button sx={mainThemeStyle.filterResetButton} onClick={handleReset}>Reset Filters</Button>
                  </Stack>
                  </Box>
          
  <Box sx={{position: 'absolute',
           width:'auto',
           height: '280px',
           top:'160px',
           left: '38px',
           display: { xs: "flex", md: "none" },
           right: '38px',
           flexGrow: 1,
          
           }}>
             <Grid container  direction="column">
             <Grid item >
           <Stack direction="row" spacing={2} >
                  
                   <NestedButtonDropdown
            itemId={FilterMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.filterButton}
            data={FilterMenu}
          /> {/* {shownameType  &&
           
          <Typography sx={mainThemeStyle.lableContains}>Contains</Typography>} */}
                
                  
                {showDateAdded  && <TextField
        id="date"
        type="date"
        hiddenLabel
        sx={mainThemeStyle.InputFilter}
        onChange={handleChangeText}
        InputLabelProps={{
          shrink: true,
        }}
        variant="filled"
        InputProps={{
                           
          sx: {
              
              color: 'rgba(255, 255, 255, 1)',
              height:'33px',
              borderRadius: '5px',
              backgroundColor: '#171E29',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '5px 0 5px 0',
              textIndent: '15px !important',
              border: `1px solid rgba(31, 39, 54, 1)`,
             
              
          },
      }}/>}

{shownameType &&
                   
                   <TextField
                        id="input-location"
                        ref={myContainer}
                        type= 'text'
                        name="text"
                        onChange={handleChangeText}
                        sx={mainThemeStyle.InputFilterSmall}
                        InputLabelProps={{ shrink: true }
                      }
                      hiddenLabel
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color: 'rgba(255, 255, 255, 1)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#171E29',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '5px 0 5px 0',
                                textIndent: '15px !important',
                                border: `1px solid rgba(31, 39, 54, 1)`,
                               
                                
                            },
                        }}
                       
                    />}</Stack></Grid>
                   <Grid >
                   <Stack direction="row" >
                   <Button sx={mainThemeStyle.filterResetButtonSmall} onClick={handleSearch}>Go</Button>
                   
                   <Button sx={mainThemeStyle.filterResetButtonSmall} onClick={handleReset}>Reset Filters</Button>
                   </Stack>
                 </Grid></Grid></Box>
  
  
                 <Typography sx={mainThemeStyle.lableJump}>Jump</Typography>
                 
                 <ButtonGroup variant="text"  sx={{position: 'absolute',
           maxWidth:'250px',
           height: 'auto',
           top:'250px',
           left: '38px',
           display: { xs: "none", md: "flex" }
           }}>

          

          {
            Alphabet.map(item => (
              checkAtOrNot(item) > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  onClick={() => {jumpToAlphabet(item)}}>{item}</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>{item}</Button>
            ))
          }

    </ButtonGroup>
    
   
   <Box sx={mainThemeStyle.mainTablebox}>
   <BelowTblPagination ></BelowTblPagination>
    <TblContainer>
                    <TblHead />
                    <TableBody>
                        {
                            recordsAfterPagingAndSorting().map(item =>
                                ( <TableRow
                                  key={item.id}
                                  sx={{ borderBottom: "1.5px solid black",'&:last-child td, &:last-child th': { border: 0 },
                                   }}
                                >
                                  
                                  <TableCell align="left" sx={mainThemeStyle.cellGreenClickable} onClick={() => {ChangeId(item.del_id,3)}}>{item.company_name}</TableCell>
                                 
                                   <TableCell align="left" sx={mainThemeStyle.cellRow}>{changeTimeZone(item.start_date, 'America/New_York')}</TableCell>
                                   <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.contact_name}</TableCell>
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.distributor_company_name}</TableCell>
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.email}</TableCell>
                                  {/* <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.password}</TableCell> */}
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.street_add}</TableCell>
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.city}</TableCell>
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.state}</TableCell>
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.zip_code}</TableCell>
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.telephone}</TableCell>
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.total_unit}</TableCell>
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>${item.unit_cost_month}</TableCell>
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>${item.unit_cost_year}</TableCell>
                                  
                              
                                  <TableCell>
                                  <Stack direction="row" spacing={2}>
                                  <IconButton sx={{ backgroundColor: '#2A2F3B'}}  size="small" color="primary"  onClick={() => {ChangeId(item.del_id,4)}}><KeyIcon /></IconButton>
                                    <IconButton sx={{ backgroundColor: '#2A2F3B'}}  size="small" color="primary"  onClick={() => {ChangeId(item.del_id,0)}}><EditIcon /></IconButton>
                                    <IconButton sx={{ backgroundColor: '#2A2F3B'}}  size="small" color="greensuccess"  onClick={() => {ChangeId(item.del_id,3)}}><ManageAccountsIcon /></IconButton>
                                    <IconButton sx={{ backgroundColor: '#2A2F3B'}}  size="small" color="redwarning"  onClick={() => {ChangeId(item.del_id,2)}}><DeleteIcon /></IconButton>
                                    </Stack> 
                                  </TableCell>
                                </TableRow>)
                            )
                        }
                    </TableBody>
                   
                </TblContainer>
                <Box sx={{marginTop:'10px'}}>
                <PuffLoader color="#878787" loading={loading} cssOverride={{display: "block",
    margin: "0 auto",
    marginTop:"50px",
    marginBottom:"50px",}} />



</Box></Box>
                 </Box>
                 <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={severityData} sx={{ width: '100%' }}>
          {openSnackData}
        </Alert>
      </Snackbar>
      <Popup 
              title="Change Password"
              openPopup={openPopupPassword}
              setOpenPopup={setOpenPopupPassword}
          >
           
           <>
     <Box sx={{
      display: { xs: "none", md: "flex" },
      }}>
    <Grid container spacing={2}> 
      <Grid item >
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> New Password </Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> Re-Enter Password </Typography>
      </Stack>
      </Grid>
      <Grid item >
      <Stack direction="column"  spacing={2}>
      <TextField
                        id="input-password"
                        type= {isVisible ? 'text' : 'password'}
                        name="password"
                        placeholder="Password"
                        height={40}
                        sx={mainThemeStyle.InputMenuItem}
                        value={passwords}
                        variant = "filled"
                        onChange={handlePasswordChange}
                        InputLabelProps={{ shrink: true }}
                        
                        InputProps={{
                           
                            style: {
                                
                              color:'rgba(170, 170, 170, 1)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                               
                                
                            },
                            
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        style={{
                                            color:
                                            'rgba(170, 170, 170,1)',
                                            padding: '18px 5px 0 0',
                                        }}
                                        aria-label={
                                            'visibility token'
                                        }
                                        onClick={onClickTogglePassword}
                                    >
                                       
                                       {isVisible ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )}
                                        
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                       
                    />
                    <TextField
                        id="input-password"
                        type= {isVisibleReenter ? 'text' : 'password'}
                        name="password"
                        placeholder="Password"
                        height={40}
                        sx={mainThemeStyle.InputMenuItem}
                        value={passwordsReEnter}
                        variant = "filled"
                        onChange={handlePasswordReEnterChange}
                        InputLabelProps={{ shrink: true }}
                        
                        InputProps={{
                           
                            style: {
                                
                              color:'rgba(170, 170, 170, 1)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                               
                                
                            },
                            
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        style={{
                                            color:
                                            'rgba(170, 170, 170,1)',
                                            padding: '18px 5px 0 0',
                                        }}
                                        aria-label={
                                            'visibility token'
                                        }
                                        onClick={onClickToggleReEnterPassword}
                                    >
                                       
                                       {isVisibleReenter ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )}
                                        
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                       
                    />
                  
                   
      </Stack>
      </Grid>
      
      </Grid></Box>
      <Box sx={{
      display: { xs: "flex", md: "none" },
      }}>
    <Stack  direction="row"> 
        
        <Stack direction="column"  spacing={2}>
        <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> New Password </Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> Re-Enter Password </Typography>
      
      </Stack>
      
      <Stack direction="column"  spacing={2}>
      <TextField
                        id="input-password"
                        type= {isVisible ? 'text' : 'password'}
                        name="password"
                        placeholder="Password"
                        height={40}
                        sx={mainThemeStyle.InputMenuItem}
                        value={passwords}
                        variant = "filled"
                        onChange={handlePasswordChange}
                        InputLabelProps={{ shrink: true }}
                        
                        InputProps={{
                           
                            style: {
                                
                              color:'rgba(170, 170, 170, 1)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                               
                                
                            },
                            
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        style={{
                                            color:
                                            'rgba(170, 170, 170,1)',
                                            padding: '18px 5px 0 0',
                                        }}
                                        aria-label={
                                            'visibility token'
                                        }
                                        onClick={onClickTogglePassword}
                                    >
                                       
                                       {isVisible ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )}
                                        
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                       
                    />
                    <TextField
                        id="input-password"
                        type= {isVisibleReenter ? 'text' : 'password'}
                        name="password"
                        placeholder="Password"
                        height={40}
                        sx={mainThemeStyle.InputMenuItem}
                        value={passwordsReEnter}
                        variant = "filled"
                        onChange={handlePasswordReEnterChange}
                        InputLabelProps={{ shrink: true }}
                        
                        InputProps={{
                           
                            style: {
                                
                              color:'rgba(170, 170, 170, 1)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                               
                                
                            },
                            
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        style={{
                                            color:
                                            'rgba(170, 170, 170,1)',
                                            padding: '18px 5px 0 0',
                                        }}
                                        aria-label={
                                            'visibility token'
                                        }
                                        onClick={onClickToggleReEnterPassword}
                                    >
                                       
                                       {isVisibleReenter ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )}
                                        
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                       
                    />
                   
      </Stack>
      
      
      </Stack></Box>
      
      <LoadingButton sx={mainThemeStyle.saveButton} loading={loading} onClick={handleChangePassword}>Change Password</LoadingButton>
        </>
             
          </Popup>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          padding: '16px',
      
         backgroundColor:'#00000000',
         
      }}
      >
        <DialogTitle id="alert-dialog-title" sx={{
        
      
         backgroundColor:'#2A2F3B',
         color:'white'
         
      }}>
          {titleforalert}
        </DialogTitle>
        <DialogContent sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          
        </DialogContent>
        <DialogActions sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          <Button onClick={handleClose} sx={mainThemeStyle.normalBigButton}>Cancel</Button>
          <Button onClick={handleCloseActive} autoFocus sx={mainThemeStyle.normalBigButton}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          padding: '16px',
      
         backgroundColor:'#00000000',
         
      }}
      >
        <DialogTitle id="alert-dialog-title" sx={{
        
      
         backgroundColor:'#2A2F3B',
         color:'white'
         
      }}>
          {titleforalert}
        </DialogTitle>
        <DialogContent sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          
        </DialogContent>
        <DialogActions sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          <Button onClick={handleCloseDelete} sx={mainThemeStyle.normalBigButton}>Cancel</Button>
          <Button onClick={handleCloseActiveDelete} autoFocus sx={mainThemeStyle.normalBigButtonRed}>
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openChangePassword}
        onClose={handleClosePasswrod}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          padding: '16px',
      
         backgroundColor:'#00000000',
         
      }}
      >
        <DialogTitle id="alert-dialog-title" sx={{
        
      
         backgroundColor:'#2A2F3B',
         color:'white'
         
      }}>
          {titleforalert}
        </DialogTitle>
        <DialogContent sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          
        </DialogContent>
        <DialogActions sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          <Button onClick={handleClosePasswrod} sx={mainThemeStyle.normalBigButton}>Cancel</Button>
          <Button onClick={handleChangePasswordEnter} autoFocus sx={mainThemeStyle.normalBigButtonRed}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
          </> 
          
          
          
          
          ;
};

export default ManageDealer;