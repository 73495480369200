import React from "react";
import {  ThemeProvider } from '@mui/material/styles';
import Theme from './components/ItemTheme'
import { Box } from "@mui/system";
import { Button, Grid, List, Typography } from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import  NestedButtonDropDownHeader  from "./components/header/NestedButtonDropDownHeader";
import ManageLocation from "./ManageLocation";
import CallLog from "./CallLog";
import AddIcon from '@mui/icons-material/Add';
import LpTags from "./LpTags";
import { useState } from "react";
import ManageUsers from "./ManageUsers";
import { useParams,useNavigate ,useLocation } from "react-router-dom";
import { useEffect } from "react";
import EditUser from "./EditUser";
import EditCallingGroup from "./EditCallingGroup";
import AddUser from "./AddUser";
import CallingGroup from "./CallingGroup";
import OfficeGroup from "./OfficeGroup";
import PuffLoader from "react-spinners/PuffLoader";
import GuestPIN from "./GuestPIN";
import GuestParking from "./GuestParking";
import MasterSearch from "./MasterSearch";
import useToken from "./useToken";
import axios from "./axios";
const User = (props) => {
  console.log("IN location Page");
  const {token} = useToken();
  const {RefreshToken} = props;
  const navigate = useNavigate();
  const location = useLocation();
  const {id} = useParams();
  console.log('id is '+id);
  const theme = Theme;
  const[dataOfUser,setDataOfUser]  = useState('');
  const[dataOfGroup,setDataOfGroup]  = useState('');
  const[dataOfLocation,setDataOfLocation]  = useState('');
  const getMyPostData = async () => {
    try {
      const res = await axios.get("/admin/getuserdetails/"+token.id+"/"+location.state.edituser.id+"/" +location.state.edituser.type,{
        headers: { 'Content-Type':  'application/json',
        'token' : token.token,
        'tokenid' : token.email },
      });
      //console.log("data of user admin is "+res.data['custom_field'][0]['id']);
      

      setDataOfUser(res.data);
    } catch (error) {
      console.log(error.response);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
      }
    }
  };


  const getLocationPostData = async () => {
    try {
      const res = await axios.get("/admin/getadduserdetails/"+token.id,{
        headers: { 'Content-Type':  'application/json',
        'token' : token.token,
        'tokenid' : token.email },
      });
      console.log("data of user admin is "+ JSON.stringify(res.data));
      

      setDataOfLocation(res.data);
    } catch (error) {
      console.log(error.message);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
      }
    }
  };

  const getGroupData = async () => {
    console.log("data of user admin is "+(JSON.stringify(location.state.editgroup)));
    try {
      const res = await axios.get("/admin/callgroupedit/"+token.id+"/"+location.state.editgroup.group_id,{
        headers: { 'Content-Type':  'application/json',
        'token' : token.token,
        'tokenid' : token.email },
      });
      console.log("data of user admin is "+(JSON.stringify(res.data)));
      

      setDataOfGroup(res.data);
    } catch (error) {
      console.log(error.message);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
      }
    }
  };
 
  useEffect(()=>{
    if(id == 'edituser'){
      setDataOfUser('');
      getMyPostData();
     }
     else if(id == 'adduser'){
      setDataOfLocation('');
      getLocationPostData();
     }
     else if(id == 'editgroup'){
      setDataOfGroup('');
      getGroupData();
     }
  },[id]);
  
  
 const handleAdd = () =>{
  navigate('../user/adduser', { replace: true });
 }
 const handleEdit = () =>{
  navigate('../user/manageuser', { replace: true });
}
const handleGuestParking = () => {
  navigate('../user/guestparking', { replace: true });
}
const handleGuestPIN = () => {
  navigate('../user/guestpin', { replace: true });
}
const handleCallingGroup = ()=>{
  navigate('../user/groups/officegroup', { replace: true });
}

const handleOfficeGroup = ()=>{
  navigate('../user/groups/callinggroup', { replace: true });
}

  return (
<ThemeProvider theme={theme} >

    <Box sx={mainThemeStyle.mainbox} backgroundColor="menubarprimary.main">
    <Box sx={mainThemeStyle.box} >
    {(id === 'adduser' &&  <Typography sx={mainThemeStyle.lable}>Add Users</Typography>)}
    {(id === 'manageuser' &&  <Typography sx={mainThemeStyle.lable}>Manage User</Typography>)}
    {(id === 'guestpin' &&  <Typography sx={mainThemeStyle.lable}>Guest PIN</Typography>)}
    {(id === 'guestparking' &&  <Typography sx={mainThemeStyle.lable}>Guest Parking</Typography>)}
    {(id === 'edituser' &&  <Typography sx={mainThemeStyle.lable}>User : {dataOfUser.fname} {dataOfUser.lname}</Typography>)}
    {(id === 'callinggroup' &&  <Typography sx={mainThemeStyle.lable}>Family Groups</Typography>)}
    {(id === 'editgroup' &&  <Typography sx={mainThemeStyle.lable}>Family Group : {location.state.editgroup.group_name}</Typography>)}
    {(id === 'officegroup' &&  <Typography sx={mainThemeStyle.lable}>Office Groups</Typography>)}
    {(id === 'search' &&  <Typography sx={mainThemeStyle.lable}>Master Search</Typography>)}
    
   {/*  {token.name == 'SUPER ADMIN' && <Button
                  variant="text"
                  key="user"
                  disabled
                 
                  sx={{ mt: "-6px", right:"0px",position: "absolute", color: "white","&:disabled": {color: "#68BBE3" ,backgroundColor:'#ffffff00'}, height: "60px",borderRadius: 0,fontFamily: "Lato",
               fontSize:'100%',width:'400px'}}
                >
                   Currently Managing :  {token.fname}
                  
                </Button>} */}
    </Box>
    
   {/*  {(id === 'manageuser' &&
    <Button startIcon={<AddIcon />} sx={mainThemeStyle.activityButton} onClick={handleAdd} >Add User</Button>)}
   {(id === 'adduser' && <Button sx={mainThemeStyle.activityButton} onClick={handleEdit} >View Users</Button>)}
   {(id === 'edituser' && <Button sx={mainThemeStyle.activityButton} onClick={handleEdit} >View Users</Button>)}
   {(id === 'guestpin' && <Button sx={mainThemeStyle.activityButton} onClick={handleGuestParking} >Guest Parking</Button>)}
   {(id === 'guestparking' && <Button sx={mainThemeStyle.activityButton} onClick={handleGuestPIN} >Guest PIN</Button>)}

   {(id === 'search' && <Button sx={mainThemeStyle.activityButton} onClick={handleGuestParking} >Guest Parking</Button>)}
   {(id === 'callinggroup' && <Button sx={mainThemeStyle.activityButton} onClick={handleCallingGroup} >Office Group</Button>)}
   {(id === 'officegroup' && <Button sx={mainThemeStyle.activityButton} onClick={handleOfficeGroup} >Family Groups</Button>)}
   {(id === 'editgroup' && <Button sx={mainThemeStyle.activityButton} onClick={handleOfficeGroup} >Family Groups</Button>)}
    */} {(id === 'manageuser' && <ManageUsers RefreshToken={RefreshToken}/>)}
   {(id === 'adduser' && dataOfLocation != "" &&  <AddUser idOfuser={dataOfLocation} RefreshToken={RefreshToken}/>)}
   {(id === 'adduser'  && dataOfLocation == '' &&<PuffLoader color="#878787" loading cssOverride={{display: "block",
    margin: "0 auto",
    marginTop:"250px"}} />)}
   {(id === 'edituser' && dataOfUser != "" && <EditUser  idOfuser={dataOfUser} RefreshToken={RefreshToken}/>)}
   {(id === 'edituser'  && dataOfUser == '' &&<PuffLoader color="#878787" loading cssOverride={{display: "block",
    margin: "0 auto",
    marginTop:"250px"}} />)}
     {(id === 'guestpin' && <GuestPIN RefreshToken={RefreshToken}/>)}
     {(id === 'guestparking' && <GuestParking RefreshToken={RefreshToken}/>)}
    {(id === 'callinggroup' && <CallingGroup RefreshToken={RefreshToken}/>)}
    {(id === 'officegroup' && <OfficeGroup RefreshToken={RefreshToken}/>)}
    {(id === 'search' && <MasterSearch RefreshToken={RefreshToken}/>)}
    {(id === 'editgroup' && dataOfGroup != "" && <EditCallingGroup  idOfuser={dataOfGroup} idGroupName={location.state.editgroup.group_name} RefreshToken={RefreshToken}/>)}
    {(id === 'editgroup'  && dataOfGroup == '' &&<PuffLoader color="#878787" loading cssOverride={{display: "block",
    margin: "0 auto",
    marginTop:"250px"}} />)}
    </Box>
   


   
  
</ThemeProvider>);
};

export default User;