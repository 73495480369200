import React from "react";
import { makeStyles } from "@mui/styles";
// CUSTOM COMPONENTS
import { nestedMenuItemsFromObject } from "./nestedMenuItemsFromObject";

// MUI
import { Button, Menu } from "@mui/material";

// MUI ICONS
import ArrowDownIcon from "@mui/icons-material/ArrowDropDown";
const useStyles = makeStyles({
  popOverRoot: {
    pointerEvents: "none"
  }
});
export const NestedDropdown = React.forwardRef(function NestedDropdown(
  props,
  ref
) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  let currentlyHovering = false;
  const { itemId, data,navigate, onClick, ...ButtonProps } = props;
  const styles = useStyles();
  const handleClick = (event) => {
    if (anchorEl != event.currentTarget) {
     
      setAnchorEl(event.currentTarget);
    }
   

    if (onClick) {
      onClick();
    }
  };
  const handleHover= () =>  {
    currentlyHovering = true;
  }
  const handleClose = () => setAnchorEl(null);
  const leaveMenu = () => {
    currentlyHovering = false;
    setTimeout(() => {
      if (!currentlyHovering) {
        handleClose();
      }
    }, 50);
  };
  const menuItems = nestedMenuItemsFromObject({
    items: data.items,
    navigte:navigate,
    isOpen: open,
    handleClose
  });

  return (
    <div>
      <Button
      variant="outlined"
        onClick={handleClick}
        onMouseOver={handleClick}
        onMouseLeave={leaveMenu}
        endIcon={<ArrowDownIcon />}
        sx={{ mt: '-5px', color: "white", height: "60px",'&:hover': {
          backgroundColor: '#3C3C3C',borderRadius: 0,
          color: '#fff',borderRight: '1px solid black' ,borderLeft: '1px solid black'
      },borderRight: '1px solid black' ,borderLeft: '1px solid black',fontFamily: "Lato",fontSize:'12px'}}
       
      >
        {data.label}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} 
       MenuListProps={{
        onMouseEnter: handleHover,
        onMouseLeave: leaveMenu,
        style: { pointerEvents: "auto" }
      }}
     
      sx={{ mt: "44px" ,borderRadius: 0,"& .MuiList-root":{
                      padding:0
                    },"& .MuiPaper-root": {
                      backgroundColor: "#1E1E1E",
                      borderRadius: 0
                    }}} anchorOrigin={{
                        vertical: "top",
                        horizontal: "left"
                      }}
                      getContentAnchorEl={null}
       
                      PopoverClasses={{
                        root: styles.popOverRoot
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left"
                      }}>
        {menuItems}
      </Menu>
    </div>
  );
});
