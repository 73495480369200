import React from "react";
import { Box } from "@mui/system";
import { Button, Grid, InputLabel, List, Typography,ButtonGroup ,IconButton, Icon } from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { faker } from '@faker-js/faker';
import { format } from 'date-fns';
import { useState,useRef,useEffect } from "react";
import useTable from "./components/useTable";
import SvgIcon from "@mui/material/SvgIcon";
import NoImage from './asset/noimage.png';
import Popup from "./components/Popup";
import _ from "lodash";
import moment from 'moment';
import useToken from "./useToken";
import axios from "./axios";
import PuffLoader from "react-spinners/PuffLoader";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}
function randomDate(start, end) {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}



function setataForReact(){

}
const headCells = [
  { id: 'location_name', label: 'Location Name' },
  { id: 'guestname', label: 'Guest Name' },
  { id: 'usertype', label: 'User Type' },
  { id: 'pintype', label: 'Entry Type' },
  { id: 'dateadded', label: 'Date' },
  { id: 'action', label: 'Action', disableSorting: true },
]


let TYPE = ['Android','iOS','Phone','Unkonwn'],ENTRYTYPE = ['RFID Card','Door PIN','Delivery PIN','Guest PIN'];
let Alphabet =['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
/*for (let i =0; i < 200; i++){
  EnLog[i] = {
    id: i,
    locationname:faker.company.name(),
    residentname:faker.name.fullName(),
    guestname:faker.name.fullName(),
    usertype:TYPE[Math.floor(Math.random()*TYPE.length)],
    entrytype:ENTRYTYPE[Math.floor(Math.random()*ENTRYTYPE.length)],
    dateadded:format(faker.date.recent(), 'dd/MM/yyyy'),
    timestamp:format(faker.date.recent(), 'HH:mm'),
  }
}*/


function EntryLogUser ( props){
  const [EnLog,setEnLog] = useState([]);
  let [loading, setLoading] = useState(true);
  const {token} = useToken();
  const {RefreshToken} = props;
  const getMyPostData = async () => {
    try {
      const res = await axios.get("/enduser/entrylog/"+token.name,{
        headers: { 'Content-Type':  'application/json',
        'token' : token.token,
        'tokenid' : token.phone },
      });
      console.log(res.data);
      setEnLog(res.data);
      setRecords(res.data);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };

  // NOTE:  calling the function
  useEffect(() => {
    getMyPostData();
  }, []);
  const myContainer = useRef(null);
  const [shownameType, setShownameType] = useState(true);
  const [showLocationType, setShowLocationType] = useState(true);
  const [showResidentType, setShowResidentType] = useState(false);
  const [showGuestType, setShowGuestType] = useState(false);
  const [showuserType, setShowuserType] = useState(false);
  const [showentryType, setShowentryType] = useState(false);
  const [showDateType, setShowDateType] = useState(false);
  const [filterText, setfilterText] = useState('');
  const [resetClick, setResetClicked] = useState(false);
  const [currentId, setCurrentId] = useState(0);
  const [openPopup, setOpenPopup] = useState(false);
  const FilterMenu = {
    id: "1",
    label: "Location Name",
    items: [
      {
        label: "Location Name",
        callback: () =>  {
          setShowuserType(false);
          setShowentryType(false);
          setShowDateType(false);
          setShownameType(true);
          setShowLocationType(true);
          setShowResidentType(false);
          setShowGuestType(false);
         // myContainer.this.setState({ //value:''});
         if(document.getElementById('input-location') != null)
    document.getElementById('input-location').value ='';
    if(document.getElementById('date') != null)
          document.getElementById('date').value ='';
          setfilterText('');
          //console.log(format(new Date(), 'dd/MM/yyyy'));
        }
         
      },
      {
        label: "Guest Name",
        callback: () => {setShowuserType(false);
          setShowentryType(false);
          setShowDateType(false);
          setShownameType(true);
          setShowLocationType(false);
          setShowResidentType(false);
          setShowGuestType(true);
          if(document.getElementById('input-location') != null)
    document.getElementById('input-location').value ='';
    if(document.getElementById('date') != null)
          document.getElementById('date').value ='';
          setfilterText('');
        }
      },
      {
        label: "User Type",
        callback: () => {
          setShowentryType(false);
          setShowDateType(false);
          setShownameType(false);
          setShowuserType(true);
          if(document.getElementById('input-location') != null)
    document.getElementById('input-location').value ='';
    if(document.getElementById('date') != null)
          document.getElementById('date').value ='';
          setfilterText('');
        }
      },
      {
        label: "Entry Type",
        callback: () => {
          setShowDateType(false);
          setShownameType(false);
          setShowuserType(false);
          setShowentryType(true);
          if(document.getElementById('input-location') != null)
    document.getElementById('input-location').value ='';
    if(document.getElementById('date') != null)
          document.getElementById('date').value ='';
          setfilterText('');
        }
      },
      {
        label: "Date Added",
        callback: () => {
          setShownameType(false);
          setShowuserType(false);
          setShowentryType(false);
          setShowDateType(true);
          if(document.getElementById('input-location') != null)
          document.getElementById('input-location').value ='';
          if(document.getElementById('date') != null)
                document.getElementById('date').value ='';
          setfilterText('');
        }
      },
    ]
  };
  const TypeMenu = {
    id: "1",
    label: "Select Type",
    items: [
      {
        label: "Android",
        callback: () =>  {setfilterText("Android")}
       
      },
      {
        label: "iOS",
        callback: () =>  {setfilterText("iOS")}
      },
      {
        label: "Phone",
        callback: () => {setfilterText("Phone")}
      },
      {
        label: "Unknown",
        callback: () => {setfilterText("Unknown")}
      },
    ]
  };
 
  const EntryTypeMenu = {
    id: "1",
    label: "Select Type",
    items: [
      {
        label: "Internal Reader",
        callback: () =>  {setfilterText("RFIDPIN")}
       
      },
      {
        label: "External Reader",
        callback: () =>  {setfilterText("WIEGANDPIN")}
       
      },
      {
        label: "Door PIN",
        callback: () =>  {setfilterText("Doorpin")}
      },
      {
        label: "Management",
        callback: () =>  {setfilterText("Management")}
      },
      {
        label: "User Swipe",
        callback: () =>  {setfilterText("User Swipe")}
      },
      {
        label: "Delivery PIN",
        callback: () => {setfilterText("Delivery")}
      },
     
      
    ]
  };
  const [records, setRecords] = useState(EnLog)
  const [filterFn, setFilterFn] = useState({ fn: items => { return items; } })
  const handleChangeText = e => {
    console.log(e.target.value);
    let value = e.target.value;
    setfilterText(value);
    console.log(filterText);
}
  const {
      TblContainer,
      TblHead,
      TblPagination,
      BelowTblPagination,
      recordsAfterPagingAndSorting,
      setPageToNewOne
  } = useTable(records, headCells, filterFn,"Download Logs");

  const handleReset =() =>{
    setfilterText('');
    if(document.getElementById('input-location') != null)
    document.getElementById('input-location').value ='';
    if(document.getElementById('date') != null)
          document.getElementById('date').value ='';
    
          setFilterFn({
            fn: items => {
              return items;
                    
            }
        })
        setPageToNewOne();
  }
  const checkAtOrNot = (value) =>{
     let ad = records.filter(x => x.location_name.toLowerCase().startsWith(value.toLowerCase()));
     console.log(ad.length);
     return ad.length;
  }

  const jumpToAlphabet = (val) =>{
    console.log('value is '+val);
    setFilterFn({
      fn: items => {
        return items.filter(x => x.location_name.toLowerCase().startsWith(val.toLowerCase()))
              
      }
  })
  setPageToNewOne();
  }

  const ChangeId = (val) =>{
    console.log(currentId);
    setCurrentId(val);
    setOpenPopup(true);
  }

  const getRefineData = (val) =>{
    if(val === "Unknown"){
      return "N/A";
    }
    else if(val === "ADMIN"){
      return "Property Admin";
    }
    else if(val === "android"){
      return "Android";
    }
    else if(val === "Doorpin"){
      return "Door PIN";
    }
    else if(val === "Delivery"){
      return "Delivery PIN";
    }
    else if(val === "RFIDPIN"){
      return "Internal Reader";
    }
    else if(val === "WIEGANDPIN"){
      return "External Reader";
    }
    else{
      return val;
    }
  }
  /*useEffect(()=>{
    setOpenPopup(true);
  },[currentId]);
  .target.src = {NoImage}; 
    }*/

    const getName = (val) => {
      if(val == "1"){
        return "Success";
      }
      else{
        return "Fail";
      }
    }
    const ShowLog = () =>{
      let indexOfCurrent;
      for(let i = 0; i < EnLog.length ; i++){
          if(EnLog[i].id == currentId ){
            indexOfCurrent = i;
              break;
          }
      }
      let imageurl = "https://mdvaccess.com/"+EnLog[indexOfCurrent].imageurl;
    return(<>
      <Box sx={{
      display: { xs: "none", md: "flex" },
      }}>
    <Grid container spacing={2}> 
      <Grid item >
        <Stack direction="column"  spacing={2}>
      {/* <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> id </Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer}  height={40}> Location Code </Typography> */}
      <Typography sx={mainThemeStyle.popUpLableAnswer}  height={40}> Location Name </Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer} height={200}> Entry Captured Photo </Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer}  height={40}>User Name </Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer}  height={40} >Guest Name </Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer}  height={40}>User Type </Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer}  height={40}>PIN Type </Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer}  height={40}>Date Added </Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer}  height={40}>Status </Typography>
      </Stack>
      </Grid>
      <Grid item >
      <Stack direction="column"  spacing={2}>
      {/* <Typography sx={mainThemeStyle.popUpLable} height={40}>{currentId}</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> {EnLog[indexOfCurrent].location_code} </Typography> */}
      <Typography sx={mainThemeStyle.popUpLable} height={40}> {EnLog[indexOfCurrent].location_name} </Typography>
      <img  src={imageurl} height={200} width={200} ></img>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>{ EnLog[indexOfCurrent].username} </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}> {EnLog[indexOfCurrent].guestname}</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>{ EnLog[indexOfCurrent].usertype}</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>{ EnLog[indexOfCurrent].pintype}</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}> {EnLog[indexOfCurrent].dateadded} </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}> {getName(EnLog[indexOfCurrent].status)}</Typography>
      </Stack>
      </Grid>
      {/* 
      <Grid item xs={6}>
      <Typography>Guest Name :</Typography>
      <Typography> {EnLog[currentId].guestname}</Typography>
      </Grid>
      <Grid item xs={6}>
      <Typography>User Type :</Typography>
      <Typography>{ EnLog[currentId].usertype}</Typography>
      </Grid>
      <Grid item xs={6}>
      <Typography>PIN Type :</Typography>
      <Typography>{ EnLog[currentId].entrytype}</Typography>
      </Grid>
      <Grid item xs={6}>
      <Typography>Date Added :</Typography>
      <Typography> {EnLog[currentId].dateadded}</Typography>
      </Grid>
      <Grid item xs={6}>
      <Typography>Status :</Typography>
      <Typography> Value</Typography>
      </Grid> */}
      </Grid></Box>
       <Box sx={{
        display: { xs: "flex", md: "none" },
        }}>
      <Stack  direction="row"> 
        
          <Stack direction="column"  spacing={2}>
        {/* <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> id </Typography>
        <Typography sx={mainThemeStyle.popUpLableAnswer}  height={40}> Location Code </Typography> */}
        <Typography sx={mainThemeStyle.popUpLableAnswer}  height={40}> Location Name </Typography>
        <Typography  sx={mainThemeStyle.popUpLableAnswer} height={100}> Entry Captured Photo </Typography>
        <Typography  sx={mainThemeStyle.popUpLableAnswer}  height={40}>User Name </Typography>
        <Typography  sx={mainThemeStyle.popUpLableAnswer}  height={40} >Guest Name </Typography>
        <Typography  sx={mainThemeStyle.popUpLableAnswer}  height={40}>User Type </Typography>
        <Typography  sx={mainThemeStyle.popUpLableAnswer}  height={40}>PIN Type </Typography>
        <Typography  sx={mainThemeStyle.popUpLableAnswer}  height={40}>Date Added </Typography>
        <Typography  sx={mainThemeStyle.popUpLableAnswer}  height={40}>Status </Typography>
        </Stack>
       
        <Stack direction="column"  spacing={2}>
        {/* <Typography sx={mainThemeStyle.popUpLable} height={40}>{currentId}</Typography>
        <Typography sx={mainThemeStyle.popUpLable} height={40}> {EnLog[indexOfCurrent].location_code} </Typography> */}
        <Typography sx={mainThemeStyle.popUpLable} height={40}> {EnLog[indexOfCurrent].location_name} </Typography>
        <img  src={imageurl} height={100} width={100} ></img>
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>{ EnLog[indexOfCurrent].username} </Typography>
        <Typography  sx={mainThemeStyle.popUpLable} height={40}> {EnLog[indexOfCurrent].guestname}</Typography>
        <Typography sx={mainThemeStyle.popUpLable} height={40}>{ EnLog[indexOfCurrent].usertype}</Typography>
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>{ EnLog[indexOfCurrent].pintype}</Typography>
        <Typography  sx={mainThemeStyle.popUpLable} height={40}> {EnLog[indexOfCurrent].dateadded} </Typography>
        <Typography  sx={mainThemeStyle.popUpLable} height={40}> {getName(EnLog[indexOfCurrent].status)}</Typography>
        </Stack>
        </Stack>
        {/* 
        <Grid item xs={6}>
        <Typography>Guest Name :</Typography>
        <Typography> {EnLog[currentId].guestname}</Typography>
        </Grid>
        <Grid item xs={6}>
        <Typography>User Type :</Typography>
        <Typography>{ EnLog[currentId].usertype}</Typography>
        </Grid>
        <Grid item xs={6}>
        <Typography>PIN Type :</Typography>
        <Typography>{ EnLog[currentId].entrytype}</Typography>
        </Grid>
        <Grid item xs={6}>
        <Typography>Date Added :</Typography>
        <Typography> {EnLog[currentId].dateadded}</Typography>
        </Grid>
        <Grid item xs={6}>
        <Typography>Status :</Typography>
        <Typography> Value</Typography>
        </Grid> */}
        </Box>
        </>
      )
  }
  
  const handleSearch = () => {
    //let target = e.target;
    setFilterFn({
        fn: items => {
            if (filterText == "")
                return items;
            else{
              if(shownameType){
                console.log(shownameType);
                if(showLocationType)
                return items.filter(x => x.location_name.toLowerCase().includes(filterText.toLowerCase()))

                else if(showResidentType)
                return items.filter(x => x.username.toLowerCase().includes(filterText.toLowerCase()))

                else if(showGuestType)
                return items.filter(x => x.guestname.toLowerCase().includes(filterText.toLowerCase()))
                
                else 
                return items;
                
              }
              else if(showDateType){
                var mystr = filterText;
                mystr = moment(mystr).format('YYYY-MM-DD');
                return items.filter(x => x.dateadded.toLowerCase().includes(mystr))
              }
              else if(showuserType){
                
                
                return items.filter(x => x.usertype.toLowerCase() === (filterText.toLowerCase()))
                
              }
              else if(showentryType){
                  return items.filter(x => x.pintype.toLowerCase() === (filterText.toLowerCase()))
                
              }
            }
                
        }
    })
    setPageToNewOne();
}
  return <>
   
  <Box><Typography sx={mainThemeStyle.lablefilter}>Filter</Typography>
  
  <Box sx={{position: 'absolute',
           width:'680px',
           height: '280px',
           top:'160px',
           left: '38px',
           display: { xs: "none", md: "flex" },
           right: '38px',
           flexGrow: 1,
          
           }}>
             
           <Stack direction="row" spacing={2} >
                  
                   <NestedButtonDropdown
            itemId={FilterMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.filterButton}
            data={FilterMenu}
          /> {shownameType  &&
           
          <Typography sx={mainThemeStyle.lableContains}>Contains</Typography>}
                 {(showuserType || showentryType )  && 
                   <Typography sx={mainThemeStyle.lableContains}>Equals</Typography>}
                   {showuserType  &&  
                   <NestedButtonDropdown
            itemId={TypeMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.filterButton}
            data={TypeMenu}
          />
                  }

                   {showentryType  &&  
                   <NestedButtonDropdown
            itemId={EntryTypeMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.filterButton}
            data={EntryTypeMenu}
            
          />
                  }
                   {showDateType && <TextField
        id="date"
        type="date"
        defaultValue=""
        sx={mainThemeStyle.InputFilter}
        onChange={handleChangeText}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
                           
          sx: {
              
              color: 'rgba(255, 255, 255, 1)',
              height:'33px',
              borderRadius: '5px',
              backgroundColor: '#171E29',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '5px 0 5px 0',
              textIndent: '15px !important',
              border: `1px solid rgba(31, 39, 54, 1)`,
             
              
          },
      }}
      />}

{shownameType &&
                   
                   <TextField
                        id="input-location"
                        ref={myContainer}
                        type= 'text'
                        name="text"
                        hiddenLabel
                        onChange={handleChangeText}
                        sx={mainThemeStyle.InputFilter}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                      InputProps={{
                           
                        sx: {
                            
                            color: 'rgba(255, 255, 255, 1)',
                            height:'33px',
                            borderRadius: '5px',
                            backgroundColor: '#171E29',
                            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                            padding: '5px 0 5px 0',
                            textIndent: '15px !important',
                            border: `1px solid rgba(31, 39, 54, 1)`,
                            
                            
                        },
                    }}
                       
                    />}
                   
                   <Button sx={mainThemeStyle.filterResetButton} onClick={handleSearch}>Go</Button>
                   
                   <Button sx={mainThemeStyle.filterResetButton} onClick={handleReset}>Reset Filters</Button>
                  </Stack>
                  </Box>
          
  <Box sx={{position: 'absolute',
           width:'auto',
           height: '280px',
           top:'160px',
           left: '38px',
           display: { xs: "flex", md: "none" },
           right: '38px',
           flexGrow: 1,
          
           }}>
             <Grid container direction="column">
             <Grid item >
           <Stack direction="row" spacing={2} >
                  
                   <NestedButtonDropdown
            itemId={FilterMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.filterButton}
            data={FilterMenu}
          /> {/* {shownameType  &&
           
          <Typography sx={mainThemeStyle.lableContains}>Contains</Typography>}
                 {(showuserType || showentryType )  && 
                   <Typography sx={mainThemeStyle.lableContains}>Equals</Typography>} */}
                   {showuserType  &&  
                   <NestedButtonDropdown
            itemId={TypeMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.filterButton}
            data={TypeMenu}
          />
                  }

                   {showentryType  &&  
                   <NestedButtonDropdown
            itemId={EntryTypeMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.filterButton}
            data={EntryTypeMenu}
            
          />
                  }
                   {showDateType && <TextField
        id="date"
        type="date"
        defaultValue=""
        sx={mainThemeStyle.InputFilter}
        onChange={handleChangeText}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
                           
          sx: {
              
              color: 'rgba(255, 255, 255, 1)',
              height:'33px',
              borderRadius: '5px',
              backgroundColor: '#171E29',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '5px 0 5px 0',
              textIndent: '15px !important',
              border: `1px solid rgba(31, 39, 54, 1)`,
             
              
          },
      }}
      />}

{shownameType &&
                   
                   <TextField
                        id="input-location"
                        ref={myContainer}
                        type= 'text'
                        name="text"
                        hiddenLabel
                        onChange={handleChangeText}
                        sx={mainThemeStyle.InputFilterSmall}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                      InputProps={{
                           
                        sx: {
                            
                            color: 'rgba(255, 255, 255, 1)',
                            height:'33px',
                            borderRadius: '5px',
                            backgroundColor: '#171E29',
                            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                            padding: '5px 0 5px 0',
                            textIndent: '15px !important',
                            border: `1px solid rgba(31, 39, 54, 1)`,
                            
                            
                        },
                    }}
                       
                    />}</Stack></Grid>
                   <Grid >
                   <Stack direction="row" >
                   <Button sx={mainThemeStyle.filterResetButtonSmall} onClick={handleSearch}>Go</Button>
                   
                   <Button sx={mainThemeStyle.filterResetButtonSmall} onClick={handleReset}>Reset Filters</Button>
                   </Stack>
                 </Grid></Grid></Box>
  
  
                 <Typography sx={mainThemeStyle.lableJump}>Jump</Typography>
                 
                 <ButtonGroup variant="text"  sx={{position: 'absolute',
           maxWidth:'250px',
           height: 'auto',
           top:'250px',
           left: '38px',
           display: { xs: "none", md: "flex" }
           }}>

          

          {
            Alphabet.map(item => (
              checkAtOrNot(item) > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  onClick={() => {jumpToAlphabet(item)}}>{item}</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>{item}</Button>
            ))
          }

        
     

    </ButtonGroup>
   
   
   <Box sx={mainThemeStyle.mainTablebox}>
   <BelowTblPagination ></BelowTblPagination>
    <TblContainer>
                    <TblHead />
                    <TableBody>
                        {
                            recordsAfterPagingAndSorting().map(item =>
                                ( <TableRow
                                  key={item.id}
                                  sx={{ borderBottom: "1.5px solid black",'&:last-child td, &:last-child th': { border: 0 },
                                   }}
                                >
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.location_name}</TableCell>
                                 {/*  <TableCell align="left" sx={mainThemeStyle.cellRow}>{getRefineData(item.username)}</TableCell> */}
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.guestname}</TableCell>
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{getRefineData(item.usertype)}</TableCell>
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{getRefineData(item.pintype)}</TableCell>
                                  <TableCell align="left" sx={mainThemeStyle.cellGreen}>{item.dateadded}</TableCell>
                                  <TableCell ><Button  sx={mainThemeStyle.viewLogButton} onClick={() => {ChangeId(item.id)}}>View Log</Button></TableCell>
                                </TableRow>)
                            )
                        }
                    </TableBody>
                   
                </TblContainer>
                <Box sx={{marginTop:'10px'}}>
                <PuffLoader color="#878787" loading={loading} cssOverride={{display: "block",
    margin: "0 auto",
    marginTop:"50px",
    marginBottom:"50px",}} />



</Box></Box>

                 </Box>
                
          <Popup
                title="Entry Log"
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
            >
             
      <ShowLog/>
               
            </Popup></> 
          
          
          
          
          ;
};

export default EntryLogUser;