import { useState,useRef,useEffect } from "react";
 



  export default function GlobalAlert() {

    const getAlertTitle = val => {
        let idData ;
        for(let i = 0; i < globalAlertData.length ; i++){
          if(globalAlertData[i].code == val ){
              idData = i;
              break;
          }
      }
      return globalAlertData[idData].message;
  
    };
    const getGlobalAlertData = () => {
      //localStorage.setItem('token', '{}');
      //localStorage.clear();
      const alertString = localStorage.getItem('alertdata');
     
      const userAlert = JSON.parse(alertString);
  
     // console.log('user token is '+userToken.name);
      return userAlert;
  
    };
    const [globalAlertData,setGlobalAlertData] = useState(getGlobalAlertData());
  
    
  
    
  
    const setGlobalAlertDataD = userData => {
      localStorage.setItem('alertdata', JSON.stringify(userData));
        
      setGlobalAlertData(userData);
  
    };
  
    
  
    return {
  
    setGlobalAlertData: setGlobalAlertDataD,
  
    globalAlertData,
  
    getAlertTitle : getAlertTitle,
  
    }
  
  }

