import { useState,useRef,useEffect } from "react";
 



  export default function GlobalRoleAccessInner() {

    const getRoleAccessInnerVal = val => {
        let idData  = [];
        for(let i = 0; i < globalRoleAccessInnerData.length ; i++){
          if(globalRoleAccessInnerData[i].upper_id == val ){
             // idData = i;
             // break;
            idData.push(globalRoleAccessInnerData[i]);
          }
      }
      return idData;
  
    };
    const getGlobalRoleAccessInnerData = () => {
      //localStorage.setItem('token', '{}');
      //localStorage.clear();
      const alertString = localStorage.getItem('role_access_inner');
     
      const userAlert = JSON.parse(alertString);
  
     // console.log('user token is '+userToken.name);
      return userAlert;
  
    };
    const [globalRoleAccessInnerData,setGlobalRoleAccessInnerData] = useState(getGlobalRoleAccessInnerData());
  
    
  
    
  
    const setGlobalRoleAccessInnerDataD = userData => {
      localStorage.setItem('role_access_inner', JSON.stringify(userData));
        
      setGlobalRoleAccessInnerData(userData);
  
    };
  
    
  
    return {
  
    setGlobalRoleAccessInnerData: setGlobalRoleAccessInnerDataD,
  
    globalRoleAccessInnerData,
  
    getRoleAccessInnerVal : getRoleAccessInnerVal,
  
    }
  
  }

