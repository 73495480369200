import React from "react";
import {  ThemeProvider } from '@mui/material/styles';
import Theme from './components/ItemTheme'
import { Box } from "@mui/system";
import { Button, Grid, List, Typography } from "@mui/material";
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { ReactComponent as LogoManageDis } from './asset/MDsvg.svg';
import { ReactComponent as LogoManageDealer } from './asset/ManageDealer.svg';
import { ReactComponent as LogoManageUser } from './asset/ManageUser.svg';
import { ReactComponent as LogoSystemLog } from './asset/SystemLog.svg';
import SvgIcon from "@mui/material/SvgIcon";
import {mainThemeStyle} from './components/MainTheme';
import { useParams,useNavigate  } from "react-router-dom";
const ManageIcon=()=>(
  <SvgIcon>
    <LogoManageDis/>
  </SvgIcon>
);

const ManageDealerIcon=()=>(
  <SvgIcon>
    <LogoManageDealer/>
  </SvgIcon>
);

const ManageUserIcon=()=>(
  <SvgIcon>
    <LogoManageUser/>
  </SvgIcon>
);


const SystemLogIcon=()=>(
  <SvgIcon>
    <LogoSystemLog/>
  </SvgIcon>
);


const HomeDealer = props => {
  const theme = Theme;
  console.log("IN home Page");
  const navigate = useNavigate();
  const handleManageProperty = () =>{
    navigate('../property/manage');
  }

  const handleAddProperty = () => {
    navigate('../propertyowner/addproperty');
  }

  const handleManageDistributor = () =>{
    navigate('../distributor/managedistributor');
  }

  const handleAddDistributor = () => {
    navigate('../distributor/adddistributor');
  }

  const handleManageDealer = () =>{
    navigate('../dealer/managedealer');
  }

  const handleAddDealer = () => {
    navigate('../property/add');
  }

  const handleManageUser = () => {
    navigate('../profile');
  }

  const handleManageOrder = () => {
    navigate('../setup/manageorder');
  }

  const handleManageOrderForm = () => {
    navigate('../setup/neworder');
  }

  const handleSystemLog = () => {
    navigate('../extra/systemlogs');
  }
  return <ThemeProvider theme={theme}>
    <Box sx={mainThemeStyle.mainbox} backgroundColor="menubarprimary.main">
    <Box sx={mainThemeStyle.box}>
    <Typography sx={mainThemeStyle.lable}>Home</Typography>
    </Box>
    
    {/* <Typography sx={mainThemeStyle.lableshortcut}>Shortcuts</Typography> */}
  <Box sx={{position: 'absolute',
width:'auto',
height: '280px',
top:'220px',
left: '38px',
display: { xs: "none", md: "flex"},
right: '38px',
flexGrow: 1
}}>
  
<Grid container rowSpacing={0} columnSpacing={{ xs: 2, sm: 3, md: 4 }} sx={{ flexGrow: 1}}>
        <Grid item xs={3}>
          <Button fullWidth={true} size="large" startIcon={<MapsHomeWorkOutlinedIcon />} sx={mainThemeStyle.boxHome} onClick={handleManageProperty}>
           Property Manager</Button>
        </Grid>
        
       
        <Grid item xs={3}>
        <Button fullWidth={true} size="large" startIcon={<AddOutlinedIcon />} sx={mainThemeStyle.boxHome} onClick={handleAddDealer}>Add Property Account</Button>
        </Grid>
        
        <Grid item xs={3}>
        <Button  item xs={3} fullWidth={true} size="large" startIcon={<ManageUserIcon />} sx={mainThemeStyle.boxHome} onClick={handleManageUser}>Manage Profile</Button>
        </Grid>

        <Grid item xs={3}>
        <Button  item xs={3} fullWidth={true} size="large" startIcon={<MenuBookIcon />} sx={mainThemeStyle.boxHome} onClick={handleManageOrder}>Order History</Button>
        </Grid>

        <Grid item xs={3}>
        <Button  item xs={3} fullWidth={true} size="large" startIcon={<MenuBookIcon />} sx={mainThemeStyle.boxHome} onClick={handleManageOrderForm}>Order Form</Button>
        </Grid>

      </Grid>

     
</Box>

<Box sx={{position: 'absolute',
width:'auto',
height: '280px',
top:'80px',
left: '38px',
display: 'flex',
right: '38px',
flexGrow: 1,
display: { xs: "flex", md: "none"}
}}>
  
<Grid container rowSpacing={0} columnSpacing={{ xs: 4, sm: 5, md: 6 }} sx={{ flexGrow: 1,   }}>
        <Grid item xs={6}>
          <Button fullWidth={true} size="large" startIcon={<MapsHomeWorkOutlinedIcon />} sx={mainThemeStyle.boxHomeMobile} onClick={handleManageProperty}>
           Property Manager</Button>
        </Grid>
        
       
        <Grid item xs={6}>
        <Button fullWidth={true} size="large" startIcon={<AddOutlinedIcon />} sx={mainThemeStyle.boxHomeMobile} onClick={handleAddDealer}>Add Property Account</Button>
        </Grid>
        
        <Grid item xs={6}>
        <Button  item xs={6} fullWidth={true} size="large" startIcon={<ManageUserIcon />} sx={mainThemeStyle.boxHomeMobile} onClick={handleManageUser}>Manage Profile</Button>
        </Grid>

        <Grid item xs={3}>
        <Button  item xs={3} fullWidth={true} size="large" startIcon={<MenuBookIcon />} sx={mainThemeStyle.boxHome} onClick={handleManageOrder}>Order History</Button>
        </Grid>

        <Grid item xs={3}>
        <Button  item xs={3} fullWidth={true} size="large" startIcon={<MenuBookIcon />} sx={mainThemeStyle.boxHome} onClick={handleManageOrderForm}>Order Form</Button>
        </Grid>
        
      </Grid>

     
</Box>
    </Box></ThemeProvider>;
};

export default HomeDealer;