import React from "react";
import { Box } from "@mui/system";
import { Button, Grid, InputLabel, List, Dialog,Typography,Snackbar,Alert,ButtonGroup,DialogContent,DialogActions ,DialogTitle,IconButton, Icon } from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { faker } from '@faker-js/faker';
import { format } from 'date-fns';
import { useState,useRef,useEffect } from "react";
import useTable from "./components/useTable";
import SvgIcon from "@mui/material/SvgIcon";
import NoImage from './asset/noimage.png';
import Popup from "./components/Popup";
import useToken from "./useToken";
import _ from "lodash";
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import GlobalAlert from "./GlobalAlert";
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from "react-router-dom";
import axios from "./axios";
import PuffLoader from "react-spinners/PuffLoader"; 
import GlobalRoleAccess from "./GlobalRoleAccess";
import GlobalRoleAccessInner from "./GlobalRoleAccessInner";


let TYPE = ['Android','iOS','Phone','Unknown'],STATUSTYPE = ['Active','Pending','Blocked'];
let Alphabet =['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
/*for (let i =0; i < 200; i++){
  EnLog[i] = {
    id: i,
    username:faker.name.fullName(),
    devicetype:TYPE[Math.floor(Math.random()*TYPE.length)],
    contactphone:faker.phone.number('(###) ###-####'),
    virtualphone:faker.phone.number('(###) ###-####'),
    unit:faker.random.numeric(3) ,
    email:faker.internet.email() ,
    dateadded:format(faker.date.recent(), 'dd/MM/yyyy'),
    expdate:format(faker.date.recent(), 'dd/MM/yyyy'),
    status:STATUSTYPE[Math.floor(Math.random()*STATUSTYPE.length)],
  }
}*/


function ManageUsers ( props){
  const [EnLog,setEnLog] = useState([]);
  const {token} = useToken();
  const {RefreshToken} = props;
  const { getAlertTitle} = GlobalAlert();
  let [loading, setLoading] = useState(true);
  const [currentCustType, setCurrentCustType] = useState();
  const CreateheadCells = (valArray) => {
    let headCell = [];
    headCell.push( { id: 'fname', label: 'Name' });
    headCell.push( { id: 'type', label: 'Device Type' });
    headCell.push({ id: 'phone_no', label: 'Contact Phone #' });
    headCell.push({ id: 'virtual_phone', label: 'Virtual Phone #' });
    headCell.push({ id: 'unitno', label: 'Unit' });
    headCell.push({ id: 'user_email', label: 'Email' });
    
    
    
    if(valArray.id != null){
      
    for(let ist = 0 ; ist < valArray.name.length ; ist ++){
      console.log('val array is '+valArray.name[ist]);
      headCell.push({ id: valArray.id[ist], label: valArray.name[ist]});
    }
    
  }
  headCell.push({ id: 'dateadded', label: 'Date Added' });
    headCell.push({ id: 'expiry_date', label: 'Exp. Date'});
  headCell.push({ id: 'admin_State', label: 'System Status'});

    headCell.push( { id: 'action', label: 'Action', disableSorting: true });

    
    
    return headCell;
  }

  const setToArray = (valArray) => {
    let setArr = [];
    if(valArray.id != null){
      
      for(let ist = 0 ; ist < valArray.id.length ; ist ++){
        //console.log('val array is '+valArray.name[ist]);
        setArr.push(valArray.id[ist]);
       
      }
     
  }
  return setArr;
}
  const [topArray ,setTopArray] = useState(setToArray(EnLog));
  const [headCells,setHeadCells] = useState(CreateheadCells(EnLog));
  const {getRoleAccessVal} = GlobalRoleAccess();
  const {getRoleAccessInnerVal} = GlobalRoleAccessInner();

  const getDisableValueEdit = () => {
    if(token.role == 'superadmin' || token.name === 'SUPER ADMIN'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("42").administrator_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("42").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'distributor' || token.name === 'SUPER DISTRIBUTOR'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("42").distributor_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("42").distributor_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'dealer' || token.name === 'SUPER DEALER'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("42").dealer_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("42").dealer_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'admin'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("42").admin_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("42").admin_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
    
    
  }
  const getDisableValueDelete = () => {
    if(token.role == 'superadmin' || token.name === 'SUPER ADMIN'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("43").administrator_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("43").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'distributor' || token.name === 'SUPER DISTRIBUTOR'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("43").distributor_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("43").distributor_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'dealer' || token.name === 'SUPER DEALER'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("43").dealer_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("43").dealer_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'admin'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("43").admin_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("43").admin_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
    
    
  }
  
  const getMyPostData = async () => {
    try {
      const res = await axios.get("/admin/getusers/"+token.id,{
        headers: { 'Content-Type':  'application/json' ,
        'token' : token.token,
        'tokenid' : token.email},
      });
      console.log(res.data);
      
      setEnLog(res.data.custom_field);
      setHeadCells(CreateheadCells(res.data.custom_field));
      setFilterMenu(createFilterMenu(res.data.custom_field));
      setTopArray(setToArray(res.data.custom_field));
      setRecords(res.data.data);
      
      setLoading(false);
    } catch (error) {
      console.log(error.response);
      setLoading(false);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
      }
    }
  };
  const handleCloseSnack= (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };

  // NOTE:  calling the function
  useEffect(() => {
    getMyPostData();
  }, []);
  const navigate = useNavigate();
  const [openDelete, setOpenDelete] = React.useState(false);
  const [titleforalert, setTitleforalert] = React.useState("");
  const myContainer = useRef(null);
  const [shownameType, setShownameType] = useState(true);
  const [showUserName, setShowUserName] = useState(true);
  const [showDeviceType, setShowDeviceType] = useState(false);
  const [showContacPhoneType, setShowContacPhoneType] = useState(false);
  const [showVirtualPhoneType, setShowVirtualPhoneType] = useState(false);
  const [showUnitType, setShowUnitType] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showDateType, setShowDateType] = useState(false);
  const [showExpDateType, setShowExpDateType] = useState(false);
  const [showCustType, setShowCustType] = useState(false);
  const [showStatsType, setShowStatsType] = useState(false);
  const [filterText, setfilterText] = useState('');
  const [resetClick, setResetClicked] = useState(false);
  const [currentId, setCurrentId] = useState(0);
  const [currentObj, setCurrentObj] = useState();
  const [openPopup, setOpenPopup] = useState(false);
  const [openSnackData, setOpenSnackData] = React.useState("This is Success Data");
  const [openSnack, setOpenSnack] = React.useState(false);
  const [severityData, setSeverityData] = React.useState("success");
  
  const createFilterMenu = (valArray) => {
    let filteritems = [];
    filteritems.push({
      label: "User Name",
      callback: () =>  {
          setShownameType(true);
          setShowUserName(true);
          setShowDeviceType(false);
          setShowContacPhoneType(false);
          setShowVirtualPhoneType(false);
          setShowUnitType(false);
          setShowEmail(false);
          setShowDateType(false);
          setShowExpDateType(false);
          setShowStatsType(false);
          setShowCustType(false);
       // myContainer.this.setState({ //value:''});
       if(document.getElementById('input-location') != null)
  document.getElementById('input-location').value ='';
  if(document.getElementById('date') != null)
        document.getElementById('date').value ='';
        setfilterText('');
        //console.log(format(new Date(), 'dd/MM/yyyy'));
      }
       
    });
    filteritems.push({
      label: "Device Type",
      callback: () =>  {
          setShownameType(false);
          setShowUserName(false);
          setShowDeviceType(true);
          setShowContacPhoneType(false);
          setShowVirtualPhoneType(false);
          setShowUnitType(false);
          setShowEmail(false);
          setShowDateType(false);
          setShowExpDateType(false);
          setShowStatsType(false);
          setShowCustType(false);
        if(document.getElementById('input-location') != null)
        document.getElementById('input-location').value ='';
        if(document.getElementById('date') != null)
              document.getElementById('date').value ='';
        setfilterText('');
      }
    });
    filteritems.push( {
      label: "Contact Phone #",
      callback: () => {
          setShownameType(true);
          setShowUserName(false);
          setShowDeviceType(false);
          setShowContacPhoneType(true);
          setShowVirtualPhoneType(false);
          setShowUnitType(false);
          setShowEmail(false);
          setShowDateType(false);
          setShowExpDateType(false);
          setShowStatsType(false);
          setShowCustType(false);
        if(document.getElementById('input-location') != null)
  document.getElementById('input-location').value ='';
  if(document.getElementById('date') != null)
        document.getElementById('date').value ='';
        setfilterText('');
      }
    });
    filteritems.push( {
      label: "Virual Phone #",
      callback: () => {
          setShownameType(true);
          setShowUserName(false);
          setShowDeviceType(false);
          setShowContacPhoneType(false);
          setShowVirtualPhoneType(true);
          setShowUnitType(false);
          setShowEmail(false);
          setShowDateType(false);
          setShowExpDateType(false);
          setShowStatsType(false);
          setShowCustType(false);
        if(document.getElementById('input-location') != null)
  document.getElementById('input-location').value ='';
  if(document.getElementById('date') != null)
        document.getElementById('date').value ='';
        setfilterText('');
      }
    });
    filteritems.push( {
      label: "Unit",
      callback: () => {
          setShownameType(true);
          setShowUserName(false);
          setShowDeviceType(false);
          setShowContacPhoneType(false);
          setShowVirtualPhoneType(false);
          setShowUnitType(true);
          setShowEmail(false);
          setShowDateType(false);
          setShowExpDateType(false);
          setShowStatsType(false);
          setShowCustType(false);
        if(document.getElementById('input-location') != null)
  document.getElementById('input-location').value ='';
  if(document.getElementById('date') != null)
        document.getElementById('date').value ='';
        setfilterText('');
      }
    });
    filteritems.push( {
      label: "Email",
      callback: () => {
          setShownameType(true);
          setShowUserName(false);
          setShowDeviceType(false);
          setShowContacPhoneType(false);
          setShowVirtualPhoneType(false);
          setShowUnitType(false);
          setShowEmail(true);
          setShowDateType(false);
          setShowExpDateType(false);
          setShowStatsType(false);
          setShowCustType(false);
        if(document.getElementById('input-location') != null)
  document.getElementById('input-location').value ='';
  if(document.getElementById('date') != null)
        document.getElementById('date').value ='';
        setfilterText('');
      }
    });


    if(valArray.id != null){
      
      for(let ist = 0 ; ist < valArray.name.length ; ist ++){
        filteritems.push( {
          label: valArray.name[ist],
          callback: () =>  {
              if(valArray.type[ist] == "Date"){
                setShownameType(false);
                setShowDateType(true);
              }
              else{
                setShownameType(true);
                setShowDateType(false);
              }
              setShowUserName(false);
              setShowDeviceType(false);
              setShowContacPhoneType(false);
              setShowVirtualPhoneType(false);
              setShowUnitType(false);
              setShowEmail(false);
             
              setShowExpDateType(false);
              setShowStatsType(false);
              setShowCustType(true);
              setCurrentCustType(valArray.id[ist]);
            if(document.getElementById('input-location') != null)
            document.getElementById('input-location').value ='';
            if(document.getElementById('date') != null)
                  document.getElementById('date').value ='';
            setfilterText('');
          }
        });
        
  
        
       
      }
      
    }



    filteritems.push( {
      label: "Date Added",
      callback: () => {
          setShownameType(false);
          setShowUserName(false);
          setShowDeviceType(false);
          setShowContacPhoneType(false);
          setShowVirtualPhoneType(false);
          setShowUnitType(false);
          setShowEmail(false);
          setShowDateType(true);
          setShowExpDateType(false);
          setShowStatsType(false);
          setShowCustType(false);
        if(document.getElementById('input-location') != null)
  document.getElementById('input-location').value ='';
  if(document.getElementById('date') != null)
        document.getElementById('date').value ='';
        setfilterText('');
      }
    });
    filteritems.push( {
      label: "Exp. Date",
      callback: () => {
          setShownameType(false);
          setShowUserName(false);
          setShowDeviceType(false);
          setShowContacPhoneType(false);
          setShowVirtualPhoneType(false);
          setShowUnitType(false);
          setShowEmail(false);
          setShowDateType(false);
          setShowExpDateType(true);
          setShowStatsType(false);
          setShowCustType(false);
        if(document.getElementById('input-location') != null)
  document.getElementById('input-location').value ='';
  if(document.getElementById('date') != null)
        document.getElementById('date').value ='';
        setfilterText('');
      }
    });
    filteritems.push({
      label: "Status",
      callback: () => {
          setShownameType(false);
          setShowUserName(false);
          setShowDeviceType(false);
          setShowContacPhoneType(false);
          setShowVirtualPhoneType(false);
          setShowUnitType(false);
          setShowEmail(false);
          setShowDateType(false);
          setShowExpDateType(false);
          setShowStatsType(true);
          setShowCustType(false);
        if(document.getElementById('input-location') != null)
  document.getElementById('input-location').value ='';
  if(document.getElementById('date') != null)
        document.getElementById('date').value ='';
        setfilterText('');
      }
    });

    let newFilter = {
      id: "1",
      label: "User Name",
      items:filteritems
    };

    return newFilter;
  }
 
  const [FilterMenu,setFilterMenu] = useState( createFilterMenu(EnLog));
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  const handleCloseActiveDelete =() =>{
    setOpenDelete(false);
    deleteMyPostData();
  }
  const deleteMyPostData = async () => {
   // console.log(currentObj.type + " "+ currentObj.devicetoken + " " +currentObj.id);
    let utype = currentObj.type;
    let device;
    if(utype == 'iOS' || utype == 'Android'){
      device = currentObj.devicetoken;
    } 
    else{
      device = currentObj.id;
    }
    try {
      const res = await axios.delete("/admin/deleteUser/"+token.id+"/"+utype+"/"+device,
      {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8' ,
      'token' : token.token,
      'tokenid' : token.email,
      'issub':token.issub,
      'subId':token.subid,
      'username':deleteUserName}});
      console.log( res.data);
      setOpenSnackData(getAlertTitle('EUUDS'));
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      getMyPostData();
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      setOpenSnackData(getAlertTitle('EUUDF'));
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
      }
    }
  };
  const TypeMenu = {
    id: "1",
    label: "Select Type",
    items: [
      {
        label: "Android",
        callback: () =>  {setfilterText("Android")}
       
      },
      {
        label: "iOS",
        callback: () =>  {setfilterText("iOS")}
      },
      {
        label: "Phone",
        callback: () => {setfilterText("Phone")}
      },
      {
        label: "PDK",
        callback: () => {setfilterText("PDK")}
      },
    ]
  };
  const StatusTypeMenu = {
    id: "1",
    label: "Select Type",
    items: [
      {
        label: "Active",
        callback: () =>  {setfilterText("1")}
       
      },
      {
        label: "Pending",
        callback: () =>  {setfilterText("0")}
      },
      {
        label: "Blocked",
        callback: () => {setfilterText("-1")}
      },
      
      
    ]
  };
  const [records, setRecords] = useState(EnLog)
  const [filterFn, setFilterFn] = useState({ fn: items => { return items; } })
  const handleChangeText = e => {
    console.log(e.target.value);
    let value = e.target.value;
    setfilterText(value);
    console.log(filterText);
}
  const {
      TblContainer,
      TblHead,
      TblPagination,
      BelowTblPagination,
      recordsAfterPagingAndSorting,
      setPageToNewOne
  } = useTable(records, headCells, filterFn,"Download Users");
 
  const handleReset =() =>{
    setfilterText('');
    if(document.getElementById('input-location') != null)
    document.getElementById('input-location').value ='';
    if(document.getElementById('date') != null)
          document.getElementById('date').value ='';
    
          setFilterFn({
            fn: items => {
              return items;
                    
            }
        })
        setPageToNewOne();
  }
  const checkAtOrNot = (value) =>{
     let ad = records.filter(x => x.fname.toLowerCase().startsWith(value.toLowerCase()));
     console.log(ad.length);
     return ad.length;
  }

  const jumpToAlphabet = (val) =>{
    console.log('value is '+val);
    setFilterFn({
      fn: items => {
        return items.filter(x => x.fname.toLowerCase().startsWith(val.toLowerCase()))
              
      }
  })
  setPageToNewOne();
  }

  const updateStateMyPostData = async (val,val2,val3) => {
    // console.log(currentObj.type + " "+ currentObj.devicetoken + " " +currentObj.id);
    setLoading(true);
     try {
       const res = await axios.patch("/admin/updatestate/"+token.id+"/"+val,{issub:token.issub,subId:token.subid,userfname:val2,userlname:val3},{headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
       'token' : token.token,
       'tokenid' : token.email }});
       console.log( res.data);
       setOpenSnackData("User System Status successfully updated");
       setSeverityData("success");
       setOpenSnack(true);
       setLoading(false);
       getMyPostData();
     } catch (error) {
       console.log(error.message);
       setLoading(false);
       setOpenSnackData("User System Status update failed, try again later");
       setSeverityData("error");
       setOpenSnack(true);
       if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
       }
     }
   };
  const changeState =(val,val2,val3) => {
    console.log('change val');
    if(val != ''){
      updateStateMyPostData(val,val2,val3);
     console.log('change val');
    }

  }
  const [deleteUserName,setDeleteUserName] = useState('');
  const ChangeId = (val,val2) =>{
    console.log(currentId);
    if(val2 == 0){
      let disableval = getDisableValueEdit();
      if(disableval == true){
        setOpenSnackData("You don't have access to perform this action");
        setSeverityData("error");
        setOpenSnack(true);
      }
      else{
      let idofItem =  records.filter(x => x.phone_no == val);
      let itemtopass = idofItem[0];
      //navigate('../location/editlocation', {state:{editlocation : itemtopass}, replace: true });
      navigate('../user/edituser', {state:{edituser : itemtopass} });
      }
    }else{
      let disableval = getDisableValueDelete();
      if(disableval == true){
        setOpenSnackData("You don't have access to perform this action");
        setSeverityData("error");
        setOpenSnack(true);
      }
      else{
    setCurrentId(val);
    //setOpenPopup(true);
      let idofItem =  records.filter(x => x.phone_no == val);
      setCurrentObj(idofItem[0]);
      setDeleteUserName(idofItem[0].fname + " "+idofItem[0].lname);
      setTitleforalert("Are you sure you want to delete user "+ idofItem[0].fname + " "+idofItem[0].lname + " ?");
      setOpenDelete(true);
      }
  }
  }

  const ShowLog = () =>{
    return(<Grid container spacing={2}> 
      <Grid item >
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLableAnswer}> id </Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} > Location Code </Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} > Location Name </Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer} height={200}> Entry Captured Photo </Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer} >User Name </Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer}  >Guest Name </Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer} >User Type </Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer} >PIN Type </Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer} >Date Added </Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer} >Status </Typography>
      </Stack>
      </Grid>
      <Grid item >
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable}>12352</Typography>
      <Typography sx={mainThemeStyle.popUpLable}> 85622300230 </Typography>
      <Typography sx={mainThemeStyle.popUpLable}> {EnLog[currentId].locationname} </Typography>
      <img  src={NoImage} height={200} width={200}></img>
      <Typography  sx={mainThemeStyle.popUpLable}>{ EnLog[currentId].residentname} </Typography>
      <Typography  sx={mainThemeStyle.popUpLable}> {EnLog[currentId].guestname}</Typography>
      <Typography sx={mainThemeStyle.popUpLable}>{ EnLog[currentId].usertype}</Typography>
      <Typography  sx={mainThemeStyle.popUpLable}>{ EnLog[currentId].entrytype}</Typography>
      <Typography  sx={mainThemeStyle.popUpLable}> {EnLog[currentId].dateadded}  {EnLog[currentId].timestamp}</Typography>
      <Typography  sx={mainThemeStyle.popUpLable}> Success</Typography>
      </Stack>
      </Grid>
      
      </Grid>)
  }
  
  const handleSearch = () => {
    //let target = e.target;
    setFilterFn({
        fn: items => {
            if (filterText == "")
                return items;
            else{
              if(shownameType){
                console.log(shownameType);
                if(showUserName)
                return items.filter(x => (x.fname.toString().toLowerCase()+" "+x.lname.toString().toLowerCase()).includes(filterText.toLowerCase()))

                else if(showContacPhoneType)
                return items.filter(x => x.phone_no.toString().toLowerCase().includes(filterText.toLowerCase()))

                else if(showVirtualPhoneType)
                return items.filter(x => x.virtual_phone.toString().toLowerCase().includes(filterText.toLowerCase()))
                
                else if(showUnitType)
                return items.filter(x => x.unitno.toString().toLowerCase().includes(filterText.toLowerCase()))
                
                else if(showEmail)
                return items.filter(x => x.user_email.toString().toLowerCase().includes(filterText.toLowerCase()))
                
                else if(showCustType){
                  return items.filter(x => x[currentCustType].toString().toLowerCase().includes(filterText.toLowerCase()))
                }
                
                else 
                return items;
                
              }
              else if(showDateType){
                 if(showCustType){
                  var mystr = filterText;
                  mystr = moment(mystr).format('YYYY-MM-DD');
                  return items.filter(x => x[currentCustType].toLowerCase().includes(mystr))
                }else{
                var mystr = filterText;
                mystr = moment(mystr).format('YYYY-MM-DD');
                return items.filter(x => x.dateadded.toLowerCase().includes(mystr))
              }
              }
              else if(showExpDateType){
                var mystr = filterText;
                mystr = moment(mystr).format('YYYY-MM-DD');
                return items.filter(x => x.expiry_date.toLowerCase().includes(mystr))
              }
              else if(showDeviceType){
                return items.filter(x => x.type.toLowerCase() === (filterText.toLowerCase()))
              }
              else if(showStatsType){
                  return items.filter(x => x.admin_State.toString().toLowerCase() === (filterText.toLowerCase()))
              }
              
            }
                
        }
    })
    setPageToNewOne();
}
  return <><Box><Typography sx={mainThemeStyle.lablefilter}>Filter</Typography>
  
  <Box sx={{position: 'absolute',
           width:'680px',
           height: '280px',
           top:'160px',
           left: '38px',
           display: { xs: "none", md: "flex" },
           right: '38px',
           flexGrow: 1,
          
           }}>
             
           <Stack direction="row" spacing={2} >
                  
                   <NestedButtonDropdown
            itemId={FilterMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.filterButton}
            data={FilterMenu}
          /> {shownameType  &&
           
          <Typography sx={mainThemeStyle.lableContains}>Contains</Typography>}
                 {(showDeviceType || showStatsType )  && 
                   <Typography sx={mainThemeStyle.lableContains}>Equals</Typography>}
                   {showDeviceType  &&  
                   <NestedButtonDropdown
            itemId={TypeMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.filterButton}
            data={TypeMenu}
          />
                  }

                   {showStatsType  &&  
                   <NestedButtonDropdown
            itemId={StatusTypeMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.filterButton}
            data={StatusTypeMenu}
            
          />
                  }
                   {(showDateType || showExpDateType) && <TextField
        id="date"
        type="date"
        hiddenLabel
        sx={mainThemeStyle.InputFilter}
        onChange={handleChangeText}
        InputLabelProps={{
          shrink: true,
        }}
        variant="filled"
        InputProps={{
                           
          sx: {
              
              color: 'rgba(255, 255, 255, 1)',
              height:'33px',
              borderRadius: '5px',
              backgroundColor: '#171E29',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '5px 0 5px 0',
              textIndent: '15px !important',
              border: `1px solid rgba(31, 39, 54, 1)`,
             
              
          },
      }}
      />}

{shownameType &&
                   
                   <TextField
                        id="input-location"
                        ref={myContainer}
                        type= 'text'
                        name="text"
                        onChange={handleChangeText}
                        hiddenLabel
                        sx={mainThemeStyle.InputFilter}
                        InputLabelProps={{ shrink: true }
                      }
                          variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color: 'rgba(255, 255, 255, 1)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#171E29',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '5px 0 5px 0',
                                textIndent: '15px !important',
                                border: `1px solid rgba(31, 39, 54, 1)`,
                               
                                
                            },
                        }}
                       
                    />}
                   
                   <Button sx={mainThemeStyle.filterResetButton} onClick={handleSearch}>Go</Button>
                   
                   <Button sx={mainThemeStyle.filterResetButton} onClick={handleReset}>Reset Filters</Button>
                  </Stack>
                  </Box>
          
  <Box sx={{position: 'absolute',
           width:'auto',
           height: '280px',
           top:'160px',
           left: '38px',
           display: { xs: "flex", md: "none" },
           right: '38px',
           flexGrow: 1,
          
           }}>
             <Grid container  direction="column">
             <Grid item >
           <Stack direction="row" spacing={2} >
                  
                   <NestedButtonDropdown
            itemId={FilterMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.filterButton}
            data={FilterMenu}
          /> 
                   {showDeviceType  &&  
                   <NestedButtonDropdown
            itemId={TypeMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.filterButton}
            data={TypeMenu}
          />
                  }

                   {showStatsType  &&  
                   <NestedButtonDropdown
            itemId={StatusTypeMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.filterButton}
            data={StatusTypeMenu}
            
          />
                  }
                   {(showDateType || showExpDateType) && <TextField
        id="date"
        type="date"
        defaultValue=""
        sx={mainThemeStyle.InputFilter}
        onChange={handleChangeText}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
                           
          sx: {
              
              color: 'rgba(255, 255, 255, 1)',
              height:'33px',
              borderRadius: '5px',
              backgroundColor: '#171E29',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '5px 0 5px 0',
              textIndent: '15px !important',
              border: `1px solid rgba(31, 39, 54, 1)`,
             
              
          },
      }}
      />}

{shownameType &&
                   
                   <TextField
                        id="input-location"
                        ref={myContainer}
                        type= 'text'
                        name="text"
                        onChange={handleChangeText}
                        hiddenLabel
                        sx={mainThemeStyle.InputFilterSmall}
                        InputLabelProps={{ shrink: true }
                      }
                          variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color: 'rgba(255, 255, 255, 1)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#171E29',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '5px 0 5px 0',
                                textIndent: '15px !important',
                                border: `1px solid rgba(31, 39, 54, 1)`,
                               
                                
                            },
                        }}
                       
                    />}</Stack></Grid>
                    <Grid item>
                   <Stack direction="row" >
                   <Button sx={mainThemeStyle.filterResetButtonSmall} onClick={handleSearch}>Go</Button>
                   
                   <Button sx={mainThemeStyle.filterResetButtonSmall} onClick={handleReset}>Reset Filters</Button>
                   </Stack>
                 </Grid></Grid></Box>
  
  
                 <Typography sx={mainThemeStyle.lableJump}>Jump</Typography>
                 
                 <ButtonGroup variant="text"  sx={{position: 'absolute',
           maxWidth:'250px',
           height: 'auto',
           top:'250px',
           left: '38px',
           display: { xs: "none", md: "flex" }
           }}>

          

          {
            Alphabet.map(item => (
              checkAtOrNot(item) > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  onClick={() => {jumpToAlphabet(item)}}>{item}</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>{item}</Button>
            ))
          }


    </ButtonGroup>
   
   <Box sx={mainThemeStyle.mainTablebox} backgroundColor="menubarprimary.main">
   <BelowTblPagination ></BelowTblPagination>
    <TblContainer>
                    <TblHead />
                    <TableBody>
                        {
                            recordsAfterPagingAndSorting().map(item =>
                                ( <TableRow
                                  key={item.id}
                                  sx={{ borderBottom: "1.5px solid black",'&:last-child td, &:last-child th': { border: 0 },
                                   }}
                                >
                                   
                                  { (item.type == 'iOS' || item.type == 'Android' || item.type == 'Phone') &&<TableCell align="left" sx={mainThemeStyle.cellGreenClickable} onClick={() => {ChangeId(item.phone_no,0)}}>{item.fname+" "+item.lname}</TableCell>}
                                  { item.type == 'PDK'  && <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.fname+" "+item.lname}</TableCell>}
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.type}</TableCell>
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.phone_no}</TableCell>
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.virtual_phone}</TableCell>
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.unitno}</TableCell>
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.user_email}</TableCell>
                                  {topArray != null && topArray.map(innerItem => (
                                     <TableCell align="left" sx={mainThemeStyle.cellRow}>{item[innerItem]}</TableCell>
                                  ))}  
                                  
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.dateadded}</TableCell>
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.expiry_date}</TableCell>

                                  
                                 

                                  {item.admin_State == "1" && <TableCell align="left"
                                 >
                                   <Button sx={mainThemeStyle.cellGreenButton}  variant='text' onClick={() =>{changeState(item.admin_State_ID,item.fname,item.lname)}}>Active</Button> </TableCell>}
                                    {item.admin_State == "0" && <TableCell align="left"
                                 > <Button sx={mainThemeStyle.cellRedButton}  variant='text' onClick={() =>{changeState(item.admin_State_ID,item.fname,item.lname)}}>Blocked</Button> 
                                    </TableCell>}
                                    {item.admin_State == "-1" && <TableCell align="left" 
                                 ><Button sx={mainThemeStyle.cellYellowButton}  variant='text' onClick={() =>{changeState(item.admin_State_ID,item.fname,item.lname)}}>Pending</Button> 
                                    </TableCell>}
                                    <TableCell>
                                  <Stack direction="row" spacing={2}>
                                    { (item.type == 'iOS' || item.type == 'Android' || item.type == 'Phone') && <IconButton sx={{ backgroundColor: '#2A2F3B'}}  size="small" color="primary"  onClick={() => {ChangeId(item.phone_no,0)}}><EditIcon /></IconButton>}
                                    
                                    <IconButton sx={{ backgroundColor: '#2A2F3B'}}  size="small" color="redwarning"  onClick={() => {ChangeId(item.phone_no,2)}}><DeleteIcon /></IconButton>
                                    </Stack>
                                  </TableCell>
                                </TableRow>)
                            )
                        }
                    </TableBody>
                   
                </TblContainer>
                <Box sx={{marginTop:'10px'}}>
                <PuffLoader color="#878787" loading={loading} cssOverride={{display: "block",
    margin: "0 auto",
    marginTop:"50px",
    marginBottom:"50px",}} />



</Box></Box>
                 </Box>
          
          <Popup
                title="Entry Log"
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
            >
             
      <ShowLog/>
               
            </Popup>
            <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={severityData} sx={{ width: '100%' }}>
          {openSnackData}
        </Alert>
      </Snackbar>
            <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          padding: '16px',
      
         backgroundColor:'#00000000',
         
      }}
      >
        <DialogTitle id="alert-dialog-title" sx={{
        
      
         backgroundColor:'#2A2F3B',
         color:'white'
         
      }}>
          {titleforalert}
        </DialogTitle>
        <DialogContent sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          
        </DialogContent>
        <DialogActions sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          <Button onClick={handleCloseDelete} sx={mainThemeStyle.normalBigButton}>Cancel</Button>
          <Button onClick={handleCloseActiveDelete} autoFocus sx={mainThemeStyle.normalBigButtonRed}>
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>
            </> 
          
          
          
          
          ;
};

export default ManageUsers;