import * as React from "react";
import { Box } from "@mui/system";
import { Button,Checkbox, Switch,Grid, InputLabel,Select,MenuItem, List,Divider, FormControlLabel, Typography,ButtonGroup,Snackbar ,Alert ,IconButton, Icon,Dialog,DialogTitle,DialogContent,DialogActions,DialogContentText, cardMediaClasses } from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { faker } from '@faker-js/faker';
import { format } from 'date-fns';
import { useState,useRef,useEffect } from "react";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import axios from "./axios";
import useTable from "./components/useTable";
import SvgIcon from "@mui/material/SvgIcon";
import NoImage from './asset/noimage.png';
import Popup from "./components/Popup";
import _ from "lodash";
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import useToken from "./useToken";
import { styled } from '@mui/material/styles';
import GlobalAlert from "./GlobalAlert"; 
import GlobalRoleAccess from "./GlobalRoleAccess";
import GlobalRoleAccessInner from "./GlobalRoleAccessInner";
import TimezoneSelect from 'react-timezone-select'
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


function AddDealerDist ( {RefreshToken,...props}){
    const {token} = useToken();
    const { globalAlertData} = GlobalAlert();
  let [dataOfDealer,setDataOfDealer] = useState({
   
    dis_id: token.id,
    company_name: '',
    contact_name: '',
    street_add: '',
    city: '',
    state: '',
    zip_code: '',
    telephone: '',
    email: '',
    password: '',
    unit_cost:'',
    total_unit:'',
    issub:token.issub,
    subId:token.subid
  });
  const {getRoleAccessVal} = GlobalRoleAccess();
  const {getRoleAccessInnerVal} = GlobalRoleAccessInner();
  const [openSnack, setOpenSnack] = React.useState(false);
  const [openSnackData, setOpenSnackData] = React.useState("This is Success Data");
  const [severityData, setSeverityData] = React.useState("success");
  

  const getDisableValue = () => {
    if(token.role == 'superadmin' || token.name === 'SUPER ADMIN'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("24").administrator_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let valOfDisable = getRoleAccessVal("24").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'distributor'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("24").distributor_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let valOfDisable = getRoleAccessVal("24").distributor_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
    
    
  }
  const currentDisableValue = getDisableValue();

  const handleCloseSnack= (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
  const [showUpdateButton,setshowUpdateButton] = useState(false);
  
  const [loading,setLoading] = useState(false);
  const [isVisible,setIsVisibile] = useState(false);
  const onClickTogglePassword = () =>{
    setIsVisibile(!isVisible);
  }
  const setDealerData = () => {
    setshowUpdateButton(true);
    setDataOfDealer({
     
      dis_id: dataOfDealer.dis_id,
      company_name: dataOfDealer.company_name,
      contact_name: dataOfDealer.contact_name,
      street_add: dataOfDealer.street_add,
      city: dataOfDealer.city,
      state: dataOfDealer.state,
      zip_code: dataOfDealer.zip_code,
      telephone: dataOfDealer.telephone,
      email: dataOfDealer.email,
      password: dataOfDealer.password,
      unit_cost:dataOfDealer.unit_cost,
      total_unit:dataOfDealer.total_unit,
    issub:token.issub,
    subId:token.subid

    });
  }
  const handleInputChange = (event) =>{
    let ds = event.target.name;
    dataOfDealer[ds] = event.target.value;
    setDealerData();
  }
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const getAlertTitle = (val) => {
    let idData ;
    for(let i = 0; i < globalAlertData.length ; i++){
      if(globalAlertData[i].code == val ){
          idData = i;
          break;
      }
  }
  return globalAlertData[idData].message;
  }
  const handleUpdateDistributor = () =>{
    if(dataOfDealer.contact_name == ""){
      setOpenSnackData(getAlertTitle('ADNNE'));
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    if(dataOfDealer.company_name == ""){
      setOpenSnackData(getAlertTitle('ADCNNE'));
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    if(dataOfDealer.email == ""){
      setOpenSnackData(getAlertTitle('ADENE'));
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    if(!isValidEmail(dataOfDealer.email)){
      setOpenSnackData(getAlertTitle('ADENV'));
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    /* if(dataOfDealer.password.length  < 1 ){
      setOpenSnackData(getAlertTitle('ADPNE'));
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    } */
    updateMyPostData();
  }
  const updateMyPostData = async () => {
    setLoading(true);
    try {
      const res = await axios.post("/distributor/adddealer/",dataOfDealer, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      'token' : token.token,
      'tokenid' : token.email }});
      console.log( res.data);
      setOpenSnackData(getAlertTitle('ADDAS'));
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      setshowUpdateButton(false);
      setDataOfDealer({
        dis_id: token.id,
        company_name: '',
        contact_name: '',
        street_add: '',
        city: '',
        state: '',
        zip_code: '',
        telephone: '',
        email: '',
        password: '',
        unit_cost:'',
        total_unit:'',
    issub:token.issub,
    subId:token.subid
    
      });
    } catch (error) {
      console.log(error.response.data);
      setLoading(false);
      setOpenSnackData(getAlertTitle('ADDAF'));
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
/*   const GetDistributorCompany = () => {
    for(let i = 0; i < dataOfDistributor.length ; i++){
      if(dataOfDistributor[i].dis_id == dataOfDealer.dis_id){
        return dataOfDistributor[i].company_name;
      }
    }

  };
  const handleSetDistributor = (event) => {
   
    for(let i = 0; i < dataOfDistributor.length ; i++){
      if(dataOfDistributor[i].company_name == event.target.value){
        console.log("evetm "+ event.target.value);
        dataOfDealer.dis_id = dataOfDistributor[i].dis_id;
        setDealerData();
        break;
      }
    }
   
  }; */
  const MenuProps = {
    PaperProps: {
      style: {
        backgroundColor: "#1E1E1E",
          borderRadius: 0,
          maxHeight:'250px',
          borderRadius: 10,
          padding:0
      },
    },
  };
 
  return <>{currentDisableValue == false &&  <Box sx={mainThemeStyle.boxTopHeader}>
     
            
</Box>}
{currentDisableValue == false && <Box sx={mainThemeStyle.boxHeaderBelow}>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                      Add Dealer
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           <Box sx={{display: { xs: "none", md: "flex" }}}>
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>Name </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Company Name </Typography>
      {/* <Typography  sx={mainThemeStyle.popUpLable} height={40}>Distributor Company</Typography> */}
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Address </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>City </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> State </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> ZipCode </Typography>
      
      </Stack>
      </Grid>
      
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
         <TextField
       
       type="text"
       name = "contact_name"
       onChange={handleInputChange}
       value={dataOfDealer.contact_name}
       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     
                         variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                              
                               
                           },
                       }}
                      
     />
         <TextField
      
       type="text"
       name = "company_name"
       onChange={handleInputChange}
       value={dataOfDealer.company_name}
       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                              
                               
                           },
                       }}
                      
     />
 {/*  <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "app_unlock_mode"
            
            value={GetDistributorCompany()}
          label="Age"
          onChange={handleSetDistributor}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            { dataOfDistributor.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.company_name}>
                      {menuItem.company_name}
                    </MenuItem>
                  );
                })} 
          </Select> */}
         <TextField
      
       type="text"
       name = "street_add"
       onChange={handleInputChange}
       value={dataOfDealer.street_add}
       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                              
                               
                           },
                       }}
                      
     />
      <TextField
       
       type="text"
       name = "city"
       onChange={handleInputChange}
       value={dataOfDealer.city}
       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                              
                               
                           },
                       }}
                      
     />
     <TextField
                      
                      height={40}
                      type= 'text'
                      name = "state"
                       onChange={handleInputChange}
                      value={dataOfDealer.state}
                      sx={mainThemeStyle.InputMenuItem}
                      InputLabelProps={{ shrink: true }
                    }
                    
                    variant="filled"
                      InputProps={{
                         
                          sx: {
                              
                              color:'rgba(135, 135, 135, 0.5)',
                              height:'33px',
                              borderRadius: '5px',
                              backgroundColor: '#121212',
                              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                              padding: '0 0 15px 0',
                              textIndent: '15px !important',
                            
                             
                              
                          },
                      }}
                     
                  />
                   <TextField
                       
                       height={40}
                       type= 'text'
                       name = "zip_code"
                       onChange={handleInputChange}
                       value={dataOfDealer.zip_code}
                       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                              
                               
                           },
                       }}
                      
                   />
      </Stack>
      </Grid>
      <Grid item xs={1}>
    
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        <Typography sx={mainThemeStyle.popUpLable} height={40}>Cell Phone #</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>Email</Typography>
      {/* <Typography sx={mainThemeStyle.popUpLable} height={40}>Password</Typography> */}
      <Typography sx={mainThemeStyle.popUpLable} height={40}>Unit Cost</Typography>
      {/* <Typography sx={mainThemeStyle.popUpLable} height={40}>Total Unit</Typography> */}
     
      </Stack>
      </Grid>
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
        
      
      
                     
        <TextField
           
           height={40}
           type= 'number'
           name = "telephone"
           onChange={handleInputChange}
           value={dataOfDealer.telephone}
           sx={mainThemeStyle.InputMenuItem}
           InputLabelProps={{ shrink: true }
         }
         
         variant="filled"
           InputProps={{
              
               sx: {
                   
                   color:'rgba(135, 135, 135, 0.5)',
                   height:'33px',
                   borderRadius: '5px',
                   backgroundColor: '#121212',
                   boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                   padding: '0 0 15px 0',
                   textIndent: '15px !important',
                 
                  
                   
               },
           }}
          
       />
        <TextField
           
           height={40}
           type= 'text'
           name = "email"
           onChange={handleInputChange}
           value={dataOfDealer.email}
           sx={mainThemeStyle.InputMenuItem}
           InputLabelProps={{ shrink: true }
         }
         
         variant="filled"
           InputProps={{
              
               sx: {
                   
                   color:'rgba(135, 135, 135, 0.5)',
                   height:'33px',
                   borderRadius: '5px',
                   backgroundColor: '#121212',
                   boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                   padding: '0 0 15px 0',
                   textIndent: '15px !important',
                 
                  
                   
               },
           }}
          
       />
       {/* <TextField
           
           height={40}
           type= {isVisible ? 'text' : 'password'}
           name = "password"
           onChange={handleInputChange}
           value={dataOfDealer.password}
           margin="normal"
           variant = "filled"
           
           InputLabelProps={{
               shrink: true,
               style: {
               '&::placeholder': {
                   textOverflow: 'ellipsis !important',
                   color: 'blue'
                 },
               }
           }}
           
           InputProps={{
              
               style: {
                   
                 color:'rgba(135, 135, 135, 0.5)',
                   height:'33px',
                   borderRadius: '5px',
                   backgroundColor: '#121212',
                   boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                   padding: '0 0 18px 0',
                   textIndent: '15px !important',
                  
                   
               },
               
               endAdornment: (
                   <InputAdornment position="end">
                       <IconButton
                           style={{
                               color:
                               'rgba(135, 135, 135, 0.5)',
                               padding: '18px 5px 0 0',
                           }}
                           aria-label={
                               'visibility token'
                           }
                           onClick={onClickTogglePassword}
                       >
                          
                          {isVisible ? (
                           <VisibilityOff />
                       ) : (
                           <Visibility />
                       )}
                           
                       </IconButton>
                   </InputAdornment>
               ),
           }}
          
       /> */}
        <TextField
           
           height={40}
           type= 'number'
           name = "unit_cost"
           onChange={handleInputChange}
           value={dataOfDealer.unit_cost}
           sx={mainThemeStyle.InputMenuItem}
           InputLabelProps={{ shrink: true }
         }
         
         variant="filled"
           InputProps={{
              
               sx: {
                   
                   color:'rgba(135, 135, 135, 0.5)',
                   height:'33px',
                   borderRadius: '5px',
                   backgroundColor: '#121212',
                   boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                   padding: '0 0 15px 0',
                   textIndent: '15px !important',
                 
                  
                   
               },
           }}
          
       />
        {/* <TextField
           
           height={40}
           type= 'number'
           name = "total_unit"
           onChange={handleInputChange}
           value={dataOfDealer.total_unit}
           sx={mainThemeStyle.InputMenuItem}
           InputLabelProps={{ shrink: true }
         }
         
         variant="filled"
           InputProps={{
              
               sx: {
                   
                   color:'rgba(135, 135, 135, 0.5)',
                   height:'33px',
                   borderRadius: '5px',
                   backgroundColor: '#121212',
                   boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                   padding: '0 0 15px 0',
                   textIndent: '15px !important',
                 
                  
                   
               },
           }}
          
       /> */}
       
</Stack>
      </Grid>
     
      
      </Grid>
      </Box>
      <Box sx={{display: { xs: "flex", md: "none" }}}>
           <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={5}>
        <Stack direction="column"  spacing={2}>
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>Name </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Company Name </Typography>
     
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Address </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>City </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> State </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> ZipCode </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>Cell Phone #</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>Email</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>Unit Cost</Typography>
      </Stack>
      </Grid>
      
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
         <TextField
       
       type="text"
       name = "contact_name"
       onChange={handleInputChange}
       value={dataOfDealer.contact_name}
       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     
                         variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                              
                               
                           },
                       }}
                      
     />
         <TextField
      
       type="text"
       name = "company_name"
       onChange={handleInputChange}
       value={dataOfDealer.company_name}
       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                              
                               
                           },
                       }}
                      
     />

         <TextField
      
       type="text"
       name = "street_add"
       onChange={handleInputChange}
       value={dataOfDealer.street_add}
       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                              
                               
                           },
                       }}
                      
     />
      <TextField
       
       type="text"
       name = "city"
       onChange={handleInputChange}
       value={dataOfDealer.city}
       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                              
                               
                           },
                       }}
                      
     />
     <TextField
                      
                      height={40}
                      type= 'text'
                      name = "state"
                       onChange={handleInputChange}
                      value={dataOfDealer.state}
                      sx={mainThemeStyle.InputMenuItem}
                      InputLabelProps={{ shrink: true }
                    }
                    
                    variant="filled"
                      InputProps={{
                         
                          sx: {
                              
                              color:'rgba(135, 135, 135, 0.5)',
                              height:'33px',
                              borderRadius: '5px',
                              backgroundColor: '#121212',
                              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                              padding: '0 0 15px 0',
                              textIndent: '15px !important',
                            
                             
                              
                          },
                      }}
                     
                  />
                   <TextField
                       
                       height={40}
                       type= 'text'
                       name = "zip_code"
                       onChange={handleInputChange}
                       value={dataOfDealer.zip_code}
                       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                              
                               
                           },
                       }}
                      
                   />
                   <TextField
           
           height={40}
           type= 'number'
           name = "telephone"
           onChange={handleInputChange}
           value={dataOfDealer.telephone}
           sx={mainThemeStyle.InputMenuItem}
           InputLabelProps={{ shrink: true }
         }
         
         variant="filled"
           InputProps={{
              
               sx: {
                   
                   color:'rgba(135, 135, 135, 0.5)',
                   height:'33px',
                   borderRadius: '5px',
                   backgroundColor: '#121212',
                   boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                   padding: '0 0 15px 0',
                   textIndent: '15px !important',
                 
                  
                   
               },
           }}
          
       />
        <TextField
           
           height={40}
           type= 'text'
           name = "email"
           onChange={handleInputChange}
           value={dataOfDealer.email}
           sx={mainThemeStyle.InputMenuItem}
           InputLabelProps={{ shrink: true }
         }
         
         variant="filled"
           InputProps={{
              
               sx: {
                   
                   color:'rgba(135, 135, 135, 0.5)',
                   height:'33px',
                   borderRadius: '5px',
                   backgroundColor: '#121212',
                   boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                   padding: '0 0 15px 0',
                   textIndent: '15px !important',
                 
                  
                   
               },
           }}
          
       />
       
        <TextField
           
           height={40}
           type= 'number'
           name = "unit_cost"
           onChange={handleInputChange}
           value={dataOfDealer.unit_cost}
           sx={mainThemeStyle.InputMenuItem}
           InputLabelProps={{ shrink: true }
         }
         
         variant="filled"
           InputProps={{
              
               sx: {
                   
                   color:'rgba(135, 135, 135, 0.5)',
                   height:'33px',
                   borderRadius: '5px',
                   backgroundColor: '#121212',
                   boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                   padding: '0 0 15px 0',
                   textIndent: '15px !important',
                 
                  
                   
               },
           }}
          
       />
      </Stack>
      </Grid>
      
   
      
     
      
      </Grid>
      </Box>
      {showUpdateButton &&  <Box sx= {mainThemeStyle.boxDown}><LoadingButton
         
         sx={mainThemeStyle.normalButtonRelative}
         loading={loading}
         onClick={handleUpdateDistributor}
         variant="outlined"
         startIcon={<SaveIcon />}
         loadingPosition="start"
       >
         Add Dealer
       </LoadingButton>
    <Typography></Typography>
      </Box>}
</Box>}
{currentDisableValue == true && 
 <Box sx={mainThemeStyle.box} >
 <Typography sx={mainThemeStyle.lable}>Access For This Page Is Blocked</Typography>
 
  
  
  </Box>
    
    
    }
<Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={severityData} sx={{ width: '100%' }}>
          {openSnackData}
        </Alert>
      </Snackbar>
 </> 
          
          
          
          
          ;
};

export default AddDealerDist;