import * as React from "react";
import { Box } from "@mui/system";
import { Button,Checkbox, Switch,Grid, InputLabel, List,Divider, FormControlLabel, Typography,ButtonGroup,Snackbar ,Alert ,IconButton, Icon,Dialog,DialogTitle,DialogContent,DialogActions,DialogContentText, cardMediaClasses } from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { faker } from '@faker-js/faker';
import { format } from 'date-fns';
import { useState,useRef,useEffect } from "react";
import InputAdornment from '@mui/material/InputAdornment';
import useTable from "./components/useTable";
import SvgIcon from "@mui/material/SvgIcon";
import NoImage from './asset/noimage.png';
import Popup from "./components/Popup";
import _ from "lodash";
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { styled } from '@mui/material/styles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import useToken from "./useToken";
import GlobalAlert from "./GlobalAlert";
import axios from "./axios";
import TimezoneSelect from 'react-timezone-select'
import { LoadingButton } from "@mui/lab";
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


function DistributorPassword ( props){
  const handleCloseSnack= (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
  const {RefreshToken} = props;
  const {token} = useToken();
  const { getAlertTitle} = GlobalAlert();
  const [isVisible ,setIsVisibile] = useState(false);
  const [isVisibleReenter,setIsVisibleReenter] = useState(false);
  const [passwords, setPasswords] = useState('');
  const [passwordsReEnter, setPasswordsReEnter] = useState('');
  const [openSnack, setOpenSnack] = React.useState(false);
  const [openSnackData, setOpenSnackData] = React.useState("This is Success Data");
  const [severityData, setSeverityData] = React.useState("success");

  const handlePasswordReEnterChange= (event) => {
    setPasswordsReEnter(event.target.value);
  }
  const handlePasswordChange = (event) => {
    setPasswords(event.target.value);
  }
  const onClickTogglePassword = () => {
    setIsVisibile(!isVisible);
  }

  const onClickToggleReEnterPassword = () => {
    setIsVisibleReenter(!isVisible);
  }
  let [loading, setLoading] = useState(false);
  const updateMyPostData = async () => {
    setLoading(true);
    try {
      const res = await axios.patch("/distributor/password/"+token.id,{email:token.email,password:passwords,issub:token.issub,subId:token.subid}, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8' ,
      'token' : token.token,
      'tokenid' : token.email}});
      console.log( res.data);
      
      setOpenSnackData(getAlertTitle('PSUS'));
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      setOpenSnackData(getAlertTitle('PSUF'));
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  const handleChangePassword = (event) => {

    if(passwords == ""){
      setOpenSnackData(getAlertTitle('PNE'));
      setSeverityData("error");
      setOpenSnack(true);
    }else if(passwordsReEnter == ""){
      setOpenSnackData(getAlertTitle('RPNE'));
      setSeverityData("error");
      setOpenSnack(true);
    }
    else if(passwords.length < 6){
      setOpenSnackData(getAlertTitle('PLST6E'));
      setSeverityData("error");
      setOpenSnack(true);
    }
    else if(passwords != passwordsReEnter){
      setOpenSnackData(getAlertTitle('PRPNS'));
      setSeverityData("error");
      setOpenSnack(true);
    }
    else{
      updateMyPostData();
    }
    
  }
  return <> <Box sx={mainThemeStyle.boxTopHeader}>
  
</Box>
<Box sx={mainThemeStyle.boxHeaderBelow}>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
              
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Change Password
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
   
           <Box sx={{display: { xs: "none", md: "flex" }}}>
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40} style={{paddingTop: '16px'}}> New Password</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40} style={{paddingTop: '16px'}}> Re-Enter Password</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> </Typography>
      <LoadingButton sx={mainThemeStyle.normalButton} loading={loading} onClick={handleChangePassword}>Change Password</LoadingButton>
      </Stack>
      </Grid>
      
      <Grid item xs={3}>
      <Stack direction="column"  spacing={2}>
      
      <TextField
                        id="input-password"
                        type= {isVisible ? 'text' : 'password'}
                        name="password"
                        placeholder="Password"
                        
                        margin="normal"
                        value={passwords}
                        variant = "filled"
                        onChange={handlePasswordChange}
                        InputLabelProps={{
                            shrink: true,
                            style: {
                            '&::placeholder': {
                                textOverflow: 'ellipsis !important',
                                color: 'blue'
                              },
                            }
                        }}
                        
                        InputProps={{
                           
                            style: {
                                
                              color:'rgba(170, 170, 170, 1)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                               
                                
                            },
                            
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        style={{
                                            color:
                                            'rgba(170, 170, 170,1)',
                                            padding: '18px 5px 0 0',
                                        }}
                                        aria-label={
                                            'visibility token'
                                        }
                                        onClick={onClickTogglePassword}
                                    >
                                       
                                       {isVisible ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )}
                                        
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                       
                    />
                    <TextField
                        id="input-password"
                        type= {isVisibleReenter ? 'text' : 'password'}
                        name="password"
                        placeholder="Password"
                        
                        margin="normal"
                        value={passwordsReEnter}
                        variant = "filled"
                        onChange={handlePasswordReEnterChange}
                        InputLabelProps={{
                            shrink: true,
                            style: {
                            '&::placeholder': {
                                textOverflow: 'ellipsis !important',
                                color: 'blue'
                              },
                            }
                        }}
                        
                        InputProps={{
                           
                            style: {
                                
                              color:'rgba(170, 170, 170, 1)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                               
                                
                            },
                            
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        style={{
                                            color:
                                            'rgba(170, 170, 170,1)',
                                            padding: '18px 5px 0 0',
                                        }}
                                        aria-label={
                                            'visibility token'
                                        }
                                        onClick={onClickToggleReEnterPassword}
                                    >
                                       
                                       {isVisibleReenter ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )}
                                        
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                       
                    />
      </Stack>
    
      
      </Grid>
     
     
      
      </Grid>
      </Box>

      
      <Box sx={{display: { xs: "flex", md: "none" }}}>
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={5}>
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40} style={{paddingTop: '16px'}}> New Password</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40} style={{paddingTop: '16px'}}> Re-Enter Password</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> </Typography>
       </Stack>
      </Grid>
      
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
      
      <TextField
                        id="input-password"
                        type= {isVisible ? 'text' : 'password'}
                        name="password"
                        placeholder="Password"
                        
                        margin="normal"
                        value={passwords}
                        variant = "filled"
                        onChange={handlePasswordChange}
                        InputLabelProps={{
                            shrink: true,
                            style: {
                            '&::placeholder': {
                                textOverflow: 'ellipsis !important',
                                color: 'blue'
                              },
                            }
                        }}
                        
                        InputProps={{
                           
                            style: {
                                
                              color:'rgba(170, 170, 170, 1)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                               
                                
                            },
                            
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        style={{
                                            color:
                                            'rgba(170, 170, 170,1)',
                                            padding: '18px 5px 0 0',
                                        }}
                                        aria-label={
                                            'visibility token'
                                        }
                                        onClick={onClickTogglePassword}
                                    >
                                       
                                       {isVisible ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )}
                                        
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                       
                    />
                    <TextField
                        id="input-password"
                        type= {isVisibleReenter ? 'text' : 'password'}
                        name="password"
                        placeholder="Password"
                        
                        margin="normal"
                        value={passwordsReEnter}
                        variant = "filled"
                        onChange={handlePasswordReEnterChange}
                        InputLabelProps={{
                            shrink: true,
                            style: {
                            '&::placeholder': {
                                textOverflow: 'ellipsis !important',
                                color: 'blue'
                              },
                            }
                        }}
                        
                        InputProps={{
                           
                            style: {
                                
                              color:'rgba(170, 170, 170, 1)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                               
                                
                            },
                            
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        style={{
                                            color:
                                            'rgba(170, 170, 170,1)',
                                            padding: '18px 5px 0 0',
                                        }}
                                        aria-label={
                                            'visibility token'
                                        }
                                        onClick={onClickToggleReEnterPassword}
                                    >
                                       
                                       {isVisibleReenter ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )}
                                        
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                       
                    />
      </Stack>
    
      
      </Grid>
     
      <LoadingButton sx={mainThemeStyle.normalButton} loading={loading} onClick={handleChangePassword}>Change Password</LoadingButton>
     
      
      </Grid>
      </Box>


</Box>
<Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={severityData} sx={{ width: '100%' }}>
          {openSnackData}
        </Alert>
      </Snackbar>
 </> 
          
          
          
          
          ;
};

export default DistributorPassword;