import * as React from "react";
import { Box } from "@mui/system";
import { Button,Checkbox, Switch,Grid, InputLabel, List,Select,MenuItem,Divider, FormControlLabel, Typography,ButtonGroup,Snackbar ,Alert ,IconButton, Icon,Dialog,DialogTitle,DialogContent,DialogActions,DialogContentText, cardMediaClasses } from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import MuiToggleButton from '@mui/material/ToggleButton';	
import FilledInput from '@mui/material/FilledInput';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { faker } from '@faker-js/faker';
import { format } from 'date-fns';
import { useState,useRef,useEffect } from "react";
import useTable from "./components/useTable";
import SvgIcon from "@mui/material/SvgIcon";
import NoImage from './asset/noimage.png';
import Popup from "./components/Popup";
import _ from "lodash";
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { styled } from '@mui/material/styles';
import axios from "./axios";
import GlobalAlert from "./GlobalAlert";
import PuffLoader from "react-spinners/PuffLoader"; 
import LoadingButton from '@mui/lab/LoadingButton';
import useToken from "./useToken";
import GlobalRoleAccess from "./GlobalRoleAccess";
import GlobalRoleAccessInner from "./GlobalRoleAccessInner";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import useInnerTable from "./components/useInnerTable";
import InputAdornment from '@mui/material/InputAdornment';
import TimezoneSelect from 'react-timezone-select'
import { useSSR } from "react-i18next";
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


function ManagePromoCode ( props){
  const handleCloseSnack= (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
  const {token} = useToken();
  const {RefreshToken} = props;
  const { getAlertTitle} = GlobalAlert();
  const [openDelete, setOpenDelete] = React.useState(false);
  const [titleforalert, setTitleforalert] = React.useState("");
  const [isVisible ,setIsVisibile] = useState(false);
  const [isVisibleCheck ,setIsVisibileCheck] = useState(false);
  const [isVisibleArray, setIsVisibileArray] = useState([]);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [openSnackData, setOpenSnackData] = React.useState("This is Success Data");
  const [severityData, setSeverityData] = React.useState("success");
  const [loadingbtn ,setloadingbtn] = React.useState(false);
  const [EnLog,setEnLog] = useState([]);
  const [apiValue, setAPIValue] = useState();
  const [fullName,setFullName] = useState('');
  const [percentage,setPercentage] = useState('');
  const [expiry,setExpiry] = useState('');
  const [currentSelection, setCurrentSelection] = useState("0");
  const [showUpdateButton, setshowUpdateButton] = React.useState(false);
  let [loading, setLoading] = useState(true);
  const [currentDeleteID,setCurrentDeleteId] = useState();
  const {getRoleAccessVal} = GlobalRoleAccess();
  const {getRoleAccessInnerVal} = GlobalRoleAccessInner();
  const [max_use,setMax_Use] = useState("10");
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  const ToggleButtonSmall = styled(MuiToggleButton)(({ selectedColor, unSelectedColor }) => ({	
    width: 'auto',	
    height: '2.46rem',	
    color: 'white',	
    backgroundColor: unSelectedColor,	
    border: '1px solid !important',
    borderColor : '#696969 !important' ,
    fontWeight: '500',	
            fontSize: '0.9rem',	
            textTransform: 'capitalize',	
              
            alignItems: 'center',	
            color: '#FFFFFF',	
            fontFamily: "Lato",	
            borderRadius:'0.31rem',	
    '&.Mui-selected, &.Mui-selected:hover': {	
      color: 'white',	
      backgroundColor: selectedColor,	
        
    },	
    '&:hover': {	
      color: 'white',	
      backgroundColor: unSelectedColor,	
        
    },	
  }));
  
  const [currentSelectionName,setCurrentSelectionName] = useState('Percentage');
const handleChangeMDMType = (event, newAlignment) => {	
  setCurrentSelection(newAlignment);
  
  if(newAlignment == "0"){
  
    setCurrentSelectionName('Percentage');
  }
  else if(newAlignment == "1"){
    
    setCurrentSelectionName('Amount');
  }

}
  const handleCloseActiveDelete =() =>{
    setOpenDelete(false);
    console.log("currentDeleteID"+currentDeleteID);
    deleteMyPostData();
  }
  const MenuProps = {
    PaperProps: {
      style: {
        backgroundColor: "#1E1E1E",
          borderRadius: 0,
          maxHeight:'250px',
          borderRadius: 10,
          padding:0
      },
    },
  };
  const deleteMyPostData = async () => {
    setloadingbtn(true);
     try {
       const res = await axios.delete("/administrator/deletepromocode/"+currentDeleteID,
       {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
       'token' : token.token,
       'tokenid' : token.email,
       'issub':token.issub,
       'subId':token.subid,
       'name':deleteUserName }});
       console.log( res.data);
       setOpenSnackData(getAlertTitle('PCDS'));
       setSeverityData("success");
       setOpenSnack(true);
       setLoading(false);
       
       getMyPostData();
     } catch (error) {
       console.log(error.response);
       setLoading(false);
       setOpenSnackData(getAlertTitle('PCDF'));
       setSeverityData("error");
       setOpenSnack(true);
       setloadingbtn(false);
       if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
     }
   };
   const getDisableValueEdit = (val) => {
    if(token.role == 'superadmin' || token.name === 'SUPER ADMIN'){
      console.log("token sub type" + token.sub_type);
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("66").administrator_control;
        console.log("valOfDisable" + valOfDisable);
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let valOfDisable = getRoleAccessVal("66").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
    
    
  }
  const getDisableValueDelete = (val) => {
    if(token.role == 'superadmin' || token.name === 'SUPER ADMIN'){
      console.log("token sub type" + token.sub_type);
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("67").administrator_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let valOfDisable = getRoleAccessVal("67").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
    
    
  }
  const getDisableValueAdd = (val) => {
    if(token.role == 'superadmin' || token.name === 'SUPER ADMIN'){
      console.log("token sub type" + token.sub_type);
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("68").administrator_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let valOfDisable = getRoleAccessVal("68").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
    
    
  }
  const getDisableValue = (val) => {
    if(getDisableValueEdit("") == false){
    if(token.role == 'superadmin' || token.name === 'SUPER ADMIN'){
      if(token.sub_type == "1"){
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("66");
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
        let valOfDisable = valOfDisableData[idData].administrator_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("66");
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
        let valOfDisable = valOfDisableData[idData].administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
  }else{
    return true;
  }
    
  }
  const getMyPostData = async () => {
    try {
      const res = await axios.get("/administrator/getpromodetails/",{
        headers: { 'Content-Type':  'application/json',
        'token' : token.token,
        'tokenid' : token.email },
      });
     // console.log("data format"+JSON.stringify( res.data));
      //setEnLog(res.data[0]);
      //let ad  = apiValue;
      //ad.contacts = res.data[0].contacts;
      let adsArray = [];
      //if(records.length > 0)
      setRecords( res.data);
   /*   if(res.data.length > 0){
        //let rd = JSON.parse(res.data);

        for(let ist = 0; ist < res.data.length ; ist ++){
          adsArray.push(false);
        }
        setIsVisibileArray(adsArray);
      }

      console.log("is visible array"+adsArray);*/
      //setAPIValue(ad);
      setLoading(false);
      setloadingbtn(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      setloadingbtn(false);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  useEffect(() => {
    getMyPostData();
  }, []);
  const handleInputChange=(event) =>{
    console.log(apiValue);
    setshowUpdateButton(true);
    setAPIValue({contacts:event.target.value,});
  }
  const [records,setRecords] = useState([]);
  const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });
  const headCells = [
    { id: 'name', label: 'Promo Code', disableSorting: true },
    { id: 'pora', label: 'Percentage/Amount', disableSorting: true },
    { id: 'discount', label: 'Discount', disableSorting: true },
    
    { id: 'max_use', label: 'Max Use', disableSorting: true },
    { id: 'expiry', label: 'Exp. Date', disableSorting: true },
    { id: 'access', label: 'Action', disableSorting: true },
  ];
  const {
    TblContainer,
    TblHead,
    TblPagination ,
    BelowTblPagination,
    recordsAfterPagingAndSorting ,
    setPageToNewOne ,
  } = useInnerTable(records, headCells, filterFn,5,650);
  
  
  const updateMyPostData = async (val) => {
    console.log(apiValue);
    setloadingbtn(true);
    try {
      const res = await axios.patch("/administrator/updatepromocode/",val, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      'token' : token.token,
      'tokenid' : token.email }});
      console.log( res.data);
      setOpenSnackData(getAlertTitle('PCUS'));
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      setshowUpdateButton(false);
      setFullName('');
      setPercentage('');
      setExpiry('');
     
      getMyPostData();
    } catch (error) {
      console.log(error.response.data);
      setLoading(false);
      setOpenSnackData(getAlertTitle('PCUF'));
      setSeverityData("error");
      setOpenSnack(true);
      setloadingbtn(false);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  const addMyPostData = async (val) => {
    console.log(apiValue);
    setloadingbtn(true);
    try {
      const res = await axios.post("/administrator/addpromo/",val, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      'token' : token.token,
      'tokenid' : token.email }});
      console.log( res.data);
      if(res.data.rows == '1'){
      setOpenSnackData(getAlertTitle('PCAS'));
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      setshowUpdateButton(false);
      setFullName('');
      setExpiry('');
      setPercentage('');
     
      getMyPostData();
      setloadingbtn(false);
      }
      else{
        setOpenSnackData(getAlertTitle('PCPAE'));
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      setshowUpdateButton(false);
      setloadingbtn(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setOpenSnackData(getAlertTitle('PCAF'));
      setSeverityData("error");
      setOpenSnack(true);
      setloadingbtn(false);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  const handleSaveCardFormat = () => {
    updateMyPostData();
  }
  const handleTableNameChange = (event) => {
    let idIn;
    //console.log('record email'+records[0]  );
    for(let i = 0; i < records.length ; i++){
      let item = records[i];
      if(item.id == event.target.name ){
       
          idIn = i;
          break;
      }
    }
    records[idIn].name = event.target.value;
    setRecords(records);
  }
  const handleTableExpiryChange= (event) => {
    let idIn;
    //console.log('record email'+records[0]  );
    for(let i = 0; i < records.length ; i++){
      let item = records[i];
      if(item.id == event.target.name ){
       
          idIn = i;
          break;
      }
    }
    records[idIn].expiry = event.target.value;
    setRecords(records);
  }
  const handleTablePasswordChange = (event) => {
    let idIn;
    for(let i = 0; i < records.length ; i++){
      if(records[i].id == event.target.name ){
          idIn = i;
          break;
      }
    }
    console.log(event.target.name );
    console.log(idIn );
    records[idIn].password = event.target.value;
    setRecords(records);
  }
  const [deleteUserName,setDeleteUserName] = useState('');
  const handleDelete = (event) =>{
   /* let idIn;
    for(let i = 0; i < records.length ; i++){
      if(records[i].email == event.target.name ){
          idIn = i;
          break;
      }
    }
    records.splice(idIn,1);
    setRecords(records);
    apiValue.contacts= JSON.stringify(records);
    setAPIValue(apiValue);
    updateMyPostData();*/
   /* if(token.issub == true){
      setOpenSnackData("You don't have access to perform any action on this page");
      setSeverityData("error");
      setOpenSnack(true);
      return;
    }*/
    let idofItem =  records.filter(x => x.id == event.target.name);
    setCurrentDeleteId(event.target.name);
    setDeleteUserName(idofItem[0].name);
    setTitleforalert("Are you sure you want to delete promo code "+ idofItem[0].name + " ?");
    setOpenDelete(true);
  }
  const getVisibleEvent = (event) => {
    let idIn;
    for(let i = 0; i < records.length ; i++){
      if(records[i].id == event){
          idIn = i;
          break;
      }
    }
    return isVisibleArray[idIn];
  }
  const handleNameChange = (event) => {
    setFullName(event.target.value);
  }
  const handlePercentageChange = (event) => {
    setPercentage(event.target.value);
  }
  const handleMaxUseChange = (event) => {
    setMax_Use(event.target.value);
  }
  
  const handleExpiryChange = (event) => {
    setExpiry(event.target.value);
  }
  
  


  const onClickTogglePassword = () => {
    setIsVisibile(!isVisible);
  }
 
  const handlePromoModify = (event) => {
    console.log('token is '+token.issub);
    let idIn;
    for(let i = 0; i < records.length ; i++){
      if(records[i].id == event.target.name){
          idIn = i;
          break;
      }
    }
   
    if((records[idIn].name.trim()).length <= 0){
          setOpenSnackData(getAlertTitle('PCNNE'));
          setSeverityData("error");
          setOpenSnack(true);
          return;
         
    }
        
    let asv = { name:records[idIn].name,expiry:records[idIn].expiry,id:records[idIn].id,issub:token.issub,subId:token.subid};
    updateMyPostData(asv);
  }
  const AccessType = {
    id: "1",
    label: "Super Admin Sub-User",
    items: [
      {
        label: "Super Admin",
        callback: () =>  {}
      },
      {
        label: "Super Admin Sub-User",
        callback: () =>  {}
      }
    ]
  }

  const handleChangeAccessType = (event) => {
    let ds = event.target.value;
    let idIn;
    //console.log('record email'+records[0]  );
    for(let i = 0; i < records.length ; i++){
      let item = records[i];
      console.log('record email'+item.email  );
      console.log(' email '+ event.target.name );
      if(item.id == event.target.name){
       
          idIn = i;
          break;
      }
    }
    console.log(ds);
    console.log(idIn );
    if(ds == "Super Admin"){
      records[idIn].sub_type = "1";
    }else{
      records[idIn].sub_type = "0";
    }
    
    setRecords(records);
    
  }

  const handlePromoAdd = () => {
    /*if(token.issub == true){
      setOpenSnackData("You don't have access to perform any action on this page");
      setSeverityData("error");
      setOpenSnack(true);
      return;
    }*/
    if((fullName.trim()).length <= 0){
      setOpenSnackData(getAlertTitle('PCNNE'));
      setSeverityData("error");
      setOpenSnack(true);
      return;
    }
    if((percentage.trim()).length <= 0 ){
      setOpenSnackData(getAlertTitle('PCPNE'));
      setSeverityData("error");
      setOpenSnack(true);
      return;
    }
    
    
   

    let asv = {name:fullName,discount:percentage,pora:currentSelection,max_use:max_use,user_type:'superadmin',expiry:expiry,account_name:token.name,"issub":token.issub,"subId":token.subid};
    console.log(asv);
    //records.push(asv);
   // apiValue = JSON.stringify(records);
   // setAPIValue(apiValue);
    //setRecords(records);
   // console.log(records);
   addMyPostData(asv);

  }
  let rowOfOne = -1;
  return <> <Box sx={mainThemeStyle.boxTopHeader}>
  
</Box>
<Box sx={mainThemeStyle.boxHeaderBelow}>
<Box  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px',display: { xs: "none", md: "flex" }}}>
               <div style={{ display: 'flex' }}>
                <Stack direction='row' spacing={2} padding={2}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Add Promo Code
                   </Typography>
                   <TextField
        id="text"
        type="text"
      placeholder="Promo Code Name"
      value={fullName}
       variant="filled"
       onChange={handleNameChange}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValueAdd("")}
                          style={{paddingTop: '16px'}}
                          sx={mainThemeStyle.InputMenuItem}
                        InputProps={{
                          
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      />
       <ToggleButtonGroup
      
      value={currentSelection}
      exclusive
      onChange={handleChangeMDMType}
      aria-label="large button group"
      style={{  paddingTop: '12px',flexGrow: 1,color:'#fff' }}
    >
      <ToggleButtonSmall   value="0" selectedColor="#24A0ED" unSelectedColor="gray" >Percentage</ToggleButtonSmall>
      <ToggleButtonSmall   value="1" selectedColor="#24A0ED" unSelectedColor="gray">Amount</ToggleButtonSmall>
    
    </ToggleButtonGroup>
       <TextField
        id="text"
        type="number"
      placeholder="Discount Value"
      value={percentage}
       variant="filled"
       onChange={handlePercentageChange}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValueAdd("")}
                      sx={mainThemeStyle.InputMenuItem}
                          style={{paddingTop: '16px'}}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      />

<TextField
        id="text"
        type="number"
      placeholder="Max Use"
      value={max_use}
       variant="filled"
       onChange={handleMaxUseChange}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValueAdd("")}
                      sx={mainThemeStyle.InputMenuItem}
                          style={{paddingTop: '16px'}}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      />
      <TextField
        id="text"
        type="date"
      placeholder="Expiry Date"
      value={expiry}
       variant="filled"
       onChange={handleExpiryChange}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValueAdd("")}
                          style={{paddingTop: '16px'}}
                          sx={mainThemeStyle.InputMenuItem}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      />
    
      <LoadingButton sx={mainThemeStyle.normalButtonDown} onClick={handlePromoAdd}   disabled = {getDisableValueAdd("")} loading={loadingbtn} > Add </LoadingButton>
      </Stack>
               </div>
              
           </Box>
           <Box  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px',display: { xs: "flex", md: "none" }}}>
               <Stack direction='column' spacing={0.1} padding={2}>
                <Stack direction='row' spacing={2} padding={2}>
                   <Typography sx={mainThemeStyle.popUpLable}>
                   Add Promo Code
                   </Typography>
                   <TextField
        id="text"
        type="text"
      placeholder="Promo Code Name"
      value={fullName}
       variant="filled"
       onChange={handleNameChange}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValueAdd("")}
                          style={{paddingTop: '16px'}}
                          sx={mainThemeStyle.InputMenuItem}
                        InputProps={{
                          
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      /></Stack> <Stack direction='row' spacing={2} padding={2}>

<ToggleButtonGroup
      
      value={currentSelection}
      exclusive
      onChange={handleChangeMDMType}
      aria-label="large button group"
      style={{  paddingTop: '12px',flexGrow: 1,color:'#fff' }}
    >
      <ToggleButtonSmall   value="0" selectedColor="#24A0ED" unSelectedColor="gray" >Percentage</ToggleButtonSmall>
      <ToggleButtonSmall   value="1" selectedColor="#24A0ED" unSelectedColor="gray">Amount</ToggleButtonSmall>
    
    </ToggleButtonGroup>
       <TextField
        id="text"
        type="number"
      placeholder="Promo Code Discount Value"
      value={percentage}
       variant="filled"
       onChange={handlePercentageChange}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValueAdd("")}
                      sx={mainThemeStyle.InputMenuItem}
                          style={{paddingTop: '16px'}}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      />
      <TextField
        id="text"
        type="number"
      placeholder="Max Use"
      value={max_use}
       variant="filled"
       onChange={handleMaxUseChange}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValueAdd("")}
                      sx={mainThemeStyle.InputMenuItem}
                          style={{paddingTop: '16px'}}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      />
      <TextField
        id="text"
        type="date"
      placeholder="Expiry Date"
      value={expiry}
       variant="filled"
       onChange={handleExpiryChange}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValueAdd("")}
                          style={{paddingTop: '16px'}}
                          sx={mainThemeStyle.InputMenuItem}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      />
    </Stack> 
      <LoadingButton sx={{
          width: 'auto',
          height: '2.06rem',
          
          
          fontWeight: '700',
          fontSize: '0.93rem',
          
          
          alignItems: 'center',
          color: '#FFFFFF',
          fontFamily: "Lato",
          borderRadius:'0.31rem',
         '&:hover': {
          backgroundColor: 'rgba(109, 195, 252, 1)',
         }
      }} onClick={handlePromoAdd}  loading={loadingbtn}  disabled = {getDisableValueAdd("")} > Add </LoadingButton>
      </Stack>
              
              
           </Box>
           <Divider sx={{backgroundColor:'white'}} />
         
<TblContainer>
                    <TblHead />

        <TableBody>
         {recordsAfterPagingAndSorting().map(item => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              {() =>{rowOfOne++; console.log(rowOfOne)}}
              <TableCell align="left"> <TextField
        id="text"
        type="text"
        defaultValue={item.name}
        name = {item.id}
       onChange={handleTableNameChange}
       sx={mainThemeStyle.InputMenuItem}
          variant = "filled"
                        InputLabelProps={{ shrink: true }
                      }
                      disabled={getDisableValue("246")}
                        InputProps={{
                          
                            sx: {
                                minWidth:'150px',
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell align="left"  sx={mainThemeStyle.cellRowBig}> {item.pora == "0" ? " Percentage" : "Amount"} </TableCell>
      <TableCell align="left"  sx={mainThemeStyle.cellRowBig}> {item.discount} {item.pora == "0" ? " %" : " $"} </TableCell>
      <TableCell align="left"  sx={mainThemeStyle.cellRowBig}> {item.max_use} </TableCell>
      <TableCell align="left"> <TextField
        id="text"
        type="date"
        defaultValue={item.expiry}
        name = {item.id}
       onChange={handleTableExpiryChange}
       sx={mainThemeStyle.InputMenuItem}
          variant = "filled"
                        InputLabelProps={{ shrink: true }
                      }
                      disabled={getDisableValue("248")}
                        InputProps={{
                          
                            sx: {
                              minWidth:'120px',
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      /></TableCell>
     
     
              
              <TableCell align="left">
                  <Stack direction='row' spacing={2}>
                  <Typography></Typography>  
               <LoadingButton sx={mainThemeStyle.viewLogButton} name={item.id} loading={loadingbtn} onClick={handlePromoModify}  disabled = {getDisableValueEdit("")}> Save Changes </LoadingButton>
                <LoadingButton loading={loadingbtn} name={item.id} sx={mainThemeStyle.viewLogButtonRed} onClick={handleDelete}  disabled = {getDisableValueDelete("")}>Delete</LoadingButton>
                  </Stack>  </TableCell>
            </TableRow>
           ))}
        </TableBody>
        </TblContainer>
        <BelowTblPagination></BelowTblPagination>
</Box>
<Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={severityData} sx={{ width: '100%' }}>
          {openSnackData}
        </Alert>
      </Snackbar>
      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          padding: '16px',
      
         backgroundColor:'#00000000',
         
      }}
      >
        <DialogTitle id="alert-dialog-title" sx={{
        
      
         backgroundColor:'#2A2F3B',
         color:'white'
         
      }}>
          {titleforalert}
        </DialogTitle>
        <DialogContent sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          
        </DialogContent>
        <DialogActions sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          <Button onClick={handleCloseDelete} sx={mainThemeStyle.normalBigButton}>Cancel</Button>
          <Button onClick={handleCloseActiveDelete} autoFocus sx={mainThemeStyle.normalBigButtonRed}>
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>
 </> 
          
          
          
          
          ;
};

export default ManagePromoCode;