import * as React from "react";
import { Box } from "@mui/system";
import { Button,Checkbox, Switch,Grid, InputLabel, List,Divider, FormControlLabel, Typography,ButtonGroup,Snackbar ,Alert ,IconButton, Icon,Dialog,DialogTitle,DialogContent,DialogActions,DialogContentText, cardMediaClasses } from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { faker } from '@faker-js/faker';
import { format } from 'date-fns';
import { useState,useRef,useEffect } from "react";
import useTable from "./components/useTable";
import SvgIcon from "@mui/material/SvgIcon";
import NoImage from './asset/noimage.png';
import Popup from "./components/Popup";
import _ from "lodash";
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { styled } from '@mui/material/styles';
import useToken from "./useToken";
import GlobalAlert from "./GlobalAlert";
import axios from "./axios";
import useInnerTable from "./components/useInnerTable";
import LoadingButton from '@mui/lab/LoadingButton';
import GlobalRoleAccess from "./GlobalRoleAccess";
import GlobalRoleAccessInner from "./GlobalRoleAccessInner";
import PuffLoader from "react-spinners/PuffLoader"; 
import TimezoneSelect from 'react-timezone-select'
import { BreakfastDiningOutlined } from "@mui/icons-material";
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


function LPBlackList ( props){
  const handleCloseSnack= (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
  const {token} = useToken();
  const {RefreshToken} = props;
  const { getAlertTitle} = GlobalAlert();
  const [openSnack, setOpenSnack] = React.useState(false);
  const [openSnackData, setOpenSnackData] = React.useState("This is Success Data");
  const [severityData, setSeverityData] = React.useState("success");
  const [showUpdateButton, setshowUpdateButton] = React.useState(false);
  const [EnLog,setEnLog] = useState([]);
  let [loading, setLoading] = useState(true);
  let [loadingbtn, setloadingbtn] = useState(false);
  const [apiValue, setAPIValue] = useState({lic_blacklist:""});
  const [records,setRecords] = useState([]);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [titleforalert, setTitleforalert] = React.useState("");
  const {getRoleAccessVal} = GlobalRoleAccess();
  const {getRoleAccessInnerVal} = GlobalRoleAccessInner();
  const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });
  const headCells = [
    { id: 'lic_plate', label: 'Blacklisted License Plate' },
    { id: 'note', label: 'Note' },
    { id: 'date', label: 'Date Added'},
    { id: 'access', label: 'Action', disableSorting: true },
  ];
  const {
    TblContainer,
    TblHead,
    TblPagination ,
    BelowTblPagination,
    recordsAfterPagingAndSorting ,
    setPageToNewOne ,
  } = useInnerTable(records, headCells, filterFn,5,650);

  const getDisableValueEdit = () => {
    if(token.role == 'superadmin' || token.name === 'SUPER ADMIN'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("57").administrator_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("57").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'distributor' || token.name === 'SUPER DISTRIBUTOR'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("57").distributor_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("57").distributor_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'dealer' || token.name === 'SUPER DEALER'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("57").dealer_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("57").dealer_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'admin'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("57").admin_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("57").admin_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
    
    
  }
  const getDisableValueAdd = () => {
    if(token.role == 'superadmin' || token.name === 'SUPER ADMIN'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("56").administrator_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("56").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'distributor' || token.name === 'SUPER DISTRIBUTOR'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("56").distributor_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("56").distributor_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'dealer' || token.name === 'SUPER DEALER'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("56").dealer_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("56").dealer_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'admin'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("56").admin_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("56").admin_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
    
    
  }
  const getDisableValueDelete = () => {
    if(token.role == 'superadmin' || token.name === 'SUPER ADMIN'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("58").administrator_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("58").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'distributor' || token.name === 'SUPER DISTRIBUTOR'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("58").distributor_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("58").distributor_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'dealer' || token.name === 'SUPER DEALER'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("58").dealer_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("58").dealer_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'admin'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("58").admin_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("58").admin_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
    
    
  }
  const getMyPostData = async () => {
    try {
      const res = await axios.get("/admin/blacklistlp/"+token.id,{
        headers: { 'Content-Type':  'application/json',
        'token' : token.token,
        'tokenid' : token.email },
      });
      console.log("data format"+res.data);
      setEnLog(res.data);
     // apiValue.lic_blacklist = res.data[0].lic_blacklist;
      setRecords(res.data);
    //  console.log((apiValue.lic_blacklist).split(','));
    //  setAPIValue(apiValue);
      setLoading(false);
      setloadingbtn(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      setloadingbtn(false);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  useEffect(() => {
    getMyPostData();
  }, []);
  
  const updateMyPostData = async (val) => {
    setloadingbtn(true);
    try {
    
      const res = await axios.post("/admin/blacklistlp/"+token.id,val, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      'token' : token.token,
      'tokenid' : token.email }});
      console.log( res.data);
      setOpenSnackData(getAlertTitle('LPBUS'));
      setSeverityData("success");
      setDataOfAdd('');
      setDataOfNote('');
      setOpenSnack(true);
      setLoading(false);
      setshowUpdateButton(false);
      setloadingbtn(false);
      getMyPostData();
    } catch (error) {
      console.log(error);
      setLoading(false);
      setOpenSnackData(getAlertTitle('LPBUF'));
      setSeverityData("error");
      setOpenSnack(true);
      setloadingbtn(false);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };

  const handleChangeBlackLP = (event) => {
    //var index = records.indexOf(event.target.name); 
    let blck = '';
    let anyZero = 0;
    for(let lt = 0; lt < records.length ; lt ++){
      if((records[lt].trim()).length == 0){
        anyZero = 1;
        break;
      }
      else{
        if(lt == records.length -1){
          blck = blck + records[lt] ;
        }else{
        blck = blck + records[lt] + ",";
      }
      }
    }
    if(anyZero == 0){
    apiValue.lic_blacklist = blck;
    setAPIValue(apiValue);
    console.log(blck);
    updateMyPostData();
    }
    else{
      setOpenSnackData(getAlertTitle('LPBBNE'));
      setSeverityData("error");
      setOpenSnack(true);
    }
  }
  const [currentId, setCurrentId] = useState(0);
  const [deleteBLP, setDeleteBLP] = useState('');
  const handleDelete = (event) => {
    console.log(event.target.name);
    setCurrentId(event.target.name);
    let deletid;
    for(let i = 0; i < EnLog.length ; i++){
        if(EnLog[i].id == event.target.name ){
            deletid = i;
            break;
        }
    }
    setDeleteBLP(EnLog[deletid].lic_plate);
    setTitleforalert("Are you sure you want to delete LP Blacklist "+ EnLog[deletid].lic_plate + " ?");
    setOpenDelete(true);


  }
  const handleInputChange=(event) =>{
    //console.log(apiValue);
    //setshowUpdateButton(true);
    //setAPIValue({lic_blacklist:event.target.value});

    var index = event.target.name; 
    records[index] = event.target.value;
    setRecords(records);
    console.log(records);
  }
  
  const [dataOfAdd,setDataOfAdd] = useState('');
  const handleLPBlackChange = (event) => {
    setDataOfAdd(event.target.value);
  }
  const [dataOfNote,setDataOfNote] = useState('');
  const handleLPBlackChangeNote = (event) => {
    setDataOfNote(event.target.value);
  }
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleCloseActiveDelete =() =>{
    setOpenDelete(false);
    setloadingbtn(true);
    deleteMyPostData();
  }
  const deleteMyPostData = async () => {
    try {
      const res = await axios.delete("/admin/deleteblacklistlp/"+token.id+"/"+currentId,
      {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      'token' : token.token,
      'tokenid' : token.email,
      'issub':token.issub,
      'subId':token.subid,
      'license':deleteBLP }});
      
      console.log( 'delete data'+res.data);
      setOpenSnackData(getAlertTitle('LPBDS'));
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      setloadingbtn(false);
      getMyPostData();
    } catch (error) {
      console.log(error);
      setLoading(false);
      setOpenSnackData(getAlertTitle('LPBDF'));
      setSeverityData("error");
      setloadingbtn(false);
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}

    }
  };
  const handleAddBlackLP = () => {
    if((dataOfAdd.trim()).length > 0) {
     /* let blck = '';
    for(let lt = 0; lt < records.length ; lt ++){
      
      blck = blck + records[lt] + ",";
    
    }
    blck = blck + dataOfAdd;
    apiValue.lic_blacklist = blck;
    setAPIValue(apiValue);*/
   // console.log(blck);
    updateMyPostData({lic_plate:dataOfAdd,note:dataOfNote,user_id:token.id,"issub":token.issub,"subId":token.subid});
  }
  else{
    setOpenSnackData(getAlertTitle('LPBBNE'));
    setSeverityData("error");
    setOpenSnack(true);
  }
  }
  return <> <Box sx={mainThemeStyle.boxTopHeader}>
  
</Box>
<Box sx={mainThemeStyle.boxHeaderBelow}>
{/* <div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
              
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Manage ResidentLink LP Blacklist
                   </Typography>
                   
               </div>
              
           </div> */}
           <Box  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px',display: { xs: "none", md: "flex" }}}>
               <div style={{ display: 'flex' }}>
                <Stack direction='row' spacing={2} padding={2}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Add LP Blacklist
                   </Typography>
                   <TextField
        id="text"
        type="text"
      placeholder="LP Blacklist"
      value={dataOfAdd}
      sx={mainThemeStyle.InputMenuItem}
      onChange={handleLPBlackChange}
      disabled = {getDisableValueAdd()}
       variant="filled"
                        InputLabelProps={{ shrink: true }
                      }
                          style={{paddingTop: '16px'}}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(170, 170, 170, 1)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      />
       <TextField
        id="text"
        type="text"
      placeholder="Note"
      value={dataOfNote}
      sx={mainThemeStyle.InputMenuItem}
      onChange={handleLPBlackChangeNote}
       variant="filled"
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValueAdd()}
                          style={{paddingTop: '16px'}}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(170, 170, 170, 1)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      />
      <LoadingButton sx={mainThemeStyle.normalButtonDown} loading={loadingbtn} onClick={handleAddBlackLP} disabled = {getDisableValueAdd()}> Add </LoadingButton>
      </Stack>
               </div>
              
           </Box>

           <Box  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px',display: { xs: "flex", md: "none" }}}>
               <Stack direction='column' spacing={2} padding={2}>
                <Stack direction='row' spacing={2} padding={2}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Add LP Blacklist
                   </Typography>
                   <TextField
        id="text"
        type="text"
        sx={mainThemeStyle.InputMenuItem}
      placeholder="LP Blacklist"
      value={dataOfAdd}
      onChange={handleLPBlackChange}
       variant="filled"
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValueAdd()}
                          style={{paddingTop: '25px'}}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(170, 170, 170, 1)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      /> </Stack><Stack direction='row' spacing={2} >
       <TextField
        id="text"
        type="text"
      placeholder="Note"
      value={dataOfNote}
      sx={mainThemeStyle.InputMenuItem}
      onChange={handleLPBlackChangeNote}
       variant="filled"
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValueAdd()}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(170, 170, 170, 1)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      />
      <LoadingButton sx={{
         width: 'auto',
         height: '2.06rem',
         top:'0rem',
         
         fontWeight: '700',
         fontSize: '0.93rem',
         
         
         alignItems: 'center',
         color: '#FFFFFF',
         fontFamily: "Lato",
         borderRadius:'0.31rem',
        '&:hover': {
         backgroundColor: 'rgba(109, 195, 252, 1)',
        }
      }} loading={loadingbtn} onClick={handleAddBlackLP} disabled = {getDisableValueAdd()}> Add </LoadingButton>
      </Stack>
               </Stack>
              
           </Box>
           <Divider sx={{backgroundColor:'white'}} />
           {/* <Typography sx={mainThemeStyle.popUpWarner} height={30}> Note :- Enter Blacklist LP Separated By Comma ' , '.</Typography> */}
         {/*   <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Blacklist LPs</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> </Typography>
      {showUpdateButton && <Button sx={mainThemeStyle.normalButton} onClick={handleSaveCardFormat}>Save Blacklist LPs</Button>
}</Stack>
      </Grid>
      
      <Grid item xs={3}>
      <Stack direction="column"  spacing={2}>
      
                    <TextField
         
          multiline
          rows={4}
          onChange={handleInputChange}
          value={ apiValue.lic_blacklist}
          variant="filled"
          InputProps={{
                           
            sx: {
                
                color:'rgba(135, 135, 135, 0.5)',
               
                borderRadius: '5px',
                backgroundColor: '#121212',
                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                padding: '5px 0 5px 0',
                textIndent: '15px !important',
              
               
                
            },
        }}
        />
      </Stack>
    
      
      </Grid>
     
     
      
      </Grid> */}
   
           <TblContainer>
                    <TblHead />

        <TableBody>
        {
        recordsAfterPagingAndSorting().map(item => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              
              <TableCell align="left"  sx={mainThemeStyle.cellRow}> {item.lic_plate}</TableCell>
              <TableCell align="left"  sx={mainThemeStyle.cellRow}> {item.note}</TableCell>
              <TableCell align="left"  sx={mainThemeStyle.cellRow}> {item.date}</TableCell>
              
              <TableCell align="left">
                {/* <Stack direction='row' spacing={2}>
                  <Typography></Typography> */}
              {/* <LoadingButton sx={mainThemeStyle.viewLogButton} name={value} loading={loadingbtn} onClick={handleChangeBlackLP}> Save Changes </LoadingButton> */}
                <LoadingButton loading={loadingbtn} name={item.id} sx={mainThemeStyle.viewLogButtonRed} onClick={handleDelete} disabled = {getDisableValueDelete()} >Delete</LoadingButton>
                {/* </Stack> */} </TableCell>
            </TableRow>
          ))}
        </TableBody>
      
    </TblContainer>
    <BelowTblPagination ></BelowTblPagination>




</Box>
<Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          padding: '16px',
      
         backgroundColor:'#00000000',
         
      }}
      >
        <DialogTitle id="alert-dialog-title" sx={{
        
      
         backgroundColor:'#2A2F3B',
         color:'white'
         
      }}>
          {titleforalert}
        </DialogTitle>
        <DialogContent sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          
        </DialogContent>
        <DialogActions sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          <Button onClick={handleCloseDelete} sx={mainThemeStyle.normalBigButton}>Cancel</Button>
          <Button onClick={handleCloseActiveDelete} autoFocus sx={mainThemeStyle.normalBigButtonRed}>
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>
<Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={severityData} sx={{ width: '100%' }}>
          {openSnackData}
        </Alert>
      </Snackbar>
 </> 
          
          
          
          
          ;
};

export default LPBlackList;