import React from "react";
import {  ThemeProvider } from '@mui/material/styles';
import Theme from './components/ItemTheme'
import { Box } from "@mui/system";
import {  Button,Checkbox, Switch,Grid, TextField,InputLabel, List,Divider,Select, MenuItem,FormControlLabel, Typography,ButtonGroup,Snackbar ,Alert ,IconButton, Icon,Dialog,DialogTitle,DialogContent,DialogActions,DialogContentText} from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import  NestedButtonDropDownHeader  from "./components/header/NestedButtonDropDownHeader";
import ManageLocation from "./ManageLocation";
import EditLocation from "./EditLocation";
import AddLocation from "./AddLocation";
import CallLog from "./CallLog";
import AddIcon from '@mui/icons-material/Add';
import LpTags from "./LpTags";
import { useState } from "react";
import GuestPIN from "./GuestPIN";
import GuestParking from "./GuestParking";
import Stack from '@mui/material/Stack';
import { useParams,useNavigate ,useLocation } from "react-router-dom";
import { useEffect } from "react";
import useInnerTable from "./components/useInnerTable";
import PuffLoader from "react-spinners/PuffLoader"; 
import TableBody from '@mui/material/TableBody';
import Popup from "./components/Popup";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import LoadingButton from '@mui/lab/LoadingButton';
import GlobalAlert from "./GlobalAlert";
import useToken from "./useToken";
import axios from "./axios";
const LocationUser = (props) => {
  console.log("IN location Page");
  const navigate = useNavigate();
  const { getAlertTitle} = GlobalAlert();
  const location = useLocation();
  const {id} = useParams();
  console.log('id is '+id);
  const {RefreshToken} = props;
  const {token} = useToken();
  const [openSnackData, setOpenSnackData] = React.useState("This is Success Data");
  const [severityData, setSeverityData] = React.useState("success");
  const [recordsDirectory,setRecordsDirectory] = useState([]);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [titleforalert, setTitleforalert] = React.useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const headCellsDirectory = [
    { id: 'locationname', label: 'Location Name' },
    { id: 'door_pin', label: 'Door PIN', disableSorting: true },
    { id: 'floor', label: 'Floor', disableSorting: true },
    { id: 'dial_code', label: 'Dial Code', disableSorting: true },
    { id: 'hide', label: 'Hide From Directory', disableSorting: true },
    { id: 'action', label: 'Action', disableSorting: true },
    
  ];
  const handleCloseSnack= (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
  const [filterFnDirectory, setFilterFn] = useState({ fn: items => { return items; } });
  let [loading, setLoading] = useState(true);
  const getMyPostData = async () => {
    try {
      const res = await axios.get("/enduser/location/"+token.phone,{
        headers: { 'Content-Type':  'application/json',
        'token' : token.token,
        'tokenid' : token.phone },
      });
      setRecordsDirectory(res.data);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  const {
    TblContainer : TblContainerDirectory,
    TblHead :  TblHeadDirecory,
    TblPagination : TblPaginationDirectory,
    BelowTblPagination : BelowTblPaginationDirectory,
    recordsAfterPagingAndSorting : recordsAfterPagingAndSorting,
    setPageToNewOne : setPageToNewOneDirectory
  } = useInnerTable(recordsDirectory, headCellsDirectory, filterFnDirectory,5,650);
  useEffect(() => {
    getMyPostData();
  }, []);
  const theme = Theme;
  const[dataOfLocation,setDataOfLocation]  = useState('');
  const[addDataOfLocation,setaddDataOfLocation] = useState('');
  const directoryCheckChange = (event) =>{
    let ds = event.target.name;
    let idIn;
    for(let i = 0; i < recordsDirectory.length ; i++){
      if(recordsDirectory[i].id == ds ){
          idIn = i;
          break;
      }
    }
    let hide = event.target.checked ? 1 : 0;
   
   
    recordsDirectory[idIn].hide = hide;

    setRecordsDirectory(recordsDirectory);
    
  }
  const directoryChange = (event) =>{
    let ds = event.target.name;
    let idIn;
    for(let i = 0; i < recordsDirectory.length ; i++){
      if(recordsDirectory[i].id == ds ){
          idIn = i;
          break;
      }
    }
   

    let leg = recordsDirectory[idIn].pin_length;

    if((event.target.value).length > leg ){
      event.target.value = event.target.value.slice(0, leg);
    }

    recordsDirectory[idIn].door_pin = event.target.value;
    setRecordsDirectory(recordsDirectory);
  }
  const floorValChange = (event) =>{
    let ds = event.target.name;
    let idIn;
    for(let i = 0; i < recordsDirectory.length ; i++){
      if(recordsDirectory[i].id == ds ){
          idIn = i;
          break;
      }
    }
   

    let leg = recordsDirectory[idIn].pin_length;

    if((event.target.value).length > 64 ){
      event.target.value = 64;
    }

    recordsDirectory[idIn].floor = event.target.value;
    setRecordsDirectory(recordsDirectory);
  }
  const handleDirectoryDelete = (event) => {
    let ds = event.target.name;
    let idIn;
    for(let i = 0; i < recordsDirectory.length ; i++){
      if(recordsDirectory[i].id == ds ){
          idIn = i;
          break;
      }
    }
    setCurrentId(ds);
   
    setTitleforalert("Are you sure you want to delete location "+ recordsDirectory[idIn].locationname + " ?");
    setOpenDelete(true);
  }
  const [currentId,setCurrentId] = React.useState("");
  const [openDelete, setOpenDelete] = React.useState(false);
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleCloseActiveDelete =() =>{
    setOpenDelete(false);
    deleteMyPostData();
  }
  const deleteMyPostData = async () => {
    try {
      const res = await axios.delete("/enduser/deletelocation/"+currentId, 
      {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8' ,
      'token' : token.token,
      'tokenid' : token.phone}});
      console.log( 'delete data'+res.data);
      setOpenSnackData(getAlertTitle('ULDS'));
      setSeverityData("success");
      setOpenSnack(true);
      //setLoading(false);
      getMyPostData();
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      setOpenSnackData(getAlertTitle('ULDF'));
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  const [currentVal, setCurrenVal] = React.useState("");
  const [showUpdateButton,setshowUpdateButton] = React.useState(false);
  const handleAdd =  () => {
    setCurrenVal("");
    setshowUpdateButton(false);
    setOpenPopup(true);
  }
  const handleChangeEditText = (e) => {
    setCurrenVal(e.target.value);
    setshowUpdateButton(true);
  }

  const addLocationForUser = async() => {
    try {
      const res = await axios.delete("/user/addlocation/"+currentVal, 
      {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8' ,
      'token' : token.token,
      'tokenid' : token.phone}});
      console.log( 'add data'+res.data);
      setOpenSnackData(getAlertTitle('ULAS'));
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      setOpenPopup(false);
      getMyPostData();
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      setOpenSnackData(getAlertTitle('ULAF'));
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  }
  
  const ShowLog = () =>{
   
    return(<Grid container spacing={2}> 
      <Grid item >
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLableAnswer} >Location Code </Typography>
      </Stack>
      </Grid>
      <Grid item >
      <Stack direction="column"  spacing={2}>
    
      <TextField
        id="date"
        type="date"
        defaultValue={currentVal}
        sx={mainThemeStyle.InputFilterPopup}
        onChange={handleChangeEditText}
        InputLabelProps={{
          shrink: true,
        }}
        hiddenLabel
        variant='filled'
        InputProps={{
                           
          sx: {
              
              color: 'rgba(255, 255, 255, 1)',
              height:'33px',
              borderRadius: '5px',
              backgroundColor: '#171E29',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '5px 0 5px 0',
              textIndent: '15px !important',
              border: `1px solid rgba(31, 39, 54, 1)`,
             
              
          },
      }}
      />
      </Stack>
      </Grid>
      
    { showUpdateButton &&  <Button sx={mainThemeStyle.saveButton} onClick={addLocationForUser}>Add</Button>}
      
      </Grid>)
  }
  const updateMyDirectoryPostData = async (val) => {
    console.log(val);
    try {
      const res = await axios.patch("/enduser/updateuserlocation/"+token.phone,val, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      'token' : token.token,
      'tokenid' : token.phone }});
      console.log( res.data);
      setOpenSnackData(res.data.rows);
      setSeverityData("success");
      setOpenSnack(true);
      //setLoading(false);
      getMyPostData();
    } catch (error) {
      console.log(error.response.data);
      setLoading(false);
      setOpenSnackData(getAlertTitle('UDUF'));
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  const handleDirectorySave = (event) => {
    let ds = event.target.name;
    let idIn;
    for(let i = 0; i < recordsDirectory.length ; i++){
      if(recordsDirectory[i].id == ds ){
          idIn = i;
          break;
      }
    }
    
        let val = recordsDirectory[idIn].pin_length;
        if((recordsDirectory[idIn].door_pin).length == parseInt(val)){
          setLoading(true);
          updateMyDirectoryPostData(recordsDirectory[idIn]);
        }
        else{
          if((recordsDirectory[idIn].door_pin).length == 0){
            setLoading(true);
            updateMyDirectoryPostData(recordsDirectory[idIn]);
          }
          else{
            if(val == 6){
              setOpenSnackData(getAlertTitle('ULLDP6NV') );
            }else{
              setOpenSnackData(getAlertTitle('ULLDP4NV'));
            }
           
            setSeverityData("error");
            setOpenSnack(true);
          }
        }
    
   
  }
  
  return (
<ThemeProvider theme={theme}>

    <Box sx={mainThemeStyle.mainbox} backgroundColor="menubarprimary.main">
    <Box sx={mainThemeStyle.box}>
     <Typography sx={mainThemeStyle.lable}>Location</Typography>
    </Box>
    
   {/*  <Button startIcon={<AddIcon />} sx={mainThemeStyle.activityButton} onClick={handleAdd} >Add Location</Button> */}
    <Box sx={mainThemeStyle.boxTopHeader}>
    
  
            
</Box>



 <Box sx={mainThemeStyle.boxHeaderBelow}>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       User Locations
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           <TblContainerDirectory>
                    <TblHeadDirecory />

        <TableBody>
         {recordsAfterPagingAndSorting().map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell align="left" sx={mainThemeStyle.cellRow}>
              {row.locationname}
              </TableCell>
              <TableCell align="left" sx={mainThemeStyle.cellRow}> 
           {(row.cam_brand == "ResidentLink" || row.cam_brand == "Virtual Panel") &&   <TextField
        id="text"
        type="number"
        
        name={row.id}
        defaultValue =  {row.door_pin}
        onChange = {directoryChange}
        
          variant = "filled"
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: {max: row.pin_length },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      />} 
      {(row.cam_brand != "ResidentLink"  && row.cam_brand != "Virtual Panel") &&  "N/A"
                       
                      } </TableCell><TableCell align="left" sx={mainThemeStyle.cellRow}> 
      {(row.cam_brand == "ResidentLink" || row.cam_brand == "Virtual Panel") &&   <TextField
        id="text"
        type="number"
        
        name={row.id}
        defaultValue =  {row.floor}
        onChange = {floorValChange}
        
          variant = "filled"
                        InputLabelProps={{ shrink: true }
                      }
                          
                        
                      InputProps={{
                        inputProps: {max: 64 },
                          sx: {
                              
                              color:'rgba(135, 135, 135, 0.5)',
                              height:'33px',
                              borderRadius: '5px',
                              backgroundColor: '#121212',
                              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                              padding: '0 0 15px 0',
                              textIndent: '15px !important',
                            
                             
                              
                          },
                      }}
                       
      />} 
      {(row.cam_brand != "ResidentLink"  && row.cam_brand != "Virtual Panel") &&  "N/A"
                       
      } 
      </TableCell>
      <TableCell align="left" sx={mainThemeStyle.cellRow}> {row.dial_code}</TableCell>
              <TableCell align="left" sx={mainThemeStyle.cellRow}>
              {(row.cam_brand == "ResidentLink" || row.cam_brand == "Virtual Panel")  && <Checkbox name={row.id} sx={mainThemeStyle.formCheckBox} onChange = {directoryCheckChange} defaultChecked={row.hide == 1 ? true : false}/>}
                
              {(row.cam_brand != "ResidentLink"  && row.cam_brand != "Virtual Panel") &&  "N/A"} 
              </TableCell>
              <TableCell align="left">
                <Stack direction="row" spacing={2}>
                  <Typography></Typography>
                  {(row.cam_brand == "ResidentLink" || row.cam_brand == "Virtual Panel") && <LoadingButton loading={loading} name={row.id} sx={mainThemeStyle.viewLogButton} onClick={handleDirectorySave} >Save Changes</LoadingButton>}
                <LoadingButton loading={loading} name={row.id}  sx={mainThemeStyle.viewLogButtonRed} onClick={handleDirectoryDelete} >Delete</LoadingButton>
                </Stack>
                </TableCell>
            </TableRow>
          ))}
        </TableBody>
      
    </TblContainerDirectory>
    <BelowTblPaginationDirectory ></BelowTblPaginationDirectory>

</Box>
<Popup
                title="Add Location"
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
            >
             
      <ShowLog/>
               
            </Popup>
<Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={severityData} sx={{ width: '100%' }}>
          {openSnackData}
        </Alert>
      </Snackbar>
<Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          padding: '16px',
      
         backgroundColor:'#00000000',
         
      }}
      >
        <DialogTitle id="alert-dialog-title" sx={{
        
      
         backgroundColor:'#2A2F3B',
         color:'white'
         
      }}>
          {titleforalert}
        </DialogTitle>
        <DialogContent sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          
        </DialogContent>
        <DialogActions sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          <Button onClick={handleCloseDelete} sx={mainThemeStyle.normalBigButton}>Cancel</Button>
          <Button onClick={handleCloseActiveDelete} autoFocus sx={mainThemeStyle.normalBigButtonRed}>
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>

    
    
    </Box>
   
</ThemeProvider>);
};

export default LocationUser;