import React from "react";
import {  ThemeProvider } from '@mui/material/styles';
import Theme from './components/ItemTheme'
import { Box } from "@mui/system";
import { Button, Grid, List, Typography } from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import  NestedButtonDropDownHeader  from "./components/header/NestedButtonDropDownHeader";
import ManageLocation from "./ManageLocation";
import CallLog from "./CallLog";
import AddIcon from '@mui/icons-material/Add';
import LpTags from "./LpTags";
import { useState } from "react";
import ManageUsers from "./ManageUsers";
import AddDistributor from "./AddDistributor";
import ManageDistributor from "./ManageDistributor";
import EditDistributor from "./EditDistributor";
import DistributorManager from "./DistributorManager";
import DistributorLogs from "./DistributorLogs";
import DistributorPassword from "./DistributorPassword";
import { useParams,useNavigate,useLocation  } from "react-router-dom";
import { useEffect } from "react";


const DistributorSetUp = (props) => {
  console.log("IN location Page");
  const navigate = useNavigate();
  const {id} = useParams();
  const {RefreshToken} = props;
  console.log('id is '+id);
  const theme = Theme;
  const location = useLocation();
  useEffect(()=>{
   
  },[id]);
 
  
 const handleAdd = () =>{
  navigate('../setup/manager', { replace: true });
 }
 const handleEdit = () =>{
  navigate('../setup/password', { replace: true });
}


  return (
<ThemeProvider theme={theme}>

    <Box sx={mainThemeStyle.mainbox} backgroundColor="menubarprimary.main">
    <Box sx={mainThemeStyle.box}>
    {(id === 'manager' &&  <Typography sx={mainThemeStyle.lable}>Distributor Manager</Typography>)}
   {/*  {(id === 'password' &&  <Typography sx={mainThemeStyle.lable}>Password</Typography>)} */}
   {(id === 'syslogs' &&  <Typography sx={mainThemeStyle.lable}>System Logs</Typography>)}
    </Box>
    
   {/*   {(id === 'manager' && <Button sx={mainThemeStyle.activityButton} onClick={handleEdit} >Password</Button>)}
   {(id === 'password' &&  <Button sx={mainThemeStyle.activityButton} onClick={handleAdd} >Distributor Manager</Button>)}
     */}
    {(id === 'manager' && <DistributorManager RefreshToken={RefreshToken}/>)}
   {/*  {(id === 'password' && <DistributorPassword RefreshToken={RefreshToken}/>)} */}
   {(id === 'syslogs' && <DistributorLogs RefreshToken={RefreshToken}/>)}
    </Box>
   


   
  
</ThemeProvider>);
};

export default DistributorSetUp;